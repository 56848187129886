import React from "react";
import compose from "recompose/compose";
import defaultProps from "recompose/defaultProps";
import withState from "recompose/withState";
import withHandlers from "recompose/withHandlers";

import CanvasHoverMap from "./Map/CanvasHoverMap";
import HoveredTooltipMarker from "./Markers/HoveredTooltipMarker";
// import mapStyles from "./map.sass";
import { autoGenKey } from "../../../../lib/helper";

export const map = ({
  bootstrapURLKeys,
  yesIWantToUseGoogleMapApiInternals,
  options,
  defaultCenter,
  onGoogleApiLoaded,
  style,
  markerHoverDistance,
  markers,
  renderMarkers,
  renderMarker,
  mapParams: { zoom, center },
  onMapParamsChange,
  children
}) => (
  <div>
    <CanvasHoverMap
      bootstrapURLKeys={bootstrapURLKeys}
      yesIWantToUseGoogleMapApiInternals={yesIWantToUseGoogleMapApiInternals}
      onGoogleApiLoaded={onGoogleApiLoaded}
      defaultCenter={defaultCenter}
      // flex: 1 here
      style={style}
      // google map options https://developers.google.com/maps/documentation/javascript/controls#ControlOptions
      options={options}
      // see CanvasMap onMouseMove, distance at which algorithm decides that marker is hovered
      markerHoverDistance={markerHoverDistance}
      // google-map-react props
      // center={center}
      zoom={zoom}
      onChange={onMapParamsChange}
      // canvas markers, and render functions
      markers={markers}
      // render markers at canvas
      renderMarkers={renderMarkers}
      // render hovered marker as is
      renderMarker={renderMarker}
      // to force redraw just pass a new empty object to refresh for example
      // refresh={selected}
      children={children}
    />
  </div>
);

export const mapHOC = compose(
  // themr("map", mapStyles),
  defaultProps({
    options: {
      scrollwheel: true,
      zoomControl: true,
      zoomControlOptions: {
        position: 1 // google.maps.ControlPosition.LEFT_TOP
      },
      minZoom: 3,
      zoom: 10,
      maxZoom: 18,
      disableDoubleClickZoom: true
    },
    style: {
      flex: 1
    },
    hoverDistance: 15,
    markerHoverDistance: 15
  }),
  withState("mapParams", "setMapParams", {
    center: {
      lat: 13.729852,
      lng: 100.559484
    },
    zoom: 6
  }),
  withHandlers({
    onMapParamsChange: ({ setMapParams }) => ({ center, zoom, bounds }) => {
      setMapParams({ center, zoom, bounds });
    },
    renderMarker: ({ theme }) => marker => (
      <HoveredTooltipMarker
        /* key is needed to play initial anim in some cases */
        key={autoGenKey(`replay-gps-point-${marker.id}`)}
        // themeNamespace={"tooltipMarker"}
        // active={fa}
        initialScale={2}
        defaultScale={2}
        hoveredScale={2.3}
        tooltipContent={<div></div>}
        paddingOffset={60} // used for tooltip position
        tooltipContentHeight={10} // no need to be exact, used for tooltip position
        tooltipContentWidth={100} // no need to be exact, used for tooltip position
        {...marker}
      />
    ),
    // be sure in current implementation markers is tile markers, not all markers.
    // tiling is used as it allows some perf optimizations not used here
    renderMarkers: () => ({ ctx, markers, tileSize }) => {
      ctx.clearRect(0, 0, tileSize, tileSize);

      const radius = 2;
      markers.forEach(({ /* id, */ x, y, image, ...rest }) => {
        // just circles here but can be images, use id or other marker props to render
        // console.log(rest);

        // if (image === "Snapshot") {
        //   let img = new Image();

        //   img.addEventListener(
        //     "load",
        //     function() {
        //       ctx.drawImage(img, x - 9.8, y - 30);
        //     },
        //     false
        //   );

        //   img.src = "/img/map/status_icon/20_20/camera20.png";
        // }

        if (rest.speedOverLimit === "Speed over") {
          let img = new Image();

          img.addEventListener(
            "load",
            function() {
              ctx.drawImage(img, x - 9.8, y - 30);
            },
            false
          );

          img.src = "/img/map/status_icon/20_20/speed20.png";
        }

        if (rest.isfixed == 1) {
          if (rest.engineOn == "Engine on") {
            if (image === "Snapshot") {
              ctx.fillStyle = "#717171";
              ctx.fillRect(x - 5, y - 5, 12, 12);

              ctx.fillStyle = "white";
              ctx.beginPath();
              ctx.arc(x + 0.9, y + 0.8, radius + 2, 0, Math.PI * 2, true);
              ctx.closePath();
              ctx.fill();

              ctx.fillStyle = "#06C900";
              ctx.beginPath();
              ctx.arc(x + 0.9, y + 0.8, radius + 0.7, 0, Math.PI * 2, true);
              ctx.closePath();
              ctx.fill();
            } else {
              ctx.fillStyle = "#2E9AFE";
              ctx.beginPath();
              ctx.arc(x, y, radius + 3.5, 0, Math.PI * 2, true);
              ctx.closePath();
              ctx.fill();

              ctx.fillStyle = "white";
              ctx.beginPath();
              ctx.arc(x, y, radius + 2, 0, Math.PI * 2, true);
              ctx.closePath();
              ctx.fill();

              ctx.fillStyle = "#06C900";
              ctx.beginPath();
              ctx.arc(x, y, radius + 0.7, 0, Math.PI * 2, true);
              ctx.closePath();
              ctx.fill();
            }
          }

          if (rest.engineOff == "Engine off") {
            if (image === "Snapshot") {
              ctx.fillStyle = "#2E9AFE";
              ctx.fillRect(x - 5, y - 5, 12, 12);

              ctx.fillStyle = "white";
              ctx.beginPath();
              ctx.arc(x + 0.9, y + 0.8, radius + 2, 0, Math.PI * 2, true);
              ctx.closePath();
              ctx.fill();

              ctx.fillStyle = "#dc3545";
              ctx.beginPath();
              ctx.arc(x + 0.9, y + 0.8, radius + 0.7, 0, Math.PI * 2, true);
              ctx.closePath();
              ctx.fill();
            } else {
              ctx.fillStyle = "#2E9AFE";
              ctx.beginPath();
              ctx.arc(x, y, radius + 3.5, 0, Math.PI * 2, true);
              ctx.closePath();
              ctx.fill();

              ctx.fillStyle = "white";
              ctx.beginPath();
              ctx.arc(x, y, radius + 2, 0, Math.PI * 2, true);
              ctx.closePath();
              ctx.fill();

              ctx.fillStyle = "#dc3545";
              ctx.beginPath();
              ctx.arc(x, y, radius + 0.7, 0, Math.PI * 2, true);
              ctx.closePath();
              ctx.fill();
            }
          }
        } else {
          ctx.fillStyle = "#6F6F6F";
          ctx.beginPath();
          ctx.arc(x, y, radius + 3.5, 0, Math.PI * 2, true);
          ctx.closePath();
          ctx.fill();

          // ctx.fillStyle = "white";
          // ctx.beginPath();
          // ctx.arc(x, y, radius + 2, 0, Math.PI * 2, true);
          // ctx.closePath();
          // ctx.fill();

          // ctx.fillStyle = "#06C900";
          // ctx.beginPath();
          // ctx.arc(x, y, radius + 0.7, 0, Math.PI * 2, true);
          // ctx.closePath();
          // ctx.fill();
        }
      });
    }
  })
);

export default mapHOC(map);
