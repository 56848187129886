import {
  LOAD_FRIMWARE,
  CREATE_FRIMWARE,
  LOAD_FRIMWARE_DETAIL,
  UPDATE_FRIMWARE_DETAIL,
  REMOVE_LISTS,
} from "./actions";

const initialState = {
  lists: [],
  total: 1,
  listsDetail: [],
  loading: false,
  frimwareLoading: false,
  check: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_LISTS:
      return initialState;

    case LOAD_FRIMWARE.REQUEST:
      const { loading } = action.payload.data;
      return {
        ...state,
        loading: loading,
      };

    case LOAD_FRIMWARE.SUCCESS:
      const { page } = action.payload;

      for (let i = 0; i < action.payload.lists.data.length; i++) {
        action.payload.lists.data[i].rowNumber = i + 1;
        if (page > 1)
          action.payload.lists.data[i].rowNumber =
            action.payload.lists.data[i].rowNumber + (page - 1) * 10;
      }

      return {
        ...state,
        lists: action.payload.lists.data,
        total:
          action.payload.lists.total - 1 <= 0
            ? 1
            : action.payload.lists.total <= 0
            ? 1
            : action.payload.lists.total,
        loading: false,
        draw: Math.floor(Math.random() * 999 + 1),
      };

    case CREATE_FRIMWARE.REQUEST:
      const { createFrimwareloading } = action.payload.data;
      return {
        ...state,
        frimwareLoading: createFrimwareloading,
      };

    case CREATE_FRIMWARE.SUCCESS:
      return {
        ...state,
        frimwareLoading: false,
      };

    case LOAD_FRIMWARE_DETAIL.REQUEST:
      return {
        ...state,
        frimwareLoading: action.payload.data.frimwareLoading,
        check: true,
      };

    case LOAD_FRIMWARE_DETAIL.SUCCESS:
      return {
        ...state,
        listsDetail: action.payload.lists.data,
        frimwareLoading: false,
        check: false,
      };

    case UPDATE_FRIMWARE_DETAIL.REQUEST:
      return {
        ...state,
        frimwareLoading: true,
        check: true,
      };

    case UPDATE_FRIMWARE_DETAIL.SUCCESS:
      return {
        ...state,
        frimwareLoading: false,
        check: false,
      };
    default:
      return state;
  }
};
