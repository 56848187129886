import React, { useState, useEffect } from "react";
import {
  Button,
  message,
  Col,
  Form,
  Select,
  Divider,
  Input,
  TimePicker,
  Checkbox,
} from "antd";
import LangContext from "modules/shared/context/langContext";
import moment from "moment";
import { Row } from "antd/es/grid";
import {
  positionList,
  hours,
  availableOptions,
} from "../../../constants/template-policy";
import { getAudioList } from "../../../fetchAPI/apiClient";
import ControlledAudio from "./ControlledAudio";
import { Formik, FieldArray, Field, ErrorMessage, move } from "formik";
import { resetFormHelper } from "../Helper/Helper";
const { Item } = Form;
const Option = Select.Option;
const format = "HH:mm:ss";

const SpeedLimit = ({
  setFieldValue,
  values,
  touched,
  errors,
  subPolicyType,
  current,
  steps,
  prev,
  companyId,
  type,
  setIsDataType,
}) => {
  const [audioList, setAudioList] = useState([]);
  // const [audioKeyRed, setAudioKeyRed] = useState(0); // State to force re-render of audio tag
  const [currentAudioSrcRed, setCurrentAudioSrcRed] = useState("");
  const [
    warningAudioForParkingTimeComplete,
    setWarningAudioForParkingTimeComplete,
  ] = useState("");
  const [
    warningAudioForNotStoppingInTime,
    setWarningAudioForNotStoppingInTime,
  ] = useState("");

  // const [audioKeyYellow, setAudioKeyYellow] = useState(0); // State to force re-render of audio tag
  useEffect(() => {
    prepairAudio();
  }, []);

  useEffect(() => {
    // Find the selected audio item based on ringToneRed value
    if (values.warningAudioForParkingTimeComplete && audioList.length > 0) {
      const selectedAudio = audioList.find(
        (item) => item.id === values.warningAudioForParkingTimeComplete
      );
      if (selectedAudio) {
        setWarningAudioForParkingTimeComplete(selectedAudio.link_file);
        // Increment key to force re-render of audio tag
        // setAudioKeyRed((prevKey) => prevKey + 1);
      }
    }
    if (values.warningAudioForParkingTimeComplete == "") {
      setWarningAudioForParkingTimeComplete("");
    }
  }, [values.warningAudioForParkingTimeComplete, audioList]);

  useEffect(() => {
    // Find the selected audio item based on ringToneRed value
    if (values.warningAudioForNotStoppingInTime && audioList.length > 0) {
      const selectedAudio = audioList.find(
        (item) => item.id === values.warningAudioForNotStoppingInTime
      );
      if (selectedAudio) {
        setWarningAudioForNotStoppingInTime(selectedAudio.link_file);
        // Increment key to force re-render of audio tag
        // setAudioKeyRed((prevKey) => prevKey + 1);
      }
    }
    if (values.warningAudioForNotStoppingInTime == "") {
      setWarningAudioForNotStoppingInTime("");
    }
  }, [values.warningAudioForNotStoppingInTime, audioList]);

  useEffect(() => {
    // Find the selected audio item based on ringToneRed value
    if (values.ringToneRed && audioList.length > 0) {
      const selectedAudio = audioList.find(
        (item) => item.id === values.ringToneRed
      );
      if (selectedAudio) {
        setCurrentAudioSrcRed(selectedAudio.link_file);
        // Increment key to force re-render of audio tag
        // setAudioKeyRed((prevKey) => prevKey + 1);
      }
    }
    if (values.ringToneRed == "") {
      setCurrentAudioSrcRed("");
    }
  }, [values.ringToneRed, audioList]);

  // useEffect(() => {
  //   if (values.ringToneYellowVCD && audioList.length > 0) {
  //     setAudioKeyYellow((prevKey) => prevKey + 1);
  //   }
  // }, [values.ringToneYellowVCD, audioList]);

  const prepairAudio = () => {
    getAudioList(
      {
        company_id: companyId,
      },
      (status, response) => {
        if (response.status == 200) {
          setAudioList(response.data);
        }
      }
    );
  };

  return (
    <>
      <Item
        label={
          <LangContext.Consumer>
            {(i18n) => i18n.d.dataType}
          </LangContext.Consumer>
        }
        required={true}
        validateStatus={touched.dataType && errors.dataType && "error"}
        help={touched.dataType && errors.dataType}
      >
        <Select
          style={{ width: "100%" }}
          placeholder="กรุณาเลือก"
          optionFilterProp="children"
          onChange={(e) => {
            setIsDataType(e);
            setFieldValue("dataType", e);
            if (["A_NO_PARKING_AREA", "A_RISK_AREA"].includes(e)) {
              setFieldValue("stop_point", "INSIDE");
            }
            resetFormHelper(setFieldValue);
          }}
          name="dataType"
          value={values.dataType}
          disabled={type === "edit"}
        >
          {subPolicyType.map((item) => (
            <Option key={item.id} value={item.id}>
              <LangContext.Consumer>
                {(i18n) => i18n.policy[item.name]}
              </LangContext.Consumer>
            </Option>
          ))}
        </Select>
      </Item>

      <Item>
        <label style={{ fontSize: 20, fontWeight: "bold" }}>เงื่อนไข</label>
        <Divider style={{ margin: "10px 0px", width: "100%" }} />
      </Item>

      {[
        "AS",
        "AI",
        "APON",
        "AFUA",
        "A_WDLR",
        "A_NO_PARKING_AREA",
        "A_RISK_AREA",
      ].includes(values.dataType) && (
          <>
            <Item
              label={
                <>
                  <LangContext.Consumer>
                    {(i18n) => i18n.p.position}
                  </LangContext.Consumer>
                  <a
                    onClick={() => {
                      window.open("/location", "_blank");
                    }}
                  >
                    {" "}
                    สถานที่
                  </a>
                </>
              }
              validateStatus={touched.stop_point && errors.stop_point && "error"}
              help={touched.stop_point && errors.stop_point}
            >
              <Select
                style={{ width: "100%" }}
                placeholder="กรุณาเลือก"
                optionFilterProp="children"
                onChange={(e) => {
                  if (e === "-") {
                    setFieldValue("typeLocation", []);
                  }
                  setFieldValue("stop_point", e);
                }}
                name="stop_point"
                value={values.stop_point}
                disabled={["A_NO_PARKING_AREA", "A_RISK_AREA"].includes(
                  values.dataType
                )}
              >
                {positionList.map((item) => (
                  <Option key={item.value} value={item.value}>
                    <LangContext.Consumer>
                      {(i18n) => i18n.policy[item.name]}
                    </LangContext.Consumer>
                  </Option>
                ))}
              </Select>
            </Item>

            {values.stop_point !== "-" && (
              <Item
                label={
                  <LangContext.Consumer>
                    {(i18n) => i18n.t.typeLocation}
                  </LangContext.Consumer>
                }
                validateStatus={
                  touched.typeLocation && errors.typeLocation && "error"
                }
                help={touched.typeLocation && errors.typeLocation}
              >
                <Button
                  type="link"
                  onClick={() => {
                    const newTypeLocation =
                      values.typeLocation.length < 6
                        ? [
                          "Plant",
                          "Site",
                          "ParkingLot",
                          "RestArea",
                          "GasStation",
                          "CarService",
                        ]
                        : [];
                    setFieldValue("typeLocation", newTypeLocation);
                  }}
                  disabled={values.stop_point === "-"}
                >
                  {values.typeLocation.length < 6 ? "เลือกทั้งหมด" : "ไม่เลือก"}
                </Button>

                <Checkbox.Group
                  options={[
                    { label: "จุดโหลดของ", value: "Plant" },
                    { label: "จุดส่งของ", value: "Site" },
                    { label: "ลานจอดรถ", value: "ParkingLot" },
                    { label: "จุดพักรถ", value: "RestArea" },
                    { label: "ปั๊มน้ำมัน", value: "GasStation" },
                    { label: "ศูนย์บริการ", value: "CarService" },
                    { label: "จุดเสี่ยง", value: "RaskPoint" },
                    { label: "จุดห้ามจอด", value: "NoParking" },
                  ]}
                  value={values.typeLocation}
                  onChange={(checkedValues) => {
                    setFieldValue("typeLocation", checkedValues);
                  }}
                  disabled={values.stop_point === "-"}
                />
              </Item>
            )}

            <Row gutter={[8, 8]}>
              <Col span={16}>
                <Item
                  label={
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.duration}
                    </LangContext.Consumer>
                  }
                  validateStatus={
                    touched.stop_point && errors.stop_point && "error"
                  }
                  help={touched.stop_point && errors.stop_point}
                >
                  <Input
                    onChange={(e) => {
                      const { value } = e.target;
                      const reg = /^-?[0-9]*(\.[0-9]*)?$/;
                      if ((!isNaN(value) && reg.test(value)) || value === "") {
                        setFieldValue("day_duration", value);
                      }
                    }}
                    value={values.day_duration}
                    maxLength={25}
                    style={{ width: "95%" }}
                  />{" "}
                  วัน
                </Item>
              </Col>
              <Col span={8}>
                <Item
                  label={
                    <LangContext.Consumer>
                      {(i18n) => i18n.p.policyPeriodFormat}
                    </LangContext.Consumer>
                  }
                  validateStatus={touched.period && errors.period && "error"}
                  help={touched.period && errors.period}
                >
                  <TimePicker
                    style={{ width: "100%" }}
                    value={moment(values.timepicker, format)}
                    format={format}
                    onChange={(time, timeString) => {
                      setFieldValue("timepicker", timeString);
                    }}
                    allowClear={false}
                  />
                </Item>
              </Col>
            </Row>
          </>
        )}

      {/* {["AS"].includes(values.dataType) && (
        <>
          <Item
            label={
              <LangContext.Consumer>
                {(i18n) => i18n.p.position}
              </LangContext.Consumer>
            }
            validateStatus={touched.stop_point && errors.stop_point && "error"}
            help={touched.stop_point && errors.stop_point}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="กรุณาเลือก"
              optionFilterProp="children"
              onChange={(e) => {
                if (e === "-") {
                  setFieldValue("typeLocation", []);
                }
                setFieldValue("stop_point", e);
              }}
              name="stop_point"
              value={values.stop_point}
              disabled={["A_NO_PARKING_AREA", "A_RISK_AREA"].includes(
                values.dataType
              )}
            >
              {positionList.map((item) => (
                <Option key={item.value} value={item.value}>
                  <LangContext.Consumer>
                    {(i18n) => i18n.policy[item.name]}
                  </LangContext.Consumer>
                </Option>
              ))}
            </Select>
          </Item>

          {values.stop_point !== "-" && (
            <Item
              label={
                <LangContext.Consumer>
                  {(i18n) => i18n.t.typeLocation}
                </LangContext.Consumer>
              }
              validateStatus={
                touched.typeLocation && errors.typeLocation && "error"
              }
              help={touched.typeLocation && errors.typeLocation}
            >
              <Button
                type="link"
                onClick={() => {
                  const newTypeLocation =
                    values.typeLocation.length < 6
                      ? [
                          "Plant",
                          "Site",
                          "ParkingLot",
                          "RestArea",
                          "GasStation",
                          "CarService",
                          "RaskPoint",
                          "NoParking",
                        ]
                      : [];
                  setFieldValue("typeLocation", newTypeLocation);
                }}
                disabled={values.stop_point === "-"}
              >
                {values.typeLocation.length < 6 ? "เลือกทั้งหมด" : "ไม่เลือก"}
              </Button>

              <Checkbox.Group
                options={[
                  { label: "จุดโหลดของ", value: "Plant" },
                  { label: "จุดส่งของ", value: "Site" },
                  { label: "ลานจอดรถ", value: "ParkingLot" },
                  { label: "จุดพักรถ", value: "RestArea" },
                  { label: "ปั๊มน้ำมัน", value: "GasStation" },
                  { label: "ศูนย์บริการ", value: "CarService" },
                  { label: "จุดเสี่ยง", value: "RaskPoint" },
                  { label: "จุดห้ามจอด", value: "NoParking" },
                ]}
                value={values.typeLocation}
                onChange={(checkedValues) => {
                  setFieldValue("typeLocation", checkedValues);
                }}
                disabled={values.stop_point === "-"}
              />
            </Item>
          )}

          <Row gutter={[8, 8]}>
            <Col span={8}>
              <Item
                label={
                  <>
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.duration}
                    </LangContext.Consumer>
                    {"("}
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.day}
                    </LangContext.Consumer>
                    {")"}
                  </>
                }
                validateStatus={
                  touched.stop_point && errors.stop_point && "error"
                }
                help={touched.stop_point && errors.stop_point}
              >
                <Input
                  className={"policy-new-alarm-input"}
                  onChange={(e) => {
                    const { value } = e.target;
                    const reg = /^-?[0-9]*(\.[0-9]*)?$/;
                    if ((!isNaN(value) && reg.test(value)) || value === "") {
                      setFieldValue("day_duration", value);
                    }
                  }}
                  value={values.day_duration}
                  maxLength={25}
                  style={{ width: "95%" }}
                />
              </Item>
            </Col>
            <Col span={8}>
              <Item
                label={
                  <LangContext.Consumer>
                    {(i18n) => i18n.p.policyPeriodFormat}
                  </LangContext.Consumer>
                }
                validateStatus={touched.period && errors.period && "error"}
                help={touched.period && errors.period}
              >
                <TimePicker
                  style={{ width: "100%" }}
                  value={moment(values.timepicker, format)}
                  format={format}
                  onChange={(time, timeString) => {
                    setFieldValue("timepicker", timeString);
                  }}
                  allowClear={false}
                />
              </Item>
            </Col>
            <Col span={8}>
              <Item
                label={
                  <LangContext.Consumer>
                    {(i18n) => i18n.d.distanceNotice}
                  </LangContext.Consumer>
                }
                validateStatus={
                  touched.INTERVAL_WARNING && errors.INTERVAL_WARNING && "error"
                }
                help={touched.INTERVAL_WARNING && errors.INTERVAL_WARNING}
              >
                <Select
                  value={values.INTERVAL_WARNING}
                  onChange={(e) => {
                    setFieldValue("INTERVAL_WARNING", e);
                  }}
                >
                  <Option value="1">30 วินาที</Option>
                  <Option value="2">60 วินาที</Option>
                  <Option value="3">120 วินาที</Option>
                </Select>
              </Item>
            </Col>
            <Col span={12}>
              <Item
                label={
                  <LangContext.Consumer>
                    {(i18n) => i18n.r.ringTone}
                  </LangContext.Consumer>
                }
                validateStatus={
                  touched.ringToneRed && errors.ringToneRed && "error"
                }
                help={touched.ringToneRed && errors.ringToneRed}
              >
                <Select
                  value={values.ringToneRed}
                  onChange={(e) => {
                    setFieldValue("ringToneRed", e);
                  }}
                >
                  <Option key={""} value={""}>
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.disableNotification}
                    </LangContext.Consumer>
                  </Option>
                  {audioList &&
                    audioList.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                </Select>
              </Item>
            </Col>
            <Col span={12}>
              <Item
                label={
                  <LangContext.Consumer>
                    {(i18n) => i18n.s.soundPreview}
                  </LangContext.Consumer>
                }
              >
                <ControlledAudio
                  src={currentAudioSrcRed}
                  style={{
                    height: 37,
                    paddingTop: 0,
                    width: "100%",
                    borderRadius: 20,
                  }}
                />
              </Item>
            </Col>
            <Col span={8}>
              <Item
                label={
                  <>
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.duration}
                    </LangContext.Consumer>
                    {"("}
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.day}
                    </LangContext.Consumer>
                    {")"}
                  </>
                }
                validateStatus={
                  touched.stop_point && errors.stop_point && "error"
                }
                help={touched.stop_point && errors.stop_point}
              >
                <Input
                  className={"policy-new-alert-input"}
                  onChange={(e) => {
                    const { value } = e.target;
                    const reg = /^-?[0-9]*(\.[0-9]*)?$/;
                    if ((!isNaN(value) && reg.test(value)) || value === "") {
                      setFieldValue("day_duration_red", value);
                    }
                  }}
                  value={values.day_duration_red}
                  maxLength={25}
                  style={{ width: "95%" }}
                />
              </Item>
            </Col>
            <Col span={8}>
              <Item
                label={
                  <LangContext.Consumer>
                    {(i18n) => i18n.p.policyPeriodFormat}
                  </LangContext.Consumer>
                }
                validateStatus={touched.period && errors.period && "error"}
                help={touched.period && errors.period}
              >
                <TimePicker
                  style={{ width: "100%" }}
                  value={moment(values.timepicker_red, format)}
                  format={format}
                  onChange={(time, timeString) => {
                    setFieldValue("timepicker_red", timeString);
                  }}
                  allowClear={false}
                />
              </Item>
            </Col>
            <Col span={8}>
              <Item
                label={
                  <LangContext.Consumer>
                    {(i18n) => i18n.d.distanceNotice}
                  </LangContext.Consumer>
                }
                validateStatus={
                  touched.INTERVAL_POLICY && errors.INTERVAL_POLICY && "error"
                }
                help={touched.INTERVAL_POLICY && errors.INTERVAL_POLICY}
              >
                <Select
                  value={values.INTERVAL_POLICY}
                  onChange={(e) => {
                    setFieldValue("INTERVAL_POLICY", e);
                  }}
                >
                  <Option value="1">10 วินาที</Option>
                  <Option value="2">30 วินาที</Option>
                  <Option value="3">60 วินาที</Option>
                </Select>
              </Item>
            </Col>
            <Col span={12}>
              <Item
                label={
                  <LangContext.Consumer>
                    {(i18n) => i18n.w.warningAudioForParkingTimeComplete}
                  </LangContext.Consumer>
                }
                validateStatus={
                  touched.warningAudioForParkingTimeComplete &&
                  errors.warningAudioForParkingTimeComplete &&
                  "error"
                }
                help={
                  touched.warningAudioForParkingTimeComplete &&
                  errors.warningAudioForParkingTimeComplete
                }
              >
                <Select
                  value={values.warningAudioForParkingTimeComplete}
                  onChange={(e) => {
                    setFieldValue("warningAudioForParkingTimeComplete", e);
                  }}
                >
                  <Option key={""} value={""}>
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.disableNotification}
                    </LangContext.Consumer>
                  </Option>
                  {audioList &&
                    audioList.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                </Select>
              </Item>
            </Col>
            <Col span={12}>
              <Item
                label={
                  <LangContext.Consumer>
                    {(i18n) => i18n.s.soundPreview}
                  </LangContext.Consumer>
                }
              >
                <ControlledAudio
                  src={warningAudioForParkingTimeComplete}
                  style={{
                    height: 37,
                    paddingTop: 0,
                    width: "100%",
                    borderRadius: 20,
                  }}
                />
              </Item>
            </Col>
            <Col span={24}>
              <Item
                label={
                  <LangContext.Consumer>
                    {(i18n) => i18n.a.approachRange}
                  </LangContext.Consumer>
                }
                validateStatus={
                  touched.approachRange && errors.approachRange && "error"
                }
                help={touched.approachRange && errors.approachRange}
              >
                <Input
                  style={{ width: "100%" }}
                  name="approachRange"
                  value={values.approachRange}
                  onChange={(e) => {
                    setFieldValue("approachRange", e.target.value);
                  }}
                  type="number"
                />
              </Item>
            </Col>
            <Col span={12}>
              <Item
                label={
                  <LangContext.Consumer>
                    {(i18n) => i18n.w.warningAudioForNotStoppingInTime}
                  </LangContext.Consumer>
                }
                validateStatus={
                  touched.warningAudioForNotStoppingInTime &&
                  errors.warningAudioForNotStoppingInTime &&
                  "error"
                }
                help={
                  touched.warningAudioForNotStoppingInTime &&
                  errors.warningAudioForNotStoppingInTime
                }
              >
                <Select
                  value={values.warningAudioForNotStoppingInTime}
                  onChange={(e) => {
                    setFieldValue("warningAudioForNotStoppingInTime", e);
                  }}
                >
                  <Option key={""} value={""}>
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.disableNotification}
                    </LangContext.Consumer>
                  </Option>
                  {audioList &&
                    audioList.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                </Select>
              </Item>
            </Col>
            <Col span={12}>
              <Item
                label={
                  <LangContext.Consumer>
                    {(i18n) => i18n.s.soundPreview}
                  </LangContext.Consumer>
                }
              >
                <ControlledAudio
                  src={warningAudioForNotStoppingInTime}
                  style={{
                    height: 37,
                    paddingTop: 0,
                    width: "100%",
                    borderRadius: 20,
                  }}
                />
              </Item>
            </Col>
            <Col span={12}>
              <Item
                label={
                  <LangContext.Consumer>
                    {(i18n) => i18n.s.startAt}
                  </LangContext.Consumer>
                }
                validateStatus={
                  touched.wigp_start_time && errors.wigp_start_time && "error"
                }
                help={touched.wigp_start_time && errors.wigp_start_time}
              >
                <TimePicker
                  value={moment(values.wigp_start_time, format)}
                  format={format}
                  onChange={(time, timeString) => {
                    setFieldValue("wigp_start_time", timeString);
                  }}
                  allowClear={false}
                />{" "}
                <span>
                  <LangContext.Consumer>
                    {(i18n) => i18n.p.policyPeriodFormat}
                  </LangContext.Consumer>
                </span>
              </Item>
            </Col>
            <Col span={12}>
              <Item
                label={
                  <LangContext.Consumer>
                    {(i18n) => i18n.e.endAt}
                  </LangContext.Consumer>
                }
                validateStatus={
                  touched.wigp_end_time && errors.wigp_end_time && "error"
                }
                help={touched.wigp_end_time && errors.wigp_end_time}
              >
                <TimePicker
                  value={moment(values.wigp_end_time, format)}
                  format={format}
                  onChange={(time, timeString) => {
                    setFieldValue("wigp_end_time", timeString);
                  }}
                  allowClear={false}
                />{" "}
                <span>
                  <LangContext.Consumer>
                    {(i18n) => i18n.p.policyPeriodFormat}
                  </LangContext.Consumer>
                </span>
              </Item>
            </Col>
          </Row>
        </>
      )} */}

      {values.dataType === "ATEMP" && (
        <>
          <Item
            label={
              <LangContext.Consumer>
                {(i18n) => i18n.m.min_temp}
              </LangContext.Consumer>
            }
            validateStatus={touched.min_temp && errors.min_temp && "error"}
            help={touched.min_temp && errors.min_temp}
          >
            <Input
              style={{ width: "20%" }}
              name="min_temp"
              placeholder="โปรดระบุความเร็ว"
              value={values.min_temp}
              onChange={(e) => {
                setFieldValue("min_temp", e.target.value);
              }}
              type="number"
            />
          </Item>
          <Item
            label={
              <LangContext.Consumer>
                {(i18n) => i18n.m.max_temp}
              </LangContext.Consumer>
            }
            validateStatus={touched.max_temp && errors.max_temp && "error"}
            help={touched.max_temp && errors.max_temp}
          >
            <Input
              style={{ width: "20%" }}
              name="max_temp"
              placeholder="โปรดระบุความเร็ว"
              value={values.max_temp}
              onChange={(e) => {
                setFieldValue("max_temp", e.target.value);
              }}
              type="number"
            />
          </Item>
        </>
      )}

      {values.dataType === "A_WIGP" && (
        <>
          <Item
            label="Start time"
            validateStatus={
              touched.wigp_start_time && errors.wigp_start_time && "error"
            }
            help={touched.wigp_start_time && errors.wigp_start_time}
          >
            <TimePicker
              value={moment(values.wigp_start_time, format)}
              format={format}
              onChange={(time, timeString) => {
                setFieldValue("wigp_start_time", timeString);
              }}
              allowClear={false}
            />{" "}
            <span>
              <LangContext.Consumer>
                {(i18n) => i18n.p.policyPeriodFormat}
              </LangContext.Consumer>
            </span>
          </Item>
          <Item
            label="End time"
            validateStatus={
              touched.wigp_end_time && errors.wigp_end_time && "error"
            }
            help={touched.wigp_end_time && errors.wigp_end_time}
          >
            <TimePicker
              value={moment(values.wigp_end_time, format)}
              format={format}
              onChange={(time, timeString) => {
                setFieldValue("wigp_end_time", timeString);
              }}
              allowClear={false}
            />{" "}
            <span>
              <LangContext.Consumer>
                {(i18n) => i18n.p.policyPeriodFormat}
              </LangContext.Consumer>
            </span>
          </Item>
        </>
      )}

      {values.dataType === "A_WLTL" && (
        <Item
          label={
            <LangContext.Consumer>
              {(i18n) => i18n.d.duration}
            </LangContext.Consumer>
          }
          validateStatus={touched.wltl_dur && errors.wltl_dur && "error"}
          help={touched.wltl_dur && errors.wltl_dur}
        >
          <Input
            style={{ width: "20%" }}
            name="wltl_dur"
            onChange={(e) => {
              const { value } = e.target;
              const reg = /^-?[0-9]*(\.[0-9]*)?$/;
              if ((!isNaN(value) && reg.test(value)) || value === "") {
                setFieldValue("wltl_dur", value);
              }
            }}
            value={values.wltl_dur}
            maxLength={25}
          />
          <span>{` (ชั่วโมง)`}</span>
        </Item>
      )}

      {values.dataType == "A_VDC" ? (
        <Item
          label={
            <LangContext.Consumer>
              {(i18n) => i18n.d.duration}
            </LangContext.Consumer>
          }
          validateStatus={touched.parkingTime && errors.parkingTime && "error"}
          help={touched.parkingTime && errors.parkingTime}
        >
          <TimePicker
            value={moment(values.parkingTime, "HH:mm")}
            format={"HH:mm"}
            onChange={(time, timeString) => {
              setFieldValue(
                "parkingTime",
                moment(timeString, "HH:mm").format("HH:mm:ss")
              );
            }}
            allowClear={false}
          />{" "}
          <span>
            <LangContext.Consumer>
              {(i18n) => i18n.d.durationMin}
            </LangContext.Consumer>
          </span>
        </Item>
      ) : null}
      {values.dataType == "A_VCD" ? (
        <Row gutter={[8, 8]}>
          {/* <Col span={24}>
            <Item
              label={
                <LangContext.Consumer>
                  {(i18n) => i18n.d.drivingSpeed}
                </LangContext.Consumer>
              }
              validateStatus={
                touched.drivingSpeed && errors.drivingSpeed && "error"
              }
              help={touched.drivingSpeed && errors.drivingSpeed}
            >
              <Input
                style={{ width: 150 }}
                value={values.drivingSpeed}
                onChange={(e) => setFieldValue("drivingSpeed", e.target.value)}
                type="number"
                disabled
              />{" "}
              กม./ชั่วโมง
            </Item>
          </Col> */}
          {/* <Col span={8}>
            <Item
              label={
                <LangContext.Consumer>
                  {(i18n) => i18n.d.drivingTimePolicy}
                </LangContext.Consumer>
              }
              validateStatus={
                touched.drivingTimePolicy && errors.drivingTimePolicy && "error"
              }
              help={touched.drivingTimePolicy && errors.drivingTimePolicy}
            >
              <Select
                className="entry-selection-red"
                style={{ width: "100%" }}
                value={values.drivingTimePolicy}
                onChange={(e) => {
                  setFieldValue("drivingTimePolicy", e);
                }}
              >
                {hours.map((hour) => (
                  <Option key={hour} value={hour.toString()}>
                    {hour}{" "}
                    <LangContext.Consumer>
                      {(i18n) => i18n.h.hour}
                    </LangContext.Consumer>
                  </Option>
                ))}
              </Select>
            </Item>
          </Col>
          <Col span={8}>
            <Item
              label={
                <LangContext.Consumer>
                  {(i18n) => i18n.r.ringTone}
                </LangContext.Consumer>
              }
              validateStatus={
                touched.ringToneRed && errors.ringToneRed && "error"
              }
              help={touched.ringToneRed && errors.ringToneRed}
            >
              <Select
                className="entry-selection-red"
                value={values.ringToneRed}
                onChange={(e) => {
                  setFieldValue("ringToneRed", e);
                }}
              >
                {audioList &&
                  audioList.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            </Item>
          </Col>
          <Col span={8}>
            <Item
              label={
                <LangContext.Consumer>
                  {(i18n) => i18n.s.soundPreview}
                </LangContext.Consumer>
              }
            >
              <ControlledAudio
                src={currentAudioSrcRed}
                style={{
                  height: 37,
                  paddingTop: 0,
                  width: "100%",
                  border: "1px solid red",
                  borderRadius: 20,
                }}
              />
            </Item>
          </Col> */}

          <FieldArray
            name="ringToneYellowVCD"
            render={(arrayHelpers) => (
              <></>
              // <>
              //   {values["ringToneYellowVCD"].map((item, idx) => (
              //     <>
              //       <Field
              //         name={`ringToneYellowVCD[${idx}].time`}
              //         render={({
              //           field /* { name, value, onChange, onBlur } */,
              //         }) => (
              //           <>
              //             <Col span={8}>
              //               <Item
              //                 label={
              //                   <>
              //                     <LangContext.Consumer>
              //                       {(i18n) =>
              //                         i18n.n.notificationTimeBeforeDueDate
              //                       }
              //                     </LangContext.Consumer>{" "}
              //                     {idx + 1}
              //                   </>
              //                 }
              //                 validateStatus={
              //                   touched.drivingTimePolicy &&
              //                   errors.drivingTimePolicy &&
              //                   "error"
              //                 }
              //                 help={
              //                   touched.drivingTimePolicy &&
              //                   errors.drivingTimePolicy
              //                 }
              //               >
              //                 <Select
              //                   {...field}
              //                   className="entry-selection-yellow"
              //                   style={{ width: "100%" }}
              //                   value={values.ringToneYellowVCD[idx].time}
              //                   onChange={(e) => {
              //                     const replaceData = values[
              //                       "ringToneYellowVCD"
              //                     ].map((obj, index) => {
              //                       if (index == idx) {
              //                         return { ...obj, time: e };
              //                       }
              //                       return obj;
              //                     });
              //                     setFieldValue(
              //                       "ringToneYellowVCD",
              //                       replaceData
              //                     );
              //                   }}
              //                 >
              //                   {availableOptions.map((option) => (
              //                     <Option
              //                       key={option.value}
              //                       value={option.value}
              //                       disabled={values.ringToneYellowVCD.some(
              //                         ({ time }) => time === option.value
              //                       )}
              //                     >
              //                       {option.label}
              //                     </Option>
              //                   ))}
              //                 </Select>
              //               </Item>
              //               <ErrorMessage
              //                 name={`ringToneYellowVCD[${idx}].time`}
              //                 component="div"
              //                 style={{
              //                   color: "red",
              //                   position: "absolute",
              //                   fontSize: "13px",
              //                   marginTop: -11,
              //                 }}
              //               />
              //             </Col>
              //           </>
              //         )}
              //       />
              //       <Field
              //         name={`ringToneYellowVCD[${idx}].audio`}
              //         render={({
              //           field /* { name, value, onChange, onBlur } */,
              //         }) => (
              //           <>
              //             <Col span={8}>
              //               <Item
              //                 label={
              //                   <LangContext.Consumer>
              //                     {(i18n) => i18n.r.ringTone}
              //                   </LangContext.Consumer>
              //                 }
              //               >
              //                 <Select
              //                   {...field}
              //                   className="entry-selection-yellow"
              //                   value={values.ringToneYellowVCD[idx].audio}
              //                   onChange={(e) => {
              //                     const updatedRingToneYellowVCD = values.ringToneYellowVCD.map(
              //                       (obj, index) =>
              //                         index === idx ? { ...obj, audio: e } : obj
              //                     );

              //                     setFieldValue(
              //                       "ringToneYellowVCD",
              //                       updatedRingToneYellowVCD
              //                     );
              //                   }}
              //                 >
              //                   {audioList.map((item) => (
              //                     <Option key={item.id} value={item.id}>
              //                       {item.name}
              //                     </Option>
              //                   ))}
              //                 </Select>
              //                 <ErrorMessage
              //                   name={`ringToneYellowVCD[${idx}].audio`}
              //                   component="div"
              //                   style={{
              //                     color: "red",
              //                     position: "absolute",
              //                     fontSize: "13px",
              //                     marginTop: -11,
              //                   }}
              //                 />
              //               </Item>
              //             </Col>
              //             <Col span={8}>
              //               <Item
              //                 label={
              //                   <LangContext.Consumer>
              //                     {(i18n) => i18n.s.soundPreview}
              //                   </LangContext.Consumer>
              //                 }
              //               >
              //                 <ControlledAudio
              //                   src={
              //                     audioList.length > 0
              //                       ? audioList.find(
              //                           (list) => list.id === item.audio
              //                         ) == undefined
              //                         ? null
              //                         : audioList.find(
              //                             (list) => list.id === item.audio
              //                           )["link_file"]
              //                       : null
              //                   }
              //                   style={{
              //                     height: 37,
              //                     paddingTop: 0,
              //                     width: "100%",
              //                     border: "1px solid #ffbf00",
              //                     borderRadius: 20,
              //                   }}
              //                 />
              //               </Item>
              //             </Col>
              //           </>
              //         )}
              //       />
              //     </>
              //   ))}
              //   <Col span={24}>
              //     <Button
              //       type="primary"
              //       shape="round"
              //       icon="plus-circle"
              //       style={{ marginRight: 10 }}
              //       disabled={
              //         values.ringToneYellowVCD.length >= 12 ? true : false
              //       }
              //       onClick={() => arrayHelpers.push({ audio: "", time: null })}
              //     >
              //       {" "}
              //       <LangContext.Consumer>
              //         {(i18n) => i18n.a.add}
              //       </LangContext.Consumer>
              //     </Button>
              //     {values.ringToneYellowVCD.length > 1 && (
              //       <Button
              //         type="danger"
              //         shape="round"
              //         icon="minus-circle"
              //         onClick={() => {
              //           let getArr = values.ringToneYellowVCD;
              //           getArr.pop();
              //           setFieldValue("ringToneYellowVCD", getArr);
              //         }}
              //       >
              //         {" "}
              //         <LangContext.Consumer>
              //           {(i18n) => i18n.d.delete}
              //         </LangContext.Consumer>
              //       </Button>
              //     )}
              //   </Col>
              // </>
            )}
          />
          <Col span={24}>
            <Item
              label={
                'ขับต่อเนื่องเป็นเวลา'
              }
            >
              <TimePicker
                disabled
                style={{ width: 150 }}
                value={moment(values.drivingContinueusly, "HH:mm")}
                format={"HH:mm"}
                onChange={(time, timeString) => {
                  setFieldValue(
                    "drivingContinueusly",
                    moment(timeString, "HH:mm").format("HH:mm:ss")
                  );
                }}
                allowClear={false}
              />{" "}
              ชั่วโมง:นาที
            </Item>
          </Col>
          <Col span={24}>
            <Item
              label={
                'จอดพักรถ'
              }
            >
              <TimePicker
                disabled
                style={{ width: 150 }}
                value={moment(values.parkingTime, "HH:mm")}
                format={"HH:mm"}
                onChange={(time, timeString) => {
                  setFieldValue(
                    "parkingTime",
                    moment(timeString, "HH:mm").format("HH:mm:ss")
                  );
                }}
                allowClear={false}
              />{" "}
              ชั่วโมง:นาที
            </Item>
          </Col>

          {/* <Col span={12}>
            <Item
              label={
                <LangContext.Consumer>
                  {(i18n) => i18n.w.warningAudioForParkingTimeComplete}
                </LangContext.Consumer>
              }
              validateStatus={
                touched.warningAudioForParkingTimeComplete &&
                errors.warningAudioForParkingTimeComplete &&
                "error"
              }
              help={
                touched.warningAudioForParkingTimeComplete &&
                errors.warningAudioForParkingTimeComplete
              }
            >
              <Select
                value={values.warningAudioForParkingTimeComplete}
                onChange={(e) => {
                  setFieldValue("warningAudioForParkingTimeComplete", e);
                }}
              >
                <Option key={""} value={""}>
                  <LangContext.Consumer>
                    {(i18n) => i18n.d.disableNotification}
                  </LangContext.Consumer>
                </Option>
                {audioList &&
                  audioList.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            </Item>
          </Col>
          <Col span={12}>
            <Item
              label={
                <LangContext.Consumer>
                  {(i18n) => i18n.s.soundPreview}
                </LangContext.Consumer>
              }
            >
              <ControlledAudio
                src={warningAudioForParkingTimeComplete}
                style={{
                  height: 37,
                  paddingTop: 0,
                  width: "100%",
                  borderRadius: 20,
                }}
              />
            </Item>
          </Col> */}
          {/* <Col span={12}>
            <Item
              label={
                <LangContext.Consumer>
                  {(i18n) => i18n.w.warningAudioForNotStoppingInTime}
                </LangContext.Consumer>
              }
              validateStatus={
                touched.warningAudioForNotStoppingInTime &&
                errors.warningAudioForNotStoppingInTime &&
                "error"
              }
              help={
                touched.warningAudioForNotStoppingInTime &&
                errors.warningAudioForNotStoppingInTime
              }
            >
              <Select
                value={values.warningAudioForNotStoppingInTime}
                onChange={(e) => {
                  setFieldValue("warningAudioForNotStoppingInTime", e);
                }}
              >
                <Option key={""} value={""}>
                  <LangContext.Consumer>
                    {(i18n) => i18n.d.disableNotification}
                  </LangContext.Consumer>
                </Option>
                {audioList &&
                  audioList.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            </Item>
          </Col>
          <Col span={12}>
            <Item
              label={
                <LangContext.Consumer>
                  {(i18n) => i18n.s.soundPreview}
                </LangContext.Consumer>
              }
            >
              <ControlledAudio
                src={warningAudioForNotStoppingInTime}
                style={{
                  height: 37,
                  paddingTop: 0,
                  width: "100%",
                  borderRadius: 20,
                }}
              />
            </Item>
          </Col> */}
        </Row>
      ) : null}

      {values.dataType === "A_WLTD" && (
        <>
          <Item
            label={
              <LangContext.Consumer>
                {(i18n) => i18n.d.duration}
              </LangContext.Consumer>
            }
            validateStatus={touched.wltd_dur && errors.wltd_dur && "error"}
            help={touched.wltd_dur && errors.wltd_dur}
          >
            <Input
              style={{ width: "20%" }}
              name="wltd_dur"
              onChange={(e) => {
                const { value } = e.target;
                const reg = /^-?[0-9]*(\.[0-9]*)?$/;
                if ((!isNaN(value) && reg.test(value)) || value === "") {
                  setFieldValue("wltd_dur", value);
                }
              }}
              value={values.wltd_dur}
              maxLength={25}
            />
            <span>{` (วัน)`}</span>
          </Item>
          <Item
            label={
              <LangContext.Consumer>
                {(i18n) => i18n.d.distance}
              </LangContext.Consumer>
            }
            validateStatus={
              touched.wltd_distance && errors.wltd_distance && "error"
            }
            help={touched.wltd_distance && errors.wltd_distance}
          >
            <Input
              style={{ width: "20%" }}
              name="wltd_distance"
              onChange={(e) => {
                const { value } = e.target;
                const reg = /^-?[0-9]*(\.[0-9]*)?$/;
                if ((!isNaN(value) && reg.test(value)) || value === "") {
                  setFieldValue("wltd_distance", value);
                }
              }}
              value={values.wltd_distance}
              maxLength={25}
            />
            <span>{` (Km)`}</span>
          </Item>
        </>
      )}

      {values.dataType === "A_VMOFLT" && (
        <Item
          label={
            <LangContext.Consumer>
              {(i18n) => i18n.d.duration}
            </LangContext.Consumer>
          }
          validateStatus={touched.wltd_dur && errors.wltd_dur && "error"}
          help={touched.wltd_dur && errors.wltd_dur}
        >
          <Input
            style={{ width: "20%" }}
            name="wltd_dur"
            onChange={(e) => {
              const { value } = e.target;
              const reg = /^-?[0-9]*(\.[0-9]*)?$/;
              if ((!isNaN(value) && reg.test(value)) || value === "") {
                setFieldValue("wltd_dur", value);
              }
            }}
            value={values.wltd_dur}
            maxLength={25}
          />
          <span>{` (วัน)`}</span>
        </Item>
      )}

      <Item>
        {current < steps.length - 1 && (
          <Button type="primary" htmlType="submit">
            <LangContext.Consumer>{(i18n) => i18n.n.next}</LangContext.Consumer>
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button
            type="primary"
            onClick={() => message.success("Processing complete!")}
          >
            Done
          </Button>
        )}
        {current > 0 && (
          <Button style={{ marginLeft: 8 }} onClick={() => prev()}>
            Previous
          </Button>
        )}
      </Item>
    </>
  );
};

export default SpeedLimit;
