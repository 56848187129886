import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";

function* loadFrimware(action) {
  const {
    data: {
      page,
      pageSize,
      companyID,
      orderBy,
      orderType,
      accessToken,
      filterObj,
    },
  } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getfrimware`,
      {
        page,
        pageSize,
        companyID,
        orderBy,
        orderType,
        filterObj,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    yield put(actions.loadFrimware.success(res.data, page));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadFrimware.failure());
  }
}

function* createFrimware(action) {
  const {
    data: {
      name,
      description,
      device_types_id,
      allow_technicins,
      allow_device_lots_id,
      file,
      file_name,
      accessToken,
    },
    createFrimwareloading,
  } = action.payload;

  const formData = new FormData();
  formData.append("name", name);
  formData.append("description", description);
  formData.append("device_types_id", device_types_id);
  formData.append("allow_technicins", allow_technicins);
  formData.append("allow_device_lots_id", allow_device_lots_id);
  formData.append("file", file);
  formData.append("file_name", file_name);

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/createfrimware`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    createFrimwareloading(res.data);
    yield put(actions.createFrimware.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.createFrimware.failure());
  }
}

function* loadFrimwareDetail(action) {
  const {
    data: { id, accessToken },
  } = action.payload;

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getfrimware/detail/${id}`,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    yield put(actions.loadFrimwareDetail.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadFrimwareDetail.failure());
  }
}

function* updateFrimware(action) {
  const {
    data: {
      id,
      name,
      description,
      fw_path,
      file,
      file_name,
      is_edit_file,
      device_types_id,
      allow_technicins,
      allow_device_lots_id,
      accessToken,
    },
    updateFrimwareloading,
  } = action.payload;

  const formData = new FormData();
  formData.append("id", id);
  formData.append("name", name);
  formData.append("description", description);
  formData.append("fw_path", fw_path);
  formData.append("device_types_id", device_types_id);
  formData.append("allow_technicins", allow_technicins);
  formData.append("allow_device_lots_id", allow_device_lots_id);
  formData.append("file", file);
  formData.append("file_name", file_name);
  formData.append("is_edit_file", is_edit_file ? "Y" : "N");

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/updatefrimware`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    updateFrimwareloading(res.data);
    yield put(actions.updateFrimware.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.updateFrimware.failure());
  }
}

export default function* watchcreatefrimware() {
  yield all([
    takeEvery(actions.LOAD_FRIMWARE.REQUEST, loadFrimware),
    takeEvery(actions.CREATE_FRIMWARE.REQUEST, createFrimware),
    takeEvery(actions.LOAD_FRIMWARE_DETAIL.REQUEST, loadFrimwareDetail),
    takeEvery(actions.UPDATE_FRIMWARE_DETAIL.REQUEST, updateFrimware),
  ]);
}

export { loadFrimware, createFrimware, loadFrimwareDetail, updateFrimware };
