import styled from "styled-components";
import { Button, Table, Icon, Transfer, Modal, TreeSelect, Select } from "antd";
import { getOS } from "../../../shared/components/GetOS";
const os = getOS();

export const TableStyled = styled(Table)(() => {
  return {
    "& .ant-table-scroll": {
      borderRadius: "8px",
    },
    "& .ant-table-header-column": {
      verticalAlign: "middle !important",
      color: "black",
    },
    "& .ant-table-column-sorter-up.off": {
      color: "black",
    },
    "& .ant-table-column-sorter-down.off": {
      color: "black",
    },
    "& .ant-table-tbody > tr > td": {
      alignContent: "center",
    },
    "& .ant-table-column-sorters": {
      marginRight: "0 !important",
      marginLeft: "0 !important",
    },
    "& .ant-table-fixed-header .ant-table-scroll .ant-table-header": {
      overflow: os === "Mac OS" && "hidden !important",
    },
  };
});
