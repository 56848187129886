import React, { useRef, useState } from "react";
import Icon from "../../common/Icon";
import { isBlockActive } from "../../utils/SlateUtilityFunctions";
import usePopup from "../../utils/usePopup";
import { insertEmbed } from "../../utils/embed.js";
import { Button } from "antd";
import { MdImage } from "react-icons/md";

const Embed = ({ editor, format }) => {
  const urlInputRef = useRef();
  const [showInput, setShowInput] = usePopup(urlInputRef);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    if (file) {
      if (!file.type.startsWith("image/")) {
        alert("Please upload a valid image.");
        return;
      }
      if (file.size > 5 * 1024 * 1024) {
        // Limit size to 5MB
        alert("Image size must be less than 5MB.");
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        const url = reader.result;
        insertEmbed(
          editor,
          {
            url,
            width: "",
            height: "",
          },
          format
        );
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <div ref={urlInputRef} className="popup-wrapper">
      <Button
        className={
          isBlockActive(editor, format)
            ? "richTextButtonActive"
            : "richTextButtonInActive"
        }
        type="link"
        style={{
          border: showInput ? "1px solid lightgray" : "",
          borderBottom: "none",
        }}
        format={format}
        onMouseDown={(event) => {
          event.preventDefault();
          document.getElementById("fileUpload").click();
        }}
      >
        <MdImage fontSize={20} color={"gray"} />
        <input
          id="fileUpload"
          type="file"
          style={{ display: "none" }}
          accept="image/*"
          onChange={handleFormSubmit}
        />
      </Button>
    </div>
  );
};

export default Embed;
