import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import LangContext from "modules/shared/context/langContext";
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Spin,
  AutoComplete,
  Select,
  Divider,
} from "antd";
import TableDetail from "./TableDetail";

const FormItem = Form.Item;
const Option = Select.Option;
const OptionAuto = AutoComplete.Option;

export default class Forms extends Component {
  state = {
    platenoList: [],
    codeList: [],
    driverList: [],
    deliverLists: [],
    vehicle_id: "",
    driver_id: "",
    driver_name: "",
    trip_code: "",
    product_type: "",
    plate_no: "",
    vehicle_code: "",
    productactionLoading: false,
    deliveractionLoading: false,
  };

  componentDidMount() {
    const { loadVehicle, loadProducttype, loadDriver } = this.props;
    loadVehicle();
    loadProducttype();
    loadDriver();
  }

  componentWillReceiveProps = (nextProps) => {
    const { vehicleLists, driverLists } = nextProps;
    const codelists = [];
    vehicleLists.map((item) =>
      item.code != ""
        ? codelists.push({
            key: item.id,
            name: item.code,
            driver_id: item.driver_id,
            driver_name: item.driver_name,
            plate_no: item.plate_no,
          })
        : ""
    );

    const platenolists = [];
    vehicleLists.map((item) =>
      item.code != ""
        ? platenolists.push({
            key: item.id,
            name: item.plate_no,
            driver_id: item.driver_id,
            driver_name: item.driver_name,
            code: item.code,
          })
        : ""
    );

    const driverlists = [];
    driverLists.map((item) =>
      driverlists.push({
        key: item.id,
        name: item.firstname + " " + item.lastname,
      })
    );

    this.setState({ codeList: codelists });
    this.setState({ platenoList: platenolists });
    this.setState({ driverList: driverlists });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.productactionLoading != this.state.productactionLoading) {
      this.setState({
        productactionLoading: false,
      });
    }

    if (prevState.deliveractionLoading != this.state.deliveractionLoading) {
      this.setState({
        deliveractionLoading: false,
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.check != nextProps.check) {
      if (nextProps.selectedTrip.trip_id != "") {
        this.setState({
          vehicle_id: nextProps.selectedTrip.vehicle_id,
          driver_id: nextProps.selectedTrip.driver_id,
          driver_name: nextProps.selectedTrip.driver_name,
          trip_code: nextProps.selectedTrip.trip_code,
          plate_no: nextProps.selectedTrip.plate_no,
          vehicle_code: nextProps.selectedTrip.vehicle_code,
          product_type: nextProps.selectedTrip.product_type,
        });
      }
    }
    return true;
  }

  changeVehicle = (value, option, setFieldValue) => {
    this.setState({ vehicle_id: value });
    this.setState({ driver_id: option.props.driver });
    this.setState({ driver_name: option.props.drivername });
    this.setState({ plate_no: option.props.children });
    this.setState({ vehicle_code: option.props.code });
  };

  selectVehicle = (value, option, setFieldValue) => {
    this.setState({ driver_id: option.props.driver });
    this.setState({ vehicle_id: value });
    this.setState({ driver_name: option.props.drivername });
    this.setState({ plate_no: option.props.children });
    this.setState({ vehicle_code: option.props.code });
  };

  changeVehicleCode = (value, option, setFieldValue) => {
    this.setState({ vehicle_id: value });
    this.setState({ driver_id: option.props.driver });
    this.setState({ driver_name: option.props.drivername });
    this.setState({ plate_no: option.props.plate_no });
    this.setState({ vehicle_code: option.props.children });
  };

  selectVehicleCode = (value, option, setFieldValue) => {
    this.setState({ driver_id: option.props.driver });
    this.setState({ vehicle_id: value });
    this.setState({ driver_name: option.props.drivername });
    this.setState({ plate_no: option.props.plate_no });
    this.setState({ vehicle_code: option.props.children });
  };

  changeDriver = (value, option, setFieldValue) => {
    this.setState({ driver_id: value });
    this.setState({ driver_name: option.props.children });
  };

  selectDriver = (value, option, setFieldValue) => {
    this.setState({ driver_id: value });
    this.setState({ driver_name: option.props.children });
  };
  setTripCode = (value) => {
    this.setState({ trip_code: value });
  };

  setProductType = (value) => {
    this.setState({ product_type: value });
  };

  setDataDetail = (deliverLists) => {
    this.setState({
      deliverLists,
      productactionLoading: true,
      deliveractionLoading: true,
    });
  };

  handleSubmitClick = (value, setFieldValue, handleSubmit) => {
    setFieldValue("driver_id", this.state.driver_id, false);
    setFieldValue("driver_name", this.state.driver_name, false);
    setFieldValue("deliverList", this.state.deliverLists, false);
    setFieldValue("vehicle_id", this.state.vehicle_id, false);
    setFieldValue("vehicle_code", this.state.vehicle_code, false);
    setFieldValue("plate_no", this.state.plate_no, false);
    setFieldValue("product_type", this.state.product_type, false);
    setFieldValue("trip_code", this.state.trip_code, false);
    handleSubmit();
  };

  render() {
    const {
      deliverytripState,
      onSubmit,
      vehicleLists,
      producttypeLists,
      loading,
      selectedTrip,
    } = this.props;

    const codelists = this.state.codeList.map((v) => (
      <OptionAuto
        key={v.key}
        plate_no={v.plate_no}
        driver={v.driver_id}
        drivername={v.driver_name}
      >
        {v.name}
      </OptionAuto>
    ));

    const platenolists = this.state.platenoList.map((v) => (
      <OptionAuto
        key={v.key}
        driver={v.driver_id}
        drivername={v.driver_name}
        code={v.code}
      >
        {v.name}
      </OptionAuto>
    ));

    const driverlists = this.state.driverList.map((v) => (
      <OptionAuto key={v.key}>{v.name}</OptionAuto>
    ));

    const buttonItemLayout = {
      wrapperCol: { span: 14, offset: 4 },
    };

    return (
      <div>
        <Spin spinning={loading}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              vehicle_code: this.state.vehicle_id,
              plate_no: this.state.vehicle_id,
              product_type: this.state.product_type,
              trip_code: this.state.trip_code,
              driver_name: this.state.driver_id,
            }}
            validationSchema={yup.object().shape({
              product_type: yup.string().required("กรุณาเลือกชนิดของผลิตภัณฑ์"),
              vehicle_code: yup.string().required("กรุณาเลือกรหัสพาหนะ"),
              plate_no: yup.string().required("กรุณาเลือกทะเบียนพาหนะ"),
              trip_code: yup.string().required("กรุณากรอกรหัสทริป"),
              driver_name: yup.string().required("กรุณาเลือกพนักงานขับรถ"),
            })}
            onSubmit={(values) => {
              const loading = true;
              // console.log("submit", values);
              onSubmit(values, loading);
            }}
            render={({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              handleFocus,
              setFieldValue,
              resetForm,
            }) => (
              <div>
                <Divider orientation="left">
                  <h2>
                    {
                      <LangContext.Consumer>
                        {(i18n) => i18n.h.header}
                      </LangContext.Consumer>
                    }
                  </h2>
                </Divider>

                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <FormItem
                        layout="vertical"
                        label={
                          <LangContext.Consumer>
                            {(i18n) => i18n.p.product_type}
                          </LangContext.Consumer>
                        }
                        required={true}
                        validateStatus={
                          touched.product_type &&
                          errors.product_type &&
                          "warning"
                        }
                        help={touched.product_type && errors.product_type}
                      >
                        <Select
                          onChange={(value) => this.setProductType(value)}
                          value={this.state.product_type}
                        >
                          <Option value="">
                            <LangContext.Consumer>
                              {(i18n) => i18n.p.pleaseSelect}
                            </LangContext.Consumer>
                          </Option>
                          {producttypeLists.map((item) => (
                            <Option key={item.id} value={item.id}>
                              <LangContext.Consumer>
                                {(i18n) => i18n.product_types[item.name]}
                              </LangContext.Consumer>
                            </Option>
                          ))}
                        </Select>
                      </FormItem>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <FormItem
                        style={{ margin: "0px 0px 0px 10px" }}
                        layout="vertical"
                        label={
                          <LangContext.Consumer>
                            {(i18n) => i18n.t.tripCode}
                          </LangContext.Consumer>
                        }
                        required={true}
                        validateStatus={
                          touched.trip_code && errors.trip_code && "warning"
                        }
                        help={touched.trip_code && errors.trip_code}
                      >
                        <Input
                          name="trip_code"
                          onChange={(value) =>
                            this.setTripCode(value.target.value)
                          }
                          // onChange={handleChange}
                          onBlur={handleBlur}
                          value={this.state.trip_code}
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <FormItem
                        style={{ margin: "0px 0px 0px 10px" }}
                        layout="vertical"
                        label={
                          <LangContext.Consumer>
                            {(i18n) => i18n.p.plateNo}
                          </LangContext.Consumer>
                        }
                        required={true}
                        validateStatus={
                          touched.plate_no && errors.plate_no && "warning"
                        }
                        help={touched.plate_no && errors.plate_no}
                      >
                        <AutoComplete
                          dataSource={platenolists}
                          optionLabelProp="children"
                          filterOption={true}
                          optionFilterProp="children"
                          value={this.state.plate_no}
                          onChange={(value, option) =>
                            this.changeVehicle(value, option, setFieldValue)
                          }
                          onSelect={(value, option) =>
                            this.selectVehicle(value, option, setFieldValue)
                          }
                        />
                      </FormItem>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <FormItem
                        layout="vertical"
                        label={
                          <LangContext.Consumer>
                            {(i18n) => i18n.v.vehicleCode}
                          </LangContext.Consumer>
                        }
                        required={true}
                        validateStatus={
                          touched.vehicle_code &&
                          errors.vehicle_code &&
                          "warning"
                        }
                        help={touched.vehicle_code && errors.vehicle_code}
                      >
                        <AutoComplete
                          dataSource={codelists}
                          optionLabelProp="children"
                          filterOption={true}
                          optionFilterProp="children"
                          value={this.state.vehicle_code}
                          onChange={(value, option) =>
                            this.changeVehicleCode(value, option, setFieldValue)
                          }
                          onSelect={(value, option) =>
                            this.selectVehicleCode(value, option, setFieldValue)
                          }
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <FormItem
                        style={{ margin: "0px 0px 0px 10px" }}
                        layout="vertical"
                        label={
                          <LangContext.Consumer>
                            {(i18n) => i18n.d.driverName}
                          </LangContext.Consumer>
                        }
                        required={true}
                        validateStatus={
                          touched.driver_name && errors.driver_name && "warning"
                        }
                        help={touched.driver_name && errors.driver_name}
                      >
                        <AutoComplete
                          dataSource={driverlists}
                          optionLabelProp="children"
                          filterOption={true}
                          optionFilterProp="children"
                          value={this.state.driver_name}
                          onChange={(value, option) =>
                            this.changeDriver(value, option, setFieldValue)
                          }
                          onSelect={(value, option) =>
                            this.selectDriver(value, option, setFieldValue)
                          }
                        />
                      </FormItem>
                    </Col>
                  </Row>

                  <Divider orientation="left">
                    <h2>
                      {
                        <LangContext.Consumer>
                          {(i18n) => i18n.d.detail}
                        </LangContext.Consumer>
                      }
                    </h2>
                  </Divider>

                  <TableDetail
                    setDataDetail={this.setDataDetail}
                    deliveractionLoading={this.state.deliveractionLoading}
                    deliverytripState={this.props.deliverytripState}
                    trip_id={this.props.trip_id}
                  />

                  <FormItem>
                    <Row style={{ marginTop: "20px", marginBottom: "5px" }}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Button
                          type="defualt"
                          style={{
                            marginRight: "10px",
                          }}
                        >
                          <Link to="/deliverytrip">
                            <LangContext.Consumer>
                              {(i18n) => i18n.b.back}
                            </LangContext.Consumer>
                          </Link>
                        </Button>
                        <Button
                          type="primary"
                          onClick={(value) =>
                            this.handleSubmitClick(
                              value,
                              setFieldValue,
                              handleSubmit
                            )
                          }
                        >
                          <LangContext.Consumer>
                            {(i18n) => i18n.s.save}
                          </LangContext.Consumer>
                        </Button>
                      </Col>
                    </Row>
                  </FormItem>
                </Form>
              </div>
            )}
          />
        </Spin>
      </div>
    );
  }
}
