// src/HereMap.js
import * as React from "react";
import { renderToString } from "react-dom/server";

export class HereMap extends React.Component {
  mapRef = React.createRef();

  state = {
    // The map instance to use during cleanup
    map: null,
    maps: null,
    allMarker: null,
    mapMount: false,
  };

  calculatedCluster = (self, hObject, map, allMarker) => {
    if (this.state.allMarker != null) {
      map.removeObjects(this.state.allMarker);
      this.setState({ allMarker: null });
    }
    let tempMarker = [];
    let zoom = Math.round(map.getZoom());
    let center = map.getCenter();
    let bounds = map
      .getViewModel()
      .getLookAtData()
      .bounds.getBoundingBox();
    if (typeof bounds != "undefined") {
      let zoom = Math.round(map.getZoom());
      let center = map.getCenter();

      let resObj = {
        nw: { lat: bounds.ka, lng: bounds.ca },
        se: { lat: bounds.ma, lng: bounds.ga },
      };
      self.props.setMapBound({
        center: { lat: center.lat, lng: center.lng },
        zoom: zoom,
        bounds: resObj,
      });

      self.props.marker &&
        self.props.marker.map((ele) => {
          let htmlMarker = renderToString(ele);
          //let htmlMarker = ele.type;
          // Create a marker icon from an image URL:
          var icon = new hObject.map.DomIcon(htmlMarker),
            coords = { lat: ele.props.lat, lng: ele.props.lng },
            marker = new hObject.map.DomMarker(coords, { icon: icon });

          if (typeof ele.props.onClick != "undefined") {
            new hObject.map.Group({
              objects: [marker],
            });

            marker.addEventListener(
              "tap",
              function(e) {
                ele.props.onClick();
              },
              false
            );

            //   map.addObject(group);
          }

          // Add the marker to the map:
          map.addObject(marker);
          tempMarker.push(marker);
        });
    }
    return tempMarker;
  };

  componentDidMount() {
    let self = this;
    let resObj = {
      nw: { lat: 32.30437568401429, lng: 83.948155875 },
      se: { lat: -6.426047614401924, lng: 117.170812125 },
    };
    self.props.setMapBound({ bounds: resObj });

    setTimeout(function() {
      const hObject = window.H;

      const platform = new hObject.service.Platform({
        // app_id: "i0LwEtxjFpnVqm0nX91a",
        // app_code: "i0LwEtxjFpnVqm0nX91a",
        // apikey: "UVdqN2COORDLPQGpJQaQVUlSUsfAspzy6LNTzeDnkmM",
        app_id: process.env.REACT_APP_HERE_MAP_APP_ID,
        app_code: process.env.REACT_APP_HERE_MAP_APP_ID,
        apikey: process.env.REACT_APP_HERE_MAP_API_KEY,
      });

      const defaultLayers = platform.createDefaultLayers();

      // Create an instance of the map
      const map = new hObject.Map(
        self.mapRef.current,
        defaultLayers.vector.normal.map,
        {
          center: { lat: 13.729852, lng: 100.559484 },
          zoom: 5,
          pixelRatio: window.devicePixelRatio || 1,
        }
      );
      window.addEventListener("resize", () => map.getViewPort().resize());
      // MapEvents enables the event system
      // Behavior implements default interactions for pan/zoom (also on mobile touch environments)
      // This variable is unused and is present for explanatory purposes
      const behavior = new hObject.mapevents.Behavior(
        new hObject.mapevents.MapEvents(map)
      );

      // Create the default UI components to allow the user to interact with them
      // This variable is unused
      const ui = hObject.ui.UI.createDefault(map, defaultLayers, "en-US");
      let allMarker = [];

      map.addEventListener("mapviewchangeend", (e) => {
        allMarker = self.calculatedCluster(
          self,
          hObject,
          map,
          self.state.allMarker
        );
        self.setState({ allMarker });
      });

      self.setState({ map: map, maps: hObject, mapMount: true }, () => {
        allMarker = self.calculatedCluster(
          self,
          hObject,
          map,
          self.state.allMarker
        );
        self.setState({ allMarker });
      });

      self.props.handleApiLoaded(map, hObject, platform, defaultLayers);
    }, 500);
  }

  componentWillUnmount() {
    // Cleanup after the map to avoid memory leaks when this component exits the page
    this.state.map && this.state.map.dispose();
  }

  render() {
    return (
      // Set a height on the map so it will display
      <div ref={this.mapRef} style={this.props.style}></div>
    );
  }
}
