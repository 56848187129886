import { insertLink } from "../../utils/link.js";
import Button from "../../common/Button";
import Icon from "../../common/Icon";
import { isBlockActive } from "../../utils/SlateUtilityFunctions.js";
import React from "react";
const LinkButton = (props) => {
  const { editor } = props;
  const handleInsertLink = (e) => {
    e.preventDefault();
    const url = prompt("Enter URL");
    insertLink(editor, url);
  };
  return (
    <Button
      htmlType="button"
      active={isBlockActive(editor, "link")}
      format={"link"}
      onClick={handleInsertLink}
    >
      <Icon icon="link" />
    </Button>
  );
};

export default LinkButton;
