import React, { useEffect, useState } from "react";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import { Formik } from "formik";
import * as yup from "yup";
import {
  Form,
  Row,
  Col,
  Input,
  Select,
  Button,
  Switch,
  Upload,
  message,
  Icon,
  Spin,
  Progress,
  Checkbox,
} from "antd";
import LangContext from "modules/shared/context/langContext";
import RichText from "../SlateEditor/Editor";
import { getNewsCategory, uploadImg } from "../fetchApi/apiClient";

const FormItem = Form.Item;
const Option = Select.Option;

const FormNews = ({ initialValues, readOnly, onSubmit, imgList }) => {
  const [newsCategory, setNewsCategory] = useState([]);
  const [uploadFile, setUploadFile] = useState([]);
  const [validationUploadFile, setValidationUploadFile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    getNewsCategory({}, (status, res) => {
      setNewsCategory(res.data.data);
    });
  }, []);

  useEffect(() => {
    if (imgList != undefined && imgList.length != 0) {
      setUploadFile(imgList);
    }
  }, [imgList]);

  const handleChangeSelect = (value, setFieldValue) => {
    setFieldValue("newsCatagory", value);
  };

  const handleChangeDescription = (value, setFieldValue) => {
    setFieldValue("description", value);
  };

  const onChangePublish = (val, setFieldValue) => {
    setFieldValue("isPublish", val);
  };

  const handleChangeTitleName = (value, setFieldValue) => {
    setFieldValue("titleName", value);
  };

  const handleUploadChange = (info, setFieldValue) => {
    if (info.file.status === "removed") {
      setProgress(0);
      return setUploadFile([]);
    }
    const isJpgOrPng =
      info.file.type === "image/jpeg" || info.file.type === "image/png";
    if (isJpgOrPng) {
      const formData = new FormData();
      formData.append("file", info.file.originFileObj);
      setLoading(true);
      uploadImg(
        formData,
        (status, res) => {
          setUploadFile([
            {
              uid: info.file.uid,
              name: "รูปหน้าปก",
              status: "done",
              url: res.data.data,
              thumbUrl: res.data.data,
            },
          ]);
          setValidationUploadFile(false);
          setLoading(false);
        },
        (progress) => {
          setProgress(progress);
        }
      );
    }
  };

  const previewUploadChange = (file) => {
    const newTab = window.open();
    newTab.document.write(
      `<img src="${file.url}" style="max-width:100%;height:auto;"/>`
    );
    newTab.document.title = file.name;
  };

  const onChangeImportant = (val, setFieldValue) => {
    setFieldValue("isImportant", val);
  };

  const onChangeNew = (val, setFieldValue) => {
    setFieldValue("isNew", val);
  }


  return (
    <Spin spinning={loading}>
      <LangContext.Consumer>
        {(i18n) => (
          <GeneralStyledContent>
            <div
              style={{
                border: "1px solid gray",
                borderRadius: 8,
                padding: 20,
                margin: 10,
              }}
            >
              <Formik
                validateOnChange={false}
                enableReinitialize
                initialValues={initialValues}
                validationSchema={yup.object().shape({
                  titleName: yup.string().required("กรุณากรอกชื่อหัวข้อ"),
                  description: yup.string().required("กรุณากรอกรายละเอียด"),
                  newsCatagory: yup.string().required("กรุณาเลือกหมวดข่าวสาร"),
                  content: yup
                    .string()
                    .test("is-not-empty-json", "กรุณากรอกคำอธิบาย", (value) => {
                      if (!value) return false; // ถ้าเป็น null หรือ undefined ให้ไม่ผ่าน

                      try {
                        const parsedContent = JSON.parse(value); // แปลงเป็น JSON
                        if (!Array.isArray(parsedContent)) return false; // ต้องเป็น array

                        // ตรวจสอบว่ามีข้อความที่ไม่ใช่ค่าว่างอยู่ในโครงสร้าง
                        return parsedContent.some(
                          (block) =>
                            block.children &&
                            block.children.some(
                              (child) => child.text && child.text.trim() !== ""
                            )
                        );
                      } catch (error) {
                        return false; // ถ้า JSON ไม่ถูกต้อง ให้ไม่ผ่าน
                      }
                    }),
                })}
                onSubmit={(values) => {
                  if (uploadFile.length === 0) {
                    setValidationUploadFile(true);
                    message.error("กรุณาอัพโหลดรูปปก"); // Show error message
                    return; // Prevent form submission
                  }

                  setValidationUploadFile(false);
                  onSubmit({
                    ...values,
                    cover: uploadFile,
                  });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit} layout="vertical">
                    <Row gutter={[12, 12]}>
                      <Col span={24}>
                        <label>{i18n.d.detail}</label>
                      </Col>
                      {/* Title Field */}
                      <Col span={8}>
                        <FormItem
                          label={i18n.t.titleName}
                          required
                          validateStatus={
                            touched.titleName && errors.titleName && "error"
                          }
                          help={touched.titleName && errors.titleName}
                        >
                          <Input
                            size="large"
                            readOnly={readOnly}
                            name="titleName"
                            value={values.titleName}
                            onChange={(e) =>
                              handleChangeTitleName(
                                e.target.value,
                                setFieldValue
                              )
                            }
                            onBlur={handleBlur}
                            placeholder={i18n.f.fillInfomation}
                            style={{ width: "100%" }}
                          />
                        </FormItem>
                      </Col>
                      <Col span={8}>
                        <FormItem
                          label={i18n.d.detail}
                          required
                          validateStatus={
                            touched.description && errors.description && "error"
                          }
                          help={touched.description && errors.description}
                        >
                          <Input
                            size="large"
                            readOnly={readOnly}
                            name="Description"
                            value={values.description}
                            onChange={(e) =>
                              handleChangeDescription(
                                e.target.value,
                                setFieldValue
                              )
                            }
                            onBlur={handleBlur}
                            placeholder={"กรอกรายละเอียด"}
                            style={{ width: "100%" }}
                          />
                        </FormItem>
                      </Col>
                      <Col span={8} />
                    </Row>
                    <Row gutter={[12, 12]}>
                      {/* Category Field */}
                      <Col span={8}>
                        <FormItem
                          label={i18n.newsManuals.newsCategory}
                          required
                          validateStatus={
                            touched.newsCatagory &&
                            errors.newsCatagory &&
                            "error"
                          }
                          help={touched.newsCatagory && errors.newsCatagory}
                        >
                          <Select
                            size="large"
                            disabled={readOnly}
                            value={values.newsCatagory}
                            style={{ width: "100%" }}
                            onChange={(val) =>
                              handleChangeSelect(val, setFieldValue)
                            }
                          >
                            {newsCategory.map((item) => (
                              <Option value={item.id.toString()}>
                                {i18n.newsManuals[item.name]}
                              </Option>
                            ))}
                          </Select>
                        </FormItem>
                      </Col>

                      <Col span={16} />
                    </Row>
                    <Row gutter={[12, 12]}>
                      <Col span={8}>
                        <FormItem
                          label={i18n.u.upload}
                          required
                          validateStatus={validationUploadFile && "error"}
                          help={validationUploadFile && "กรุณาอัพโหลดรูปปก"}
                        >
                          <Upload
                            disabled={readOnly}
                            listType={"picture-card"}
                            onChange={(info) => {
                              handleUploadChange(info, setFieldValue);
                            }}
                            beforeUpload={(file) => {
                              const isJpgOrPng =
                                file.type === "image/jpeg" ||
                                file.type === "image/png";
                              if (!isJpgOrPng) {
                                message.error(
                                  "You can only upload JPG/PNG file!"
                                );
                                return;
                              }
                            }}
                            fileList={uploadFile}
                            onPreview={previewUploadChange}
                          >
                            <Button htmlType="button" disabled={readOnly}>
                              <Icon type="upload" /> Upload
                            </Button>{" "}
                            {uploadFile.length > 0 ||
                              (progress > 0 && (
                                <Progress
                                  type="circle"
                                  percent={progress}
                                  width={20}
                                />
                              ))}
                          </Upload>
                        </FormItem>
                      </Col>
                      <Col span={16} />
                    </Row>
                    <Row gutter={[12, 12]}>
                      {/* Content Field with Slate */}
                      <Col span={16}>
                        <FormItem
                          label={i18n.c.content}
                          required
                          validateStatus={
                            touched.content && errors.content && "error"
                          }
                          help={touched.content && errors.content}
                        >
                          <div
                            style={{
                              border: "1px solid",
                              borderColor:
                                touched.content && errors.content
                                  ? "red"
                                  : "#d9d9d9",
                              borderRadius: 4,
                              padding: 8,
                              width: "100%",
                              minHeight: 150,
                            }}
                          >
                            {values.content && (
                              <RichText
                                description={JSON.parse(values.content)}
                                disabled={readOnly}
                                setDescription={(val) => {
                                  setFieldValue("content", val);
                                }}
                              />
                            )}

                            {!values.content && !false && (
                              <RichText
                                description={JSON.parse(values.content)}
                                disabled={readOnly}
                                setDescription={(val) => {
                                  setFieldValue("content", val);
                                }}
                              />
                            )}
                          </div>
                        </FormItem>
                      </Col>
                      <Col span={12}>
                        <Switch
                          disabled={readOnly}
                          checkedChildren="Publish"
                          unCheckedChildren="Publish"
                          checked={values.isPublish}
                          style={{ width: 80 }}

                          onChange={(val) =>
                            onChangePublish(val, setFieldValue)
                          }
                        />
                        <Switch
                          disabled={readOnly}
                          checkedChildren="ข่าวสำคัญ"
                          unCheckedChildren="ข่าวสำคัญ"
                          checked={values.isImportant}
                          style={{ width: 80, marginLeft: 10 }}

                          onChange={(val) =>
                            onChangeImportant(val, setFieldValue)
                          }
                        />
                        <Switch
                          disabled={readOnly}
                          checkedChildren="ข่าวใหม่"
                          unCheckedChildren="ข่าวใหม่"
                          checked={values.isNew}
                          style={{ width: 80, marginLeft: 10 }}
                          onChange={(val) =>
                            onChangeNew(val, setFieldValue)
                          }
                        />
                      </Col>
                      {/* Submit Button */}
                      <Col span={12} style={{ textAlign: "right" }}>
                        <Button
                          onClick={() => {
                            window.location.replace("/news-manuals");
                          }}
                        >
                          {i18n.b.back}
                        </Button>
                        {!readOnly && (
                          <Button
                            type="primary"
                            htmlType="submit"
                            style={{ marginLeft: 10 }}
                            onClick={() => {
                              if (uploadFile.length === 0) {
                                return setValidationUploadFile(true);
                              }
                              return setValidationUploadFile(false);
                            }}
                          >
                            {i18n.s.submit}
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </div>
          </GeneralStyledContent>
        )}
      </LangContext.Consumer>
    </Spin>
  );
};

export default FormNews;
