import React, { useState, useEffect } from "react";
import { LandingPageNewsAndManuals } from "../../../../styled/common-styled";
import LangContext from "modules/shared/context/langContext";
import { Tabs, Row, Col, Empty, Spin } from "antd";
import TabLists from "./TabLists";
import RichText from "../../../../modules/setting/NewsManuals/SlateEditor/Editor";
import { getNewsCategory, getNews, createAxios } from "../../fetch/apiclient";
import { connect } from "react-redux";

const { TabPane } = Tabs;

const News = (props) => {
  const [list, setList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [detail, setDetail] = useState(null);
  const [isActiveId, setIsActiveId] = useState(null);
  const [loadingRichText, setLoadingRichText] = useState(false);
  const [header, setHeader] = useState({
    topic: "",
    description: "",
    cover: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    createAxios(props.auth.accessToken);
    setLoading(true);
    getNewsCategory({}, (status, res) => {
      setCategoryList(res.data.data);
      getNews({ is_publish: true }, (status, resNews) => {
        setList(resNews.data.data);
        setLoading(false);
        if (resNews.data.data.length > 0) {
          handleSelectType(resNews.data.data[0]);
        }
      });
    });
  }, []);

  const handleSelectType = (val) => {
    setDetail(null);
    setIsActiveId(val.id);
    setLoadingRichText(true);
    setTimeout(() => {
      callBackSelectedNews(val);
    }, 500);
  };

  const callBackSelectedNews = (val) => {
    let { topic, description, cover } = val;
    setDetail(JSON.parse(val.detail));
    setHeader({
      topic,
      description,
      cover,
    });
    setLoadingRichText(false);
  };

  return (
    <LandingPageNewsAndManuals>
      <LangContext.Consumer>
        {(i18n) => (
          <Row>
            <Col span={8}>
              <Spin spinning={loading} style={{ marginTop: 200 }}>
                <Tabs
                  defaultActiveKey="0"
                  onChange={(val) => {}}
                  className="TabsStylingHomeDashboard"
                >
                  <TabPane tab={i18n.a.all} key="0">
                    <TabLists
                      list={list}
                      handleSelectType={handleSelectType}
                      isActiveId={isActiveId}
                    />
                  </TabPane>
                  {categoryList.map((item) => (
                    <TabPane
                      tab={i18n.newsManuals[`${item.name}`]}
                      key={item.id}
                    >
                      <TabLists
                        list={list.filter(
                          (listVal) => listVal.news_category_id == item.id
                        )}
                        handleSelectType={handleSelectType}
                        isActiveId={isActiveId}
                      />
                    </TabPane>
                  ))}
                </Tabs>
              </Spin>
            </Col>
            <Col span={16}>
              <Spin spinning={loadingRichText}>
                <div
                  style={{
                    borderLeft: "1px solid rgb(232 232 232)",
                    minHeight: window.innerHeight - 200,
                    height: "auto", // ปรับความสูงตามเนื้อหา
                    overflowY: "auto",
                  }}
                >
                  <div style={{ padding: "24px 24px 0px 24px" }}>
                    <h1 style={{ fontSize: 24, margin: 0, fontWeight: 700 }}>
                      {header.topic}
                    </h1>
                    <p style={{ fontSize: 16, marginBottom: 8 }}>
                      {header.description}
                    </p>
                    <img
                      src={header.cover}
                      style={{
                        width: "100%",
                        height: "auto",
                        borderRadius: 16,
                      }}
                    />
                  </div>
                  <Row>
                    <Col span={24}>
                      {detail ? (
                        <RichText
                          description={detail}
                          disabled={true}
                          setDescription={(val) => {}}
                        />
                      ) : (
                        <Empty
                          style={{ paddingTop: window.innerHeight - 700 }}
                        />
                      )}
                    </Col>
                  </Row>
                </div>
              </Spin>
            </Col>
          </Row>
        )}
      </LangContext.Consumer>
    </LandingPageNewsAndManuals>
  );
};
export default connect(({ auth }) => ({ auth }), {})(News);
