import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";

function* loadMasterVehicle(action) {
  const {
    data: {
      page,
      pageSize,
      searchName,
      companyID,
      orderBy,
      orderType,
      accessToken,
      vehicle_visibility,
      filterObj,
      permis_super,
      permis_add,
      permis_edit,
    },
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/masterdatavehicle`,
      {
        searchName,
        page,
        pageSize,
        companyID,
        orderBy,
        orderType,
        vehicle_visibility,
        filterObj,
        permis_super,
        permis_add,
        permis_edit,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    yield put(actions.loadMasterVehicle.success(res.data, page));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadMasterVehicle.failure());
  }
}

function* loadDriverFormapping(action) {
  const {
    data: {
      page,
      pageSize,
      searchName,
      companyID,
      orderBy,
      orderType,
      accessToken,
      filterObj,
      plant,
    },
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getdriverformapping`,
      {
        searchName,
        page,
        pageSize,
        companyID,
        orderBy,
        orderType,
        filterObj,
        plant,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    yield put(actions.loadDriverFormapping.success(res.data, page));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadDriverFormapping.failure());
  }
}

function* loadVehicle(action) {
  const {
    data: {
      page,
      pageSize,
      searchName,
      companyID,
      orderBy,
      orderType,
      accessToken,
      filterObj,
    },
  } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/masterdatavehicle`,
      // `http://booking-api/api/customer`,
      {
        searchName,
        page,
        pageSize,
        companyID,
        orderBy,
        orderType,
        filterObj,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );

    yield put(actions.loadVehicle.success(res.data, page));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadVehicle.failure());
  }
}

function* loadAutocompleteLocation(action) {
  const { url, companyID, accessToken, queryString } = action.payload;
  try {
    if (queryString == "" || queryString.length <= 1) {
      yield put(actions.loadAutocompleteLocation.success([]));
    } else {
      const res = yield call(
        axios.get,
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/${url}/${companyID}/${queryString}`,

        { headers: { Authorization: `Bearer ${accessToken}` } }
      );

      yield put(actions.loadAutocompleteLocation.success(res.data));
    }
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadAutocompleteLocation.failure());
  }
}

function* selectedAutocompleteLocation(action) {
  const { id, autoCompleteLists } = action.payload;
  try {
    let selected = autoCompleteLists.filter(function(ele) {
      return ele.id == id;
    })[0];

    yield put(actions.selectedAutocompleteLocation.success(selected));
  } catch (err) {
    console.log(err.message);
    yield put(actions.selectedAutocompleteLocation.failure());
  }
}

function* loadAutocompleteDrivername(action) {
  const { url, companyID, accessToken, queryString } = action.payload;
  try {
    if (queryString == "" || queryString.length <= 1) {
      yield put(actions.loadAutocompleteDrivername.success([]));
    } else {
      const res = yield call(
        axios.get,
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/${url}/${companyID}/${queryString}`,

        { headers: { Authorization: `Bearer ${accessToken}` } }
      );

      yield put(actions.loadAutocompleteDrivername.success(res.data));
    }
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadAutocompleteDrivername.failure());
  }
}

function* selectedAutocompleteDriverName(action) {
  const { id, autoCompleteLists } = action.payload;
  try {
    let selected = autoCompleteLists.filter(function(ele) {
      return ele.id == id;
    })[0];

    yield put(actions.selectedAutocompleteDriverName.success(selected));
  } catch (err) {
    console.log(err.message);
    yield put(actions.selectedAutocompleteDriverName.failure());
  }
}

function* loadAutocompleteDriver1(action) {
  const { url, companyID, accessToken, queryString } = action.payload;
  try {
    if (queryString == "" || queryString.length <= 1) {
      yield put(actions.loadAutocompleteDriver1.success([]));
    } else {
      const res = yield call(
        axios.get,
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/${url}/${companyID}/${queryString}`,

        { headers: { Authorization: `Bearer ${accessToken}` } }
      );

      yield put(actions.loadAutocompleteDriver1.success(res.data));
    }
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadAutocompleteDriver1.failure());
  }
}

function* selectedAutocompleteDriver1(action) {
  const { id, autoCompleteLists } = action.payload;
  try {
    let selected = autoCompleteLists.filter(function(ele) {
      return ele.id == id;
    })[0];

    yield put(actions.selectedAutocompleteDriver1.success(selected));
  } catch (err) {
    console.log(err.message);
    yield put(actions.selectedAutocompleteDriver1.failure());
  }
}

function* loadAutocompleteDriver2(action) {
  const { url, companyID, accessToken, queryString } = action.payload;
  try {
    if (queryString == "" || queryString.length <= 1) {
      yield put(actions.loadAutocompleteDriver2.success([]));
    } else {
      const res = yield call(
        axios.get,
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/${url}/${companyID}/${queryString}`,

        { headers: { Authorization: `Bearer ${accessToken}` } }
      );

      yield put(actions.loadAutocompleteDriver2.success(res.data));
    }
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadAutocompleteDriver2.failure());
  }
}

function* selectedAutocompleteDriver2(action) {
  const { id, autoCompleteLists } = action.payload;
  try {
    let selected = autoCompleteLists.filter(function(ele) {
      return ele.id == id;
    })[0];

    yield put(actions.selectedAutocompleteDriver2.success(selected));
  } catch (err) {
    console.log(err.message);
    yield put(actions.selectedAutocompleteDriver2.failure());
  }
}

function* loadAutocompleteDriverCode(action) {
  const { url, companyID, accessToken, queryString } = action.payload;
  try {
    if (queryString == "" || queryString.length <= 1) {
      yield put(actions.loadAutocompleteDriverCode.success([]));
    } else {
      const res = yield call(
        axios.get,
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/${url}/${companyID}/${queryString}`,

        { headers: { Authorization: `Bearer ${accessToken}` } }
      );

      yield put(actions.loadAutocompleteDriverCode.success(res.data));
    }
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadAutocompleteDriverCode.failure());
  }
}

function* selectedAutocompleteDriverCode(action) {
  const { id, autoCompleteLists } = action.payload;
  try {
    let selected = autoCompleteLists.filter(function(ele) {
      return ele.id == id;
    })[0];

    yield put(actions.selectedAutocompleteDriverCode.success(selected));
  } catch (err) {
    console.log(err.message);
    yield put(actions.selectedAutocompleteDriverCode.failure());
  }
}

function* createVehicle(action) {
  const {
    company_id,
    location_id,
    driver_id,
    vehicle_type,
    plate_no,
    tag_id,
    vehicle_id,
    start_valid_date,
    end_valid_date,
    max_drum_capacity,
    drum_capacity,
    truck_category,
    truck_type,
    small_truck,
    mile_no,
    fuel_type,
    model,
    color,
    year,
    machine_serial_number,
    flate_number,
    horse_power,
    wigth,
    height,
    length,
    next_register_data,
    period_of_insurance_year,
    fuel_tank_size,
    fuel_a,
    fuel_b,
    fuel_c,
    discharge_denominator,
    fuel_usage,
    fuel,
    fuel_rate,
    idle_fuel_rate,
    accessToken,
  } = action.payload.data;
  const { loading } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/createvehicle`,
      {
        company_id,
        location_id,
        driver_id,
        vehicle_type,
        plate_no,
        tag_id,
        vehicle_id,
        start_valid_date,
        end_valid_date,
        max_drum_capacity,
        drum_capacity,
        truck_category,
        truck_type,
        small_truck,
        mile_no,
        fuel_type,
        model,
        color,
        year,
        machine_serial_number,
        flate_number,
        horse_power,
        wigth,
        height,
        length,
        next_register_data,
        period_of_insurance_year,
        fuel_tank_size,
        fuel_a,
        fuel_b,
        fuel_c,
        discharge_denominator,
        fuel_usage,
        fuel,
        fuel_rate,
        idle_fuel_rate,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    loading(res.data);
    yield put(actions.createVehicle.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.createVehicle.failure());
  }
}

function* matchVehicle(action) {
  const {
    company_id,
    vehicles_id_defalse_for_driver,
    vehicles_id_defalse,
    driver_id_defalse,
    vehicles_id,
    driver_id,
    accessToken,
  } = action.payload.data;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/matchtruckdriver`,
      {
        company_id,
        vehicles_id_defalse_for_driver,
        vehicles_id_defalse,
        driver_id_defalse,
        vehicles_id,
        driver_id,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );

    yield put(actions.matchVehicle.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.matchVehicle.failure());
  }
}

function* unmatchVehicle(action) {
  const {
    company_id,
    vehicles_id,
    driver_id,
    vehicles_id_defalse_for_driver,
    vehicles_id_defalse,
    driver_id_defalse,
    checkUnmatch,
    accessToken,
  } = action.payload.data;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/unmatchtruckdriver`,
      {
        company_id,
        vehicles_id_defalse_for_driver,
        vehicles_id_defalse,
        driver_id_defalse,
        vehicles_id,
        driver_id,
        checkUnmatch,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );

    yield put(actions.unmatchVehicle.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.unmatchVehicle.failure());
  }
}

function* loadVehicleForEdit(action) {
  const {
    data: { id, accessToken },
  } = action.payload;

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehicleforedit/${id}`,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );

    yield put(actions.loadVehicleForEdit.success(res.data));
  } catch (err) {
    console.log(err.message);

    if (err.response.status === 403) {
      window.location.href = "/";
    }

    yield put(actions.loadVehicleForEdit.failure());
  }
}

function* loadDriverHistory(action) {
  const {
    data: { vehicle_id, page, pageSize, orderBy, orderType, accessToken },
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/vehicle/getdriverhistory`,
      {
        vehicle_id,
        page,
        pageSize,
        orderBy,
        orderType,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    yield put(actions.loadDriverHistory.success(res.data, page));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadDriverHistory.failure());
  }
}

function* loadVehiclePart(action) {
  const {
    data: { vehicle_id, page, pageSize, orderBy, orderType, accessToken },
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/vehicle/getpart`,
      {
        vehicle_id,
        page,
        pageSize,
        orderBy,
        orderType,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    yield put(actions.loadVehiclePart.success(res.data, page));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadVehiclePart.failure());
  }
}

function* updateMasterDataVehicle(action) {
  const {
    data: {
      vehicles_id,
      company_id,
      device_users_id,
      device_users_id1,
      device_users_id2,
      driver_name,
      driver1,
      driver2,
      locations_id,
      locations_name,
      vehicle_type_id,
      plate_province_id,
      vehicle_register_types_id,
      fuel_type,
      flag_cal_fuel,
      brand,
      plate_no,
      mile_no,
      model,
      color,
      year,
      engine_no,
      flate_no,
      horse_power,
      width,
      length,
      height,
      tag_id,
      code,
      next_register_date,
      period_of_insurance,
      fuel_tank_size,
      fuel_usage,
      fuel_rate,
      idle_fuel_rate,
      dischange_denominator,
      companies_billing_id,
      dlt_plate_no,
      body_no,
      sub_companies_has_companies_id,
      meter_serial_number,
      vehicle_staff,
      shaft,
      wheel,
      tire,
      start_valid_date,
      end_valid_date,
      is_smalltruck,
      scco_truck_category,
      drum_capacity,
      max_drum_capacity,
      drum_type,
      fuel_a,
      fuel_b,
      fuel_c,
      input_limit_idle_time,
      input_limit_driving_hour_time,
      input_rest_time,
      not_send_to_dlt,
      not_send_to_dlt_comment,
      files,
      checkUpload,
      db_connect_status,
      sub_company_name,
      accessToken,
    },
    loading,
  } = action.payload;

  const formData = new FormData();
  let uploadFile = null;

  if (checkUpload == 2) {
    // มีการอัปโหลดรูปเข้ามา
    if (files != "" && files != null && files.length > 0) {
      files.forEach((File) => {
        uploadFile = File;
      });
    }
  }

  formData.append("files", uploadFile);
  formData.append("vehicles_id", vehicles_id);
  formData.append("company_id", company_id);
  formData.append("device_users_id", device_users_id);
  formData.append("device_users_id1", device_users_id1);
  formData.append("device_users_id2", device_users_id2);
  formData.append("driver_name", driver_name);
  formData.append("driver1", driver1);
  formData.append("driver2", driver2);
  formData.append("locations_id", locations_id);
  formData.append("locations_name", locations_name);
  formData.append("vehicle_type_id", vehicle_type_id);
  formData.append("plate_province_id", plate_province_id);
  formData.append("vehicle_register_types_id", vehicle_register_types_id);
  formData.append("fuel_type", fuel_type);
  formData.append("flag_cal_fuel", flag_cal_fuel);
  formData.append("brand", brand);
  formData.append("plate_no", plate_no);
  formData.append("mile_no", mile_no);
  formData.append("model", model);
  formData.append("color", color);
  formData.append("year", year);
  formData.append("engine_no", engine_no);
  formData.append("flate_no", flate_no);
  formData.append("horse_power", horse_power);
  formData.append("width", width);
  formData.append("length", length);
  formData.append("height", height);
  formData.append("tag_id", tag_id);
  formData.append("code", code);
  formData.append("next_register_date", next_register_date);
  formData.append("period_of_insurance", period_of_insurance);
  formData.append("fuel_tank_size", fuel_tank_size);
  formData.append("fuel_usage", fuel_usage);
  formData.append("fuel_rate", fuel_rate);
  formData.append("idle_fuel_rate", idle_fuel_rate);
  formData.append("dischange_denominator", dischange_denominator);
  formData.append("companies_billing_id", companies_billing_id);
  formData.append("dlt_plate_no", dlt_plate_no);
  formData.append("body_no", body_no);
  formData.append(
    "sub_companies_has_companies_id",
    sub_companies_has_companies_id
  );
  formData.append("meter_serial_number", meter_serial_number);
  formData.append("vehicle_staff", vehicle_staff);
  formData.append("shaft", shaft);
  formData.append("wheel", wheel);
  formData.append("tire", tire);
  formData.append("start_valid_date", start_valid_date);
  formData.append("end_valid_date", end_valid_date);
  formData.append("is_smalltruck", is_smalltruck);
  formData.append("scco_truck_category", scco_truck_category);
  formData.append("drum_capacity", drum_capacity);
  formData.append("max_drum_capacity", max_drum_capacity);
  formData.append("drum_type", drum_type);
  formData.append("fuel_a", fuel_a);
  formData.append("fuel_b", fuel_b);
  formData.append("fuel_c", fuel_c);
  formData.append("input_limit_idle_time", input_limit_idle_time);
  formData.append(
    "input_limit_driving_hour_time",
    input_limit_driving_hour_time
  );
  formData.append("input_rest_time", input_rest_time);
  formData.append("not_send_to_dlt", not_send_to_dlt);
  formData.append("not_send_to_dlt_comment", not_send_to_dlt_comment);
  formData.append("checkUpload", checkUpload);
  formData.append("db_connect_status", db_connect_status);
  formData.append("sub_company_name", sub_company_name);

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/vehicle/edit`,
      // {
      //             vehicles_id,
      //             company_id,
      //             device_users_id,
      //             device_users_id1,
      //             device_users_id2,
      //             driver_name,
      //             driver1,
      //             driver2,
      //             locations_id,
      //             locations_name,
      //             vehicle_type_id,
      //             plate_province_id,
      //             vehicle_register_types_id,
      //             fuel_type,
      //             flag_cal_fuel,
      //             brand,
      //             plate_no,
      //             mile_no,
      //             model,
      //             color,
      //             year,
      //             engine_no,
      //             flate_no,
      //             horse_power,
      //             width,
      //             length,
      //             height,
      //             tag_id,
      //             code,
      //             next_register_date,
      //             period_of_insurance,
      //             fuel_tank_size,
      //             fuel_usage,
      //             fuel_rate,
      //             idle_fuel_rate,
      //             dischange_denominator,
      //             companies_billing_id,
      //             dlt_plate_no,
      //             body_no,
      //             sub_companies_has_companies_id,
      //             meter_serial_number,
      //             vehicle_staff,
      //             shaft,
      //             wheel,
      //             tire,
      //             start_valid_date,
      //             end_valid_date,
      //             is_smalltruck,
      //             scco_truck_category,
      //             drum_capacity,
      //             max_drum_capacity,
      //             drum_type,
      //             fuel_a,
      //             fuel_b,
      //             fuel_c,
      //             input_limit_idle_time,
      //             input_limit_driving_hour_time,
      //             input_rest_time,
      //             not_send_to_dlt,
      //             not_send_to_dlt_comment,
      //         }
      formData,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    loading(res.data);
    yield put(actions.updateMasterDataVehicle.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.updateMasterDataVehicle.failure());
  }
}

export default function* watchVehicle() {
  yield all([
    takeEvery(actions.LOAD_MASTERDATA_VEHICLE.REQUEST, loadMasterVehicle),
    takeEvery(actions.LOAD_MASTERDATA_DRIVER.REQUEST, loadDriverFormapping),
    takeEvery(actions.LOAD_VEHICLE.REQUEST, loadVehicle),
    takeEvery(actions.LOAD_AUTOCOMPLETE.REQUEST, loadAutocompleteLocation),
    takeEvery(
      actions.SELECTED_AUTOCOMPLETE.REQUEST,
      selectedAutocompleteLocation
    ),
    takeEvery(
      actions.LOAD_AUTOCOMPLETE_DRIVER_NAME.REQUEST,
      loadAutocompleteDrivername
    ),
    takeEvery(
      actions.SELECTED_AUTOCOMPLETE_DRIVER_NAME.REQUEST,
      selectedAutocompleteDriverName
    ),
    takeEvery(
      actions.LOAD_AUTOCOMPLETE_DRIVER1.REQUEST,
      loadAutocompleteDriver1
    ),
    takeEvery(
      actions.SELECTED_AUTOCOMPLETE_DRIVER1.REQUEST,
      selectedAutocompleteDriver1
    ),
    takeEvery(
      actions.LOAD_AUTOCOMPLETE_DRIVER2.REQUEST,
      loadAutocompleteDriver2
    ),
    takeEvery(
      actions.SELECTED_AUTOCOMPLETE_DRIVER2.REQUEST,
      selectedAutocompleteDriver2
    ),
    takeEvery(
      actions.LOAD_AUTOCOMPLETE_DRIVER_CODE.REQUEST,
      loadAutocompleteDriverCode
    ),
    takeEvery(
      actions.SELECTED_AUTOCOMPLETE_DRIVER_CODE.REQUEST,
      selectedAutocompleteDriverCode
    ),
    takeEvery(actions.CREATE_VEHICLE.REQUEST, createVehicle),
    takeEvery(actions.MATCH_VEHICLE.REQUEST, matchVehicle),
    takeEvery(actions.UNMATCH_VEHICLE.REQUEST, unmatchVehicle),
    takeEvery(actions.LOAD_VEHICLE_FOR_EDIT.REQUEST, loadVehicleForEdit),
    takeEvery(
      actions.UPDATE_MASTERDATA_VEHICLE.REQUEST,
      updateMasterDataVehicle
    ),
    takeEvery(actions.LOAD_DRIVER_HISTORY.REQUEST, loadDriverHistory),
    takeEvery(actions.LOAD_VEHICLE_PART.REQUEST, loadVehiclePart),
  ]);
}

export {
  loadVehicle,
  loadAutocompleteLocation,
  selectedAutocompleteLocation,
  loadAutocompleteDrivername,
  selectedAutocompleteDriverName,
  loadAutocompleteDriver1,
  selectedAutocompleteDriver1,
  loadAutocompleteDriver2,
  selectedAutocompleteDriver2,
  loadAutocompleteDriverCode,
  selectedAutocompleteDriverCode,
  createVehicle,
  loadMasterVehicle,
  loadDriverFormapping,
  matchVehicle,
  unmatchVehicle,
  loadVehicleForEdit,
  updateMasterDataVehicle,
  loadDriverHistory,
  loadVehiclePart,
};
