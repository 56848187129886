import React, { Component, Fragment } from "react";
import { renderToString } from "react-dom/server";
import { connect } from "react-redux";
import { debounce } from "debounce";
import {
  Menu,
  Dropdown,
  Statistic,
  Button,
  Row,
  Col,
  Icon,
  Divider,
  Spin,
  Carousel,
  Tabs,
  Table,
  Typography,
  Tooltip,
} from "antd";

import * as moment from "moment";
import {
  checkInsidePolygon,
  calcCrowDistance,
  computeArea,
} from "../../../../lib/helper";
import LangContext, { i18n } from "modules/shared/context/langContext";
import { ReplayStyledContent } from "../../../../styled/common-styled";

import {
  loadHeatMap,
  loadVehicleTypeMasterData,
  loadVehicleGroupMasterData,
  loadNextEventDetail,
  removeLists,
} from "../../actions";
import AuthorizeComponent from "./../../../auth/components/AuthorizeComponent";
import LineChart from "./Chart";
import StartEndPointMarker from "../../../shared/components/map-assets/Marker/StartEndPointMarker";
import VehicleMarker from "../../../shared/components/map-assets/Marker/VehicleMarker";
import { PinPointOfInterest } from "../../../shared/components/map-assets/PinPointOfInterest";

import Map from "./Map";
import HereMap from "./Map/HereMap";
import { SearchModal } from "./SearchModal";
import { IconDescModal } from "./IconDescModal";
const { Paragraph } = Typography;
const { TabPane } = Tabs;
const ButtonGroup = Button.Group;
const { Column } = Table;

const initialSelectedEvent = {
  event_id: null,
  plate_no: null,
  action: null,
  lat: 13.604403,
  lng: 101.359439,
};

export class Index extends Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
  }
  static defaultProps = {
    center: {
      lat: 13.729852,
      lng: 100.559484,
    },
    zoom: 9,
  };

  state = {
    scrollYPosition: 0,
    mainChartType: "speed",
    showMonitorCam: false,
    pageHeight: window.innerHeight - 45,
    detailHeight: 190,
    detailExpand: false,
    filterVisible: true,
    iconDetailVisible: false,
    descriptionBoxDrawerWidth: "0px",
    latest_config_change: new Date().getTime(),
    map: null,
    maps: null,
    mapAPI: {
      trafficLayer: null,
    },
    mapLayer: null,
    mapPlatform: null,
    hereMapDraw: 0,
    hereMarker: [],
    herePolyline: [],
    hereSetPolyline: [],
    routePolyline: null,
    showTrafficLayer: false,
    showPOI: false,
    showDistance: false,
    showArea: false,
    descriptionBoxHeader: "",
    descriptionBoxDetail: "",
    width: "0px",
    distanceMarker: [],
    distancePolyline: [],
    distanceLineString: null,
    poiMarker: null,
    areaPolyline: [],
    heatMapLayer: [],
    warningType: "alert",
    eventType: [],
    masterDataSelectvalue: ["vehicle_type_all", "vehicle_group_all"],
    startDate: moment(),
    startTime: moment().startOf("day"),
    endDate: moment(),
    endTime: moment(),
    togglePointMarker: false,
    toggleHeatMap: true,
    selectedEvent: initialSelectedEvent,
    vehicleList: [],
    selectedVehicle: [],
    selectedVehiclePlateNo: "",
    seletedStartAt: moment().format("YYYY-MM-DD 00:00:00"),
    seletedEndAt: moment().format("YYYY-MM-DD 23:59:59"),
    pageLoading: false,
    data: [],
    playBtn: "caret-right",
    currentSpeed: 0,
    virtualMarker: null,
    currentSelectedPosition: null,
    currentSelectedIndex: null,
    isPlaying: false,
    driverPeriod: [],
    dashcamImages: [],
    isShowSpeedChart: true,
    isShowEventsChart: true,
    isShowFuelChart: true,
    isShowTaxiFareChart: false,
    isShowTempChart: true,
    isMenuMapToolsCLick: "",
    isMenuMapTypeClick: "",
  };

  handleApplayButtonClick = (event) => {
    if (
      this.state.maps != null &&
      this.state.selectedVehicle.length != 0 &&
      this.props.match.params.id == undefined
    )
      this.getData();
    if (this.props.match.params.id != undefined) this.getData();
  };

  showSearchModal = () => {
    this.setState({
      filterVisible: true,
    });
  };

  showIconDescModal = () => {
    this.setState({
      iconDetailVisible: true,
    });
  };

  handleFilterCancel = () => {
    this.setState({
      filterVisible: false,
    });
  };

  handleIconDetailCancel = () => {
    this.setState({
      iconDetailVisible: false,
    });
  };

  removeDuplicateKey = (data) => {
    let dupes = {};
    data.forEach((item, index) => {
      dupes[item.created_at] = dupes[item.created_at] || [];
      dupes[item.created_at].push(index);
    });
    for (let name in dupes) {
      if (dupes[name].length > 1) {
        let index = data.findIndex((ele) => ele.created_at == name);

        data.splice(index, dupes[name].length);
      }
    }

    return data;
  };

  getVehicle = () => {
    let formData = new FormData();
    formData.append(
      "vehicle_visibility",
      this.props.auth.profile.vehicle_visibility
    );

    let fromURLData = new URLSearchParams();
    for (const pair of formData) {
      fromURLData.append(pair[0], pair[1]);
    }

    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclewithimei`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
      },
      body: fromURLData,
    })
      .then((response) => {
        try {
          if (response.status == 500) return [];
          return response.json();
        } catch (ex) {
          return [];
        }
      })
      .then((data) =>
        this.setState({ vehicleList: data }, this.filterVehicleForVehiclePage)
      );
  };

  // filterVehicleForVehiclePage = () =>
  // {
  //   // ทำงานกรณี กดlink มาจากหน้า vehicle
  //   if(this.props.match.params.id != undefined)
  //   {
  //     let data = this.state.vehicleList.find(ele => {
  //         return ele.id == this.props.match.params.id;
  //     });

  //     if(data != undefined)
  //     {
  //       this.setState({
  //         selectedVehiclePlateNo: data.plate_no
  //       });
  //     }
  //   }
  // }

  handleSeletedVehicleChange = (value, option) => {
    this.setState({
      selectedVehicle: value,
      selectedVehiclePlateNo: option.props.children,
    });
  };

  handleSeletedDateTimeChange = (value1, value2) =>
    this.setState({ seletedStartAt: value1, seletedEndAt: value2 });

  handleImageLoaded = (event) => {
    //event.target.parentNode.removeChild(event.target.parentNode.firstChild);
    event.target.parentNode.firstChild.style.display = "none";
    event.target.style.display = "inherit";
    //console.log(event.target.parentNode.firstChild);
  };

  handleImageErrored = (event) => {
    event.target.src = "/img/no-image.jpg";
    event.target.className = "";
  };

  drawData = (error, images, gpsData, nearest) => {
    let self = this;
    let image0 = [];
    let image1 = [];

    !error &&
      images.map((ele) => {
        if (ele.index == 0) image0.push(ele);
        else image1.push(ele);
      });

    let timeDiff = null;
    let isHasGpsConnectionLossToLong = false;
    let tempCreatedDateTime = null;
    let tempEngineOn = null;
    let curIndexImage0 = 0;
    let curIndexImage1 = 0;
    /* gps_location loop */
    let data = gpsData.map((ele) => {
      let haveImage0 = null;
      let haveImage1 = null;
      let imageIndex0Key = null;
      let imageIndex1Key = null;
      // let imageIndex = null;
      /* images loop */
      if (image0.length > 0)
        for (let i = curIndexImage0; i < image0.length; i++) {
          const imageEle = image0[i];
          let milliseconds =
            new Date(imageEle.gps_date) - new Date(ele.created_at);
          let min = Math.abs(milliseconds);

          if (min < 1000 * 35) {
            curIndexImage0 = i + 1;

            haveImage0 = "Snapshot";
            imageIndex0Key = imageEle.key;
            break;
          }
        }

      if (image1.length > 0)
        for (let i = curIndexImage1; i < image1.length; i++) {
          const imageEle = image1[i];
          let milliseconds =
            new Date(imageEle.gps_date) - new Date(ele.created_at);
          let min = Math.abs(milliseconds);

          if (min < 1000 * 35) {
            curIndexImage1 = i + 1;

            haveImage1 = "Snapshot";
            imageIndex1Key = imageEle.key;
            break;
          }
        }

      if (haveImage0 == null && haveImage1 == null) {
        return { ...ele, x: ele.lat, y: ele.lng };
      } else {
        return {
          ...ele,
          x: ele.lat,
          y: ele.lng,
          image: haveImage0 != null || haveImage1 != null ? "Snapshot" : null,
          imageIndex0Key,
          imageIndex1Key,
          // imageIndex
        };
      }
    });

    // console.log(newData);
    self.setState({
      driverPeriod: nearest,
      data: data,
      endIndex: data.length - 1,
    });

    if (typeof data != "undefined") {
      if (this.props.auth.profile.map_type == "google") {
        let iconArrow = {
          path: self.state.maps.SymbolPath.FORWARD_CLOSED_ARROW,
          scale: 2.6,
          fillColor: "black",
          strokeOpacity: 0.7,
        };

        let routePolyline = new self.state.maps.Polyline({
          path: data,
          strokeColor: "#2E9AFE",
          strokeOpacity: 0.7,
          strokeWeight: 5,
          icons: [
            {
              icon: iconArrow,
              repeat: "100px",
              offset: "0%",
            },
          ],
        });

        if (this.state.routePolyline == null) {
          self.setState({ routePolyline: routePolyline });
          routePolyline.setMap(self.state.map);
        } else {
          this.state.routePolyline.setMap(null);
          self.setState({ routePolyline: routePolyline });
          routePolyline.setMap(self.state.map);
        }

        if (this.state.maps != null) {
          let bounds = new this.state.maps.LatLngBounds();
          let position = null;

          for (let i = 0; i < data.length; i++) {
            position = new this.state.maps.LatLng(data[i].lat, data[i].lng);

            bounds.extend(position);
          }

          this.state.map.fitBounds(bounds);
        }

        // event handle click on polyline
        self.state.maps.event.addListener(routePolyline, "click", function(
          clickEvent
        ) {
          let latlng = clickEvent.latLng;

          let needle = {
            minDistance: 9999999, //silly high
            index: -1,
            latlng: null,
          };
          routePolyline.getPath().forEach(function(routePoint, index) {
            let dist = self.state.maps.geometry.spherical.computeDistanceBetween(
              latlng,
              routePoint
            );
            if (dist < needle.minDistance) {
              needle.minDistance = dist;
              needle.index = index;
              needle.latlng = routePoint;
            }
          });
          // The closest point in the polyline
          self.handleVirtualMarkerChange(
            self.state.data[needle.index],
            needle.index
          );
          // The clicked point on the polyline
        });
      } else {
        let speedOver = `/img/map/status_icon/20_20/speed20.png`;
        let engineOn = `<svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" width="10px" height="10px" onClick="alert('test')">
            <circle cx="5" cy="5" r="5.5" fill="#2E9AFE" stroke-width="0" stroke="#06C900" opacity="1"/>
            <circle cx="5" cy="5" r="4" fill="white" stroke-width="0" stroke="#06C900" opacity="1"/>
            <circle cx="5" cy="5" r="2.7" fill="#06C900" stroke-width="0" stroke="#06C900" opacity="1"/>
            </svg>`;
        let engineOnWithSnapShot = `<svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" width="12px" height="12px">
            <rect x="0" y="0" width="100%" height="100%" style="fill:#717171;stroke-width:4;stroke:#717171;" />
            <circle cx="6" cy="6" r="5.5" fill="#2E9AFE" stroke-width="0" stroke="#06C900" opacity="1"/>
            <circle cx="6" cy="6" r="4" fill="white" stroke-width="0" stroke="#06C900" opacity="1"/>
            <circle cx="6" cy="6" r="2.7" fill="#06C900" stroke-width="0" stroke="#06C900" opacity="1"/>
            </svg>`;
        let engineOff = `<svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" width="10px" height="10px">
            <circle cx="5" cy="5" r="5.5" fill="#2E9AFE" stroke-width="0" stroke="#06C900" opacity="1"/>
            <circle cx="5" cy="5" r="4" fill="white" stroke-width="0" stroke="#06C900" opacity="1"/>
            <circle cx="5" cy="5" r="2.7" fill="#dc3545" stroke-width="0" stroke="#dc3545" opacity="1"/>
            </svg>`;
        let notFix = `<svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" width="12px" height="12px">
            <circle cx="5" cy="5" r="5.5" fill="#717171" stroke-width="0" stroke="#717171" opacity="1"/>
            <circle cx="5" cy="5" r="4" fill="#717171" stroke-width="0" stroke="#717171" opacity="1"/>
            <circle cx="5" cy="5" r="2.7" fill="#717171" stroke-width="0" stroke="#717171" opacity="1"/>
            </svg>`;

        let domIcon = null;
        let markers = [];

        if (this.state.hereMarker.length > 0) {
          self.state.map.removeObjects(this.state.hereMarker);
          self.setState({
            hereMarker: [],
          });
        }
        if (this.state.herePolyline.length > 0) {
          self.state.map.removeObjects(this.state.herePolyline);
          self.setState({
            herePolyline: [],
          });
        }

        if (this.state.hereSetPolyline.length > 0) {
          self.state.map.removeObjects(this.state.hereSetPolyline);
          self.setState({
            hereSetPolyline: [],
          });
        }

        let lineString = new self.state.maps.geo.LineString();
        let setOfPolyline = [];
        // create markers
        data.forEach(function(row, index) {
          if (
            typeof row.speedOverLimit != "undefined" &&
            row.speedOverLimit == "Speed over"
          ) {
            domIcon = new self.state.maps.map.Icon(speedOver);
            markers.push(
              new self.state.maps.map.Marker(
                { lat: row.lat, lng: row.lng },
                {
                  icon: domIcon,
                }
              )
            );
          }
          if (row.isfixed == 1) {
            if (
              typeof row.engineOn != "undefined" &&
              row.engineOn == "Engine on"
            ) {
              if (typeof row.image != "undefined" && row.image === "Snapshot") {
                domIcon = new self.state.maps.map.Icon(engineOnWithSnapShot);
              } else {
                domIcon = new self.state.maps.map.Icon(engineOn);
              }
            }

            if (
              typeof row.engineOff != "undefined" &&
              row.engineOff == "Engine off"
            ) {
              if (typeof row.image != "undefined" && row.image === "Snapshot") {
                domIcon = new self.state.maps.map.Icon(engineOff);
              } else {
                domIcon = new self.state.maps.map.Icon(engineOff);
              }
            }
          } else {
            domIcon = new self.state.maps.map.Icon(notFix);
          }

          //////// start draw line//////////////////

          if (
            tempCreatedDateTime != null &&
            typeof row.engineOn != "undefined" &&
            tempEngineOn != null &&
            tempEngineOn
          ) {
            let duration = moment.duration(
              moment(tempCreatedDateTime).diff(row.created_at)
            );

            timeDiff = Math.abs(Math.ceil(duration.asMinutes()));
            // console.log(row.created_at, timeDiff);
            if (timeDiff > 3) {
              isHasGpsConnectionLossToLong = true;
            }
          }
          // if (isHasGpsConnectionLossToLong) {
          if (false) {
            setOfPolyline = [...setOfPolyline, lineString];

            lineString = new self.state.maps.geo.LineString();
          }
          lineString.pushPoint({ lat: row.lat, lng: row.lng });
          let mObj = new self.state.maps.map.Marker(
            { lat: row.lat, lng: row.lng },
            {
              icon: domIcon,
            }
          );
          mObj.addEventListener(
            "tap",
            function(e) {
              self.handleVirtualMarkerChange(self.state.data[index], index);
            },
            false
          );
          markers.push(mObj);

          tempEngineOn = typeof row.engineOn != "undefined";
          tempCreatedDateTime = row.created_at;
          isHasGpsConnectionLossToLong = false;
          // console.log(index, data.length - 1);
          if (index == data.length - 1) {
            setOfPolyline = [...setOfPolyline, lineString];
          }
        }); // end gpsData loop

        // start loop for draw multi polyline
        let routeLine = (routeLine = new self.state.maps.map.Group());
        let routeOutline = null;
        let routeArrows = null;
        console.log(setOfPolyline);
        setOfPolyline
          .filter((ele) => ele.X.length > 3)
          .map((lineStringEle) => {
            routeOutline = new self.state.maps.map.Polyline(lineStringEle, {
              style: {
                lineWidth: 7,
                strokeColor: "rgba(0, 128, 255, 0.6)",
                lineTailCap: "arrow-tail",
                lineHeadCap: "arrow-head",
              },
            });
            // Create a patterned polyline:
            routeArrows = new self.state.maps.map.Polyline(lineStringEle, {
              style: {
                lineWidth: 9,
                lineHeight: 10,
                fillColor: "rgba(255, 255, 255, 0.8)",
                strokeColor: "rgba(255, 255, 255, 0.8)",
                lineDash: [0, 6],
                lineTailCap: "arrow-tail",
                lineHeadCap: "arrow-head",
              },
            });
            // create a group that represents the route line and contains
            // outline and the pattern

            routeLine.addObjects([routeOutline, routeArrows]);
          });
        routeLine && this.state.map.addObjects([routeLine]);
        //////// end draw line//////////////////

        // add markers to map
        this.state.map.addObjects(markers);

        self.setState({
          hereMarker: markers,
          hereSetPolyline: [routeLine],
        });

        self.hereMapSetMapViewBounds(lineString);
      }
      self.setState({
        filterVisible: false,
        pageLoading: false,
      });
      return data;
    }

    self.setState({
      filterVisible: false,
      pageLoading: false,
      currentSelectedPosition: data.length > 0 ? data[0].created_at : null,
      currentSelectedIndex: data.length > 0 ? 0 : null,
    });
  };

  getData = () => {
    let device_id = this.state.selectedVehicle.split("|")[0];
    let imei = this.state.selectedVehicle.split("|")[1];

    let self = this;

    let start_at = this.state.seletedStartAt;
    let end_at = this.state.seletedEndAt;

    this.setState({
      data: [],
      playBtn: "caret-right",
      currentSpeed: 0,
      virtualMarker: null,
      currentSelectedPosition: null,
      currentSelectedIndex: null,
      isPlaying: false,
      driverPeriod: [],
      dashcamImages: [],
      pageLoading: true,
    });

    clearInterval(this.playingInterval);

    fetch(
      `${process.env.REACT_APP_GO_API_ENDPOINT}/replay/drivinginfo?start_at=${start_at}&end_at=${end_at}&device_id=${device_id}`
      //`http://localhost:8000/api/replay/drivinginfo?start_at=${start_at}&end_at=${end_at}&device_id=${device_id}`
    )
      .then((response) => {
        try {
          if (response.status == 500) return [];
          return response.json();
        } catch (ex) {
          console.error("Error-1:", ex);
          self.setState({ filterVisible: false, pageLoading: false });
          return [];
        }
      })
      .then((data) => {
        try {
          let gpsData = data != null ? data : [];
          let tempIsFixed = null;
          let tempLat = 0;
          let tempLng = 0;
          let tempDirectionDegree = 0;
          gpsData.map((ele) => {
            if (ele.isfixed == 0 && tempIsFixed != null) {
              ele.lat = tempLat;
              ele.lng = tempLng;
              ele.directionDegree = tempDirectionDegree;
            }

            tempIsFixed = ele.isfixed;
            tempLat = ele.lat;
            tempLng = ele.lng;
            tempDirectionDegree = ele.directionDegree;
            return {
              ...ele,
            };
          });

          if (data != null && data != "null")
            fetch(
              `${process.env.REACT_APP_GO_API_ENDPOINT}/replay/driverperiod?start_at=${start_at}&end_at=${end_at}&device_id=${device_id}`
              //`http://localhost:8000/api/replay/driverperiod?start_at=${start_at}&end_at=${end_at}&device_id=${device_id}`
            )
              .then((response) => {
                try {
                  if (response.status == 500) return [];
                  return response.json();
                } catch (ex) {
                  console.error("Error-2:", ex);
                  self.setState({ filterVisible: false, pageLoading: false });
                  return [];
                }
              })
              .then((data) => {
                // try {
                let driverData = data;

                if (gpsData.length > 0) {
                  let nearest =
                    driverData != null
                      ? this.findNearestDate(gpsData, driverData)
                      : [];
                  let formData = new FormData();
                  formData.append("type", "getimagebydatetime");
                  formData.append("imei", imei);
                  formData.append("start_at", `${start_at}`);
                  formData.append("end_at", `${end_at}`);

                  let fromURLData = new URLSearchParams();
                  for (const pair of formData) {
                    fromURLData.append(pair[0], pair[1]);
                  }
                  fetch(
                    `${process.env.REACT_APP_IMAGE_SERVER_API_ENDPOINT}/imageserver/gps_image_api.php`,
                    {
                      //fetch(`http://61.90.201.19/imageserver/gps_image_api.php`, {
                      method: "POST",
                      headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods":
                          "GET, POST, PUT, DELETE, OPTIONS",
                      },
                      body: fromURLData,
                    }
                  )
                    .then((response) => {
                      try {
                        if (response.status == 500) return [];
                        return response.json();
                      } catch (ex) {
                        console.error("Error-3;", ex);
                        return [];
                      }
                    })
                    .then((images) => {
                      try {
                        // console.log(images);
                        this.drawData(false, images, gpsData, nearest);

                        self.handleFilterCancel = () => {
                          this.setState({
                            filterVisible: false,
                          });
                        };
                      } catch (err) {
                        console.error("Error-4:", err);
                        self.setState({
                          filterVisible: false,
                          pageLoading: false,
                        });
                      }
                    })
                    .catch((err) => {
                      console.error("Error-5:", err);
                      this.drawData(true, [], gpsData, nearest);
                    });
                }
                // } catch (err) {
                //   console.log(err.message);
                // }
              });
          else {
            if (self.state.routePolyline != null)
              self.state.routePolyline.setMap(self.state.map);
            self.setState({ filterVisible: false, pageLoading: false });
          }
        } catch (err) {
          console.error("Error-6:", err);
          self.setState({ filterVisible: false, pageLoading: false });
        }
      });
  };

  findNearestDate = (gpsData, driverData) => {
    if (driverData[0].end_at < gpsData[0].created_at) {
      driverData.shift();
    }

    driverData.forEach(function(ele, index) {
      let startDistance = Infinity;
      let endDistance = Infinity;
      let startNearest = Infinity;
      let endNearest = Infinity;
      let start_date = new Date(ele.start_at).getTime();
      let end_date = new Date(ele.end_at).getTime();

      gpsData.forEach(function(gpsDataEle, gpsDataIndex) {
        let formatTarget = new Date(gpsDataEle.created_at).getTime();

        startDistance = Math.abs(start_date - formatTarget);
        endDistance = Math.abs(end_date - formatTarget);

        if (startDistance < startNearest) {
          ele.start_at = gpsDataEle.created_at;
          startNearest = startDistance;
          driverData[index] = ele;
        }

        if (endDistance < endNearest) {
          ele.end_at = gpsDataEle.created_at;
          endNearest = endDistance;
          driverData[index] = ele;
        }
      });
    });

    return driverData;
  };

  findNearestImageDate = (gpsData, driverData) => {
    if (driverData[0].end_at < gpsData[0].created_at) {
      driverData.shift();
    }

    driverData.forEach(function(ele, index) {
      let startDistance = Infinity;
      let endDistance = Infinity;
      let startNearest = Infinity;
      let endNearest = Infinity;
      let start_date = new Date(ele.start_at).getTime();
      let end_date = new Date(ele.end_at).getTime();

      gpsData.forEach(function(gpsDataEle, gpsDataIndex) {
        let formatTarget = new Date(gpsDataEle.created_at).getTime();

        startDistance = Math.abs(start_date - formatTarget);
        endDistance = Math.abs(end_date - formatTarget);

        if (startDistance < startNearest) {
          ele.start_at = gpsDataEle.created_at;
          startNearest = startDistance;
          driverData[index] = ele;
        }

        if (endDistance < endNearest) {
          ele.end_at = gpsDataEle.created_at;
          endNearest = endDistance;
          driverData[index] = ele;
        }
      });
    });
    return driverData;
  };

  handleVirtualMarkerChange = (e, activeTooltipIndex) => {
    this.setState({
      virtualMarker: e,
      currentSelectedPosition: e.created_at,
      currentSelectedIndex: activeTooltipIndex,
    });
    if (this.props.auth.profile.map_type == "google") {
      this.state.map.panTo(e);
    } else {
      this.state.map &&
        this.state.map.setCenter({
          lat: parseFloat(e.lat),
          lng: parseFloat(e.lng),
        });
      this.state.map && this.state.map.setZoom(15);
    }
    // console.log(this.state.virtualMarker);
  };

  playBtnToggle = () => {
    if (this.state.playBtn == "caret-right")
      this.setState({ playBtn: "pause" });
    else this.setState({ playBtn: "caret-right" });
  };

  hereMapSetMapViewBounds = (latLngData) => {
    if (typeof latLngData != "undefined") {
      let bbox = new this.state.maps.geo.Rect.coverLatLngAlts(latLngData.W);
      this.state.map.getViewModel().setLookAtData({
        bounds: bbox,
      });
    }
  };

  showMarker = (item) => {
    this.setState({
      selectedEvent: {
        event_id: item.event_id,
        plate_no: item.plate_no,
        action: item.action,
        lat: item.lat,
        lng: item.lng,
      },
    });

    this.state.map.setCenter({
      lat: parseFloat(item.lat),
      lng: parseFloat(item.lng),
    });
  };

  handleApiLoaded = (map, maps, platform = null, mapLayer = null) => {
    if (typeof maps == "undefined" || maps == null) window.location.reload();

    this.setState({
      map: map,
      maps: maps,
      mapAPI:
        typeof maps.TrafficLayer != "undefined"
          ? { ...this.state.mapAPI, trafficLayer: new maps.TrafficLayer() }
          : null,
      mapLayer: mapLayer,
      mapPlatform: platform,
    });

    ////////////////////////////////////////////////////////////////////
    if (this.props.auth.profile.map_type == "google") {
      const directionsService = new this.state.maps.DirectionsService();
      let start = { lat: parseFloat(13.538621), lng: parseFloat(100.787912) };
      let end = {
        lat: parseFloat(13.588538),
        lng: parseFloat(100.833585),
      };

      directionsService.route(
        {
          origin: start,
          destination: end,
          travelMode: "DRIVING",
        },
        (response, status) => {}
      );
    }
  };

  handleWarningTypeChange = (value) => {
    this.setState({ warningType: value });
  };

  handleEventTypeSelectboxChange = (value) => {
    this.setState({ eventType: value });
  };

  handleTogglePointMarker = (e) => {
    this.setState({
      togglePointMarker: this.state.togglePointMarker ? false : true,
    });
  };

  handleToggleHeatMap = (e) => {
    this.setState({ toggleHeatMap: this.state.toggleHeatMap ? false : true });
  };

  handleClearAllToolDrawing = () => {
    this.setState({
      isMenuMapToolsCLick: "",
    });
    this.clearPOIMarker();
    this.clearDistanceMarker();
    this.clearAreaMarker();
  };

  clearPOIMarker = () => {
    if (this.props.auth.profile.map_type == "google") {
      //if (false) {
      if (this.state.poiMarker != null) {
        this.state.poiMarker.setMap(null);
        this.handleDescriptionBoxDrawerClose();
      }
      this.setState({
        showPOI: false,
        poiMarker: null,
        descriptionBoxHeader: "",
        descriptionBoxDetail: "",
      });
      this.state.maps.event &&
        this.state.maps.event.clearListeners(this.state.map, "click");
    } else {
      if (this.state.poiMarker != null) {
        this.state.map.removeObjects([this.state.poiMarker]);
        this.handleDescriptionBoxDrawerClose();
      }
      this.setState({
        showPOI: false,
        poiMarker: null,
        descriptionBoxHeader: "",
        descriptionBoxDetail: "",
      });
      this.state.maps && this.state.map.removeEventListener("tap", () => false);
    }
  };

  clearDistanceMarker = () => {
    if (this.props.auth.profile.map_type == "google") {
      //if (false) {
      if (this.state.distanceMarker != []) {
        this.state.distanceMarker.map((value, index) => {
          value.setMap(null);
        });
      }

      if (this.state.distancePolyline != []) {
        this.state.distancePolyline.map((value, index) => {
          value.setMap(null);
        });
      }

      this.handleDescriptionBoxDrawerClose();
      this.state.maps.event &&
        this.state.maps.event.clearListeners(this.state.map, "click");

      this.setState({
        showDistance: false,
        distanceMarker: [],
        distancePolyline: [],
        descriptionBoxHeader: "",
        descriptionBoxDetail: "",
      });
    } else {
      this.handleDescriptionBoxDrawerClose();
      this.state.maps && this.state.map.removeEventListener("tap", () => false);
      if (this.state.distanceMarker.length > 0)
        this.state.map.removeObjects(this.state.distanceMarker);
      if (this.state.distancePolyline.length > 0)
        this.state.map.removeObjects(this.state.distancePolyline);

      this.state.distanceMarker.map((ele) => {
        this.state.distanceLineString.removePoint({
          lat: ele.b.lat,
          lng: ele.b.lng,
        });
      });

      this.setState({
        showDistance: false,
        distanceMarker: [],
        distancePolyline: [],
        descriptionBoxHeader: "",
        descriptionBoxDetail: "",
      });
    }
  };

  clearAreaMarker = () => {
    if (this.props.auth.profile.map_type == "google") {
      //if (false) {
      if (this.state.areaPolyline != []) {
        this.state.areaPolyline.map((value, index) => {
          value.setMap(null);
        });
      }

      this.handleDescriptionBoxDrawerClose();
      this.state.maps.event &&
        this.state.maps.event.clearListeners(this.state.map, "click");

      this.setState({
        showArea: false,
        areaPolyline: [],
        descriptionBoxHeader: "",
        descriptionBoxDetail: "",
      });
    } else {
      if (this.state.areaPolyline.length > 0) {
        this.state.map.removeObjects(this.state.areaPolyline);

        // this.state.areaPolyline.map((ele) => this.state.map.removeObjects(ele));
      }

      this.state.maps && this.state.map.removeEventListener("tap", () => false);
      this.handleDescriptionBoxDrawerClose();
      this.setState({
        showArea: false,
        areaPolyline: [],
        descriptionBoxHeader: "",
        descriptionBoxDetail: "",
        hereMapDraw: 0,
      });
    }
  };

  callApiPinPoinOfInterest = (self, lat, lng) => {
    let poiMarker = null;
    let iconImg =
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAElklEQVRoge2aT6gVZRjGf8/lIiIuRERcRNCcjXfIEImWIfeARIvCVhEFroKWLQxahASJRIRJRkZk5MZLixCzoptzkBZCFnETnbPxDtKihchFREQuh/O0mJl75pz778yfIy16Lx/zLM687/N83zPvzDdzRcORtMMnjWcx+yTtBvq27wB/SeoEUfxPk/XURJKkHQI8b/weZhaYEmANijiFPWBe6P0giq81Ubu2gMXZcJvEZ4YjBaID0gbJGBVxX3AaczToxMt16tcSkLTDHcDPwHO4kG18fBl4OYjih1U5VBaw2A6nsX+ROOiMmIB1caHgCL4odDiI4n4VHtNVBQjeMRzEazK/b/Sj5JtGU8A+zIuIbcDoSryEeBM4U5FH+Uja4W7jRcz2IUsAQmeQ3w2i7r3iOYvtcBdwEvv1NVLelXgqiLoPynKZKntCxvWI0HZJrPxJgI4h3holD9CK4rvAGxKfSOnvC2OX0atVuFQSIDhse8XLtgGuSv4giOJ1z2tFMZijwI3iuZmjDlfhUlpAMjszbbw/971xbsSTQdTd9EIMOt0ecAo7F57neTZpz5SlU/4itrRLsBUYuoIMV0qk+TW13FDsJM37qAyf8hZyKnrUQsJLJbIsjVoo41J6QksLEH5g6KP0zpp2T2G0p0SaPThrXIM8y5ScfaiyAtJ94YfCSNnAgF8YN4Xxofy8Qp47QRT3ytIpLSC9YyoxeetX1kV0NGmHWzc7f7Edbse8nV6/mYQU3yrLBareB+zrIPBKcWz22v48aYfr5kza4RbwN0hP5MLTEMCNKlwqCQB+H7VQijmC+SmZndmbPWLnxElmZ/ZjIsErK78vWMjwWxUilZ6FBFcHD2TCK2YSlg8BNwULSTuMnU7S00bPgLPfAzZSjkH46mMTgLQALGF2DnWSAZ4yHEAcwNnGJtvhFG2zguXE6HYVKpUsFERxT3B5tYXWwWyC0Xxrg0eQxgWk4R+KXWhdvLrbrMLA91VZVBZgdAmrlxMpdqQhzDq2GeD7QKcqj8oCWlG8JNGpbSFzKYji0nfg2gKyOF/XQuDzdQjUE2BfMH6Ys7azVlTEkLVZr4XvAPN1KNQSEHS692RdWGOHNTwKu7ZhzFzQ6dZ6rVLXQhi+Suc0n9URnG1a7GGcHvV13fq1BUi+Ar41sI0ZwmSCNIyNr0ks1K1fW0AQdftCX5a2EPpio/3zYxMAYHzW+FGKx7GQl4C5Jmo3IqAVde8K5sa1kKSzdV4nFqMRAQBGp8a0UA/4tKm6jQkALwCdMSz0XRDFfzdVtTEBragL8NGmFrI/bqomNLoCYDwv6foGFroiqZEPG3k0KqCVvpn7cAMLnWiidRajUQFZfItJVlkI/pBU67lnrWhcQBDFPUknVlvIx5uefZjMCgCcA24XLHQddHEShSYiIIjiZeD4wEIcCzrVPiFtFpNaAYBzkm5J+lMwkdmfeCTt8LWkHY79zrRKVP7IN2bMGSZinf/jvxKN/K8EsCUb09nIm0N+7BeOvWwsZ6NWNCFgK7CD8h2tD9yjwleZYjS1ApCuQP6da6MVyFeh9uwD/Asypo/TAASupgAAAABJRU5ErkJggg==";

    if (self.state.showPOI) {
      if (this.props.auth.profile.map_type == "google") {
        //if (false) {
        // claer old point marker
        if (self.state.poiMarker != null) self.state.poiMarker.setMap(null);
        poiMarker = new self.state.maps.Marker({
          icon: iconImg,

          position: { lat, lng },
          map: self.state.map,
        });

        self.setState({ poiMarker: poiMarker });
      } else {
        // claer old point marker
        if (self.state.poiMarker != null)
          self.state.map.removeObjects([self.state.poiMarker]);

        let htmlMarker = renderToString(<img src={iconImg} />);
        //let htmlMarker = ele.type;
        // Create a marker icon from an image URL:
        console.log(htmlMarker);
        var icon = new self.state.maps.map.DomIcon(htmlMarker),
          coords = { lat: lat, lng: lng },
          marker = new self.state.maps.map.DomMarker(coords, { icon: icon });

        self.state.map.addObject(marker);
        self.setState({ poiMarker: marker });
      }

      fetch(
        `https://api.longdo.com/map/services/address?lon=${lng}&lat=${lat}&nopostcode=1&noelevation=1&noroad=1&noaoi=1&nowater=1&key=823962a623a584cb9f7e3475fe3c90b2`
      )
        .then((response) => response.json())
        .then((data) => {
          try {
            let { subdistrict, district, province, geocode, country } = data;
            self.setState({
              descriptionBoxDrawerWidth: "300px",
              descriptionBoxHeader: `${subdistrict} ${district} ${province} ${geocode} ${country}`,
              pinLat: lat,
              pinLng: lng,
            });
          } catch (ex) {
            self.setState({
              descriptionBoxDrawerWidth: "300px",
              descriptionBoxHeader: `Cannot get location Infomation`,
            });
            console.log(ex);
          }
        });
    }
  };

  handlePinPointOfInterest = (e) => {
    let self = this;

    this.setState({
      isMenuMapToolsCLick: "pushpin",
    });

    if (this.state.showPOI) {
      this.clearPOIMarker();
    } else {
      this.setState({ showPOI: true, showDistance: false });
      this.clearDistanceMarker();
      this.clearAreaMarker();
    }

    if (this.props.auth.profile.map_type == "google") {
      // if (false) {
      this.state.maps.event.addListener(this.state.map, "click", function(
        event
      ) {
        self.callApiPinPoinOfInterest(
          self,
          event.latLng.lat(),
          event.latLng.lng()
        );
      });
    } else {
      let self = this;
      self.state.map &&
        self.state.map.addEventListener("tap", function(evt) {
          var coord = self.state.map.screenToGeo(
            evt.currentPointer.viewportX,
            evt.currentPointer.viewportY
          );
          self.callApiPinPoinOfInterest(
            self,
            Math.abs(coord.lat.toFixed(4)),
            Math.abs(coord.lng.toFixed(4))
          );
        });
    }
  };

  handleFindDistance = (e) => {
    let self = this;
    let iconImg =
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAADMUlEQVRoge2Zv2sUURDHP3McwUpEDsuwlVhYRNNYb+wtrFLmf7gUIX+Cy5G/wb9AJGDldso1IlgpiCxBrUKwEglhx+Leu3v3Y293dt+RFBl4vO/dvZ03s/N9M7N7glGS99lzRF8CfRBA3S91WNznSlyi8q7YG76x2CP1SwLj8+wZ8AHV3uRKAVVqsU1eFOnwbdPFPaPyFOghwtSwOqwAOpmbYNizGGRzQOmh6jGNsF1MNvVNqkNKiBrxop4a3FCsEXBzYFgd1uDaJtgoxgi4HaZ3SkP8FeELSjm1xPuznHgege7M6fRYbWGwOaChFQTW8RuR3SId/m2iJsmzPiqfER4vZ1tbGGwUCpLMAj5rajxAkQ6vEL6v/HGjEaCCQm2yja8Ti4f4WijUIntM6sSyquuhUEwxRsBaid1dkln6E5kVrlbK/LxIz2ZipJDOQj/H3Rah8LrmiiPmm2GkUETOSHD6lRk27tGOQn6eq8wtJTR+45X4lkKLum4MhXiY5NmDpiqSPLuLsjPTd+0U4j7wLcmzHxUXThc6vI0wgO4UMnaja8N7D3i69O20E5UVXWnQcnt8TRRa09+v4PlabJNYFFqDxYZvXBayRsG4h7Gdpl3R0gDU4Y32QvCzWy1o9DT/y6LR+lD/Gjg1XjOb6zA6BkYW9ebbmeTZANVPwLbtytrXkBcIT4r08Myi1ZxGi3R4jsg+IpeTXqbpYB0uETmwGt/KAefER+C40eJmteIEaPw+NJRWDjgZAaeosnbgZ1ZjYQxyVKTDVkZ0ai+T/NUApMV58KIXILtFOiza2tAlAhTp4TmwD1xVLqqkDSXIQRfjoaMDMD0PR3YKcWL5H6BKOjvgZITIqSELjYGjGBtHe8RK8mwALJ8H30JP03533ocSKwKT+gD7qF6uoVAJdOZ9KNEcAHceRI4rKYScgHTmfShRHQBAXX2YffZgjNA631fJJt5uruqXLtz/B0XsveJHgKV+qUTi8j6UjTgAYb+k0Xkfiv2JzCYjkF5s3t/KTZJYWWjLjb4b/mz5uQzmKzcu3egkMRy4w+StnDUhlMAf4F+XzWPWgS0mTtRFwEeh890H+A8e4J/aNz46/gAAAABJRU5ErkJggg==";
    this.setState({
      isMenuMapToolsCLick: "findDistance",
    });
    if (this.state.showDistance) {
      this.clearDistanceMarker();
    } else {
      this.setState({ showDistance: true, showPOI: false });
      this.clearPOIMarker();
      this.clearAreaMarker();
    }

    if (this.props.auth.profile.map_type == "google") {
      // if (false) {
      let poly = new this.state.maps.Polyline({
        strokeColor: "#2F7DFF",
        strokeOpacity: 0.6,
        strokeWeight: 4,
      });
      poly.setMap(this.state.map);

      this.state.maps.event.addListener(this.state.map, "click", function(
        event
      ) {
        if (self.state.showDistance) {
          let path = poly.getPath();
          path.push(event.latLng);

          let lengthInMeters = self.state.maps.geometry.spherical.computeLength(
            path
          );

          let marker = new self.state.maps.Marker({
            icon: iconImg,
            position: event.latLng,
            title: "#" + path.getLength(),
            map: self.state.map,
          });

          self.setState({
            distanceMarker: [...self.state.distanceMarker, marker],
            distancePolyline: [...self.state.distancePolyline, poly],
            descriptionBoxDrawerWidth: "300px",
            descriptionBoxHeader: `ระยะทาง : ${Number(
              lengthInMeters / 1000
            ).toFixed(2)} Km.`,
          });
        }
      });
    } else {
      let prevPoint = null;
      let lengthInMeters = 0;
      let lineString = null;
      if (this.state.distanceLineString == null)
        lineString = new self.state.maps.geo.LineString();
      else lineString = this.state.distanceLineString;
      self.state.map &&
        self.state.map.addEventListener("tap", function(evt) {
          if (self.state.showDistance) {
            var coord = self.state.map.screenToGeo(
              evt.currentPointer.viewportX,
              evt.currentPointer.viewportY
            );

            let htmlMarker = renderToString(
              <img style={{ margin: "-50px 0px 0px -23px" }} src={iconImg} />
            );
            //let htmlMarker = ele.type;
            // Create a marker icon from an image URL:
            console.log(htmlMarker);
            var icon = new self.state.maps.map.DomIcon(htmlMarker),
              coords = { lat: coord.lat, lng: coord.lng },
              marker = new self.state.maps.map.DomMarker(coords, {
                icon: icon,
              });

            self.state.map.addObject(marker);

            if ([...self.state.distanceMarker, marker].length > 0) {
              [marker].map((ele) => {
                if (prevPoint != null) {
                  lengthInMeters += calcCrowDistance(
                    prevPoint.b.lat,
                    prevPoint.b.lng,
                    ele.b.lat,
                    ele.b.lng
                  );
                }

                lineString.pushPoint({ lat: ele.b.lat, lng: ele.b.lng });
              });
              prevPoint = marker;

              if ([...self.state.distanceMarker, marker].length > 1) {
                let polyLine = new self.state.maps.map.Polyline(lineString, {
                  style: { lineWidth: 4 },
                });
                self.state.map.addObject(polyLine);
                self.setState({
                  distanceMarker: [...self.state.distanceMarker, marker],
                  distancePolyline: [...self.state.distancePolyline, polyLine],
                  distanceLineString: lineString,
                  descriptionBoxDrawerWidth: "300px",
                  descriptionBoxHeader: `ระยะทาง : ${Number(
                    lengthInMeters
                  ).toFixed(2)} Km.`,
                });
              } else {
                self.setState({
                  distanceMarker: [...self.state.distanceMarker, marker],
                  distanceLineString: lineString,
                });
              }
            }
          }
        });
    }
  };

  handleFindArea = (e) => {
    this.setState({
      isMenuMapToolsCLick: "findArea",
    });
    if (this.state.showArea) {
      this.clearAreaMarker();
    } else {
      this.setState({ showArea: true, showPOI: false });
      this.clearPOIMarker();
      this.clearDistanceMarker();
    }

    if (this.props.auth.profile.map_type == "google") {
      //if (false) {
      new this.state.maps.Polyline();

      let self = this;
      let polygonCoords = [];
      let polygonLayer = null;

      this.state.maps.event.addListener(this.state.map, "click", function(
        event
      ) {
        if (self.state.showArea) {
          polygonCoords.push({
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
          });

          // if (boundListpolygonCoords > 2) {
          if (polygonLayer != null) polygonLayer.setMap(null);
          polygonLayer = new self.state.maps.Polygon({
            path: polygonCoords,
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 3,
            fillColor: "#FF0000",
            fillOpacity: 0.35,
            editable: true,
            geodesic: true,
          });

          self.state.maps.event.addListener(
            polygonLayer.getPath(),
            "set_at",
            function() {
              subFnFindArea();
            }
          );

          subFnFindArea();
          function subFnFindArea() {
            let lengthInMeters = self.state.maps.geometry.spherical.computeArea(
              polygonLayer.getPath()
            );
            lengthInMeters > 0
              ? (lengthInMeters = (lengthInMeters / 1000).toFixed(2))
              : (lengthInMeters = lengthInMeters);

            polygonLayer.setMap(self.state.map);
            let vehicleAmount = 0;
            for (
              let i = 0;
              i < self.props.monitoringState.trackingList.length;
              i++
            ) {
              if (
                checkInsidePolygon(
                  {
                    lat: self.props.monitoringState.trackingList[i].latest_lat,
                    lng: self.props.monitoringState.trackingList[i].latest_lng,
                  },
                  polygonCoords
                )
              ) {
                vehicleAmount++;
              }
            }

            self.setState({
              areaPolyline: [...self.state.areaPolyline, polygonLayer],
              descriptionBoxDrawerWidth: "300px",
              descriptionBoxHeader: `พื้นที่ : ${Number(
                lengthInMeters / 1000
              ).toFixed(2)} Square km.`,
              descriptionBoxDetail: `จำนวนพาหนะ : ${vehicleAmount} คัน`,
            });
          }
        }
      });
    } else {
      let lengthInMeters = 0;
      let tempMaingroup = null;
      let pointerCount = 0;
      let tempPointMarker = [];

      let iconImg =
        '<div style="display: block; width: 11px; height: 11px; cursor: pointer; touch-action: none; position: absolute; left: 0px; top: 0px;"><div style="position: absolute; left: -5px; top: -5px; width: 9px; height: 9px; border-width: 1px; border-style: solid; border-radius: 6px; background-color: white; border-color: rgb(255, 0, 0);"></div></div>';
      let self = this;
      let serieOfLatLng = [];
      let latLngs = [];

      if (this.state.areaPolyline != null) {
        self.state.map.removeObjects(this.state.areaPolyline);
      }
      let randomnumber = Math.floor(Math.random() * (1000 - 1 + 1)) + 1;
      this.setState({ hereMapDraw: randomnumber });
      self.state.map &&
        self.state.map.addEventListener("tap", function(evt) {
          pointerCount++;
          if (self.state.showArea && self.state.hereMapDraw == randomnumber) {
            var coord = self.state.map.screenToGeo(
              evt.currentPointer.viewportX,
              evt.currentPointer.viewportY
            );

            serieOfLatLng.push(Math.abs(coord.lat.toFixed(4)));
            serieOfLatLng.push(Math.abs(coord.lng.toFixed(4)));
            serieOfLatLng.push(0);
            latLngs.push({
              lat: Math.abs(coord.lat.toFixed(4)),
              lng: Math.abs(coord.lng.toFixed(4)),
            });

            if (self.state.poiMarker != null)
              self.state.map.removeObjects([self.state.poiMarker]);

            if (self.state.areaPolyline != null) {
              self.state.map.removeObjects(self.state.areaPolyline);
              //tempMaingroup.dispose();
            }
            //////////////////////////////

            let polygonObj = new self.state.maps.geo.LineString(serieOfLatLng);
            let polygonObj2 = new self.state.maps.geo.Polygon(polygonObj);
            let polygon = new self.state.maps.map.Polygon(polygonObj2, {
              style: {
                fillColor: "rgba(255, 0, 0, 0.35)",
                strokeColor: "rgba(255, 0, 0, 1)",
                lineWidth: 3,
              },
            });
            let verticeGroup = new self.state.maps.map.Group({
              visibility: false,
            });
            let mainGroup = new self.state.maps.map.Group({
              volatility: true, // mark the group as volatile for smooth dragging of all it's objects
              objects: [polygon, verticeGroup],
            });
            let polygonTimeout;

            // ensure that the polygon can receive drag events
            polygon.draggable = true;

            console.log(iconImg);
            // create markers for each polygon's vertice which will be used for dragging
            polygon
              .getGeometry()
              .getExterior()
              .eachLatLngAlt(function(lat, lng, alt, index) {
                var vertice = new self.state.maps.map.DomMarker(
                  { lat, lng },
                  {
                    icon: new self.state.maps.map.DomIcon(iconImg, {
                      anchor: { x: 10, y: 10 },
                    }),
                  }
                );
                vertice.draggable = true;
                vertice.setData({ verticeIndex: index });
                verticeGroup.addObject(vertice);
              });

            // add group with polygon and it's vertices (markers) on the map
            self.state.map.addObject(mainGroup);
            tempMaingroup = mainGroup;

            mainGroup.addEventListener(
              "pointerenter",
              function(evt) {
                if (polygonTimeout) {
                  clearTimeout(polygonTimeout);
                  polygonTimeout = null;
                }

                // show vertice markers
                verticeGroup.setVisibility(true);
              },
              true
            );

            // // the vertice markers are hidden on touch devices after specific timeout
            // mainGroup.addEventListener(
            //   "pointerleave",
            //   function(evt) {
            //     var timeout = evt.currentPointer.type == "touch" ? 1000 : 0;

            //     // hide vertice markers
            //     polygonTimeout = setTimeout(function() {
            //       verticeGroup.setVisibility(false);
            //     }, timeout);
            //   },
            //   true
            // );

            // event listener for vertice markers group to change the cursor to pointer
            verticeGroup.addEventListener(
              "pointerenter",
              function(evt) {
                document.body.style.cursor = "pointer";
              },
              true
            );

            // event listener for vertice markers group to change the cursor to default
            verticeGroup.addEventListener(
              "pointerleave",
              function(evt) {
                document.body.style.cursor = "default";
              },
              true
            );

            // event listener for vertice markers group to resize the geo polygon object if dragging over markers
            verticeGroup.addEventListener(
              "drag",
              function(evt) {
                var pointer = evt.currentPointer,
                  geoLineString = polygon.getGeometry().getExterior(),
                  geoPoint = self.state.map.screenToGeo(
                    pointer.viewportX,
                    pointer.viewportY
                  );

                // set new position for vertice marker
                evt.target.setGeometry(geoPoint);

                // set new position for polygon's vertice
                geoLineString.removePoint(evt.target.getData()["verticeIndex"]);
                geoLineString.insertPoint(
                  evt.target.getData()["verticeIndex"],
                  geoPoint
                );
                polygon.setGeometry(
                  new self.state.maps.geo.Polygon(geoLineString)
                );

                // stop propagating the drag event, so the map doesn't move
                evt.stopPropagation();

                lengthInMeters = computeArea(latLngs);
              },
              true
            );

            //////////////////////////////

            let htmlMarker = iconImg;
            // Create a marker icon from an image URL:

            let icon = new self.state.maps.map.DomIcon(htmlMarker);
            let coords = { lat: coord.lat, lng: coord.lng };
            let marker = new self.state.maps.map.DomMarker(coords, {
              icon: icon,
            });

            if (pointerCount < 3) {
              self.state.map.addObject(marker);
              tempPointMarker.push(marker);
            } else {
              if (tempPointMarker.length > 0)
                self.state.map.removeObjects(tempPointMarker);
              tempPointMarker = [];
              lengthInMeters = computeArea(latLngs);
              let vehicleAmount = 0;
              for (
                let i = 0;
                i < self.props.monitoringState.trackingList.length;
                i++
              ) {
                if (
                  checkInsidePolygon(
                    {
                      lat:
                        self.props.monitoringState.trackingList[i].latest_lat,
                      lng:
                        self.props.monitoringState.trackingList[i].latest_lng,
                    },
                    latLngs
                  )
                ) {
                  vehicleAmount++;
                }
              }

              self.setState({
                areaPolyline: [mainGroup],
                descriptionBoxDrawerWidth: "300px",
                descriptionBoxHeader: `พื้นที่ : ${Number(
                  lengthInMeters
                )} Square km.`,
                descriptionBoxDetail: `จำนวนพาหนะ : ${vehicleAmount} คัน`,
              });
            }
          }
        });
    }
  };

  handleMapTypeSatellite = (e) => {
    this.setState({
      isMenuMapTypeClick: "satellite",
    });
    if (this.props.auth.profile.map_type == "google") {
      // if (false) {
      this.state.map.setMapTypeId("hybrid");
    } else {
      this.state.map.setBaseLayer(this.state.mapLayer.raster.satellite.map);
    }
  };

  handleMapTypeTerrain = (e) => {
    this.setState({
      isMenuMapTypeClick: "terrain",
    });

    if (this.props.auth.profile.map_type == "google") {
      //if (false) {
      this.state.map.setMapTypeId("roadmap");
    } else {
      if (this.state.isShowTrafficClick) {
        this.hereMapShowTraffic();
      } else {
        this.state.map.setBaseLayer(this.state.mapLayer.vector.normal.map);
      }
    }
  };

  handleShowTrafficLayer = (e) => {
    this.setState({
      isShowTrafficClick: !this.state.isShowTrafficClick,
    });
    if (this.props.auth.profile.map_type == "google") {
      //if (false) {
      if (this.state.showTrafficLayer) {
        this.state.mapAPI.trafficLayer.setMap(null);
        this.setState({
          showTrafficLayer: false,
        });
      } else {
        this.state.mapAPI.trafficLayer.setMap(this.state.map);
        this.setState({
          showTrafficLayer: true,
        });
      }
    } else {
      if (this.state.showTrafficLayer) {
        this.state.map.setBaseLayer(this.state.mapLayer.vector.normal.map);
        this.setState({
          showTrafficLayer: false,
        });
      } else {
        if (this.state.isMenuMapTypeClick != "satellite") {
          this.hereMapShowTraffic();
        }
        this.setState({
          showTrafficLayer: true,
        });
      }
    }
  };

  hereMapShowTraffic = () => {
    let trafficService = this.state.mapPlatform.getMapTileService({
      type: "traffic",
    });

    let trafficLayer = trafficService.createTileLayer(
      "traffictile",
      "normal.traffic.day",
      256,
      "png"
    );

    this.state.map.setBaseLayer(trafficLayer);
  };

  handleZoomFocusIncrease = (e) => {
    let zoomLevel = this.state.map.getZoom();
    zoomLevel++;
    this.state.map && this.state.map.setZoom(zoomLevel);
  };

  handleZoomFocusDecrease = (e) => {
    let zoomLevel = this.state.map.getZoom();
    zoomLevel--;
    this.state.map && this.state.map.setZoom(zoomLevel);
  };

  handelPoiDrawerOpen = () => {
    this.setState({
      descriptionBoxDrawerWidth: "300px",
    });
  };

  handleDescriptionBoxDrawerClose = () => {
    this.setState({
      descriptionBoxDrawerWidth: "0px",
    });
  };

  loadData = () => {
    this.setState({ selectedEvent: initialSelectedEvent });
    this.props.loadHeatMap({
      companyID: this.props.auth.profile.company_id,
      token: this.props.auth.accessToken,
      searchName: "",
      warningType: this.state.warningType,
      eventType: this.state.eventType,
      startDateTime:
        this.state.startDate.format("YYYY-MM-DD") +
        " " +
        this.state.startTime.format("HH:mm:ss"),
      endDateTime:
        this.state.endDate.format("YYYY-MM-DD") +
        " " +
        this.state.endTime.format("HH:mm:ss"),
      dataTreeFilter: this.state.masterDataSelectvalue,
      vehicle_visibility: this.props.auth.profile.vehicle_visibility,
    });
  };

  onRemoveLists = () => {
    this.props.removeLists();
  };

  componentDidMount() {
    if (this.props.match.params.id != undefined) {
      this.setState(
        {
          selectedVehicle: this.props.match.params.id,
          selectedVehiclePlateNo: this.props.vehicle.vehicleforedit.plate_no,
        },
        this.handleApplayButtonClick
      );
    }

    this.setState({
      // panelWidth: document.getElementById("tracking-collapse").clientWidth,
      heatMapLayer: this.state.data,
    });

    this.getVehicle();
    var self = this;
    window.addEventListener(
      "scroll",
      debounce(() => this.handleScroll(self), 600)
    ); //ms
  }

  componentWillUnmount() {
    this.onRemoveLists();
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll(self) {
    // lodash debounce method.
    let supportPageOffset = window.pageXOffset !== undefined;
    let isCSS1Compat = (document.compatMode || "") === "CSS1Compat";
    let scroll = {
      x: supportPageOffset
        ? window.pageXOffset
        : isCSS1Compat
        ? document.documentElement.scrollLeft
        : document.body.scrollLeft,
      y: supportPageOffset
        ? window.pageYOffset
        : isCSS1Compat
        ? document.documentElement.scrollTop
        : document.body.scrollTop,
    };
    self.scrollYPosition != scroll.y &&
      self.setState({ scrollYPosition: scroll.y });
  }

  handleMasterDataTreeChange = (value) => {
    this.setState({
      masterDataSelectvalue: value,
    });
  };

  initVehicleTypeMasterDataFilter = () => ({
    title: "ประเภทพาหนะ",
    value: "vehicle_type_all",
    key: "vehicle_type_all",
    children: this.props.monitoringState.vehicleTypeMasterDataTreeFilter.map(
      (obj) => ({
        title: obj.name,
        key: "VT_" + obj.id,
        value: "vehicle_type_id_" + obj.id,
      })
    ),
  });

  initVehicleGroupMasterDataFilter = () => ({
    title: "กลุ่มพาหนะ",
    value: "vehicle_group_all",
    key: "vehicle_group_all",
    children: this.props.monitoringState.vehicleGroupMasterDataTreeFilter.map(
      (obj) => ({
        title: obj.name,
        key: "VG_" + obj.id,
        value: "vehicle_group_id_" + obj.id,
      })
    ),
  });

  disabledStartDate = (endValue) => {
    const startValue = this.state.endDate;
    if (startValue == "") {
      return false;
    }
    return startValue.valueOf() < endValue.valueOf();
  };

  disabledEndDate = (endValue) => {
    const startValue = this.state.startDate;
    if (startValue == "") {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };

  getDisabledHours = () => {
    let hours = [];
    for (let i = 0; i < moment().hour(); i++) {
      hours.push(i);
    }
    return hours;
  };

  getDisabledMinutes = (selectedHour) => {
    let minutes = [];
    if (selectedHour === moment().hour()) {
      for (let i = 0; i < moment().minute(); i++) {
        minutes.push(i);
      }
    }
    return minutes;
  };

  onStartDateChange = (time, datetimeStr) => {
    this.setState({ startDate: moment(datetimeStr, "DD-MM-YYYY") });
  };

  onEndDateChange = (time, datetimeStr) => {
    this.setState({ endDate: moment(datetimeStr, "DD-MM-YYYY") });
  };

  onStartTimeChange = (time, datetimeStr) => {
    this.setState({ startTime: moment(datetimeStr, "HH:mm") });
  };

  onEndTimeChange = (time, datetimeStr) => {
    this.setState({ endTime: moment(datetimeStr, "HH:mm") });
  };

  onPickupTimeChange = (time, datetimeStr) => {
    let property = { ...this.state.dateTime };
    property.pickup_time = datetimeStr;
    this.setState({ dateTime: property, inputTouching: true });
  };

  onMainChartTypeChange = (value) => {
    this.setState({ mainChartType: value });
  };

  onShowMonitorCamToggle = (value) => {
    this.setState({ showMonitorCam: this.state.showMonitorCam ? false : true });
  };

  handlePlayingClick = () => {
    if (!this.state.isPlaying) {
      this.playingInterval = setInterval(() => {
        // let dataIndex = this.state.data.findIndex(
        //   ele => ele.created_at === this.state.currentSelectedPosition
        // );

        this.setState({
          currentSelectedPosition: this.state.data[
            this.state.currentSelectedIndex + 1
          ].created_at,
          currentSelectedIndex: this.state.currentSelectedIndex + 1,
          virtualMarker: this.state.data[this.state.currentSelectedIndex + 1],
          isPlaying: true,
        });
        if (this.props.auth.profile.map_type == "google") {
          this.state.map.panTo(
            this.state.data[this.state.currentSelectedIndex]
          );
        } else {
        }
      }, 1300);
    } else {
      clearInterval(this.playingInterval);
      this.setState({
        isPlaying: false,
      });
    }
  };

  handleForwardClick = () => {
    if (
      !this.state.isPlaying &&
      this.state.currentSelectedIndex < this.state.data.length
    ) {
      // let dataIndex = this.state.data.findIndex(
      //   ele => ele.created_at === this.state.currentSelectedPosition
      // );

      this.setState({
        currentSelectedPosition: this.state.data[
          this.state.currentSelectedIndex + 1
        ].created_at,
        currentSelectedIndex: this.state.currentSelectedIndex + 1,
        virtualMarker: this.state.data[this.state.currentSelectedIndex + 1],
      });
      if (this.props.auth.profile.map_type == "google") {
        this.state.map.panTo(
          this.state.data[this.state.currentSelectedIndex + 1]
        );
      } else {
      }
    } else {
      clearInterval(this.playingInterval);
      this.setState({
        isPlaying: false,
      });
    }
  };

  handleBackwardClick = () => {
    if (!this.state.isPlaying && this.state.currentSelectedIndex > 0) {
      // let dataIndex = this.state.data.findIndex(
      //   ele => ele.created_at === this.state.currentSelectedPosition
      // );

      this.setState({
        currentSelectedPosition: this.state.data[
          this.state.currentSelectedIndex - 1
        ].created_at,
        currentSelectedIndex: this.state.currentSelectedIndex - 1,
        virtualMarker: this.state.data[this.state.currentSelectedIndex - 1],
      });
      if (this.props.auth.profile.map_type == "google") {
        this.state.map.panTo(
          this.state.data[this.state.currentSelectedIndex - 1]
        );
      } else {
      }
    } else {
      clearInterval(this.playingInterval);
      this.setState({
        isPlaying: false,
      });
    }
  };

  goBack() {
    this.props.history.goBack();
  }

  render() {
    const mapTools = (
      <Menu>
        <Menu.Item
          key="3"
          onClick={this.handlePinPointOfInterest}
          style={{
            color:
              this.state.isMenuMapToolsCLick === "pushpin"
                ? "rgb(22, 146, 255)"
                : "",
          }}
        >
          <Icon type="pushpin" />
          <LangContext.Consumer>
            {(i18n) => i18n.p.pinDown}
          </LangContext.Consumer>
        </Menu.Item>
        <Menu.Item
          key="4"
          onClick={this.handleFindDistance}
          style={{
            color:
              this.state.isMenuMapToolsCLick === "FindDistance"
                ? "rgb(22, 146, 255)"
                : "",
          }}
        >
          <Icon type="highlight" />
          <LangContext.Consumer>
            {(i18n) => i18n.f.findDistance}
          </LangContext.Consumer>
        </Menu.Item>
        <Menu.Item
          key="5"
          onClick={this.handleFindArea}
          style={{
            color:
              this.state.isMenuMapToolsCLick === "findArea"
                ? "rgb(22, 146, 255)"
                : "",
          }}
        >
          <Icon type="border" />
          <LangContext.Consumer>
            {(i18n) => i18n.f.findSpace}
          </LangContext.Consumer>
        </Menu.Item>
        <Menu.Item key="6" onClick={this.handleClearAllToolDrawing}>
          <Icon type="close" />
          <LangContext.Consumer>
            {(i18n) => i18n.c.clearUp}
          </LangContext.Consumer>
        </Menu.Item>
      </Menu>
    );

    const mapType = (
      <Menu>
        <Menu.Item
          key="1"
          onClick={this.handleMapTypeSatellite}
          style={{
            color:
              this.state.isMenuMapTypeClick === "satellite"
                ? "rgb(22, 146, 255)"
                : "",
          }}
        >
          <LangContext.Consumer>
            {(i18n) => i18n.s.satellite}
          </LangContext.Consumer>
        </Menu.Item>
        <Menu.Item
          key="2"
          onClick={this.handleMapTypeTerrain}
          style={{
            color:
              this.state.isMenuMapTypeClick === "terrain"
                ? "rgb(22, 146, 255)"
                : "",
          }}
        >
          <LangContext.Consumer>{(i18n) => i18n.m.map}</LangContext.Consumer>
        </Menu.Item>
      </Menu>
    );

    let startEndMarker = [];
    let accDistance = "-";
    let accDuration = "-";
    let summaryData = [
      {
        name: (
          <LangContext.Consumer>
            {(i18n) => i18n.t.totalDistance}
          </LangContext.Consumer>
        ),
        value: accDistance,
      },
      {
        name: (
          <LangContext.Consumer>
            {(i18n) => i18n.t.totalDuration}
          </LangContext.Consumer>
        ),
        value: accDuration,
      },
    ];

    if (typeof this.state.data[0] != "undefined") {
      if (typeof this.state.data[0] != null) {
        summaryData = [];
        let start = this.state.data[0]["acc_distance"];
        let end = this.state.data[this.state.data.length - 1]["acc_distance"];
        accDistance = parseFloat(end - start).toFixed(2);

        var dateNow = new Date(this.state.data[0]["created_at"]);
        var dateFuture = new Date(
          this.state.data[this.state.data.length - 1]["created_at"]
        );

        var seconds = Math.floor((dateFuture - dateNow) / 1000);
        var minutes = Math.floor(seconds / 60);
        var hours = Math.floor(minutes / 60);
        var days = Math.floor(hours / 24);

        hours = hours - days * 24;
        minutes = minutes - days * 24 * 60 - hours * 60;

        accDuration = `${days}:${hours}:${minutes}`;
        summaryData = [
          {
            name: (
              <LangContext.Consumer>
                {(i18n) => i18n.t.totalDistance}
              </LangContext.Consumer>
            ),
            value: accDistance,
          },
          {
            name: (
              <LangContext.Consumer>
                {(i18n) => i18n.t.totalDuration}
              </LangContext.Consumer>
            ),
            value: accDuration,
          },
        ];

        startEndMarker = [
          <StartEndPointMarker
            plateNo={
              <LangContext.Consumer>
                {(i18n) =>
                  i18n.p.plateNo + " : " + this.state.selectedVehiclePlateNo
                }
              </LangContext.Consumer>
            }
            title={
              <LangContext.Consumer>
                {(i18n) =>
                  i18n.f.first_dest +
                  " : " +
                  moment(
                    this.state.data[0]["created_at"],
                    "YYYY-MM-DD HH:mm:ss"
                  ).format("DD/MM/YY HH:mm")
                }
              </LangContext.Consumer>
            }
            lat={this.state.data[0]["lat"]}
            lng={this.state.data[0]["lng"]}
            engineOn={
              typeof this.state.data[0]["engineOn"] != "undefined" ? (
                <LangContext.Consumer>
                  {(i18n) => i18n.e.engineStatus + " : " + i18n.e.engineOn}
                </LangContext.Consumer>
              ) : (
                <LangContext.Consumer>
                  {(i18n) => i18n.e.engineStatus + " : " + i18n.e.engineOff}
                </LangContext.Consumer>
              )
            }
          />,
          <StartEndPointMarker
            plateNo={
              <LangContext.Consumer>
                {(i18n) =>
                  i18n.p.plateNo + " : " + this.state.selectedVehiclePlateNo
                }
              </LangContext.Consumer>
            }
            title={
              <LangContext.Consumer>
                {(i18n) =>
                  i18n.s.second_dest +
                  " : " +
                  moment(
                    this.state.data[this.state.data.length - 1]["created_at"],
                    "YYYY-MM-DD HH:mm:ss"
                  ).format("DD/MM/YY HH:mm")
                }
              </LangContext.Consumer>
            }
            lat={this.state.data[this.state.data.length - 1]["lat"]}
            lng={this.state.data[this.state.data.length - 1]["lng"]}
            engineOn={
              typeof this.state.data[this.state.data.length - 1]["engineOn"] !=
              "undefined" ? (
                <LangContext.Consumer>
                  {(i18n) => i18n.e.engineStatus + " : " + i18n.e.engineOn}
                </LangContext.Consumer>
              ) : (
                <LangContext.Consumer>
                  {(i18n) => i18n.e.engineStatus + " : " + i18n.e.engineOff}
                </LangContext.Consumer>
              )
            }
          />,
        ];
      }
    }
    let searchBtnText =
      this.state.selectedVehiclePlateNo != "" &&
      this.state.selectedVehiclePlateNo != null ? (
        <small>
          {this.state.selectedVehiclePlateNo +
            " : " +
            moment(this.state.seletedStartAt, "YYYY-MM-DD HH:mm:ss").format(
              "DD/MM/YY HH:mm"
            ) +
            " - " +
            moment(this.state.seletedEndAt, "YYYY-MM-DD HH:mm:ss").format(
              "DD/MM/YY HH:mm"
            )}
        </small>
      ) : (
        <LangContext.Consumer>{(i18n) => i18n.s.search}</LangContext.Consumer>
      );
    return (
      <AuthorizeComponent matching_name="replay">
        <ReplayStyledContent
          style={{
            height: `${this.state.pageHeight}px`,
            background: "white",
          }}
        >
          <Spin spinning={this.state.pageLoading}>
            <Row>
              <Fragment>
                <Col sm={24} md={7} lg={7} xl={7}>
                  {this.props.match.params.id != undefined ? (
                    <Row>
                      <Col sm={24} md={5} lg={5} xl={5}>
                        <Button
                          type="default"
                          block
                          style={{ margin: "4px 0px 0px 8px" }}
                          onClick={this.goBack}
                        >
                          <LangContext.Consumer>
                            {(i18n) => i18n.b.back}
                          </LangContext.Consumer>
                        </Button>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  <Tooltip
                    placement="rightTop"
                    title={searchBtnText}
                    mouseEnterDelay={0.8}
                  >
                    <Button
                      type="default"
                      block
                      style={{ margin: "4px 0px 0px 8px", width: "95%" }}
                      onClick={(e) => this.showSearchModal()}
                    >
                      <Paragraph ellipsis style={{ margin: "0px 0px 0px 0px" }}>
                        <Icon type="search" /> {searchBtnText}
                      </Paragraph>
                    </Button>
                  </Tooltip>
                  {/* <Col span={24} align="center">
                    {this.state.selectedVehiclePlateNo != "" &&
                      this.state.selectedVehiclePlateNo != null && (
                     
                      )}
                  </Col> */}
                  <Col sm={24} md={16} lg={16} xl={16}>
                    <div className="replay-info-box">
                      <Row>
                        <Col span={24}>
                          <Statistic
                            valueStyle={{ fontSize: "16px" }}
                            title={
                              <LangContext.Consumer>
                                {(i18n) => i18n.d.dateTime}
                              </LangContext.Consumer>
                            }
                            value={
                              this.state.virtualMarker != null
                                ? "" + this.state.virtualMarker.created_at
                                : ""
                            }
                          />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col sm={24} md={8} lg={8} xl={8}>
                    <div className="replay-info-box">
                      <Row>
                        <Col span={24}>
                          <Statistic
                            valueStyle={{ fontSize: "16px" }}
                            title={
                              <LangContext.Consumer>
                                {(i18n) => i18n.s.speed}
                              </LangContext.Consumer>
                            }
                            value={
                              this.state.virtualMarker != null
                                ? this.state.virtualMarker.speed
                                : 0
                            }
                            suffix={
                              <LangContext.Consumer>
                                {(i18n) => i18n.k.kmH}
                              </LangContext.Consumer>
                            }
                          />
                        </Col>
                      </Row>
                    </div>
                  </Col>

                  <Col sm={24} md={24} lg={24} xl={24}>
                    <Tabs defaultActiveKey="1">
                      <TabPane
                        tab={
                          <LangContext.Consumer>
                            {(i18n) => i18n.i.imageFromDashCamera}
                          </LangContext.Consumer>
                        }
                        key="1"
                      >
                        {" "}
                        <Carousel
                          style={{
                            margin: "0px 20px 0px 40px",
                          }}
                        >
                          <div>
                            <img
                              style={{
                                width: "70%",
                                height: "70%",
                              }}
                              src="/img/no-image.jpg"
                            />
                            <img
                              style={{
                                display: "none",
                                width: "70%",
                                height: "70%",
                              }}
                              src={
                                this.state.virtualMarker != null
                                  ? `http://media.terminusfleet.com/imageserver/get_gps_image.php?key=${this.state.virtualMarker.imageIndex0Key}&type=1`
                                  : "/img/no-image.jpg"
                              }
                              onLoad={this.handleImageLoaded.bind(this)}
                              onError={this.handleImageErrored.bind(this)}
                            />
                          </div>
                          <div>
                            <img
                              style={{
                                width: "70%",
                                height: "70%",
                              }}
                              src="/img/no-image.jpg"
                            />
                            <img
                              style={{
                                width: "70%",
                                height: "70%",
                              }}
                              src={
                                this.state.virtualMarker != null
                                  ? `http://media.terminusfleet.com/imageserver/get_gps_image.php?key=${this.state.virtualMarker.imageIndex1Key}&type=2`
                                  : "/img/no-image.jpg"
                              }
                              onLoad={this.handleImageLoaded.bind(this)}
                              onError={this.handleImageErrored.bind(this)}
                            />
                          </div>
                        </Carousel>
                      </TabPane>
                      <TabPane
                        tab={
                          <LangContext.Consumer>
                            {(i18n) => i18n.s.summaryInfo}
                          </LangContext.Consumer>
                        }
                        key="2"
                      >
                        <Row>
                          <Col sm={0} md={1} lg={1} xl={1} />
                          <Col sm={24} md={22} lg={22} xl={22}>
                            <Table
                              className="tracking-table"
                              dataSource={summaryData}
                              size="small"
                              pagination={false}
                            >
                              <Column
                                title={"หัวข้อ"}
                                dataIndex="name"
                                key="name"
                              />
                              <Column
                                dataIndex="value"
                                key="value"
                                className="column-money"
                              />
                            </Table>
                          </Col>
                        </Row>
                      </TabPane>
                    </Tabs>
                  </Col>
                </Col>
                <Col sm={24} md={17} lg={17} xl={17}>
                  <div
                    style={{
                      position: "absolute",
                      marginTop: "4px",
                      top: "0px",
                      left: "5px",
                      zIndex: "12",
                    }}
                  >
                    <ButtonGroup
                      style={{
                        marginRight: "4px",
                      }}
                    >
                      {/* <Button
                        type="primary"
                        className="edge-element"
                        onClick={e => this.showSearchModal()}
                      >
                        <Icon type="search" />
                      </Button> */}
                      <Button
                        type="primary"
                        className="edge-element"
                        onClick={(e) => this.showIconDescModal()}
                      >
                        <Icon type="dot-chart" />{" "}
                        <LangContext.Consumer>
                          {(i18n) => i18n.i.iconDesc}
                        </LangContext.Consumer>
                      </Button>
                    </ButtonGroup>
                  </div>

                  <div
                    style={{
                      position: "absolute",
                      marginTop: "4px",
                      top: "0px",
                      right: "0px",
                      zIndex: "12",
                    }}
                  >
                    {this.state.currentSelectedIndex != null &&
                      this.state.data.length > 0 && (
                        <Button
                          style={{
                            marginRight: "4px",
                          }}
                        >
                          <a
                            href={`http://www.google.com/maps?layer=c&cbll=${
                              this.state.data[this.state.currentSelectedIndex]
                                .lat
                            },${
                              this.state.data[this.state.currentSelectedIndex]
                                .lng
                            }`}
                            target="_blank"
                          >
                            Google Street View
                          </a>
                        </Button>
                      )}

                    <ButtonGroup
                      style={{
                        marginRight: "4px",
                      }}
                    >
                      <Button
                        className="edge-element"
                        disabled={this.state.data.length == 0}
                        onClick={(e) => this.handleBackwardClick()}
                      >
                        <Icon type="step-backward" />
                      </Button>
                      <Button
                        disabled={this.state.data.length == 0}
                        className="edge-element"
                        onClick={(e) => this.handlePlayingClick()}
                        style={{
                          backgroundColor: this.state.isPlaying
                            ? "rgb(22, 146, 255)"
                            : "",
                          color: this.state.isPlaying
                            ? "rgb(255, 255, 255)"
                            : "",
                        }}
                      >
                        <Icon
                          type={this.state.isPlaying ? "pause" : "caret-right"}
                        />
                      </Button>
                      <Button
                        className="edge-element"
                        disabled={this.state.data.length == 0}
                        onClick={(e) => this.handleForwardClick()}
                      >
                        <Icon type="step-forward" />
                      </Button>
                    </ButtonGroup>

                    <ButtonGroup
                      style={{
                        marginRight: "4px",
                      }}
                    >
                      <Dropdown className="edge-element" overlay={mapTools}>
                        <Button>
                          เครื่องมือ <Icon type="down" />
                        </Button>
                      </Dropdown>
                      <Dropdown className="edge-element" overlay={mapType}>
                        <Button>
                          มุมมองแผนที่ <Icon type="down" />
                        </Button>
                      </Dropdown>
                    </ButtonGroup>

                    <div
                      className="edge-btn-group"
                      style={{
                        position: "absolute",
                        marginTop: "40%",
                        right: "0px",
                        zIndex: "12",
                      }}
                    >
                      <Tooltip placement="left" title="แสดงการจราจร">
                        <button
                          onClick={this.handleShowTrafficLayer}
                          style={{
                            backgroundColor: this.state.showTrafficLayer
                              ? "rgb(22, 146, 255)"
                              : "",
                            color: this.state.showTrafficLayer
                              ? "rgb(255, 255, 255)"
                              : "",
                          }}
                          // className={
                          //   this.state.showTrafficLayer ? "tool-btn-active" : ""
                          // }
                        >
                          <Icon type="swap" />
                        </button>
                      </Tooltip>
                      <Tooltip placement="left" title="Zoom in">
                        <button onClick={this.handleZoomFocusIncrease}>
                          <Icon type="zoom-in" />
                        </button>
                      </Tooltip>
                      <Tooltip placement="left" title="Zoom out">
                        <button onClick={this.handleZoomFocusDecrease}>
                          <Icon type="zoom-out" />
                        </button>
                      </Tooltip>
                      {/* <Tooltip placement="left" title="Toggle Point Marker">
                    <button
                      onClick={this.handleTogglePointMarker}
                      className={
                        this.state.togglePointMarker ? "tool-btn-active" : ""
                      }
                    >
                      <Icon type="small-dash" />
                    </button>
                  </Tooltip> */}
                      {/* <Tooltip placement="left" title="Toggle Dash Camera">
                      <button
                        onClick={this.onShowMonitorCamToggle}
                        className={
                          this.state.showMonitorCam ? "tool-btn-active" : ""
                        }
                      >
                        <Icon type="appstore" />
                      </button>
                    </Tooltip> */}
                    </div>
                  </div>

                  <PinPointOfInterest
                    descriptionBoxDrawerWidth={
                      this.state.descriptionBoxDrawerWidth
                    }
                    handleDescriptionBoxDrawerClose={
                      this.handleDescriptionBoxDrawerClose
                    }
                    showDistance={this.state.showDistance}
                    descriptionBoxHeader={this.state.descriptionBoxHeader}
                    descriptionBoxDetail={this.state.descriptionBoxDetail}
                    pinLat={this.state.pinLat}
                    pinLng={this.state.pinLng}
                  />
                  {this.props.auth.profile.map_type == "google" && (
                    <Map
                      markers={this.state.data}
                      selectedEvent={this.state.selectedEvent}
                      loadedDateTime={this.props.monitoringState.loadedDateTime}
                      toggleHeatMap={this.state.toggleHeatMap}
                      bootstrapURLKeys={{
                        key: this.props.auth.profile.google_map_key,
                        libraries: process.env.REACT_APP_GOOGLE_MAP_USING_LIBRARIES.split(
                          ","
                        ),
                        region: "thailand",
                        language: "th",
                      }}
                      yesIWantToUseGoogleMapApiInternals={true}
                      onGoogleApiLoaded={({ map, maps }) =>
                        this.handleApiLoaded(map, maps)
                      }
                      options={{
                        mapTypeControl: false,
                        fullscreenControl: false,
                        zoomControl: false,
                        // styles: MAPSTYLE,
                        streetViewControl: false,
                      }}
                      defaultCenter={{
                        lat: parseFloat(13.564365),
                        lng: parseFloat(100.797739),
                      }}
                      defaultZoom={this.props.zoom}
                      mapParams={{
                        zoom: {
                          lat: parseFloat(13.564365),
                          lng: parseFloat(100.797739),
                        },
                        center: this.props.zoom,
                      }}
                      style={{
                        width: "100%",
                        height: `${window.innerHeight - 300}px`,
                      }}
                    >
                      {startEndMarker.map((ele) => ele)}
                      {this.state.virtualMarker != null && (
                        <VehicleMarker
                          key={"virtualMarker_1"}
                          // title={"Test 001"}
                          vehicleImg="/img/map/marker/arrow.png"
                          stopImg="/img/map/marker/point.png"
                          lat={parseFloat(this.state.virtualMarker.lat)}
                          lng={parseFloat(this.state.virtualMarker.lng)}
                          x={parseFloat(this.state.virtualMarker.lat)}
                          y={parseFloat(this.state.virtualMarker.lng)}
                          engine={0}
                          speed={this.state.virtualMarker.speed}
                          vehicleStatus={"running"}
                          directionDegree={
                            this.state.virtualMarker.directionDegree
                          }
                          onClick={(e) => console.log("click marker")}
                        />
                      )}
                    </Map>
                  )}
                  {this.props.auth.profile.map_type == "here" && (
                    <HereMap
                      virtualMarker={
                        this.state.virtualMarker != null ? (
                          <VehicleMarker
                            key={"virtualMarker_1"}
                            // title={"Test 001"}
                            vehicleImg="/img/map/marker/arrow.png"
                            stopImg="/img/map/marker/point.png"
                            lat={parseFloat(this.state.virtualMarker.lat)}
                            lng={parseFloat(this.state.virtualMarker.lng)}
                            x={parseFloat(this.state.virtualMarker.lat)}
                            y={parseFloat(this.state.virtualMarker.lng)}
                            engine={0}
                            speed={this.state.virtualMarker.speed}
                            vehicleStatus={"running"}
                            directionDegree={
                              this.state.virtualMarker.directionDegree
                            }
                            onClick={(e) => console.log("click marker")}
                          />
                        ) : null
                      }
                      handleApiLoaded={this.handleApiLoaded}
                      markers={this.state.data}
                      selectedEvent={this.state.selectedEvent}
                      loadedDateTime={this.props.monitoringState.loadedDateTime}
                    />
                  )}
                </Col>
              </Fragment>
            </Row>

            <Row>
              <Divider orientation="left">
                <LangContext.Consumer>
                  {(i18n) => i18n.r.replayDetail}
                </LangContext.Consumer>
                <Button
                  style={{
                    zIndex: 10,
                    position: "relative",
                    left: "100",
                    top: "0",
                    margin: "0px 0px 0px 40px",
                  }}
                  size="small"
                  type={this.state.isShowSpeedChart ? "primary" : "default"}
                  shape="round"
                  onClick={(e) => {
                    this.setState({
                      isShowSpeedChart: !this.state.isShowSpeedChart,
                    });
                  }}
                >
                  <LangContext.Consumer>
                    {(i18n) => i18n.s.speed}
                  </LangContext.Consumer>
                </Button>

                <Button
                  style={{
                    zIndex: 10,
                    position: "relative",
                    left: "0",
                    top: "0",
                    margin: "0px 0px 0px 10px",
                  }}
                  size="small"
                  type={this.state.isShowEventsChart ? "primary" : "default"}
                  shape="round"
                  onClick={(e) => {
                    this.setState({
                      isShowEventsChart: !this.state.isShowEventsChart,
                    });
                  }}
                >
                  <LangContext.Consumer>
                    {(i18n) => i18n.e.event}
                  </LangContext.Consumer>
                </Button>

                <Button
                  style={{
                    zIndex: 10,
                    position: "relative",
                    left: "0",
                    top: "0",
                    margin: "0px 0px 0px 10px",
                  }}
                  size="small"
                  type={this.state.isShowFuelChart ? "primary" : "default"}
                  shape="round"
                  onClick={(e) => {
                    this.setState({
                      isShowFuelChart: !this.state.isShowFuelChart,
                    });
                  }}
                >
                  <LangContext.Consumer>
                    {(i18n) => i18n.f.fuel}
                  </LangContext.Consumer>
                </Button>

                <Button
                  style={{
                    zIndex: 10,
                    position: "absolute",
                    left: "100",
                    top: "0",
                    margin: "0px 0px 0px 10px",
                  }}
                  size="small"
                  type={this.state.isShowTempChart ? "primary" : "default"}
                  shape="round"
                  onClick={(e) => {
                    this.setState({
                      isShowTempChart: !this.state.isShowTempChart,
                    });
                  }}
                >
                  <LangContext.Consumer>
                    {(i18n) => i18n.t.temperature}
                  </LangContext.Consumer>
                </Button>

                {/* <Button
                  style={{
                    zIndex: 10,
                    position: "absolute",
                    left: "100",
                    top: "0",
                    margin: "0px 0px 0px 372px"
                  }}
                  size="small"
                  type={this.state.isShowTaxiFareChart?"primary":"default"}
                  shape="round"
                  onClick={e => {
                    this.setState({
                       isShowTaxiFareChart: !this.state.isShowTaxiFareChart
                    });
                  }}
                >
                 Taxi Fare
                </Button> */}

                <Tooltip
                  placement="left"
                  title={
                    this.state.detailExpand == false ? (
                      <LangContext.Consumer>
                        {(i18n) => i18n.e.expand}
                      </LangContext.Consumer>
                    ) : (
                      <LangContext.Consumer>
                        {(i18n) => i18n.c.collapse}
                      </LangContext.Consumer>
                    )
                  }
                >
                  <Button
                    style={{
                      zIndex: 10,
                      position: "absolute",
                      right: "0",
                      top: "0",
                      margin: "0px 50px 0px 0px",
                    }}
                    size="small"
                    type="default"
                    shape="round"
                    onClick={(e) => {
                      this.setState({
                        pageHeight:
                          this.state.pageHeight == window.innerHeight - 45
                            ? window.innerHeight + 600
                            : window.innerHeight - 45,
                        detailHeight:
                          this.state.detailHeight == 190 ? 700 : 190,
                        detailExpand:
                          this.state.detailExpand == false ? true : false,
                      });
                    }}
                  >
                    <Icon
                      type={this.state.detailExpand ? "shrink" : "arrows-alt"}
                    />
                  </Button>
                </Tooltip>
              </Divider>

              <Col
                span={23}
                offset={1}
                style={{
                  height: `${this.state.detailHeight}px`,
                  overflowY: "auto",
                  padding: "0px 0px 0px 0px",
                }}
              >
                <LineChart
                  handleVirtualMarkerChange={this.handleVirtualMarkerChange}
                  currentSelectedPosition={this.state.currentSelectedPosition}
                  currentSelectedIndex={this.state.currentSelectedIndex}
                  data={this.state.data}
                  driverPeriod={this.state.driverPeriod}
                  isShowSpeedChart={this.state.isShowSpeedChart}
                  isShowEventsChart={this.state.isShowEventsChart}
                  isShowFuelChart={this.state.isShowFuelChart}
                  isShowTaxiFareChart={this.state.isShowTaxiFareChart}
                  isShowTempChart={this.state.isShowTempChart}
                />
              </Col>
            </Row>
            <SearchModal
              loading={this.state.pageLoading}
              handleSeletedDateTimeChange={this.handleSeletedDateTimeChange}
              handleSeletedVehicleChange={this.handleSeletedVehicleChange}
              selectedVehicle={this.state.selectedVehicle}
              vehicleList={this.state.vehicleList}
              filterVisible={this.state.filterVisible}
              handleApplayButtonClick={this.handleApplayButtonClick}
              handleFilterCancel={this.handleFilterCancel}
            />
            <IconDescModal
              iconDetailVisible={this.state.iconDetailVisible}
              handleIconDetailCancel={this.handleIconDetailCancel}
            />
          </Spin>
        </ReplayStyledContent>
      </AuthorizeComponent>
    );
  }
}

export default connect(
  ({ vehicle, monitoringState, auth, auth: { profile } }) => ({
    vehicle,
    monitoringState,
    auth,
    profile,
  }),
  {
    loadHeatMap: loadHeatMap.request,
    loadVehicleTypeMasterData: loadVehicleTypeMasterData.request,
    loadVehicleGroupMasterData: loadVehicleGroupMasterData.request,
    removeLists,
    loadNextEventDetail,
  }
)(Index);
