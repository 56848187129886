import React from "react";
import { ReactComponent as TrackingReport } from "../components/Icons/Tracking.svg";
import { ReactComponent as VideoRealTimeReport } from "../components/Icons/VideoRealTime.svg";
import { ReactComponent as Replay } from "../components/Icons/Replay.svg";
import { ReactComponent as DownloadVideo } from "../components/Icons/Download File.svg";

const icons = {
  tracking: {
    label: "ติดตามพาหนะ",
    icon: ({ fill }) => <TrackingReport stroke={fill} />,
  },
  videoRealTime: {
    label: "วิดีโอเรียลไทม์",
    icon: ({ fill }) => <VideoRealTimeReport fill={fill} />,
  },
  replay: {
    label: "เส้นทางเดินทางย้อนหลัง",
    icon: ({ fill }) => <Replay fill={fill} />,
  },
  downloadVideo: {
    label: "ดาวน์โหลดวิดีโอ",
    icon: ({ fill }) => <DownloadVideo fill={fill} />,
  },
};

const mockData = [
  {
    key: "1",
    number: "1",
    name: "Policy A",
    policyType: "Type D",
    policySubType: "SubType 1",
    dataforreplay: "57826|863462065440701",
    plateNo: "ABC-1234",
    vehicleCode: "V001",
    driverName: "John Doe",
    driverNumber: "123456789",
    dateTime: "2025-01-20",
    lat: "13.7563 ",
    lng: "100.5018",
    geoCode: "Bangkok, Thailand",
    speed: "80 km/h",
    start: "2025-01-20 14:00",
    end: "2025-01-20 15:00",
    address: "Central World, Bangkok",
    temp: "25°C",
  },
  {
    key: "2",
    number: "2",
    name: "Policy B",
    policyType: "Type SZ",
    policySubType: "SubType 2",
    dataforreplay: "57826|863462065440701",
    plateNo: "DEF-5678",
    vehicleCode: "V002",
    driverName: "Jane Smith",
    driverNumber: "987654321",
    dateTime: "2025-01-20",
    lat: "21.09212",
    lng: "101.10006",
    geoCode: "Siam Paragon, Bangkok",
    speed: "90 km/h",
    start: "2025-01-20 14:45",
    end: "2025-01-20 15:30",
    address: "MBK Center, Bangkok",
    temp: "26°C",
  },
  {
    key: "3",
    number: "3",
    name: "Policy C",
    policyType: "Type RZ",
    policySubType: "SubType 3",
    dataforreplay: "57826|863462065440701",
    plateNo: "GHI-9012",
    vehicleCode: "V003",
    driverName: "Michael Lee",
    driverNumber: "456123789",
    dateTime: "2025-01-20",
    lat: "-34.51229",
    lng: "120.49047",
    geoCode: "Victory Monument, Bangkok",
    speed: "70 km/h",
    start: "2025-01-20 15:15",
    end: "2025-01-20 16:00",
    address: "Chatuchak Park, Bangkok",
    temp: "27°C",
  },
];

const ACCESS_BY_COMPANY_ID = [1];

const SETTING_LIST = {
  license_exp: 1,
  tax_exp: 2,
  fuel_noti: 3,
  adas_noti: 4,
  arrived_late: 5,
  deviate_route: 6,
  speed_over_limit: 7,
  not_swiped_card: 8,
}

export { icons, mockData, ACCESS_BY_COMPANY_ID, SETTING_LIST };
