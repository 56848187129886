import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Icon, Tooltip, Row, Col, Spin, Pagination } from "antd";
import LangContext, { i18n } from "modules/shared/context/langContext";
import { TableStyled } from "./StyleComponent";
import { ReactComponent as TrackingReport } from "../components/Icons/Tracking.svg";
import { ReactComponent as VideoRealTimeReport } from "../components/Icons/VideoRealTime.svg";
import { ReactComponent as Replay } from "../components/Icons/Replay.svg";
import { ReactComponent as DownloadVideo } from "../components/Icons/Download File.svg";
import { Link } from "react-router-dom";
import CustomMap from "./CustomMap";
import LocationInfoMarker from "../../../shared/components/map-assets/Marker/LocationInfoMarker";
import { eventDetail } from "../fetchAPI/apiClient";
import moment from "moment";
import { SETTING_LIST } from "modules/setting/noticenter/constant/Constant";

const TableTab = forwardRef(({ filterObj, auth, setFilterObj }, ref) => {
  const [map, setMap] = useState(null);
  const [maps, setMaps] = useState(null);
  const [mapAPI, setMapAPI] = useState({
    trafficLayer: null,
    locationLayer: null,
  });
  const [dataTable, setDataTable] = useState([]);
  const [locationLatLng, setLocationLatLng] = useState([]);
  const [isMapVisible, setIsMapVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [totalPage, setTotalPage] = useState(1);

  // useEffect(() => {
  //   if (!!filterObj.policyId) {
  //     loadData({
  //       topic_id: !!filterObj.policyId ? [filterObj.policyId] : [],
  //       topic_type: filterObj.type,
  //       vehicle_group_id: [],
  //       vehicle_id: !!filterObj.vehicleSelected
  //         ? filterObj.vehicleSelected
  //         : "",
  //       start_at: moment(filterObj.dateTime[0]).format("YYYY-MM-DD 00:00:00"),
  //       end_at: moment(filterObj.dateTime[1]).format("YYYY-MM-DD 23:59:59"),
  //       user_id: auth.profile.id.toString(),
  //       company_id: filterObj.company,
  //       page: 1,
  //       rows_per_page: pageSize,
  //     });
  //   }
  // }, [filterObj]);

  useEffect(() => {
    if (isMapVisible) {
      if (maps) {
        let bounds = new maps.LatLngBounds();
        let position = null;

        position = new maps.LatLng(
          locationLatLng[0].lat,
          locationLatLng[0].lng
        );
        bounds.extend(position);
        if (position !== null) {
          map.fitBounds(bounds);
          map.setZoom(10);
        }
      }
    }
  }, [maps, locationLatLng, isMapVisible]);

  const loadData = (payload) => {
    setLoading(true);
    eventDetail(payload, (status, response) => {
      setDataTable(response.data.data);
      setTotalPage(response.data.total_doc);
      setLoading(false);
    });
  };

  const handleApiLoaded = (map, maps) => {
    if (typeof maps == "undefined" || maps == null) window.location.reload();
    setMap(map);
    setMaps(maps);
    setMapAPI({ ...mapAPI, trafficLayer: new maps.TrafficLayer() });
  };

  const handleSelectLocation = (lat, lng, plateNo) => {
    setLocationLatLng([
      {
        lat,
        lng,
        plateNo,
      },
    ]);
    if (map && maps) {
      let bounds = new maps.LatLngBounds();
      let position = null;

      position = new maps.LatLng(lat, lng);
      bounds.extend(position);
      if (position !== null) {
        map.fitBounds(bounds);
        map.setZoom(10);
      }
    }

    setIsMapVisible(true);
  };

  const onSearchByVehicleId = (record) => {
    setFilterObj((prev) => ({
      ...prev,
      vehicleSelected: record.vehicle_id.toString(),
    }));
  };

  const handleClose = () => {
    setIsMapVisible(false);
    setLocationLatLng([]); // Reset marker data
    setMap(null); // Reset map instance
    setMaps(null);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    loadData({
      topic_id: !!filterObj.policyId ? [filterObj.policyId] : [],
      topic_type: filterObj.type,
      vehicle_group_id: [],
      vehicle_id: !!filterObj.vehicleSelected ? filterObj.vehicleSelected : "",
      start_at: moment(filterObj.dateTime[0]).format("YYYY-MM-DD 00:00:00"),
      end_at: moment(filterObj.dateTime[1]).format("YYYY-MM-DD 23:59:59"),
      user_id: auth.profile.id.toString(),
      company_id: filterObj.company,
      page,
      rows_per_page: pageSize,
    });
  };

  const getTextTitle = (key) => {
    switch (key) {
      case "start_at":
        if (filterObj.subType === "A_VDC") {
          return "เวลาขาดการติดต่อ";
        } else if (
          [SETTING_LIST.arrived_late, SETTING_LIST.deviate_route].includes(
            filterObj.policyId
          )
        ) {
          return "ออกต้นทาง";
        } else if (
          [SETTING_LIST.license_exp, SETTING_LIST.tax_exp].includes(
            filterObj.policyId
          )
        ) {
          return "วันที่หมดอายุ";
        } else if (
          [SETTING_LIST.fuel_noti, SETTING_LIST.not_swiped_card].includes(
            filterObj.policyId
          )
        ) {
          return "วันที่เวลา";
        } else {
          return "เวลาเริ่ม";
        }
      case "end_at":
        if (
          [SETTING_LIST.arrived_late, SETTING_LIST.deviate_route].includes(
            filterObj.policyId
          )
        ) {
          return "เข้าปลายทาง";
        } else {
          return "เวลาสิ้นสุด";
        }
      case "duration":
        return "ระยะเวลา";
      default:
        break;
    }
  };

  const baseColumns = [
    {
      title: "#",
      dataIndex: "number",
      key: "name",
      render: (text, record, index) => {
        return <label>{pageSize * (currentPage - 1) + index + 1}</label>;
      },
      width: 50,
      align: "center",
    },
    {
      title: (
        <LangContext.Consumer>
          {(i18n) => i18n.p.policyName}
        </LangContext.Consumer>
      ),
      dataIndex: "topic_name",
      width: 200,
    },
    {
      title: (
        <>
          <LangContext.Consumer>
            {(i18n) => i18n.p.plateNo}
          </LangContext.Consumer>
          {" ("}
          <LangContext.Consumer>
            {(i18n) => i18n.v.vehicleCode}
          </LangContext.Consumer>
          {")"}
        </>
      ),
      width: 200,
      dataIndex: "plate_no",
      render: (text, record) => (
        <a
          onClick={() => {
            onSearchByVehicleId(record);
          }}
          style={{ cursor: "pointer" }}
        >
          <label style={{ cursor: "pointer" }}>{text}</label>
          <br />
          {" ("}
          <label style={{ cursor: "pointer" }}>{record.vehicle_code}</label>
          {")"}
        </a>
      ),
    },
    {
      title: (
        <>
          <label>ชื่อคนขับรถ</label>
          {
            [SETTING_LIST.not_swiped_card].includes(
              filterObj.policyId
            ) ? null : <><br /><label>ชื่อคนขับรถจากการรูดบัตร</label>
              <br />
              <label>เลขบัตรประชาชน</label></>
          }
        </>
      ),
      width: 200,
      dataIndex: "driver_name",
      render: (text, record) => (
        <div>
          <label>{!!text ? text : "ไม่พบชื่อคนขับ"}</label>
          {
            [SETTING_LIST.not_swiped_card].includes(
              filterObj.policyId
            ) ? null : <> <br />
              <label>
                {!!record.driver_scan_license_name
                  ? record.driver_scan_license_name
                  : "ไม่พบชื่อคนขับรถจากการรูดบัตร"}
              </label>
              <br />
              <label>
                {!!record.driver_scan_idcard
                  ? record.driver_scan_idcard
                  : "ไม่พบเลขบัตรประชาชน"}
              </label></>
          }

        </div>
      ),
    },
  ];

  if (
    !(
      filterObj.type === "event" &&
      [SETTING_LIST.arrived_late, SETTING_LIST.deviate_route].includes(
        filterObj.policyId
      )
    )
  ) {
    let indexCond = "start_at";
    let extraDate = {
      title: "วันที่เกิดเหตุการณ์",
      width: 120,
      dataIndex: "start_at",
      render: (text, record) => <div>{!!text ? text : "-"}</div>,
    };

    if (
      [SETTING_LIST.tax_exp, SETTING_LIST.license_exp].includes(
        filterObj.policyId
      )
    ) {
      indexCond = "doc_expired_at";
      baseColumns.push(extraDate);
    } else if (filterObj.subType === "A_VDC") {
      indexCond = "lost_connect_at";
      baseColumns.push(extraDate);
    }

    baseColumns.push({
      title: getTextTitle("start_at"),
      width: 120,
      dataIndex: indexCond,
      render: (text, record) => <div>{!!text ? text : "-"}</div>,
    });
  }

  const completeEventCol = [
    {
      title: getTextTitle("end_at"),
      width: 120,
      dataIndex: "end_at",
      render: (text, record) => <div>{!!text ? text : "-"}</div>,
    },
    {
      title: getTextTitle("duration"),
      width: 120,
      dataIndex: "duration",
      render: (text, record) => <div>{!!record.end_at ? !!text ? text : "-" : 'ยังไม่สิ้นสุด'}</div>,
    },
  ];

  const geoCode = [
    {
      title: (
        <>
          <LangContext.Consumer>
            {(i18n) => i18n.p.position}
          </LangContext.Consumer>
        </>
      ),
      width: 150,
      align: "left",
      dataIndex: "lat",
      render: (text, record) => (
        <a
          onClick={() => {
            handleSelectLocation(record.lat, record.lng, record.plate_no);
          }}
        >
          {record.lat.toFixed(4)}
          {`,${record.lng.toFixed(4)}`}
        </a>
      ),
    },
    {
      title: <>Geocode</>,
      dataIndex: "address",
      width: 200,
      render: (text, record) => <div>{text}</div>,
    },
  ];

  const typeOfPolicy = {
    D: {
      subColumn: [
        ...completeEventCol,
        {
          title: "ความเร็ว (km/h)",
          dataIndex: "speed",
          width: 180,
          render: (text) => <div>{text}</div>,
        },
        ...geoCode,
      ],
    },
    RZ: {
      subColumn: [
        ...completeEventCol,
        {
          title: "พื้นที่",
          dataIndex: "zone_name",
          width: 150,
          render: (text, record) => (
            <a href={`/zone/detail/${record.zone_id}`} target="_blank">
              {text}
            </a>
          ),
        },
        ...geoCode,
      ],
    },
    GZ: {
      subColumn: [
        ...completeEventCol,
        {
          title: "พื้นที่",
          dataIndex: "zone_name",
          width: 150,
          render: (text, record) => (
            <a href={`/zone/detail/${record.zone_id}`} target="_blank">
              {text}
            </a>
          ),
        },
        ...geoCode,
      ],
    },
    SZ: {
      subColumn: [
        ...completeEventCol,
        {
          title: "พื้นที่",
          dataIndex: "zone_name",
          width: 150,
          render: (text, record) => (
            <a href={`/zone/detail/${record.zone_id}`} target="_blank">
              {text}
            </a>
          ),
        },
        {
          title: "ความเร็ว (km/h)",
          dataIndex: "speed",
          width: 80,
          render: (text) => <div>{text}</div>,
        },
        ...geoCode,
      ],
    },
    AS: {
      subColumn: [
        ...completeEventCol,
        {
          title: "ชื่อสถานที่",
          dataIndex: "system_location",
          width: "200px",
          render: (text) => <div>{!!text ? text : "-"}</div>,
        },
        ...geoCode,
      ],
    },
    AI: {
      subColumn: [
        ...completeEventCol,
        {
          title: "ชื่อสถานที่",
          dataIndex: "system_location",
          width: "200px",
          render: (text) => <div>{!!text ? text : "-"}</div>,
        },
        ...geoCode,
      ],
    },
    A_VDC: {
      subColumn: [...geoCode],
    },
    SEVT: {
      subColumn: [
        ...completeEventCol,
        {
          title: "ประเภท",
          dataIndex: "event_type",
          width: 100,
          render: (text) => (
            <div>{text === 2105 ? "เปิด PTO/ประตู1" : "เปิด Boom/ประตู2"}</div>
          ),
        },
        {
          title: "ความเร็ว (km/h)",
          dataIndex: "speed",
          width: 80,
          render: (text) => <div>{text}</div>,
        },
        ...geoCode,
      ],
    },
  };

  const typeOfEvent = {
    [SETTING_LIST.adas_noti]: {
      subColumn: [
        ...completeEventCol,
        {
          title: "ประเภท",
          dataIndex: "alarm_name",
          width: 150,
          render: (text) => <div>{text}</div>,
        },
        {
          title: "ลิงค์วิดีโอ",
          dataIndex: "video_url",
          width: 100,
          align: "center",
          render: (text) => {
            if (text !== "") {
              return (
                <a href={`${text}`} target="_blank">
                  ดูวิดีโอ
                </a>
              );
            }
            return "";
          },
        },
      ],
    },
    [SETTING_LIST.arrived_late]: {
      subColumn: [
        {
          title: "ต้นทาง",
          dataIndex: "trip_start_location_name",
          width: 150,
          render: (text) => <div>{text}</div>,
        },
        {
          title: "ปลายทาง",
          dataIndex: "trip_end_location_name",
          width: 150,
          render: (text) => <div>{text}</div>,
        },
        {
          title: getTextTitle("start_at"),
          width: 120,
          dataIndex: "start_at",
          render: (text, record) => <div>{!!text ? text : "-"}</div>,
        },
        ...completeEventCol,
      ],
    },
    [SETTING_LIST.deviate_route]: {
      subColumn: [
        {
          title: "ต้นทาง",
          dataIndex: "trip_start_location_name",
          width: 150,
          render: (text) => <div>{text}</div>,
        },
        {
          title: "ปลายทาง",
          dataIndex: "trip_end_location_name",
          width: 150,
          render: (text) => <div>{text}</div>,
        },
        {
          title: getTextTitle("start_at"),
          width: 120,
          dataIndex: "start_at",
          render: (text, record) => <div>{!!text ? text : "-"}</div>,
        },
        ...completeEventCol,
        {
          title: "เส้นทาง",
          width: 120,
          dataIndex: "trip_url",
          render: (text, record) => (
            <a href={`${text}`} target="_blank">
              ดูแผนที่
            </a>
          ),
        },
      ],
    },
    [SETTING_LIST.fuel_noti]: {
      subColumn: [
        ...geoCode,
        {
          title: "ลิงค์รูปกราฟ",
          dataIndex: "img_url",
          width: 100,
          align: "center",
          render: (text) => {
            if (text !== "") {
              return (
                <a href={`${text}`} target="_blank">
                  แสดงกราฟ
                </a>
              );
            }
            return "";
          },
        },
      ],
    },
    [SETTING_LIST.tax_exp]: {
      subColumn: [
        {
          title: "เลขที่ภาษี/พ.ร.บ./ประกัน",
          dataIndex: "doc_no",
          width: 150,
          render: (text) => <div>{text}</div>,
        },
      ],
    },
    [SETTING_LIST.speed_over_limit]: {
      subColumn: [
        ...completeEventCol,
        {
          title: "ความเร็ว (km/h)",
          dataIndex: "speed",
          width: 180,
          render: (text) => <div>{text}</div>,
        },
        ...geoCode,
      ],
    },
    [SETTING_LIST.license_exp]: {
      subColumn: [
        {
          title: "เลขที่ใบขับขี่",
          dataIndex: "doc_no",
          width: 150,
          render: (text) => <div>{text}</div>,
        },
      ],
    },
    [SETTING_LIST.not_swiped_card]: {
      subColumn: [
        {
          title: (
            <>
              <LangContext.Consumer>
                {(i18n) => i18n.p.position}
              </LangContext.Consumer>
            </>
          ),
          width: 150,
          align: "left",
          dataIndex: "lat",
          render: (text, record) => (
            <a
              onClick={() => {
                handleSelectLocation(record.lat, record.lng, record.plate_no);
              }}
            >
              {record.lat.toFixed(4)}
              {`,${record.lng.toFixed(4)}`}
            </a>
          ),
        }
      ],
    },
  };

  const actionColumn = {
    title: "Action",
    key: "action",
    align: "center",
    fixed: "right",
    width: 130,
    render: (text, record) => (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Tooltip title={"รายละเอียดหน้านโยบาย"} placement="left">
          {record.is_policy ? (
            <Link
              to={`/policy-new/edit/${record.topic_id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon
                type="info-circle"
                style={{
                  color: "black",
                  cursor: "pointer",
                  paddingTop: 3,
                }}
              />
            </Link>
          ) : (
            <Link>
              <Icon
                type="info-circle"
                style={{
                  color: "gray",
                  cursor: "not-allowed",
                  paddingTop: 3,
                }}
              />
            </Link>
          )}
        </Tooltip>

        <Tooltip title={"ติดตามพาหนะ"} placement="left">
          <Link
            // to={`/tracking/vehicle/${54361}/${auth.profile.company_id}`}
            to={`/tracking/vehicle/${record.vehicle_id}/${auth.profile.company_id}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ cursor: "pointer" }}
          >
            <div style={{ width: "16px", height: "16px" }}>
              <TrackingReport stroke={"black"} />
            </div>
          </Link>
        </Tooltip>

        <Tooltip title={"เส้นทางเดินทางย้อนหลัง"} placement="left">
          <a
            onClick={() => {
              let end = record.end_at === "" ? record.start_at : record.end_at;
              window.open(
                `${window.location.origin}/replay?vehicle_id=${record.vehicle_id
                }&start_at=${moment(
                  record.start_at,
                  "YYYY-MM-DD HH:mm:ss"
                ).format("YYYY-MM-DD 00:00:00")}&end_at=${moment(
                  end,
                  "YYYY-MM-DD HH:mm:ss"
                ).format("YYYY-MM-DD 23:59:59")}`
              );
            }}
            // vehicleid|hardwareid
            style={{ cursor: "pointer" }}
            target="_blank"
          >
            <Replay fill={"black"} />
          </a>
        </Tooltip>

        <Tooltip title={"วิดีโอเรียลไทม์"} placement="left">
          <Link
            // to={`/livestream?imei=${863462062838063}&vehicle_id=${54407}`}
            target="_blank"
            rel="noopener noreferrer"
            to={`/livestream?imei=${record.hardware_id}&vehicle_id=${record.vehicle_id}`}
            style={{ cursor: "pointer" }}
          >
            <VideoRealTimeReport fill={"black"} />
          </Link>
        </Tooltip>

        <Tooltip title={"ดาวน์โหลดวิดีโอ"} placement="left">
          <Link
            to={`/downloadvideoqueue?startDate=${moment(
              record.start_at,
              "YYYY-MM-DD HH:mm:ss"
            )
              .subtract(1, "hours")
              .format("DD/MM/YYYY HH:mm:ss")}&endDate=${record.end_at != ""
                ? moment(record.end_at, "YYYY-MM-DD HH:mm:ss")
                  .add(1, "hours")
                  .format("DD/MM/YYYY HH:mm:ss")
                : moment(record.start_at, "YYYY-MM-DD HH:mm:ss")
                  .add(1, "hours")
                  .format("DD/MM/YYYY HH:mm:ss")
              }&vehicle_id=${record.vehicle_id}`}
            style={{ cursor: "pointer" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <DownloadVideo fill={"black"} />
          </Link>
        </Tooltip>
      </div>
    ),
  };

  const getSpecialCol = () => {
    let col = [];
    if (filterObj.type === "event") {
      if (
        typeOfEvent[filterObj["policyId"]] &&
        typeOfEvent[filterObj["policyId"]].subColumn
      ) {
        col = typeOfEvent[filterObj["policyId"]].subColumn;
      }
    } else {
      if (
        typeOfPolicy[filterObj["subType"]] &&
        typeOfPolicy[filterObj["subType"]].subColumn
      ) {
        col = typeOfPolicy[filterObj["subType"]].subColumn;
      }
    }

    return col;
  };

  const dynamicColumns = [...baseColumns, ...getSpecialCol(), actionColumn];

  useImperativeHandle(ref, () => ({
    loadData,
    setPageSize,
    setCurrentPage,
  }));

  return (
    <Spin spinning={loading}>
      <TableStyled
        className="noti-center"
        columns={dynamicColumns}
        dataSource={dataTable}
        pagination={false}
        scroll={{ y: 500, x: 1300 }}
      />
      <Row type="flex" justify="end" style={{ padding: "15px" }}>
        <Col span={24} align="right">
          <Pagination
            defaultPageSize={pageSize}
            size="small"
            current={currentPage}
            total={totalPage}
            onChange={handlePageChange}
          />
        </Col>
      </Row>
      {isMapVisible && locationLatLng.length > 0 && (
        <div className="custom-map-container">
          <button className="close-button" onClick={handleClose}>
            x
          </button>
          <CustomMap
            data-cy="map"
            bootstrapURLKeys={{
              // key: "AIzaSyCBh7O-7raiK3L0zOguKR1uAryIwBCQFA4",
              key: auth.profile.google_map_key,
              libraries: process.env.REACT_APP_GOOGLE_MAP_USING_LIBRARIES.split(
                ","
              ),
              region: "thailand",
              language: "th",
            }}
            center={{
              lat: 13.729852,
              lng: 100.559484,
            }}
            defaultZoom={5}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
            options={{
              mapTypeControl: false,
              fullscreenControl: false,
              zoomControl: false,
              maxZoom: 19,
              streetViewControl: false,
              styles: [
                {
                  featureType: "poi.business",
                  stylers: [{ visibility: "on" }],
                },
                {
                  featureType: "poi.park",
                  elementType: "labels.text",
                  stylers: [{ visibility: "on" }],
                },
              ],
            }}
          >
            {locationLatLng.map((item) => (
              <LocationInfoMarker
                data-cy={1}
                key={1}
                lat={item.lat}
                lng={item.lng}
                title={item.plateNo}
              />
            ))}
          </CustomMap>
        </div>
      )}
    </Spin>
  );
});

export default TableTab;
