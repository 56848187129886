import React, { useState } from "react";
import { Button, Form, Input, Drawer } from "antd";
import LangContext from "modules/shared/context/langContext";

const IndexFilterDrawer = (props) => {
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  return (
    <Drawer
      title={
        <LangContext.Consumer>{(i18n) => i18n.f.filter}</LangContext.Consumer>
      }
      width={520}
      placement="right"
      onClose={props.onClose}
      maskClosable={true}
      visible={props.visible}
      style={{
        overflow: "auto",
        paddingBottom: 53,
      }}
    >
      <Form>
        <Form.Item label="name">
          <Input
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Item>

        <Form.Item label="code">
          <Input
            name="code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
        </Form.Item>

        <Button
          style={{ marginRight: 8 }}
          onClick={() => {
            setName("");
            setCode("");
            props.onClose();
          }}
        >
          <LangContext.Consumer>{(i18n) => i18n.c.cancel}</LangContext.Consumer>
        </Button>

        <Button type="primary" onClick={() => props.onSubmit({ name, code })}>
          <LangContext.Consumer>{(i18n) => i18n.a.apply}</LangContext.Consumer>
        </Button>
      </Form>
    </Drawer>
  );
};
export default IndexFilterDrawer;
