import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, message } from 'antd'

import * as actions from '../actions'
import Form from './Forms'
import { GeneralStyledContent } from '../../../../styled/common-styled'
import Breadcrumb from './../../../shared/components/Breadcrumb'

class Edit extends Component {
  constructor(props) {
    super(props)

    this.state = {
      data_for_edit: {
        name: '',
        route_master_type_id: '',
      },
    }
  }

  componentDidMount() {
    // โหลดข้อมูล Route Master Type
    this.props.loadRouteMasterType()
  }

  componentWillUnmount() {
    this.props.clearRoutemaster()
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.routemaster.checkRouteMasterType !==
      nextProps.routemaster.checkRouteMasterType
    ) {
      if (nextProps.routemaster.routeMasterType.length > 0) {
        // โหลดข้อมูล Route Master ที่ต้องการแก้ไข
        this.props.getRoutemasterByID(this.props.match.params.id)
      }
    }

    if (
      this.props.routemaster.check_routemaster !==
      nextProps.routemaster.check_routemaster
    ) {
      if (nextProps.routemaster.dataRoutemaster.name) {
        this.setState({
          data_for_edit: {
            name: nextProps.routemaster.dataRoutemaster.name || '',
            route_master_type_id:
              nextProps.routemaster.dataRoutemaster.route_master_type_id || this.props.routemaster.routeMasterType[0].id || '',
          },
        })
      }
    }
    return true
  }

  componentDidUpdate(prevProps, prevState) {
    const { dataResponse } = this.props.routemaster

    if (
      dataResponse.action_type === 'create' ||
      dataResponse.action_type === 'update'
    ) {
      if (dataResponse.status === 'success') {
        message.success('สำเร็จ')
      } else if (dataResponse.status === 'fail') {
        message.error(dataResponse.msg)
      }
      this.props.clearDataResponse()
    }
  }

  edit = (values, loading) => {
    this.props.updateRoutemaster(
      values,
      this.props.match.params.id,
      this.props.history,
      loading
    )
  }

  render() {
    return (
      <GeneralStyledContent>
        <Row gutter={24}>
          <Col span={24}>
            <Breadcrumb
              match={this.props.match}
              style={{ margin: '0px 0px 14px 10px' }}
            />
          </Col>
        </Row>
        <Form
          action_type='edit'
          onSubmit={this.edit}
          routemaster={this.props.routemaster}
          id={this.props.match.params.id}
          getRoutemasterdetailByID={this.props.getRoutemasterdetailByID}
          createRoutemasterdetail={this.props.createRoutemasterdetail}
          updateRoutemasterdetail={this.props.updateRoutemasterdetail}
          loadRoutemasterdetail={this.props.loadRoutemasterdetail}
          loadLocation={this.props.loadLocation}
          loadLocationcode={this.props.loadLocationcode}
          auth={this.props.auth}
          data_for_edit={this.state.data_for_edit}
        />
      </GeneralStyledContent>
    )
  }
}

const mapStateToProps = ({ routemaster, auth }) => ({
  routemaster,
  auth,
})

const mapDispatchToProps = {
  updateRoutemaster: actions.updateRoutemaster.request,
  getRoutemasterByID: actions.getRoutemasterByID.request,
  loadLocation: actions.loadLocation.request,
  loadLocationcode: actions.loadLocationcode.request,
  getRoutemasterdetailByID: actions.getRoutemasterdetailByID.request,
  createRoutemasterdetail: actions.createRoutemasterdetail.request,
  updateRoutemasterdetail: actions.updateRoutemasterdetail.request,
  loadRoutemasterdetail: actions.loadRoutemasterdetail.request,
  clearDataResponse: actions.clearDataResponse,
  clearRoutemaster: actions.clearRoutemaster,
  loadRouteMasterType: actions.loadRouteMasterType.request,
}

export default connect(mapStateToProps, mapDispatchToProps)(Edit)
