import axios from "axios";
let axiosClientFromWeb = null;
export const createAxios = (token) => {
  axiosClientFromWeb = axios.create({
    baseURL: `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("access-token")}`,
    },
  });
  axiosClientFromWeb.interceptors.response.use(
    function(response) {
      return response;
    },
    function(error) {
      let res = error.response;
      if (res.status === 401) {
        window.location.href = "./";
      }
      // console.error(`Looks like there was a problem. Status Code: ` + res.status);
      return Promise.reject(error);
    }
  );
};

// Centralized error handling function (example)
const handleError = (error, callback) => {
  // Handle error here or pass it to callback

  console.error("API Error:", error);
  //   callback(error.response?.status, error.response?.data);
};

export async function getNewsCategory(params, callback) {
  try {
    const res = await axiosClientFromWeb.get("/news-category", params);
    callback(200, res);
  } catch (error) {
    handleError(error);
  }
}

export async function getNews(params, callback) {
  try {
    const res = await axiosClientFromWeb.get("/news", { params });
    callback(200, res);
  } catch (error) {
    handleError(error);
  }
}

export default axiosClientFromWeb;
