import React from "react";
import { Row, Col } from "antd";

const StartEndPointMarker = ({ plateNo, title, lat, lng, engineOn }) => {
  return (
    <Row>
      <Col span={24}>
        {title !== "" && (
          <div>
            <div
              style={{
                width: "120px",
                borderRadius: "1px",
                padding: "4px 4px 4px 4px",
                display: "inline-flex",
                textAlign: "left",
                alignItems: "left",
                justifyContent: "left",
                transform: "translate(-50%, -180%)",
                color: "black",
                backgroundColor: "rgba(255, 255, 255, 0.7)",
                position: "relative",
                left: 0,
                top: 5,
                bottom: 0,
                minHeight: "35px",
                border: "2px solid rgba(139, 139, 139)",
                fontSize: 9
              }}
            >
              {" "}
              <Row>
                <Col span={24}>{plateNo} </Col>
                <Col span={24}>{title} </Col>
                <Col span={24}>{engineOn}</Col>
              </Row>
              <div
                style={{
                  position: "absolute",
                  left: 52,
                  bottom: -15,
                  zIndex: -2,
                  width: 0,
                  height: 0,
                  borderLeft: "7px solid transparent",
                  borderRight: "7px solid transparent",
                  borderTop: "12px solid rgba(139, 139, 139)"
                }}
              />
            </div>
          </div>
        )}
      </Col>
    </Row>
  );
};

export default StartEndPointMarker;
