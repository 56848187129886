import React, { Component, Fragment } from "react";
import {
  List,
  Avatar,
  TreeSelect,
  Select,
  Form,
  Input,
  Slider,
  Spin,
  Modal,
  DatePicker
} from "antd";

import LangContext, { i18n } from "modules/shared/context/langContext";

export class IconDescModal extends Component {
  state = {
    data: [
      {
        title: (
          <LangContext.Consumer>{i18n => i18n.e.engineOn}</LangContext.Consumer>
        ),
        image: "/img/map/status_icon/etc/e_start.jpg",
        desc: (
          <LangContext.Consumer>
            {i18n => i18n.e.engineOnDesc}
          </LangContext.Consumer>
        )
      },
      {
        title: (
          <LangContext.Consumer>
            {i18n => i18n.e.engineOnWithImg}
          </LangContext.Consumer>
        ),
        image: "/img/map/status_icon/etc/e_start_with_image_point.jpg",
        desc: (
          <LangContext.Consumer>
            {i18n => i18n.e.engineOnWithImgDesc}
          </LangContext.Consumer>
        )
      },
      {
        title: (
          <LangContext.Consumer>
            {i18n => i18n.e.engineOff}
          </LangContext.Consumer>
        ),
        image: "/img/map/status_icon/etc/e_stop_point.jpg",
        desc: (
          <LangContext.Consumer>
            {i18n => i18n.e.engineOffDesc}
          </LangContext.Consumer>
        )
      },
      {
        title: (
          <LangContext.Consumer>
            {i18n => i18n.g.gpsNotFixed}
          </LangContext.Consumer>
        ),
        image: "/img/map/status_icon/etc/e_not_fixed.jpg",
        desc: (
          <LangContext.Consumer>
            {i18n => i18n.g.gpsNotFixedDesc}
          </LangContext.Consumer>
        )
      },
      {
        title: (
          <LangContext.Consumer>
            {i18n => i18n.s.speedOverLimit}
          </LangContext.Consumer>
        ),
        image: "/img/map/status_icon/15_15/speed15.png",
        desc: ""
      }
      // {
      //     title: 'Ant Design Title 4',
      //      img: "/map/status_icon/etc/e_start.jpg"
      // },
    ]
  };

  render() {
    return (
      <Modal
        style={{ top: 20 }}
        title={
          <LangContext.Consumer>{i18n => i18n.i.iconDesc}</LangContext.Consumer>
        }
        visible={this.props.iconDetailVisible}
        onOk={this.props.handleIconDetailCancel}
        onCancel={this.props.handleIconDetailCancel}
        cancelButtonProps={{ style: { display: "none" } }}
        okText={<LangContext.Consumer>{i18n => i18n.o.okay}</LangContext.Consumer>}
      >
        <List
          itemLayout="horizontal"
          dataSource={this.state.data}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                avatar={<img src={item.image} />}
                title={item.title}
                description={item.desc}
              />
            </List.Item>
          )}
        />
      </Modal>
    );
  }
}

export default IconDescModal;
