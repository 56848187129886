import {
  Select,
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Spin,
  Transfer,
  DatePicker,
  Option,
  Dropdown,
  Menu,
  Icon,
} from "antd";
import { connect } from "react-redux";
import * as withdrawActions from "../../withdrawfuelcreditreport/actions";
import { Formik } from "formik";
import React, { Component } from "react";
import moment from "moment";
import * as actions from "../actions";

import { Link } from "react-router-dom";
import LangContext from "modules/shared/context/langContext";
import Breadcrumb from "./../../../shared/components/Breadcrumbreport";
import AuthorizeComponent from "./../../../auth/components/AuthorizeComponent";
import * as yup from "yup";
import FormDivider from "../../../shared/components/FormDivider";
import { debounce } from "debounce";
import { GeneralStyledContent } from "../../../../styled/common-styled";

const { RangePicker } = DatePicker;
const initialValues = {
  dateRange: [moment("00:00", "HH:mm"), moment("23:59", "HH:mm")],
  plantID: [],
  vehicleList: [],
};

class Index extends Component {
  state = initialValues;

  formatVehicleList = (data) => {
    let newArr = data.map((item) => {
      return {
        key: item.id,
        label: item.plate_no + "(" + item.code + ")",
      };
    });
    return newArr;
  };
  changeRangdate = (value) => {
    this.setState({ eventdate: value });
  };
  handleMenuClick = (e, setFieldValue, handleSubmit) => {
    // console.log('click', e.key);
    if (e.key === "excel") {
      setFieldValue("type_file", "excel", false);
    } else if (e.key === "pdf") {
      setFieldValue("type_file", "pdf", false);
    }
    handleSubmit();
  };
  handleOnsubmit = (value) => {
    const date_start = moment(value.dateRange[0]["_d"]).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    const date_end = moment(value.dateRange[1]["_d"]).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    this.props.loadReport({
      company_id: this.props.profile.company_id.toString(),
      vehicle_list: value.vehicleList.join(","),
      date_start: date_start,
      date_end: date_end,
      type_file: value.type_file,
      user_id: this.props.profile.id,
    });
  };

  handleOnSearch = (value) => {
    debounce(
      this.props.loadPlant(
        {
          keyword: value,
          company_id: this.props.profile.company_id,
        },
        1500
      )
    );
  };

  componentDidMount() {
    this.props.loadPlant({
      keyword: "",
      company_id: this.props.profile.company_id,
    });
    this.props.loadVechicles({
      plant_id: [],
      vehicle_visibility: this.props.auth.profile.vehicle_visibility,
    });
  }
  render() {
    return (
      <AuthorizeComponent matching_name="report_flow">
        <GeneralStyledContent>
          <Spin
            spinning={
              this.props.withdrawfuelcreditreport.loading ||
              this.props.flowreport.loading
            }
          >
            <Row gutter={24}>
              <Col span={24}>
                <Breadcrumb
                  match={this.props.match}
                  style={{ margin: "0px 0px 14px 10px" }}
                />
              </Col>
            </Row>
            <h3>
              <LangContext.Consumer>
                {(i18n) => i18n.r.reportFlow}
              </LangContext.Consumer>
            </h3>
            <Row type="flex" justify="center">
              <Col>
                <Formik
                  enableReinitialize={true}
                  initialValues={initialValues}
                  validationSchema={yup.object().shape({
                    dateRange: yup
                      .array()
                      .required("กรุณาเลือกช่วงวันที่")
                      .nullable(),
                    vehicleList: yup
                      .array()
                      .required(
                        <LangContext.Consumer>
                          {(i18n) => i18n.p.pleaseSelectVehicleRegistration}
                        </LangContext.Consumer>
                      )
                      .nullable(),
                  })}
                  onSubmit={(values) => {
                    this.handleOnsubmit(values);
                  }}
                  render={({
                    values,
                    errors,
                    status,
                    handleBlur,
                    touched,
                    handleSubmit,
                    setFieldValue,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <Form.Item
                        label={
                          <LangContext.Consumer>
                            {(i18n) => i18n.v.vehicleCode}
                          </LangContext.Consumer>
                        }
                        validateStatus={
                          touched.vehicleList && errors.vehicleList && "warning"
                        }
                        help={touched.vehicleList && errors.vehicleList}
                        required
                      >
                        <Transfer
                          titles={["Source", "Target"]}
                          // name="vehicle"
                          style={{
                            margin: "10px 0px 0px 0px",
                          }}
                          listStyle={{
                            width: 220,
                            height: 300,
                          }}
                          dataSource={this.formatVehicleList(
                            this.props.withdrawfuelcreditreport.vehicleLists
                          )}
                          showSearch
                          targetKeys={values.vehicleList}
                          onChange={(targetKeys) => {
                            setFieldValue("vehicleList", targetKeys);
                          }}
                          render={(item) => item.label}
                        />
                      </Form.Item>
                      <Form.Item
                        label={
                          <LangContext.Consumer>
                            {(i18n) => i18n.d.dateRange}
                          </LangContext.Consumer>
                        }
                        validateStatus={
                          touched.dateRange && errors.dateRange && "warning"
                        }
                        help={touched.dateRange && errors.dateRange}
                        required
                      >
                        <RangePicker
                          style={{ margin: "5px 0px 0px 0px" }}
                          showTime={{ format: "HH:mm" }}
                          onChange={(value) =>
                            setFieldValue("dateRange", value)
                          }
                          onOk={(value) => this.changeRangdate(value)}
                          format="DD/MM/YYYY HH:mm"
                          placeholder={["Start Time", "End Time"]}
                          value={values.dateRange}
                        />
                      </Form.Item>
                      <Form.Item>
                        <Row gutter={24}>
                          <Col span={8}>
                            <Button type="defualt" block>
                              <Link to="/">
                                <LangContext.Consumer>
                                  {(i18n) => i18n.c.cancel}
                                </LangContext.Consumer>
                              </Link>
                            </Button>
                          </Col>
                          <Col span={8}>
                            <Dropdown
                              overlay={
                                <Menu
                                  onClick={(value) =>
                                    this.handleMenuClick(
                                      value,
                                      setFieldValue,
                                      handleSubmit
                                    )
                                  }
                                >
                                  <Menu.Item key="excel">
                                    <Icon type="file-excel" />{" "}
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.e.excel}
                                    </LangContext.Consumer>
                                  </Menu.Item>
                                  <Menu.Item key="pdf">
                                    <Icon type="file-pdf" />{" "}
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.p.pdf}
                                    </LangContext.Consumer>
                                  </Menu.Item>
                                </Menu>
                              }
                            >
                              <Button type="primary" block>
                                <LangContext.Consumer>
                                  {(i18n) => i18n.r.report}
                                </LangContext.Consumer>{" "}
                                <Icon type="down" />
                              </Button>
                            </Dropdown>
                          </Col>
                        </Row>
                      </Form.Item>
                    </Form>
                  )}
                />
              </Col>
            </Row>
          </Spin>
        </GeneralStyledContent>
      </AuthorizeComponent>
    );
  }
}
const mapStateToProps = ({
  auth,
  auth: { profile },
  withdrawfuelcreditreport,
  flowreport,
}) => ({
  auth,
  profile,
  withdrawfuelcreditreport,
  flowreport,
});
const mapDispatchToProps = {
  loadPlant: withdrawActions.loadPlantByKeyword.request,
  loadVechicles: withdrawActions.loadVehicleByPlant.request,
  loadReport: actions.loadFlowReport.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
