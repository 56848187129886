import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import moment from "moment";

import {
  Button,
  Form,
  Select,
  Row,
  Col,
  Spin,
  DatePicker,
  Transfer,
  Dropdown,
  Icon,
  Menu,
  Radio,
} from "antd";

import LangContext, { i18n } from "modules/shared/context/langContext";
import { connect } from "react-redux";

const { RangePicker } = DatePicker;
const FormItem = Form.Item;
const Options = Select.Option;
const { Option, OptGroup } = Select;

class Forms extends Component {
  state = {
    event_date_start: "",
    event_date_end: "",
    vehicleList: [],
    vehicleSelect: [],
    selectedKeys: [],
    defaultSelect: {
      key: "",
      label: (
        <LangContext.Consumer>
          {(i18n) => i18n.s.select_event}
        </LangContext.Consumer>
      ),
    },
    eventType: "policy",
    vehicle_type_id: "",
    initVehicleSelect: [],
    flag: false,
  };

  componentDidMount() {
    const {
      loadVehicle,
      loadVehicleType,
      loadEvents,
      loadPolicy,
      loadVehiclebypolicy,
      call,
    } = this.props;
    loadVehicle("", false);
    loadVehicleType();
    loadPolicy();
    loadEvents();

    if (call === "schedule") {
      if (this.props.dataedit !== "") {
        let obj = JSON.parse(this.props.dataedit);

        this.setState({
          vehicleSelect: obj.list_vehicle_id,
          vehicle_type_id: obj.vehicle_type_id,
          initVehicleSelect: obj.list_vehicle_id,
          eventType: obj.event_type,
          defaultSelect: {
            key: obj.event,
            label: obj.event_name,
          },
        });
      }
    }
    //console.log("didmount");
    //loadVehiclebypolicy(this.state.defaultSelect.key, true);
  }

  onVehicleTypeChange = (value, setFieldValue) => {
    const { loadVehiclebygroupid } = this.props;
    setFieldValue("vehicle_type_id", value);
    loadVehiclebygroupid(value, true);
    this.setState({
      selectedKeys: [],
      vehicleSelect: [],
      flag: true,
    });
    setFieldValue("list_vehicle_id", []);
  };

  onPolicyChange = (value, e, setFieldValue) => {
    const { loadVehiclebypolicy, loadVehicle } = this.props;
    setFieldValue("event_id", value);
    if (e.props.type == "policy") {
      loadVehiclebypolicy(value.key, true);
    } else {
      loadVehicle("", true);
    }

    this.setState({
      eventType: e.props.type,
      vehicleSelect: [],
      selectedKeys: [],
      flag: true,
    });
  };

  componentWillReceiveProps = (nextProps) => {
    const {
      vehicleLists,
      vehiclebygroupLists,
      policyLists,
      eventLoading,
      call,
    } = nextProps;
    //console.log(nextProps);
    const vehlist = [];
    const vehiclelists = [];
    vehicleLists.map((item) =>
      vehlist.push({
        key: item.id,
        name: item.plate_no,
      })
    );

    vehicleLists.map((item) => vehiclelists.push(item.id));
    this.setState({ vehicleList: vehlist });

    if (this.state.flag) {
      const vehlistselect = [];
      vehiclebygroupLists.map((item) => vehlistselect.push(item.id));
      var sameVehicle = [];
      for (var i in vehlistselect) {
        if (vehiclelists.indexOf(vehlistselect[i]) > -1) {
          if (vehiclelists.indexOf(vehlistselect[i]) > -1) {
          }
          sameVehicle.push(vehlistselect[i]);
        }
      }

      if (sameVehicle.length > 0) {
        this.setState({ vehicleSelect: sameVehicle });
      }
      this.setState({ flag: false });
    }
  };

  tranferChange = (targetKeys, setFieldValue) => {
    setFieldValue("list_vehicle_id", targetKeys);
    this.setState({ vehicleSelect: targetKeys });
  };

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState({
      selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
    });
  };

  handleMenuClick = (e, setFieldValue, handleSubmit) => {
    // console.log('click', e.key);
    if (e.key === "excel") {
      setFieldValue("type_file", "excel", false);
    } else if (e.key === "pdf") {
      setFieldValue("type_file", "pdf", false);
    }
    setFieldValue("list_vehicle_id", this.state.vehicleSelect, false);
    setFieldValue("event_type", this.state.eventType, false);
    handleSubmit();
  };

  handleSubmitClick = (e, setFieldValue, handleSubmit) => {
    setFieldValue("list_vehicle_id", this.state.vehicleSelect, false);
    setFieldValue("event_type", this.state.eventType, false);
    handleSubmit();
  };

  handleSubmitClick;

  render() {
    const {
      vehicleLists,
      vehicleTypeLists,
      policyLists,
      eventLists,
      vehiclebypolicyLists,
      eventLoading,
      onSubmit,
    } = this.props;

    return (
      <div>
        <h3>
          <LangContext.Consumer>
            {(i18n) => i18n.e.EventReport}
          </LangContext.Consumer>
        </h3>
        <Spin spinning={eventLoading}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              vehicle_type_id: this.state.vehicle_type_id,
              eventdate: [moment("00:00", "HH:mm"), moment("23:59", "HH:mm")],
              list_vehicle_id: this.state.initVehicleSelect,
              event_id: this.state.defaultSelect,
              sheet_format: "seperate_sheet",
            }}
            validate={(values) => {
              let errors = {};
              if (values.eventdate.length > 0) {
                const date_start = moment(values.eventdate[0]["_d"]);
                const date_end = moment(values.eventdate[1]["_d"]);
                let day_diff = date_end.diff(date_start, "days");
                if (day_diff > 31) {
                  errors.eventdate = (
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.dateRangeNotBeMoreThan31Days}
                    </LangContext.Consumer>
                  );
                }
              }

              if (this.state.vehicleSelect.length == 0) {
                errors.list_vehicle_id = (
                  <LangContext.Consumer>
                    {(i18n) => i18n.p.pleaseChooseVehicle}
                  </LangContext.Consumer>
                );
              }

              //console.log(values.event_id);
              if (values.event_id.key == "") {
                errors.event_id = (
                  <LangContext.Consumer>
                    {(i18n) => i18n.p.pleaseChooseEvent}
                  </LangContext.Consumer>
                );
              }

              return errors;
            }}
            validationSchema={yup.object().shape({
              eventdate: yup.array().required("กรุณาเลือกช่วงวันที่"),
              //event_id: yup.array().required("กรุณาเลือกเหตุการณ์")
              // list_vehicle_id: yup.array().required("กรุณาเลือกรถ")
            })}
            onSubmit={(values) => {
              const loading = true;
              onSubmit(values, loading);
            }}
            render={({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              handleFocus,
              setFieldValue,
              isValidating,
            }) => (
              <Row type="flex" justify="center">
                <Form onSubmit={handleSubmit}>
                  <FormItem
                    required={true}
                    label={
                      <LangContext.Consumer>
                        {(i18n) => i18n.e.event}
                      </LangContext.Consumer>
                    }
                    validateStatus={
                      touched.event_id && errors.event_id && "warning"
                    }
                    help={touched.event_id && errors.event_id}
                  >
                    <Select
                      labelInValue={true}
                      //defaultValue={337}
                      // style={{ width: 200 }}
                      //placeholder="เลือกเหตุการณ์"
                      onChange={(value, e) => {
                        this.onPolicyChange(value, e, setFieldValue);
                      }}
                      value={values.event_id}
                      //onChange={this.handleChange}
                      optionFilterProp="title"
                      showSearch
                    >
                      <OptGroup
                        label={
                          <LangContext.Consumer>
                            {(i18n) => i18n.p.policy}
                          </LangContext.Consumer>
                        }
                      >
                        {policyLists.map((policy, i) => (
                          <Option
                            key={"policy_" + policy.id}
                            type="policy"
                            value={policy.id}
                            title={policy.name}
                          >
                            {policy.name}
                          </Option>
                        ))}
                      </OptGroup>
                      <OptGroup
                        label={
                          <LangContext.Consumer>
                            {(i18n) => i18n.e.event}
                          </LangContext.Consumer>
                        }
                      >
                        {eventLists.map((event, i) => (
                          <Option
                            key={"event_" + event.id}
                            type="feed"
                            value={event.id}
                            title={
                              i18n[this.props.language].event[
                                event.name_key.trim().toLowerCase()
                              ]
                            }
                          >
                            {/* <LangContext.Consumer>
                              {(i18n) =>
                                i18n.event[
                                  event.name_key
                                    .trim()
                                    .toLowerCase()
                                ]
                              }
                            </LangContext.Consumer> */}
                            {
                              i18n[this.props.language].event[
                                event.name_key.trim().toLowerCase()
                              ]
                            }
                          </Option>
                        ))}
                      </OptGroup>
                    </Select>
                  </FormItem>
                  {/* <FormItem
                      label={
                        <LangContext.Consumer>
                          {i18n => i18n.v.vehicleGroup}
                        </LangContext.Consumer>
                      }
                      validateStatus={
                        touched.vehicle_type_id &&
                        errors.vehicle_type_id &&
                        "warning"
                      }
                      help={touched.vehicle_type_id && errors.vehicle_type_id}
                    >
                      <OptGroup label="นโยบาย">
                        {policyLists.map((policy, i) => (
                          <Option
                            key={"policy_" + policy.id}
                            type="policy"
                            value={policy.id}
                          >
                            {policy.name}
                          </Option>
                        ))}
                      </OptGroup>
                      <OptGroup label="เหตุการณ์">
                        {eventLists.map((event, i) => (
                          <Option
                            key={"event_" + event.id}
                            type="feed"
                            value={event.id}
                          >
                            {event.name}
                          </Option>
                        ))}
                      </OptGroup>
                    </Select>
                  </FormItem> */}
                  <FormItem
                    label={
                      <LangContext.Consumer>
                        {(i18n) => i18n.v.vehicleGroup}
                      </LangContext.Consumer>
                    }
                    validateStatus={
                      touched.vehicle_type_id &&
                      errors.vehicle_type_id &&
                      "warning"
                    }
                    help={touched.vehicle_type_id && errors.vehicle_type_id}
                  >
                    <Select
                      //onChange={value => setFieldValue("vehicle_type_id", value)}
                      onChange={(value) =>
                        this.onVehicleTypeChange(value, setFieldValue)
                      }
                      value={values.vehicle_type_id}
                    >
                      <Options value="">
                        {" "}
                        <LangContext.Consumer>
                          {(i18n) => i18n.a.all}
                        </LangContext.Consumer>
                      </Options>
                      {vehicleTypeLists.map((item) => (
                        <Options key={item.id} value={item.id}>
                          {item.name}
                        </Options>
                      ))}
                    </Select>
                  </FormItem>
                  <FormItem
                    required={true}
                    label={
                      <LangContext.Consumer>
                        {(i18n) => i18n.v.vehicle}
                      </LangContext.Consumer>
                    }
                    validateStatus={
                      touched.list_vehicle_id &&
                      errors.list_vehicle_id &&
                      "warning"
                    }
                    help={touched.list_vehicle_id && errors.list_vehicle_id}
                  >
                    <Transfer
                      lazy={false}
                      titles={[
                        <LangContext.Consumer>
                          {(i18n) => i18n.i.itemLeft}
                        </LangContext.Consumer>,
                        <LangContext.Consumer>
                          {(i18n) => i18n.i.itemSelected}
                        </LangContext.Consumer>,
                      ]}
                      dataSource={this.state.vehicleList}
                      showSearch
                      listStyle={{
                        width: 250,
                        height: 300,
                      }}
                      selectedKeys={this.state.selectedKeys}
                      operations={[">>", "<<"]}
                      targetKeys={this.state.vehicleSelect}
                      onSelectChange={this.handleSelectChange}
                      onChange={(value) =>
                        this.tranferChange(value, setFieldValue)
                      }
                      //onChange={this.tranferChange}
                      render={(item) => `${item.name}`}
                      footer={this.renderFooter}
                    />
                  </FormItem>

                  <FormItem
                    label={
                      <LangContext.Consumer>
                        {(i18n) => i18n.f.filter_type}
                      </LangContext.Consumer>
                    }
                  >
                    <Radio.Group
                      onChange={(e) => {
                        setFieldValue("sheet_format", e.target.value);
                      }}
                      value={values.sheet_format}
                    >
                      <Radio value={"seperate_sheet"}>
                        <LangContext.Consumer>
                          {(i18n) => i18n.f.format_sheet_seperate}
                        </LangContext.Consumer>
                      </Radio>
                      <Radio value={"single_sheet"}>
                        <LangContext.Consumer>
                          {(i18n) => i18n.f.format_sheet_single}
                        </LangContext.Consumer>
                      </Radio>
                    </Radio.Group>
                  </FormItem>
                  {this.props.call !== "schedule" ? (
                    <Form.Item
                      label={
                        <LangContext.Consumer>
                          {(i18n) => i18n.d.dateRange}
                        </LangContext.Consumer>
                      }
                      validateStatus={
                        touched.eventdate && errors.eventdate && "warning"
                      }
                      help={touched.eventdate && errors.eventdate}
                    >
                      <RangePicker
                        showTime={{ format: "HH:mm" }}
                        onChange={(value) => setFieldValue("eventdate", value)}
                        format="DD/MM/YYYY HH:mm"
                        placeholder={["Start Time", "End Time"]}
                        defaultValue={[
                          moment("00:00", "HH:mm"),
                          moment("23:59", "HH:mm"),
                        ]}
                      />
                    </Form.Item>
                  ) : (
                    ""
                  )}
                  <FormItem>
                    <Row gutter={24}>
                      {this.props.call !== "schedule" ? (
                        <Col span={12}>
                          <Button type="defualt" block>
                            <Link to="/">
                              <LangContext.Consumer>
                                {(i18n) => i18n.c.cancel}
                              </LangContext.Consumer>
                            </Link>
                          </Button>
                        </Col>
                      ) : (
                        ""
                      )}
                      {this.props.call !== "schedule" ? (
                        <Col span={12}>
                          <Dropdown
                            overlay={
                              <Menu
                                onClick={(value) =>
                                  this.handleMenuClick(
                                    value,
                                    setFieldValue,
                                    handleSubmit
                                  )
                                }
                              >
                                <Menu.Item key="excel">
                                  <Icon type="file-excel" />{" "}
                                  <LangContext.Consumer>
                                    {(i18n) => i18n.e.excel}
                                  </LangContext.Consumer>
                                </Menu.Item>
                                <Menu.Item key="pdf">
                                  <Icon type="file-pdf" />{" "}
                                  <LangContext.Consumer>
                                    {(i18n) => i18n.p.pdf}
                                  </LangContext.Consumer>
                                </Menu.Item>
                              </Menu>
                            }
                          >
                            <Button type="primary" block>
                              <LangContext.Consumer>
                                {(i18n) => i18n.r.report}
                              </LangContext.Consumer>{" "}
                              <Icon type="down" />
                            </Button>
                          </Dropdown>
                        </Col>
                      ) : (
                        ""
                      )}

                      {this.props.call == "schedule" ? (
                        <Col span={8}>
                          <Button
                            type="primary"
                            onClick={(value) =>
                              this.handleSubmitClick(
                                value,
                                setFieldValue,
                                handleSubmit
                              )
                            }
                            block
                          >
                            <LangContext.Consumer>
                              {(i18n) => i18n.s.save}
                            </LangContext.Consumer>
                          </Button>
                        </Col>
                      ) : (
                        ""
                      )}
                    </Row>
                  </FormItem>
                </Form>
              </Row>
            )}
          />
        </Spin>
      </div>
    );
  }
}
const mapStateToProps = ({ ui: { language } }) => ({
  language,
});
export default connect(mapStateToProps, {})(Forms);
