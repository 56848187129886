import React, { Component, createContext } from "react";

/**
 * Defines constants for responsive comparison types.
 * @enum {string}
 */
export const RESPONSIVE_COMPARE = Object.freeze({
  UP: "up",
  DOWN: "down",
  BETWEEN: "between",
  ONLY: "only",
});

/**
 * Defines constants for breakpoint names.
 * @enum {string}
 */
export const BREAKPOINTS = {
  XS: "xs",
  SM: "sm",
  MD: "md",
  LG: "lg",
  XL: "xl",
  XXL: "xxl",
};

/**
 * Maps breakpoint names to pixel values.
 * @type {Object<string, number>}
 */
export const breakpoints = {
  [BREAKPOINTS.XS]: 0,
  [BREAKPOINTS.SM]: 576,
  [BREAKPOINTS.MD]: 768,
  [BREAKPOINTS.LG]: 992,
  [BREAKPOINTS.XL]: 1200,
  [BREAKPOINTS.XXL]: 1600,
};

/**
 * React context for responsive breakpoints.
 * @type {React.Context<{breakpoint: string, isBreakpoint?: function}>}
 */
const ResponsiveContext = createContext({ breakpoint: "xs" });

/**
 * Provides responsive breakpoint context to child components.
 * @extends {Component}
 */
class ResponsiveProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breakpoint: this.getBreakpoint(
        typeof window !== "undefined" ? window.innerWidth : 1024
      ),
    };
  }

  /**
   * Determines the current breakpoint based on window width.
   * @param {number} width - The width of the window.
   * @returns {string} The corresponding breakpoint.
   */
  getBreakpoint = (width) => {
    if (width >= breakpoints[BREAKPOINTS.XXL]) return BREAKPOINTS.XXL;
    if (width >= breakpoints[BREAKPOINTS.XL]) return BREAKPOINTS.XL;
    if (width >= breakpoints[BREAKPOINTS.LG]) return BREAKPOINTS.LG;
    if (width >= breakpoints[BREAKPOINTS.MD]) return BREAKPOINTS.MD;
    if (width >= breakpoints[BREAKPOINTS.SM]) return BREAKPOINTS.SM;
    return BREAKPOINTS.XS;
  };

  /**
   * Compares the current breakpoint against a target size.
   * @param {"up" | "down" | "between" | "only"} type - The type of comparison ("up", "down", "between", "only").
   * @param {"xs" | "sm" | "md" | "lg" | "xl" | "xxl"} size1 - The first breakpoint to compare.
   * @param {"xs" | "sm" | "md" | "lg" | "xl" | "xxl"} [size2=null] - The second breakpoint (only for "between").
   * @returns {boolean} Whether the condition is met.
   */
  compareBreakpoint = (type, size1, size2 = null) => {
    const currentSize = breakpoints[this.state.breakpoint];
    const targetSize1 = breakpoints[size1];
    const targetSize2 = size2 ? breakpoints[size2] : null;

    switch (type) {
      case RESPONSIVE_COMPARE.UP:
        return currentSize >= targetSize1;
      case RESPONSIVE_COMPARE.DOWN:
        return currentSize <= targetSize1;
      case RESPONSIVE_COMPARE.BETWEEN:
        return (
          targetSize2 !== null &&
          currentSize >= targetSize1 &&
          currentSize <= targetSize2
        );
      case RESPONSIVE_COMPARE.ONLY:
        return currentSize === targetSize1;
      default:
        return false;
    }
  };

  render() {
    return (
      <ResponsiveContext.Provider
        value={{
          breakpoint: this.state.breakpoint,
          isBreakpoint: this.compareBreakpoint,
        }}
      >
        {this.props.children}
      </ResponsiveContext.Provider>
    );
  }
}

/**
 * Higher-order component to inject responsive context.
 * @param {React.ComponentType} Component - The component to wrap.
 * @returns {React.FC} Wrapped component with breakpoint props.
 */
const withResponsive = (Component) => (props) => (
  <ResponsiveContext.Consumer>
    {({ breakpoint, isBreakpoint }) => (
      <Component
        {...props}
        breakpoint={breakpoint}
        isBreakpoint={isBreakpoint}
      />
    )}
  </ResponsiveContext.Consumer>
);

export { ResponsiveProvider, ResponsiveContext, withResponsive };
