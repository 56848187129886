import React, { Component, Fragment } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  withRouter,
  useParams,
} from "react-router-dom";
// import { Connector } from "mqtt-react";
import { Layout as AppLayout, Alert, Col, Modal, message, Spin } from "antd";
import { connect } from "react-redux";
import loadable from "@loadable/component";
import axios from "axios";
import history from "lib/history";
import "../../../styled/common.css";
import * as authActions from "modules/auth/actions";
import * as uiActions from "modules/ui/actions";
import PrivateRoute from "./PrivateRoute";
import Header from "./Header";
import LangContext, { i18n } from "modules/shared/context/langContext";
import VerifyEmailModal from "modules/shared/components/VerifyEmailModal";
import SliderBar from "./SliderBar";
import { askForSoundPlayingText } from "../../../constants/constants";
//////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////
////////                      Site Page                           ////////
//////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////

import Login from "modules/auth/components/Login";
import Drivinglogspecial from "modules/reports/drivinglogspecial/components/Drivinglog";
import Routemaster from "modules/delivery-management/route-master/components/Routemaster";
import Schedulereport from "modules/reports/schedulereport/components/Schedulereport";
import Honeytoastplayback from "modules/monitoring/honeytoastplayback/components/Honeytoastplayback";
import Honeytoastdownloadfile from "modules/monitoring/honeytoastdownloadfile/components/Honeytoastdownloadfile";
import Honeytoaststream2 from "modules/monitoring/honeytoaststream2/components/Video";
import Honeytoaststream from "modules/monitoring/honeytoaststream/components/Video";
import Deliverytrip from "modules/delivery-management/delivery-trip/components/Trip";
import Servicerepairdevice from "modules/help/servicerepairdevice/components/Servicerepairdevice";
import Contactmanagement from "modules/management/contactmanagement/components/Contactmanagement";
import Mdvrdownloadfile from "modules/monitoring/mdvrdownloadfile/components/Mdvrdownloadfile";
import HomeDashboard from "modules/home-dashboard/index/components/HomeDashboard";
import Uom from "modules/master-data/uom/components/Uom";
import Product from "modules/master-data/product/components/Product";

import Calibratefuel from "modules/setting/calibratefuel/components/Calibratefuel";
import Policy from "modules/setting/policy/components/Policy";
import PolicyNew from "modules/setting/policynew/components/Policy";
import NotiCenter from "modules/setting/noticenter/components/Noticenter";
import VoiveManagement from "modules/setting/voiceManagement/components/VoiceManagement";
import Companysetting from "../../setting/companysetting/components/Companysetting";
import MapEventDetail from "modules/shared/components/map-event-detail/components/MapEventDetail";
import ConcreteDelivery from "modules/monitoring/concrete-delivery/components/ConcreteDelivery";
import MultiDrop from "modules/monitoring/multi-drop/components/MultiDrop";
import Profile from "modules/management/profile/components/Profile";

import Drivingcontinuous from "modules/reports/drivingcontinuous/components/Drivingcontinuous";
import Notinotswipecard from "modules/reports/notinotswipecard/components/Notinotswipecard";

import ManageRepairDevice from "modules/help/manage-repairdevice/components/ManageRepairDevice";
import Overview from "../../monitoring/graph-sensors/components/Overview";
import ManageOTP from "../../../modules/management/manage-otp/components/Overview";
import ManageDashboardMonitoring from "../../../modules/management/manage-dashboard-monitoring/components/Overview";
import ManageFuelRate from "../../../modules/management/manage-fuelrate/components/Overview";
import DepositFuelCredit from "../../../modules/management/deposit-fuel-credit/components/Overview";
import WithdrawFuelCredit from "../../../modules/management/withdraw-fuel-credit/components/Overview";
import FuelCreditSetting from "../../../modules/management/fuelcredit-setting/components/Overview";
import ReportWithdrawFuelCredit from "../../../modules/reports/withdrawfuelcreditreport/components/Overview";
import ReportDepositFuelCredit from "../../../modules/reports/depositfuelcreditreport/components/Overview";
import ReportFlow from "../../../modules/reports/flowreport/components/Overview";
import ReportFuelNotify from "../../../modules/reports/fuelnotify/components/Overview";
import Outofcontact from "../../../modules/management/outofcontact/components/Outofcontact";
import Performancelog from "modules/reports/performanceLog/components/Performancelog";

import Honeytoastdownloadfilewebsocketinqueue from "../../monitoring/honeytoastdownloadfilewebsocketinqueue/components/Honeytoastdownloadfile";

import DriverDashboard from "modules/monitoring/driver-dashboard/components/DriverDashboard";
import CPFDashboard from "modules/monitoring/cpf-dashboard/components/Overview";
import CalibrateFuelFromV1 from "modules/monitoring/calibrate-fuel/CalibrateFuel";
import DashboardMonitoring from "../../monitoring/dashboard-monitoring/components/Overview";
import HoneytoastplaybackWebpage from "modules/monitoring/honeytoastplaybackwebpage/components/Honeytoastplayback";
import HoneyToastdownloadfilewebpage from "modules/monitoring/honeytoastdownloadfilewebpage/components/Honeytoastdownloadfile";

import MonitorSDCard from "modules/help/monitor-sdcard/components/Overview";

import CameraStatusDashboard from "modules/monitoring/camera-status-dashboard/components/Overview";
import MonitorGPS from "modules/monitoring/monitor-gps/components/Overview";
import TaxRenewalReadiness from "modules/help/tax-renewal-readiness/components/Overview";
import DashboardAdas from "../../monitoring/dashboard-adas/components/DashboardAdas";
import NewsManuals from "../../setting/NewsManuals/Overview";
import CustomerCompany from "../../master-data/customer-company/components/OverView";

import {
  breakpoints,
  BREAKPOINTS,
  RESPONSIVE_COMPARE,
  withResponsive,
} from "../../shared/context/ResponsiveContext";
import styled from "styled-components";

const Container = styled.div`
  width: calc(-200px + 100vw);

  ${(props) =>
    props.collapsed &&
    `
    width: calc(-80px + 100vw);
    `}

  @media (max-width: ${breakpoints[BREAKPOINTS.SM]}px) {
    width: 100vw;
  }
`;

const Summaryiconsiam = loadable(() =>
  import("modules/reports/summaryiconsiam/components/Summaryiconsiam")
);

const Dailyiconsiam = loadable(() =>
  import("modules/reports/dailyiconsiam/components/Dailyiconsiam")
);

// const Drivinglogspecial = loadable(() =>
//   import("modules/reports/drivinglogspecial/components/Drivinglog")
// );

const Tripbyengineoffon = loadable(() =>
  import("modules/reports/tripbyengineoffon/components/Tripbyengineoffon")
);

const Vehicleinformationdlt = loadable(() =>
  import(
    "modules/reports/vehicleinformationdlt/components/Vehicleinformationdlt"
  )
);
const Alarmalertbyplateno = loadable(() =>
  import("modules/reports/alarmalertbyplateno/components/Alarmalert")
);
const Alarmalertbyplatenoonyear = loadable(() =>
  import("modules/reports/alarmalertbyplatenoonyear/components/Alarmalert")
);
const Alarmalertbydriver = loadable(() =>
  import("modules/reports/alarmalertbydriver/components/Alarmalert")
);
const Alarmalertbydurationtime = loadable(() =>
  import("modules/reports/alarmalertbydurationtime/components/Alarmalert")
);
const Drivingbehavior = loadable(() =>
  import("modules/reports/drivingbehavior/components/Alarmalert")
);
const Drivinglicensescanbehavior = loadable(() =>
  import(
    "modules/reports/drivinglicensescanbehavior/components/Drivinglicensescan"
  )
);
const Separatevehiclestatus = loadable(() =>
  import("modules/reports/separatevehiclestatus/components/Routereport")
);
const Fuelremainrate = loadable(() =>
  import("modules/reports/fuelremainrate/components/fuelRemainRate")
);
const GpsLostContact = loadable(() =>
  import("modules/reports/gpslostcontact/components/gpsLostContact")
);
const TaxiSos = loadable(() =>
  import("modules/reports/taxisos/components/taxiSos")
);
const SummaryLineNotification = loadable(() =>
  import(
    "modules/reports/summarylinenotification/components/summaryLineNotification"
  )
);
const SpeedOverLimit = loadable(() =>
  import("modules/reports/speedoverlimit/components/SpeedOverLimit")
);
const Comparevehiclescco = loadable(() =>
  import("modules/reports/comparevehiclescco/components/Comparevehiclescco")
);
const Alertdriverlicense = loadable(() =>
  import("modules/reports/alertdriverlicense/components/Alert")
);
const Parkingareaoutside = loadable(() =>
  import("modules/reports/parkingareaoutside/components/Parking")
);
const Taxifare = loadable(() =>
  import("modules/reports/taxifare/components/Taxifare")
);
const Parking = loadable(() =>
  import("modules/reports/parking/components/Parking")
);
const Drivinglicensescanlog = loadable(() =>
  import(
    "modules/reports/drivinglicensescanlog/components/Drivinglicensescanlog"
  )
);

const Drivinglicenseexpire = loadable(() =>
  import("modules/reports/drivinglicenseexpire/components/Drivinglicenseexpire")
);
const Speedoverlimitsummary = loadable(() =>
  import(
    "modules/reports/speedoverlimitsummary/components/Speedoverlimitsummary"
  )
);
const Drivinglog = loadable(() =>
  import("modules/reports/drivinglog/components/Drivinglog")
);

const Event = loadable(() => import("modules/reports/event/components/Event"));
const AdasEvent = loadable(() =>
  import("modules/reports/adasevent/components/Overview")
);
const SecBySec = loadable(() =>
  import("modules/reports/secbysec/components/Overview")
);
const RmcConcreteTripReport = loadable(() =>
  import("modules/reports/rmcconcretetrip/components/Overview")
);

const Summarykpi = loadable(() =>
  import("modules/reports/summarykpi/components/Summarykpi")
);
const SummaryKpiByDriver = loadable(() =>
  import("modules/reports/summarykpibydriver/components/SummaryKpiByDriver")
);
const AlarmalertbydriverNewformet = loadable(() =>
  import(
    "modules/reports/alarmalertfrequencybydriver/components/AlarmalertNewformet"
  )
);
const Loginhistory = loadable(() =>
  import("modules/reports/loginhistory/components/Loginhistory")
);
const Gpsstatus = loadable(() =>
  import("modules/reports/gpsstatus/components/Gpsstatus")
);

// const Trackingdata = loadable(() =>
//   import("modules/reports/trackingdata/components/Trackingdata")
// );

const Truckusage = loadable(() =>
  import("modules/reports/truckusage/components/Truckusage")
);

const TruckusageSJCMonthlyReport = loadable(() =>
  import("modules/reports/sjcmonthlytruckusages/components/Truckusage")
);

const Drivingdistance = loadable(() =>
  import("modules/reports/drivingdistance/components/Drivingdistance")
);

const Temperature = loadable(() =>
  import("modules/reports/temperature/components/Temperature")
);

const UserlogOnHistory = loadable(() =>
  import("modules/reports/userlogonhistory/components/UserlogOnHistory")
);

const Maintenance = loadable(() =>
  import("modules/reports/maintenance/components/Maintenance")
);

const Distfromlastmaintenance = loadable(() =>
  import(
    "modules/reports/distfromlastmaintenance/components/Distfromlastmaintenance"
  )
);

const Truckcurrentlocation = loadable(() =>
  import("modules/reports/truckcurrentlocation/components/Truckcurrentlocation")
);

const Driverinformation = loadable(() =>
  import("modules/reports/driverinformation/components/Driverinformation")
);

const Numberofdaysworked = loadable(() =>
  import("modules/reports/numberofdaysworked/components/Numberofdaysworked")
);

const Dozeoffrisk = loadable(() =>
  import("modules/reports/dozeoffrisk/components/Dozeoffrisk")
);

const Delivery = loadable(() =>
  import("modules/reports/delivery/components/Delivery")
);

const Tripsumbyveh = loadable(() =>
  import("modules/reports/tripsumbyveh/components/Tripsumbyveh")
);

const Triptemperature = loadable(() =>
  import("modules/reports/triptemperature/components/Triptemperature")
);

const Daily = loadable(() => import("modules/reports/daily/components/Daily"));

const Velocitysummary = loadable(() =>
  import("modules/reports/velocitysummary/components/Velocitysummary")
);

const Truckengineon = loadable(() =>
  import("modules/reports/truckengineon/components/Truckengineon")
);

const Truckengineoff = loadable(() =>
  import("modules/reports/truckengineoff/components/Truckengineoff")
);

const Inputactivity = loadable(() =>
  import("modules/reports/inputactivity/components/Inputactivity")
);

const Trucknotuse = loadable(() =>
  import("modules/reports/trucknotuse/components/Trucknotuse")
);

const Inoutlocation = loadable(() =>
  import("modules/reports/inoutlocation/components/Inoutlocation")
);

const Drivinghour = loadable(() =>
  import("modules/reports/drivinghour/components/Drivinghour")
);

const Tripsummary = loadable(() =>
  import("modules/reports/tripsummary/components/Tripsummary")
);

const Drivingoverlimit = loadable(() =>
  import("modules/reports/drivingoverlimit/components/Drivingoverlimit")
);

const Tripsumbylocation = loadable(() =>
  import("modules/reports/tripsumbylocation/components/Tripsumbylocation")
);

const Insurance = loadable(() =>
  import("modules/reports/insurance/components/Insurance")
);

const Vehicleperformanceconclude = loadable(() =>
  import(
    "modules/reports/vehicleperformanceconclude/components/Vehicleperformanceconclude"
  )
);

const Refuel = loadable(() =>
  import("modules/reports/refuel/components/Refuel")
);

const FuelFull = loadable(() =>
  import("modules/reports/fuelfull/components/FuelFull")
);

const Riskbehaviour = loadable(() =>
  import("modules/reports/riskbehaviour/components/Riskbehaviour")
);

const Vehicleinarea = loadable(() =>
  import("modules/reports/vehicleinarea/components/Vehicleinarea")
);

const Maintenancedeviceconclude = loadable(() =>
  import(
    "modules/reports/maintenancedeviceconclude/components/Maintenancedeviceconclude"
  )
);

const Boatdeliveryreport = loadable(() =>
  import("modules/reports/boatdelivery/components/Alarmalert")
);

const PickupAndDropoffEmployee = loadable(() =>
  import(
    "modules/reports/pickupanddropoffemployee/components/PickupAndDropoffEmployee"
  )
);

//management
const Downloadcertificate = loadable(() =>
  import(
    "modules/management/downloadcertificate/components/Downloadcertificate"
  )
);

const Createfrimware = loadable(() =>
  import("modules/management/create-frimware/components/Overview")
);

const Assignfirmware = loadable(() =>
  import("modules/management/assign-firmware/components/Overwive")
);

// const Drivingdistance = loadable(() =>
//   import("modules/reports/MoveReport/drivingdistance/components/DrivingdistanceOverview")
// );

// const Truckusage = loadable(() =>
//   import("modules/reports/truckusage/components/Truckusage")
// );

// notification
const NotificationCenter = loadable(() =>
  import("modules/ui/components/NotificationCenter")
);
// dashboard
const Tracking = loadable(() =>
  import("modules/monitoring/tracking/components/Tracking")
);
const DashCameras = loadable(() =>
  import("modules/monitoring/dash-cameras/components/DashCameras")
);
const AlertAlarm = loadable(() =>
  import("modules/monitoring/alert-alarm/components/AlertAlarm")
);

const BookingOverview = loadable(() =>
  import("modules/monitoring/booking-overview/components/BookingOverview")
);
const CashingOverview = loadable(() =>
  import("modules/monitoring/cashing-overview/components/CashingOverview")
);
const EventMap = loadable(() =>
  import("modules/monitoring/event-map/components/EventMap")
);

// const ConcreteDelivery = loadable(() =>
//   import("modules/monitoring/concrete-delivery/components/ConcreteDelivery")
// );
// const ConcreteDeliveryManager = loadable(() =>
//   import(
//     "modules/monitoring/concrete-delivery-manager/components/ConcreteDeliveryManager"
//   )
// );

const BoatDelivery = loadable(() =>
  import("modules/monitoring/boat-delivery/components/BoatDelivery")
);

const Replay = loadable(() =>
  import("modules/monitoring/replay/components/Replay")
);

const ReplayNew = loadable(() =>
  import("modules/monitoring/replaynew/components/Replay")
);

const ReplayV3 = loadable(() =>
  import("modules/monitoring/replayv3/components/Replay")
);

const Video = loadable(() =>
  import("modules/monitoring/video/components/Video")
);

const Trip = loadable(() =>
  import("modules/delivery-management/trip/components/Trip")
);

// const Policy = loadable(() =>
//   import("modules/setting/policy/components/Policy")
// );

// master data
const Customer = loadable(() =>
  import("modules/master-data/customer/components/Customer")
);
const Usergroup = loadable(() =>
  import("modules/master-data/usergroup/components/Usergroup")
);
const Pullvehiclesink = loadable(() =>
  import("modules/master-data/pullvehiclesink/components/Pullvehiclesink")
);
const Permission = loadable(() =>
  import("modules/master-data/permission/components/Permission")
);
const Datavisibility = loadable(() =>
  import("modules/master-data/datavisibility/components/Datavisibility")
);
const VehicleGroupOwerview = loadable(() =>
  import("modules/master-data/vehicleGroup/components/VehicleGroupOwerview")
);
const RouteOverview = loadable(() =>
  import("modules/master-data/route/components/RouteOverview")
);

const RouteIconSiamOverview = loadable(() =>
  import("modules/master-data/route-iconsiam/components/RouteOverview")
);

const TripDurationOverview = loadable(() =>
  import("modules/master-data/trip-duration/components/TripDurationOverview")
);

const VehicleSharing = loadable(() =>
  import("modules/master-data/vehicle-sharing/components/VehicleSharing")
);
const Facility = loadable(() =>
  import("modules/master-data/facility/components/FacilityOverviwe")
);
const Vehicle = loadable(() =>
  import("modules/master-data/vehicle/components/VehicleOverview")
);
const DriverUser = loadable(() =>
  import("modules/master-data/deviceuser/components/DeviceUserOverview")
);
const LocationGroup = loadable(() =>
  import("modules/master-data/locationgroup/components/LocationGroupOverview")
);
const LocationType = loadable(() =>
  import("modules/master-data/location-type/components/LocationTypeOverview")
);
const NearbyLocation = loadable(() =>
  import("modules/master-data/nearbylocation/componants/NearByLocationOverview")
);
const Zone = loadable(() =>
  import("modules/master-data/zone/components/ZoneOverview")
);
const User = loadable(() =>
  import("modules/master-data/user/components/UserOverview")
);
const Verifyemail = loadable(() =>
  import("modules/master-data/verifyemail/components/verifyemailOverview")
);
const Refeul = loadable(() =>
  import("modules/master-data/refuel/components/RefuelOverview")
);
const Partner = loadable(() =>
  import("modules/master-data/partner/components/PartnerOverview")
);
const LocationSharing = loadable(() =>
  import(
    "modules/master-data/locations-sharing/components/LocationSharingOverview"
  )
);
const PartType = loadable(() =>
  import("modules/master-data/parttype/components/PartTypeOverview")
);
const PartGroup = loadable(() =>
  import("modules/master-data/partgroup/components/PartGroupOverview")
);
const ManagementMaintenance = loadable(() =>
  import("modules/master-data/maintenance/components/MainntenencsOverview")
);
const ExpenseInsurance = loadable(() =>
  import("modules/master-data/insurance/components/InsuranceOverview")
);

const ManageDocument2 = loadable(() =>
  import(
    "modules/master-data/manage-document/components/ManageDocumentOverview"
  )
);

const Company = loadable(() =>
  import("modules/master-data/company/components/CompanyOverview")
);

const ServiceMoveDevice = loadable(() =>
  import("modules/help/servicemovedevice/components/ServiceMoveDeviceOverview")
);
const ServiceInstallDevice = loadable(() =>
  import("modules/help/serviceeinstalldevice/components/ServiceinstallOverview")
);
const Gellery = loadable(() =>
  import("modules/master-data/gallery/components/OverView")
);

const TemporaryTracking = loadable(() =>
  import(
    "modules/master-data/temporaryTracking/components/TemporaryTrackingOwerview"
  )
);

const DeviceConfig = loadable(() =>
  import("modules/master-data/deviceconfig/components/DeviceConfigOverview")
);

// booking
const CenterPool = loadable(() =>
  import("modules/booking-pool/center-pool/components/CenterPool")
);
const FinishedPool = loadable(() =>
  import("modules/booking-pool/finished-pool/components/FinishedPool")
);
const CancelledPool = loadable(() =>
  import("modules/booking-pool/cancelled-pool/components/CancelledPool")
);
const CashReceived = loadable(() =>
  import("modules/cashier/cash-received/components/CashReceived")
);
const ReceiveHistory = loadable(() =>
  import("modules/cashier/receive-history/components/ReceiveHistory")
);

// operation job
const GPSCertificationRequest = loadable(() =>
  import(
    "modules/operate-job/gps-certification/gps-certification-request/components/GPSCertificationRequest"
  )
);
const GPSCertificationRequestHistory = loadable(() =>
  import(
    "modules/operate-job/gps-certification/gps-certification-request-history/components/GPSCertificationRequestHistory"
  )
);
const Announcement = loadable(() =>
  import(
    "modules/operate-job/announcement/announcement-broadcast/components/Announcement"
  )
);
const DeliveryItem = loadable(() =>
  import("modules/operate-job/delivery-item/components/DeliveryItem")
);
const DeliveryItemDeleteHistory = loadable(() =>
  import(
    "modules/operate-job/delivery-item-delete-history/components/DeliveryItemDeleteHistory"
  )
);

const SkicInhouse = loadable(() =>
  import("modules/monitoring/skic-inhouse/components/Overview")
);

const HelpLine = loadable(() =>
  import("modules/help/helpLine/components/helpLineOverview")
);

const IncidentDashboard = loadable(() =>
  import("modules/monitoring/incidentdashboard/components/IncidentDashboard")
);

const IncidentReport = loadable(() =>
  import("modules/reports/incident/components/Incident")
);
const DriverIncome = loadable(() =>
  import("modules/reports/driverincome/components/Driverincome")
);

const VideoSnapshot = loadable(() =>
  import("modules/reports/videosnapshot/components/VideoSnapshot")
);

const SummaryDrivingLog = loadable(() =>
  import("modules/reports/summarydrivinglog/components/Summarydrivinglog")
);

const VehicleEntryAlertReport = loadable(() =>
  import("modules/reports/vehicleentryalert/components/Overview")
);

const RouteDeliverMap = loadable(() =>
  import("modules/monitoring/route-deliver/components/Overview.js")
);

const Videodownloadqueuevideolist = loadable(() =>
  import(
    "modules/monitoring/videodownloadqueuevideolist/components/Videodownloadqueuevideolist"
  )
);

class Layout extends Component {
  state = {
    collapsed: true,
    pageReload: false,
    notiCount: 0,
    haveNewNoti: false,
    i18n: i18n.th,
    langBtn: "th",
    checkSelectLang: "",
    url: "",
    VerifyEmailModalVisible: true,
    VerifyLoading: false,
    VerifyEmail: {
      m_user_id: "",
      email: "",
    },
    url_help_line: "",
  };

  changeLang = (inputLng) => {
    this.setState(
      {
        i18n: i18n[inputLng],
        langBtn: inputLng,
      },
      () => {
        this.props.setLanguageChange(inputLng);
      }
    );
  };

  checkPageReload() {
    if (window.performance) {
      if (
        window.performance.navigation.type == 1 &&
        history.action == "POP" &&
        history.location.pathname != "/" &&
        !history.location.pathname.includes("/center_pool/detail")
      ) {
        this.setState({ pageReload: true });
      }
    }
  }

  componentDidMount() {
    this.props.loadCredentials();
    this.checkPageReload();

    const url = new URL(window.location.href);
    const url_help_line = window.location.pathname;
    this.setState({
      url: url.pathname.substring(18, -1),
      url_help_line: url_help_line,
    });
  }

  setMsg = (title, msg) => this.props.setFlashMessage(title, msg);

  onRemoveCredentials = () => {
    this.props.removeCredentials();
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  handleVerifyEmailCancel = () => {
    this.setState({
      VerifyEmailModalVisible: false,
    });
  };

  handleAddEmailWichVerifyEmail = (values) => {
    let self = this;
    let full_name =
      this.props.profile.firstname + " " + this.props.profile.lastname;

    this.setState({
      VerifyLoading: true,
    });

    axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/UserVerifyMail`,
        {
          m_user_id: this.props.profile.id,
          full_name: full_name,
          email: values.email,
        },
        {
          headers: {
            Authorization: `Bearer ${this.props.accessToken}`,
          },
        }
      )
      .then(function(response) {
        if (typeof response.data !== "undefined") {
          const { data } = response;
          if (data.status == "duplicate_email") {
            message.error("This email already exists in the system.");
            self.setState({
              VerifyLoading: false,
            });
          } else {
            message.success("create success");

            self.setState({
              VerifyEmailModalVisible: false,
              VerifyLoading: false,
            });
          }
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  render() {
    const { isBreakpoint } = this.props;
    const isMobile = isBreakpoint(RESPONSIVE_COMPARE.ONLY, BREAKPOINTS.XS);

    return (
      <Router>
        <Fragment>
          {this.state.url == "/verifyemail/check" ? (
            <Route
              path="/verifyemail/check"
              component={Verifyemail}
              done={this.props.done}
            />
          ) : this.state.url_help_line == "/help-line" ? (
            <Route
              path="/help-line"
              component={HelpLine}
              done={this.props.done}
            />
          ) : this.state.url_help_line == "/video-playback-webpage" ? (
            <Route
              path="/video-playback-webpage"
              component={HoneytoastplaybackWebpage}
              done={this.props.done}
            />
          ) : this.state.url_help_line ==
            "/honey-toast-download-file-webpage" ? (
            <Route
              path="/honey-toast-download-file-webpage"
              component={HoneyToastdownloadfilewebpage}
              done={this.props.done}
            />
          ) : this.state.url_help_line.includes(
              "videodownloadqueuevideolist"
            ) ? (
            <Route
              path="/videodownloadqueuevideolist"
              component={Videodownloadqueuevideolist}
              done={this.props.done}
            />
          ) : this.state.url_help_line.includes("route-delivery-map") ? (
            <Route
              path="/route-delivery-map"
              component={RouteDeliverMap}
              done={this.props.done}
            />
          ) : !this.props.done ? (
            <Login auth={this.props.path} />
          ) : (
            <LangContext.Provider value={this.state.i18n}>
              <AppLayout>
                <Header
                  collapsed={this.state.collapsed}
                  toggle={this.toggle}
                  langBtn={this.state.langBtn}
                  changeLang={this.changeLang}
                  checkSelectLang={this.state.checkSelectLang}
                />
                <AppLayout
                  style={{
                    position: "relative",
                    minHeight: "calc(-44px + 100vh)",
                  }}
                >
                  <SliderBar
                    collapsed={this.state.collapsed}
                    toggle={this.toggle}
                  />
                  <Col xs={0} sm={0} md={0} lg={0} xl={0}>
                    {this.state.pageReload && (
                      <Alert
                        message={askForSoundPlayingText}
                        type="info"
                        showIcon
                        closeText={"Enable"}
                        closable
                        style={{
                          margin: "4px 4px 4px 4px",
                        }}
                      />
                    )}
                  </Col>
                  {/* verify email comment until solutions confirm launching timeline*/}
                  <Container collapsed={this.state.collapsed}>
                    <PrivateRoute
                      key="/profile"
                      path="/profile"
                      component={(props) => <Profile {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/notification"
                      path="/notification"
                      component={(props) => <NotificationCenter {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/center_pool"
                      path="/center_pool"
                      component={(props) => <CenterPool {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/finished_pool"
                      path="/finished_pool"
                      component={(props) => <FinishedPool {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/cancelled_pool"
                      path="/cancelled_pool"
                      component={(props) => <CancelledPool {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/tracking"
                      path="/tracking"
                      component={(props) => <Tracking {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/dash_cameras"
                      path="/dash_cameras"
                      component={(props) => <DashCameras {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/graphsensors"
                      path="/graphsensors"
                      component={(props) => <Overview {...props} />}
                      done={this.props.done}
                    />
                    {/* <PrivateRoute
                    key="/dashboard-monitoring"
                    path="/dashboard-monitoring"
                    component={(props) => <DashboardMonitoring {...props} />}
                    done={this.props.done}
                  /> */}

                    <PrivateRoute
                      key="/performancelog"
                      path="/performancelog"
                      component={(props) => <Performancelog {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/manage-otp"
                      path="/manage-otp"
                      component={(props) => <ManageOTP {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/manage-fuelrate"
                      path="/manage-fuelrate"
                      component={(props) => <ManageFuelRate {...props} />}
                      done={this.props.done}
                    />

                    {/* <PrivateRoute
                    key="/manage-dashboard-monitoring"
                    path="/manage-dashboard-monitoring"
                    component={(props) => (
                      <ManageDashboardMonitoring {...props} />
                    )}
                    done={this.props.done}
                  /> */}

                    <PrivateRoute
                      key="/deposit-fuel-credit"
                      path="/deposit-fuel-credit"
                      component={(props) => <DepositFuelCredit {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/withdraw-fuel-credit"
                      path="/withdraw-fuel-credit"
                      component={(props) => <WithdrawFuelCredit {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/fuelcredit-setting"
                      path="/fuelcredit-setting"
                      component={(props) => <FuelCreditSetting {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/outofcontact"
                      path="/outofcontact"
                      component={(props) => <Outofcontact {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/alert_alarm"
                      path="/alert_alarm"
                      component={(props) => <AlertAlarm {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/booking_overview"
                      path="/booking_overview"
                      component={(props) => <BookingOverview {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/cashing_overview"
                      path="/cashing_overview"
                      component={(props) => <CashingOverview {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/cash_received"
                      path="/cash_received"
                      component={(props) => <CashReceived {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/receive_history"
                      path="/receive_history"
                      component={(props) => <ReceiveHistory {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/customer"
                      path="/customer"
                      component={(props) => <Customer {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/deliveryitem"
                      path="/deliveryitem"
                      component={(props) => <DeliveryItem {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/deliveryitem_delete_history"
                      path="/deliveryitem_delete_history"
                      component={(props) => (
                        <DeliveryItemDeleteHistory {...props} />
                      )}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/incident-dashboard"
                      path="/incident-dashboard"
                      component={(props) => <IncidentDashboard {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/usergroup"
                      path="/usergroup"
                      component={(props) => <Usergroup {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/report/alarmalertbyplateno"
                      path="/report/alarmalertbyplateno"
                      component={(props) => <Alarmalertbyplateno {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/gps_certification_request"
                      path="/gps_certification_request"
                      component={(props) => (
                        <GPSCertificationRequest {...props} />
                      )}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/gps_certification_request_history"
                      path="/gps_certification_request_history"
                      component={(props) => (
                        <GPSCertificationRequestHistory {...props} />
                      )}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/report/alarmalertbydriver"
                      path="/report/alarmalertbydriver"
                      component={(props) => <Alarmalertbydriver {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/report/alarmalertfrequencybydriver"
                      path="/report/alarmalertfrequencybydriver"
                      component={(props) => (
                        <AlarmalertbydriverNewformet {...props} />
                      )}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/report/alarmalertbydurationtime"
                      path="/report/alarmalertbydurationtime"
                      component={(props) => (
                        <Alarmalertbydurationtime {...props} />
                      )}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/report/drivingbehavior"
                      path="/report/drivingbehavior"
                      component={(props) => <Drivingbehavior {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/report/drivinglicensescanbehavior"
                      path="/report/drivinglicensescanbehavior"
                      component={(props) => (
                        <Drivinglicensescanbehavior {...props} />
                      )}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/report/alarmalertbyplatenoonyear"
                      path="/report/alarmalertbyplatenoonyear"
                      component={(props) => (
                        <Alarmalertbyplatenoonyear {...props} />
                      )}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/report/separatevehiclestatus"
                      path="/report/separatevehiclestatus"
                      component={(props) => (
                        <Separatevehiclestatus {...props} />
                      )}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/report/incident"
                      path="/report/incident"
                      component={(props) => <IncidentReport {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/announcement_broadcast"
                      path="/announcement_broadcast"
                      component={(props) => <Announcement {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/event_map"
                      path="/event_map"
                      component={(props) => <EventMap {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/concrete_delivery"
                      path="/concrete_delivery"
                      component={(props) => <ConcreteDelivery {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/driver-dashboard"
                      path="/driver-dashboard"
                      component={(props) => <DriverDashboard {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/multi_drop"
                      path="/multi_drop"
                      component={(props) => <MultiDrop {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/dashboard-adasp"
                      path="/dashboard-adas"
                      component={(props) => <DashboardAdas {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/boat_delivery"
                      path="/boat_delivery"
                      component={(props) => <BoatDelivery {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/report/boatdelivery"
                      path="/report/boatdelivery"
                      component={(props) => <Boatdeliveryreport {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/report/pickup-dropoff-employee"
                      path="/report/pickup-dropoff-employee"
                      component={(props) => (
                        <PickupAndDropoffEmployee {...props} />
                      )}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/oldreplay"
                      path="/oldreplay"
                      component={(props) => <Replay {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/replay"
                      path="/replay"
                      component={(props) => <ReplayNew {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/replayv3"
                      path="/replayv3"
                      component={(props) => <ReplayV3 {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/report/comparevehiclescco"
                      path="/report/comparevehiclescco"
                      component={(props) => <Comparevehiclescco {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/report/alertdriverlicense"
                      path="/report/alertdriverlicense"
                      component={(props) => <Alertdriverlicense {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/report/parkingareaoutside"
                      path="/report/parkingareaoutside"
                      component={(props) => <Parkingareaoutside {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/report/taxifare"
                      path="/report/taxifare"
                      component={(props) => <Taxifare {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/pullvehiclesink"
                      path="/pullvehiclesink"
                      component={(props) => <Pullvehiclesink {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/location"
                      path="/location"
                      component={(props) => <Facility {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/user"
                      path="/user"
                      component={(props) => <User {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/vehiclegroup"
                      path="/vehiclegroup"
                      component={(props) => <VehicleGroupOwerview {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/route"
                      path="/route"
                      component={(props) => <RouteOverview {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/route-iconsiam"
                      path="/route-iconsiam"
                      component={(props) => (
                        <RouteIconSiamOverview {...props} />
                      )}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/trip-duration"
                      path="/trip-duration"
                      component={(props) => <TripDurationOverview {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/vehicle"
                      path="/vehicle"
                      component={(props) => <Vehicle {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/parking"
                      path="/report/parking"
                      component={(props) => <Parking {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/parking2"
                      path="/report/parking2"
                      component={(props) => <Parking {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/drivinglicensescanlog"
                      path="/report/drivinglicensescanlog"
                      component={(props) => (
                        <Drivinglicensescanlog {...props} />
                      )}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/drivinglicenseexpire"
                      path="/report/drivinglicenseexpire"
                      component={(props) => <Drivinglicenseexpire {...props} />}
                      done={this.props.done}
                    />

                    {/* <PrivateRoute
                        <PrivateRoute
                          key="/report/drivinglicensescanlog"
                          path="/report/drivinglicensescanlog"
                          component={props => <Drivinglicensescanlog {...props} />}
                          done={this.props.done}
                        />

                        <PrivateRoute
                          key="/"
                          path="/"
                          component={props => <HomeDashboard {...props} />}

                        />


                        {/* <PrivateRoute

                        key="/vehicle"
                        path="/vehicle"
                        component={Vehicle}
                        done={this.props.done}
                      /> */}
                    <PrivateRoute
                      key="/"
                      path="/"
                      component={(props) => <HomeDashboard {...props} />}
                    />

                    <PrivateRoute
                      key="/report/speedoverlimitsummary"
                      path="/report/speedoverlimitsummary"
                      component={(props) => (
                        <Speedoverlimitsummary {...props} />
                      )}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/drivinglog"
                      path="/report/drivinglog"
                      component={(props) => <Drivinglog {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/event"
                      path="/report/event"
                      component={(props) => <Event {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/report/adasevent"
                      path="/report/adasevent"
                      component={(props) => <AdasEvent {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/report/secbysec"
                      path="/report/secbysec"
                      component={(props) => <SecBySec {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/report/rmcconcretetrip"
                      path="/report/rmcconcretetrip"
                      component={(props) => (
                        <RmcConcreteTripReport {...props} />
                      )}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/fuelnotify"
                      path="/report/fuelnotify"
                      component={(props) => <ReportFuelNotify {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/summarykpi"
                      path="/report/summarykpi"
                      component={(props) => <Summarykpi {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/summarykpibydriver"
                      path="/report/summarykpibydriver"
                      component={(props) => <SummaryKpiByDriver {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/permission"
                      path="/permission"
                      component={(props) => <Permission {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/vehicle_sharing"
                      path="/vehicle_sharing"
                      component={(props) => <VehicleSharing {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/drivingdistance"
                      path="/drivingdistance"
                      component={(props) => <Drivingdistance {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/datavisibility"
                      path="/datavisibility"
                      component={(props) => <Datavisibility {...props} />}
                      done={this.props.done}
                    />

                    {/* <PrivateRoute
                    key="/report/loginhistory"
                    path="/report/loginhistory"
                    component={(props) => <Loginhistory {...props} />}
                    done={this.props.done}
                  /> */}

                    <PrivateRoute
                      key="/report/gpsstatus"
                      path="/report/gpsstatus"
                      component={(props) => <Gpsstatus {...props} />}
                      done={this.props.done}
                    />

                    {/* <PrivateRoute
                    key="/report/trackingdata"
                    path="/report/trackingdata"
                    component={(props) => <Trackingdata {...props} />}
                    done={this.props.done}
                  /> */}

                    {/* <PrivateRoute
                      key="/verifyemail"
                      path="/verifyemail"
                      component={Verifyemail}
                      done={this.props.done}
                    /> */}

                    <PrivateRoute
                      key="/deviceuser"
                      path="/deviceuser"
                      component={(props) => <DriverUser {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/video"
                      path="/video"
                      component={(props) => <Video {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/mdvrtest"
                      path="/mdvrtest"
                      component={(props) => <Video {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/videospecial"
                      path="/videospecial"
                      component={(props) => <Video {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/locationgroup"
                      path="/locationgroup"
                      component={(props) => <LocationGroup {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/location-type"
                      path="/location-type"
                      component={(props) => <LocationType {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/nearbylocation"
                      path="/nearbylocation"
                      component={(props) => <NearbyLocation {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/refuel"
                      path="/refuel"
                      component={(props) => <Refeul {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/truckusage"
                      path="/report/truckusage"
                      component={(props) => <Truckusage {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/monthlytruckusages"
                      path="/report/monthlytruckusages"
                      component={(props) => (
                        <TruckusageSJCMonthlyReport {...props} />
                      )}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/drivingdistance"
                      path="/report/drivingdistance"
                      component={(props) => <Drivingdistance {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/temperature"
                      path="/report/temperature"
                      component={(props) => <Temperature {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/userlogonhistory"
                      path="/report/userlogonhistory"
                      component={(props) => <UserlogOnHistory {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/maintenance"
                      path="/report/maintenance"
                      component={(props) => <Maintenance {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/distfromlastmaintenance"
                      path="/report/distfromlastmaintenance"
                      component={(props) => (
                        <Distfromlastmaintenance {...props} />
                      )}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/truckcurrentlocation"
                      path="/report/truckcurrentlocation"
                      component={(props) => <Truckcurrentlocation {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/driverinformation"
                      path="/report/driverinformation"
                      component={(props) => <Driverinformation {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/numberofdaysworked"
                      path="/report/numberofdaysworked"
                      component={(props) => <Numberofdaysworked {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/dozeoffrisk"
                      path="/report/dozeoffrisk"
                      component={(props) => <Dozeoffrisk {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/delivery"
                      path="/report/delivery"
                      component={(props) => <Delivery {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/triptemperature"
                      path="/report/triptemperature"
                      component={(props) => <Triptemperature {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/tripsumbyveh"
                      path="/report/tripsumbyveh"
                      component={(props) => <Tripsumbyveh {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/daily"
                      path="/report/daily"
                      component={(props) => <Daily {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/velocitysummary"
                      path="/report/velocitysummary"
                      component={(props) => <Velocitysummary {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/truckengineon"
                      path="/report/truckengineon"
                      component={(props) => <Truckengineon {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/truckengineoff"
                      path="/report/truckengineoff"
                      component={(props) => <Truckengineoff {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/drivinghour"
                      path="/report/drivinghour"
                      component={(props) => <Drivinghour {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/tripsummary"
                      path="/report/tripsummary"
                      component={(props) => <Tripsummary {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/drivingoverlimit"
                      path="/report/drivingoverlimit"
                      component={(props) => <Drivingoverlimit {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/tripsumbylocation"
                      path="/report/tripsumbylocation"
                      component={(props) => <Tripsumbylocation {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/vehicle-entry-alert"
                      path="/report/vehicle-entry-alert"
                      component={(props) => (
                        <VehicleEntryAlertReport {...props} />
                      )}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/insurance"
                      path="/report/insurance"
                      component={(props) => <Insurance {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/vehicleperformanceconclude"
                      path="/report/vehicleperformanceconclude"
                      component={(props) => (
                        <Vehicleperformanceconclude {...props} />
                      )}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/refuel"
                      path="/report/refuel"
                      component={(props) => <Refuel {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/fuelfull"
                      path="/report/fuelfull"
                      component={(props) => <FuelFull {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/inputactivity"
                      path="/report/inputactivity"
                      component={(props) => <Inputactivity {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/trucknotuse"
                      path="/report/trucknotuse"
                      component={(props) => <Trucknotuse {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/inoutlocation"
                      path="/report/inoutlocation"
                      component={(props) => <Inoutlocation {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/riskbehaviour"
                      path="/report/riskbehaviour"
                      component={(props) => <Riskbehaviour {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/partner"
                      path="/partner"
                      component={(props) => <Partner {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/sharelocation"
                      path="/sharelocation"
                      component={(props) => <LocationSharing {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/vehicleinarea"
                      path="/report/vehicleinarea"
                      component={(props) => <Vehicleinarea {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/maintenancedeviceconclude"
                      path="/report/maintenancedeviceconclude"
                      component={(props) => (
                        <Maintenancedeviceconclude {...props} />
                      )}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/zone"
                      path="/zone"
                      component={(props) => <Zone {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/trip"
                      path="/trip"
                      component={(props) => <Trip {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/policy"
                      path="/policy"
                      component={(props) => <Policy {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/voice-management"
                      path="/voice-management"
                      component={(props) => <VoiveManagement {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/policy-new"
                      path="/policy-new"
                      component={(props) => <PolicyNew {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/noti-center"
                      path="/noti-center"
                      component={(props) => <NotiCenter {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/parttype"
                      path="/parttype"
                      component={(props) => <PartType {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/partgroup"
                      path="/partgroup"
                      component={(props) => <PartGroup {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/maintenance"
                      path="/maintenance"
                      component={(props) => (
                        <ManagementMaintenance {...props} />
                      )}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/management/downloadcertificate"
                      path="/management/downloadcertificate"
                      component={(props) => <Downloadcertificate {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/management/contactmanagement"
                      path="/management/contactmanagement"
                      component={(props) => <Contactmanagement {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/mdvrdownloadfile"
                      path="/mdvrdownloadfile"
                      component={(props) => <Mdvrdownloadfile {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/servicerepairdevice"
                      path="/servicerepairdevice"
                      component={(props) => <Servicerepairdevice {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/deliverytrip"
                      path="/deliverytrip"
                      component={(props) => <Deliverytrip {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/insurance"
                      path="/manage-document"
                      component={(props) => <ExpenseInsurance {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/manage-document2"
                      path="/manage-document2"
                      component={(props) => <ManageDocument2 {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/company"
                      path="/company"
                      component={(props) => <Company {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/servicemovedevice"
                      path="/servicemovedevice"
                      component={(props) => <ServiceMoveDevice {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/vehicleinformationdlt"
                      path="/report/vehicleinformationdlt"
                      component={(props) => (
                        <Vehicleinformationdlt {...props} />
                      )}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/livestream"
                      path="/livestream"
                      component={(props) => <Honeytoaststream {...props} />}
                      done={this.props.done}
                    />

                    {/* <PrivateRoute
                    key="/livestreamwithtemplate"
                    path="/livestreamwithtemplate"
                    component={(props) => <Honeytoaststream2 {...props} />}
                    done={this.props.done}
                  /> */}

                    <PrivateRoute
                      key="/serviceinstalldevice"
                      path="/serviceinstalldevice"
                      component={(props) => <ServiceInstallDevice {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/honeytoastdownloadfile"
                      path="/honeytoastdownloadfile"
                      component={(props) => (
                        <Honeytoastdownloadfile {...props} />
                      )}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/news-manuals"
                      path="/news-manuals"
                      component={(props) => <NewsManuals {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/downloadvideoqueue"
                      path="/downloadvideoqueue"
                      component={(props) => (
                        <Honeytoastdownloadfilewebsocketinqueue {...props} />
                      )}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/honeytoastplayback"
                      path="/honeytoastplayback"
                      component={(props) => <Honeytoastplayback {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/drivinglogspecial"
                      path="/report/drivinglogspecial"
                      component={(props) => <Drivinglogspecial {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/tripbyengineoffon"
                      path="/report/tripbyengineoffon"
                      component={(props) => <Tripbyengineoffon {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/dailyiconsiam"
                      path="/report/dailyiconsiam"
                      component={(props) => <Dailyiconsiam {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/summaryiconsiam"
                      path="/report/summaryiconsiam"
                      component={(props) => <Summaryiconsiam {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/report/withdrawfuelcredit"
                      path="/report/withdrawfuelcredit"
                      component={(props) => (
                        <ReportWithdrawFuelCredit {...props} />
                      )}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/report/flow"
                      path="/report/flow"
                      component={(props) => <ReportFlow {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/depositfuelcredit"
                      path="/report/depositfuelcredit"
                      component={(props) => (
                        <ReportDepositFuelCredit {...props} />
                      )}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/map-event-detail"
                      path="/map-event-detail"
                      component={(props) => <MapEventDetail {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/gallery"
                      path="/gallery"
                      component={(props) => <Gellery {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/temporarytracking"
                      path="/temporarytracking"
                      component={(props) => <TemporaryTracking {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/deviceconfig"
                      path="/deviceconfig"
                      component={(props) => <DeviceConfig {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/schedulereport"
                      path="/schedulereport"
                      component={(props) => <Schedulereport {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/routemaster"
                      path="/routemaster"
                      component={(props) => <Routemaster {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/fuelremainrate"
                      path="/report/fuelremainrate"
                      component={(props) => <Fuelremainrate {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/gpslostcontact"
                      path="/report/gpslostcontact"
                      component={(props) => <GpsLostContact {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/video-snapshot-report"
                      path="/report/video-snapshot-report"
                      component={(props) => <VideoSnapshot {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/taxisos"
                      path="/report/taxisos"
                      component={(props) => <TaxiSos {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/summarylinenotification"
                      path="/report/summarylinenotification"
                      component={(props) => (
                        <SummaryLineNotification {...props} />
                      )}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/report/speedoverlimit"
                      path="/report/speedoverlimit"
                      component={(props) => <SpeedOverLimit {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/firmware"
                      path="/firmware"
                      component={(props) => <Createfrimware {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/assignfirmware"
                      path="/assignfirmware"
                      component={(props) => <Assignfirmware {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/skic-inhouse"
                      path="/skic-inhouse"
                      component={(props) => <SkicInhouse {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/uom"
                      path="/uom"
                      component={(props) => <Uom {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/product"
                      path="/product"
                      component={(props) => <Product {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/calibratefuel"
                      path="/calibratefuel"
                      component={(props) => <Calibratefuel {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/companysetting"
                      path="/companysetting"
                      component={(props) => <Companysetting {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/companysetting-old"
                      path="/companysetting-old"
                      component={(props) => <Companysetting {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/drivingcontinuous"
                      path="/report/drivingcontinuous"
                      component={(props) => <Drivingcontinuous {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/notinotswipecard"
                      path="/report/notinotswipecard"
                      component={(props) => <Notinotswipecard {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/driverincome"
                      path="/report/driverincome"
                      component={(props) => <DriverIncome {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/manage-repairdevice"
                      path="/manage-repairdevice"
                      component={(props) => <ManageRepairDevice {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/cpf-dashboard"
                      path="/cpf-dashboard"
                      component={(props) => <CPFDashboard {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/calibrate-fuel"
                      path="/calibrate-fuel"
                      component={(props) => <CalibrateFuelFromV1 {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/summarydrivinglog"
                      path="/report/summarydrivinglog"
                      component={(props) => <SummaryDrivingLog {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/monitor-sdcard"
                      path="/monitor-sdcard"
                      component={(props) => <MonitorSDCard {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/camera-status-dashboard"
                      path="/camera-status-dashboard"
                      component={(props) => (
                        <CameraStatusDashboard {...props} />
                      )}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/monitor-gps"
                      path="/monitor-gps"
                      component={(props) => <MonitorGPS {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/tax-renewal-readiness"
                      path="/tax-renewal-readiness"
                      component={(props) => <TaxRenewalReadiness {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/customer-company"
                      path="/customer-company"
                      component={(props) => <CustomerCompany {...props} />}
                      done={this.props.done}
                    />
                  </Container>
                </AppLayout>
              </AppLayout>
            </LangContext.Provider>
            // </Connector>
          )}
        </Fragment>
      </Router>
    );
  }
}
//export default Layout;

const mapStateToProps = ({
  auth: { accessToken, done, profile, tokenExp },
  ui: { flash },
}) => ({
  accessToken,
  done,
  profile,
  flash,
  tokenExp,
});
const mapDispatchToProps = {
  removeCredentials: authActions.removeCredentials,
  loadCredentials: authActions.loadCredentials,
  clearFlashMessage: uiActions.clearFlashMessage,
  setFlashMessage: uiActions.setFlashMessage,
  setLanguageChange: uiActions.setLanguageChange,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withResponsive(Layout));
