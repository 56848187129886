import React, { Component } from "react";
import AuthorizeComponent from "../../../auth/components/AuthorizeComponent";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import { Badge, Button, Col, Pagination, Row, Spin, Table } from "antd";
import Breadcrumb from "../../../shared/components/Breadcrumb";
import { connect } from "react-redux";
import LangContext from "modules/shared/context/langContext";
import axios from "axios";
import IndexFilterDrawer from "./IndexFilterDrawer";

class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
      pageSize: 10,
      orderBy: "id",
      orderType: "desc",
      loading: true,
      total: 0,
      visible: false,
      filter: {
        name: "",
        code: "",
      },
    };

    this.columns = [
      {
        title: "#",
        dataIndex: "number",
        key: "number",
        width: 180,
        render(text) {
          return {
            children: <div>{text}</div>,
          };
        },
      },

      {
        title: "code",
        dataIndex: "code",
        key: "code",
        render(text) {
          return {
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "name",
        dataIndex: "name",
        key: "name",
        render: (text) => {
          return {
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "link",
        dataIndex: "st_token",
        key: "st_token",
        render(text) {
          const link = `https://login.terminusfleet.com/mobile/feedcustorc?so_token=${text}`;
          return {
            children: (
              <a href={link} target="_blank">
                {link}
              </a>
            ),
          };
        },
      },
    ];
  }

  handlePageChange = (page, pageSize) => {
    this.setState({ page: page, currentPage: page, loading: true });
    this.fetchData({
      page: page,
      pageSize: pageSize,
      orderBy: this.state.orderBy,
      orderType: this.state.orderType,
      filter: this.state.filter,
    });
  };

  fetchData = async ({ page, pageSize, orderBy, orderType, filter }) => {
    const {
      data: { data },
    } = await axios.post(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/customer-company`,
      {
        companyID: this.props.auth.profile.company_id,
        page,
        pageSize,
        orderBy,
        orderType,
        filter,
      },
      {
        headers: {
          Authorization: `Bearer ${this.props.auth.accessToken}`,
        },
      }
    );

    this.setState({
      data: data.data,
      total: data.total,
      loading: false,
    });
  };

  componentDidMount() {
    this.fetchData({
      page: this.state.currentPage,
      pageSize: this.state.pageSize,
      orderBy: this.state.orderBy,
      orderType: this.state.orderType,
      filter: this.state.filter,
    });
  }

  render() {
    return (
      <AuthorizeComponent matching_name="customer-company">
        <GeneralStyledContent>
          <Row gutter={24}>
            <Col span={24}>
              <Breadcrumb
                match={this.props.match}
                style={{ margin: "0px 0px 14px 10px" }}
              />
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24}>
              <Row
                gutter={24}
                type="flex"
                justify="space-between"
                style={{ height: "38px" }}
              >
                <Col span={24} align="right">
                  <Badge dot={this.state.isFilter} align="right">
                    <Button
                      icon="filter"
                      size="small"
                      align="right"
                      onClick={() => this.setState({ visible: true })}
                    >
                      <span></span>
                      <LangContext.Consumer>
                        {(i18n) => i18n.f.filter}
                      </LangContext.Consumer>
                    </Button>
                  </Badge>
                </Col>
              </Row>

              <IndexFilterDrawer
                visible={this.state.visible}
                onClose={() =>
                  this.setState({
                    visible: false,
                    filter: { name: "", code: "" },
                  })
                }
                onSubmit={(e) => {
                  this.setState({ filter: e, visible: false, loading: true });
                  this.fetchData({
                    ...this.state,
                    page: 1,
                    filter: e,
                  });
                }}
              />

              <Spin spinning={this.state.loading}>
                <Table
                  bordered
                  scroll={{ x: 500 }}
                  rowKey="id"
                  columns={this.columns}
                  dataSource={this.state.data}
                  size="small"
                  pagination={false}
                />
              </Spin>
              <Row type="flex" justify="end" style={{ padding: "15px" }}>
                <Col span={24} align="right">
                  <Pagination
                    defaultPageSize={1}
                    size="small"
                    current={this.state.currentPage}
                    pageSize={10}
                    total={this.state.total}
                    onChange={this.handlePageChange}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </GeneralStyledContent>
      </AuthorizeComponent>
    );
  }
}

export default connect(({ auth }) => ({ auth }), {})(Index);
