import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import moment from "moment";

import { getlocalstorage, USER_ID } from "./../../../constants/local_storage";
import * as actions from "./actions";

function* loadDriverincome(action) {
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );

  const {
    data: { driver, eventdate, type_file, user_id },
  } = action.payload;

  const date_start = moment(eventdate[0]["_d"]).format("YYYY-MM-DD");
  const date_end = moment(eventdate[1]["_d"]).format("YYYY-MM-DD");

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_REPORT_GOLANG_MONGO}/reports/go/driverincome`,
      {
        driver,
        date_start,
        date_end,
        type_file,
        company_id: COMPANY_ID,
        user_id: user_id
      }
    );

    yield put(actions.loadDriverincome.success(res.data));
    window.location = res.data.result;
  } catch (err) {
    yield put(actions.loadDriverincome.failure());
  }
}

function* loadDriver() {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getdeviceuserbycompany`,
      {
        company_id: COMPANY_ID,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );

    yield put(actions.loadDriver.success(res.data));
  } catch (err) {
    yield put(actions.loadDriver.failure());
  }
}

export default function* watchReportPoolState() {
  yield all([
    takeEvery(actions.LOAD_DRIVERINCOME.REQUEST, loadDriverincome),
    takeEvery(actions.LOAD_DRIVER.REQUEST, loadDriver),
  ]);
}

export { loadDriverincome, loadDriver };
