import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Row, Col, Form, Slider } from "antd";

const marks = {
  "-100": {
    style: {
      color: "#2AB8FE",
    },
    label: <strong>-100°C</strong>,
  },
  "-75": "-75°C",
  "-50": "-50°C",
  "-25": "-25°C",
  "0": "0°C",
  "25": "25°C",
  "50": "50°C",
  "75": "75°C",
  "100": {
    style: {
      color: "#f50",
    },
    label: <strong>100°C</strong>,
  },
};
class AbnormalTemperature extends Component {
  state = {
    zoneList: [],
  };

  componentDidMount() {}

  render() {
    if (typeof this.props.values.min == "undefined")
      this.props.values.min = -25;
    if (typeof this.props.values.max == "undefined") this.props.values.max = 25;

    return (
      <Fragment>
        <Row>
          <Col xs={24} sm={18} md={13} lg={13} xl={10}>
            <Form.Item
              label="ช่วงอุณหภูมิปกติ"
              required={true}
              validateStatus={
                this.props.touched.area && this.props.errors.area
                  ? "warning"
                  : undefined
              }
              help={
                this.props.touched.area && this.props.errors.area
                  ? this.props.errors.area.substr(
                      this.props.errors.area.indexOf(" ") + 1
                    )
                  : undefined
              }
            >
              <Slider
                name="temp"
                range
                marks={marks}
                defaultValue={[this.props.values.min, this.props.values.max]}
                max={100}
                min={-100}
                onAfterChange={(e) => {
                  console.log(e);
                  this.props.setFieldValue("min", e[0]);
                  this.props.setFieldValue("max", e[1]);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Fragment>
    );
  }
}
export default connect(
  ({ policyState, auth }) => ({ policyState, auth }),
  {}
)(AbnormalTemperature);
