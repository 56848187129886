import React, { Component } from "react";
import { Table, Tooltip } from "antd";
import LangContext, { i18n } from "modules/shared/context/langContext";
import { Resizable } from "react-resizable";
import { debounce } from "debounce";
import { TableStyled } from "./StyleComponent";

const ResizeableTitle = (props) => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }
  return (
    <Resizable
      width={width}
      height={0}
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

const checkWidth = (props) => {
  if (typeof props != "undefined") {
    if (typeof props.menu != "undefined") {
      if (typeof props.menu.tracking != "undefined") {
        if (typeof props.menu.tracking.columns_driver != "undefined") {
          if (props.menu.tracking.columns_driver.length != 0) {
            return true;
          }
        }
        return false;
      }
      return false;
    }
    return false;
  }
  return false;
};

const getWidth = (dataIndex, width, props) => {
  let data = props.menu.tracking.columns_driver[0].Column.find(
    (x) => x.dataIndex === dataIndex
  );
  if (typeof data != "undefined") {
    return data.width;
  }
  return width;
};

export class CustomTableTrackingCarRegis extends Component {
  state = {
    columns: [
      // ทะเบียน
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => (
              <Tooltip title={i18n.p.plateNo} placement="left">
                {i18n.p.plateNo}
              </Tooltip>
            )}
          </LangContext.Consumer>
        ),
        dataIndex: "plate_no",
        width: checkWidth(this.props.auth.profile.config)
          ? getWidth("plate_no", 100, this.props.auth.profile.config)
          : 100,
        className: "column-text-center",
        ellipsis: true,
        render: (text, record, index) => {
          return text;
        },
      },
      {
        // ชื่อคนขับ
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {(i18n) => (
                <Tooltip title={i18n.c.chauffeur} placement="left">
                  {i18n.c.chauffeur}
                </Tooltip>
              )}
            </LangContext.Consumer>
          </div>
        ),
        dataIndex: "driver_license_info",
        className: "column-text-center",
        ellipsis: true,
        width: checkWidth(this.props.auth.profile.config)
          ? getWidth("driver_license_info", 90, this.props.auth.profile.config)
          : 90,
        render(text, record, index) {
          return {
            children: (
              <div>
                {text.fname != "" ? (
                  <>
                    {text.fname} {text.lname}
                  </>
                ) : (
                  <>
                    <LangContext.Consumer>
                      {(i18n) => i18n.c.chauffeurDriverUnKnown}
                    </LangContext.Consumer>
                  </>
                )}
              </div>
            ),
          };
        },
      },
      {
        //รูดบัตร
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {(i18n) => (
                <Tooltip title={i18n.s.slidingCard} placement="left">
                  {i18n.s.slidingCard}
                </Tooltip>
              )}
            </LangContext.Consumer>
          </div>
        ),
        dataIndex: "driver_license_swiping_status",
        className: "column-text-center",
        ellipsis: true,
        width: checkWidth(this.props.auth.profile.config)
          ? getWidth(
              "driver_license_swiping_status",
              70,
              this.props.auth.profile.config
            )
          : 70,
        render(text, record, index) {
          return {
            children: (
              <div style={{ margin: "-4px 0px 0px 0px" }}>
                {record.gps_active_at != "now" ? (
                  <Tooltip
                    title={
                      <LangContext.Consumer>
                        {(i18n) => i18n.l.lostConnectio}
                      </LangContext.Consumer>
                    }
                  >
                    <img
                      src="/img/Group red.png"
                      alt=""
                      style={{ height: "20px" }}
                    />
                  </Tooltip>
                ) : record.engine == 0 ? (
                  text.status ? (
                    <img
                      src="/img/Group green.png"
                      alt=""
                      style={{ height: "20px" }}
                    />
                  ) : text.code == 500 ? (
                    <Tooltip
                      title={
                        <LangContext.Consumer>
                          {(i18n) => i18n.d.doNotSwipeCardsOrDamageCards}
                        </LangContext.Consumer>
                      }
                    >
                      <img
                        src="/img/Group gray.png"
                        alt=""
                        style={{ height: "20px" }}
                      />
                    </Tooltip>
                  ) : text.code == 501 ? (
                    <Tooltip
                      title={
                        <LangContext.Consumer>
                          {(i18n) => i18n.p.partiallyDamagedCard}
                        </LangContext.Consumer>
                      }
                    >
                      <img
                        src="/img/Group gray.png"
                        alt=""
                        style={{ height: "20px" }}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip
                      title={
                        <LangContext.Consumer>
                          {(i18n) => i18n.s.slideWrongCardType}
                        </LangContext.Consumer>
                      }
                    >
                      <img
                        src="/img/Group gray.png"
                        alt=""
                        style={{ height: "20px" }}
                      />
                    </Tooltip>
                  )
                ) : (
                  <Tooltip
                    title={
                      <LangContext.Consumer>
                        {(i18n) => i18n.e.engineOff}
                      </LangContext.Consumer>
                    }
                  >
                    <img
                      src="/img/Group red.png"
                      alt=""
                      style={{ height: "20px" }}
                    />
                  </Tooltip>
                )}
              </div>
            ),
          };
        },
      },
      {
        // ความเร็วโม่
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {(i18n) => (
                <Tooltip title={<span>{i18n.s.speed}.</span>} placement="left">
                  {i18n.s.speed}
                </Tooltip>
              )}
            </LangContext.Consumer>
          </div>
        ),
        dataIndex: "velocity",
        ellipsis: true,
        className: "column-text-center",
        width: checkWidth(this.props.auth.profile.config)
          ? getWidth("velocity", 70, this.props.auth.profile.config)
          : 70,
        sorter: (a, b) => a.velocity - b.velocity,
        render(text, record, index) {
          return {
            children: (
              <div style={{ padding: "0px 15px 0px 0px", textAlign: "center" }}>
                {record.latest_pos_update_at != "now" ? (
                  <span>
                    {text}{" "}
                    <LangContext.Consumer>
                      {(i18n) => i18n.k.kmH}
                    </LangContext.Consumer>
                  </span>
                ) : parseInt(text) >= 90 ? (
                  <span style={{ color: "red", fontWeight: 700 }}>{text}</span>
                ) : parseInt(text) > 0 ? (
                  <span>
                    {text}{" "}
                    <LangContext.Consumer>
                      {(i18n) => i18n.k.kmH}
                    </LangContext.Consumer>
                  </span>
                ) : (
                  <span>
                    {text}{" "}
                    <LangContext.Consumer>
                      {(i18n) => i18n.k.kmH}
                    </LangContext.Consumer>
                  </span>
                )}
              </div>
            ),
          };
        },
      },
      {
        //พาหนะ
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {(i18n) => (
                <Tooltip title={i18n.v.vehicle} placement="left">
                  {i18n.v.vehicle}
                </Tooltip>
              )}
            </LangContext.Consumer>
          </div>
        ),
        dataIndex: "vehicle_status",
        width: checkWidth(this.props.auth.profile.config)
          ? getWidth("vehicle_status", 100, this.props.auth.profile.config)
          : 100,
        className: "column-text-center",
        ellipsis: true,
        render(text, record, index) {
          let color = "";
          let tooltipMessage = "";
          let source = "";
          if (record.vehicle_status == "running") {
            color = "#00BF06";
            tooltipMessage = (
              <LangContext.Consumer>
                {(i18n) => i18n.r.running}
              </LangContext.Consumer>
            );
            source = "/img/Group 16602.png";
          } else if (record.vehicle_status == "stop-with-engine-on") {
            color = "#ffd619";
            tooltipMessage = (
              <LangContext.Consumer>
                {(i18n) => i18n.s.stopWithEngineOn}
              </LangContext.Consumer>
            );
            source = "/img/Group 16600.png";
          } else if (record.vehicle_status == "stop-with-engine-off") {
            color = "#c91c1c";
            tooltipMessage = (
              <LangContext.Consumer>
                {(i18n) => i18n.e.engineOff}
              </LangContext.Consumer>
            );
            source = "/img/Group 16601.png";
          } else if (record.vehicle_status == "connection-error") {
            color = "#848484";
            tooltipMessage = (
              <LangContext.Consumer>
                {(i18n) => i18n.l.lostConnectio}
              </LangContext.Consumer>
            );
            source = "/img/car gray.png";
          }
          return {
            children: (
              <div style={{ position: "relative", height: 30 }}>
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    width: "100%",
                  }}
                >
                  <Tooltip title={tooltipMessage} placement="left">
                    <img
                      src={source}
                      alt=""
                      style={{ height: "20px", marginTop: -15 }}
                    />
                  </Tooltip>
                </div>
                <div
                  style={{
                    position: "absolute",
                    bottom: "-5px",
                    right: "-3px",
                  }}
                >
                  <label style={{ fontSize: 8 }}>
                    {!!record.voltage ? (
                      record.voltage == -1 || record.voltage == 9.999 ? (
                        "- "
                      ) : (
                        <label>
                          {(
                            parseInt(record.voltage * Math.pow(10, 2)) /
                            Math.pow(10, 2)
                          ).toFixed(2)}{" "}
                          V
                        </label>
                      )
                    ) : (
                      "- "
                    )}
                  </label>
                </div>
              </div>
            ),
          };
        },
      },
    ],
    showSaveBtn: false,
  };

  components = {
    header: {
      cell: ResizeableTitle,
    },
  };

  handleResize = (index) => (e, { size }) => {
    this.setState(
      ({ columns }) => {
        const nextColumns = [...columns];
        nextColumns[index] = {
          ...nextColumns[index],
          width: size.width,
        };
        return { columns: nextColumns };
      },
      () => {
        debounce(
          this.setState(
            {
              showSaveBtn: true,
            },
            () => {
              this.props.handleChangeCustomizeTable(
                this.state.showSaveBtn,
                this.state.columns
              );
            }
          ),
          500
        );
      }
    );
  };

  render() {
    const columns = this.props.switchCustomizeTable
      ? this.state.columns.map((col, index) => ({
          ...col,
          onHeaderCell: (column) => ({
            width: column.width,
            onResize: this.handleResize(index),
          }),
        }))
      : this.state.columns.map((col, index) => ({
          ...col,
        }));
    return (
      <TableStyled
        bordered
        onRow={(record, rowIndex) => {
          if (record.lat !== 0 && record.lng !== 0) {
            return {
              onClick: (event) => {
                this.props.openInfoPanel(
                  this.props.trackingList,
                  record.vehicle_id
                );
              }, // double click row
            };
          }
        }}
        className="tracking-table-v2"
        rowClassName={(record, index) => {
          let returnVal = "";
          this.props.selectedVehicle.vehicle_id != "" &&
          record.vehicle_id == this.props.selectedVehicle.vehicle_id
            ? (returnVal = "highlight-row-dark")
            : (returnVal = "");

          if (typeof record.is_sos_should_alert !== undefined) {
            if (record.is_sos_should_alert == 1)
              returnVal = returnVal + " invalid";
          }

          return returnVal;
        }}
        size="default"
        pagination={false}
        components={this.components}
        columns={columns}
        dataSource={this.props.trackingList.filter((item) => {
          if (item.gps_active_at == "now") {
            if (item.engine == 0) {
              if (item.driver_license_swiping_status.status) {
                return item;
              }
            }
          }
        })}
        scroll={{
          x: 800,
          y: this.props.splitterLayoutHNT
            ? `${window.innerHeight - 530}px`
            : `${window.innerHeight - 175}px`,
        }}
      />
    );
  }
}

export default CustomTableTrackingCarRegis;
