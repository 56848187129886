import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Button, Spin, Modal, Select, Icon, message } from "antd";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import Iframe from "react-iframe";
import Fullscreen from "react-full-screen";
import Filter from "./Filter";
import ListVehicle from "./ListVehicle";
import VehicleDropdown from "./VehicleDropdown";
import {
  loadVehicleType,
  loadVehiclebygroupid,
} from "../../../reports/event/actions";
import { loadVehicleTypeWithValue } from "../../../reports/adasevent/actions";
import * as actions from "../actions";

import LangContext from "modules/shared/context/langContext";
import axios from "axios";
import AuthorizeComponent from "./../../../auth/components/AuthorizeComponent";
import { genDeviceProcessDetail } from "modules/monitoring/honeytoastdownloadfile/components/deviceProcessFunc";

import { DEFAULT_FILTER_VALUE } from "../../tracking/constants/tracking";
import DisplaySelector from "./DisplaySelector";
import CountdownButton from "./CountdownButton";
import Forms from "./Forms";
import AutoplayModal from "./AutoplayModal";
import Clear from "./Clear";
const clearEmptyString = (data) => {
  return data.filter((item) => item !== "");
};
class Index extends Component {
  state = {
    autoplay_on: false,
    autoplay_modal_visible: false,
    count: 0,
    isFull: false,
    visible: false,
    imei: "",
    vehicle_select_detail: [],
    render_frame: [],
    imei_select: [],
    windowNum: 1,
    first_icing_index: [],
    url: process.env.REACT_APP_API_MDVR_TERMINUS,
    iFrameHeight: window.innerHeight - 150,
    deviceProcess: null,
    display_id: -99,
    disply_user_permission: [this.props.profile.id],
    filter: {
      list_vehicle_id: [],
      vehicle_status: DEFAULT_FILTER_VALUE.vehicleStatus,
      device_type: ["J40", "HNT", "ISU"],
      orderBy: "plate_no",
      orderType: "asc",
    },
    autoplay_status: ["running"],
    autoplay_size: 9,
    autoplay_isu_cam: [1],
    autoplay_timeticker: 3 * 60000,
    autoplay_hnt_cam: 0,
    clear_disabled: true,
  };

  interval = null;
  intervalId = null;
  handleShowAutoplayModal = () => {
    this.setState({ autoplay_modal_visible: true });
  };
  handleAutoPlayModalCancel = () => {
    this.setState({ autoplay_modal_visible: false });
  };
  handleAutoPlayModalContinue = () => {
    this.handleAutoPlayOn();
    this.setState({ autoplay_modal_visible: false });
  };
  handleAutoPlayModalOk = () => {
    let temp = {
      autoplay_status: this.state.autoplay_status,
      autoplay_size: this.state.autoplay_size,
      autoplay_isu_cam: this.state.autoplay_isu_cam,
      ...this.state.filter,
    };
    this.setState({ count: 0 });
    this.props.loadListVehicleHoneytoast(temp, false, this.handleAutoPlayOn);
  };
  generateAutoplayButton = () => {
    return this.state.autoplay_on ? (
      <Button
        icon="pause"
        size="small"
        type="danger"
        onClick={this.handleAutoPlayOff}
        style={{ marginLeft: "5px" }}
      />
    ) : (
      <Button
        icon="caret-right"
        size="small"
        onClick={this.handleShowAutoplayModal}
        style={{ marginLeft: "5px" }}
      />
    );
  };
  generateCountdownAutoplay = () => {
    return this.state.autoplay_on ? (
      <CountdownButton
        trigger={this.state.autoplay_on}
        autoplayTimeticker={this.state.autoplay_timeticker}
      />
    ) : (
      <></>
    );
  };
  handleOnSelectVehicleGroup = (id) => {
    this.props.loadVehiclebygroupid(id);
  };
  handleSetState = (state_name, value) => {
    switch (state_name) {
      case "autoplay_size":
        this.setState({ autoplay_size: value });
        break;
      case "autoplay_status":
        this.setState({ autoplay_status: value });
        break;
      case "autoplay_isu_cam":
        this.setState({ autoplay_isu_cam: value });
        break;
      case "autoplay_hnt_cam":
        this.setState({ autoplay_hnt_cam: value });
        break;
      case "autoplay_timeticker":
        this.setState({ autoplay_timeticker: value });
        break;
      default:
        break;
    }
  };
  handleAutoPlayOn = (
    data = this.props.honeytoaststream.vehicleAutoplayList
  ) => {
    this.setState({
      autoplay_on: true,
      autoplay_modal_visible: false,
      windowNum: this.state.autoplay_size,
      vehicle_select_detail:
        data.autoplay_list[this.state.count].vehicle_select_detail,
      imei_select: data.autoplay_list[this.state.count].imei_select,
      render_frame: data.autoplay_list[this.state.count].render_frame,
      clear_disabled: true,
    });
    this.interval = setInterval(() => {
      this.setState((prevState) => {
        const index = (prevState.count + 1) % data.autoplay_list.length;
        if (prevState.count + 1 == data.autoplay_list.length) {
          let temp = {
            autoplay_status: this.state.autoplay_status,
            autoplay_size: this.state.autoplay_size,
            autoplay_isu_cam: this.state.autoplay_isu_cam,
            ...this.state.filter,
          };
          this.props.loadListVehicleHoneytoast(temp, false);
        }
        return {
          count: (prevState.count + 1) % data.autoplay_list.length,
          windowNum: this.state.autoplay_size,
          vehicle_select_detail:
            data.autoplay_list[index].vehicle_select_detail,
          imei_select: data.autoplay_list[index].imei_select,
          render_frame: data.autoplay_list[index].render_frame,
        };
      });
    }, this.state.autoplay_timeticker);
  };

  handleAutoPlayOff = () => {
    clearInterval(this.interval);
    this.interval = 0;
    this.setState({
      autoplay_on: false,
      clear_disabled: this.state.render_frame.length === 0 ? true : false,
    });
  };
  goFull = () => {
    this.setState({
      isFull: true,
      iFrameHeight: window.innerHeight + 110,
    });
  };

  onchangeFullscreen = (isFull) => {
    if (isFull) {
      this.setState({
        isFull,
      });
    } else {
      this.setState({
        isFull,
        iFrameHeight: window.innerHeight - 150,
      });
    }
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  showModalManageTemplate = () => {
    this.setState({
      visibleManageTemplate: true,
    });
  };
  hideModalManageTemplate = () => {
    this.setState({
      visibleManageTemplate: false,
    });
  };

  handleOk = (values, loading) => {
    this.loadListVehicleHoneytoast(values, loading);
    this.setState({
      visible: false,
      filter: values,
      vehicle_select_detail: [],
      render_frame: [],
      imei_select: [],
      clear_disabled: true,
    });
    this.handleAutoPlayOff();
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  loadListVehicleHoneytoast = (values, loading) => {
    if (values === null) {
      values = this.state.filter;
    }
    this.props.loadListVehicleHoneytoast(values, loading);
  };

  loadVehicle = () => {
    this.props.loadVehicle();
  };

  loadVehicleSelect = (
    imei,
    cameraId,
    shouldUpdateDetail = true,
    fromISUDropdown = false
  ) => {
    let renderFrame = [...this.state.render_frame];
    let renderImei =
      typeof cameraId !== "undefined" ? imei + "_" + cameraId.toString() : imei;
    let firstIcingIdx = [...this.state.first_icing_index];

    //case select from renderframe
    if (!renderFrame.includes(renderImei)) {
      //find index empty frame in vehicles
      let emptyFrame = renderFrame.findIndex((x) => x === "");
      //no empty frame
      if (emptyFrame === -1) {
        if (this.state.windowNum === renderFrame.length) {
          renderFrame[renderFrame.length - 1] = renderImei;
        } else {
          renderFrame.push(renderImei);
        }
      }
      //have empty frame
      else {
        renderFrame[emptyFrame] = renderImei;
      }
      //find 1st icing index
      let res = firstIcingIdx.find((x) => x.imei === imei);
      if (typeof res === "undefined" && typeof cameraId !== "undefined") {
        firstIcingIdx.push({ imei: imei, camera_idx: cameraId });
      }
    }
    //case deselect from renderframe
    else {
      //case deselect from not isu dropdown
      if (!fromISUDropdown) {
        renderFrame = renderFrame.map((x) => {
          return x.split("_")[0] === renderImei.split("_")[0] ? "" : x;
        });
      }
      //case deselect from isu dropdown
      else {
        renderFrame = renderFrame.map((x) => {
          return x === renderImei ? "" : x;
        });
      }

      firstIcingIdx = firstIcingIdx.filter((x) => x.imei !== imei);

      for (var i = 0; i < renderFrame.length; i++) {
        let pure = renderFrame[i].split("_");
        if (pure[0] === imei && pure.length > 1) {
          if (
            typeof firstIcingIdx.find((x) => x.imei === imei) === "undefined"
          ) {
            firstIcingIdx.push({ imei: imei, camera_idx: pure[1] });
          }
        }
      }
    }

    let allImei = [];
    let detail = [...this.state.vehicle_select_detail];

    if (renderFrame.length > 0) {
      allImei = renderFrame.filter((x) => x != "").map((x) => x.split("_")[0]);
      if (shouldUpdateDetail) {
        detail = this.props.honeytoaststream.vehicleListHoneytoast.filter(
          (x) => {
            return allImei.includes(x.hardware_id);
          }
        );
      }
    }
    detail = this.customSort(allImei, detail);
    this.props.honeytoaststream.vehicleListHoneytoast = this.customSort(
      allImei,
      this.props.honeytoaststream.vehicleListHoneytoast
    );

    this.setState(
      {
        imei: imei,
        deviceProcess: null,
        render_frame: renderFrame,
        vehicle_select_detail: detail,
        imei_select: allImei,
        first_icing_index: firstIcingIdx,
        display_id: -99,
        disply_user_permission: [this.props.profile.id],
        clear_disabled: allImei.length === 0 ? true : false,
      },
      this.handleCheckProcess
    );
  };
  handleSelectAllIsingCamera = (camera, vehicleId, hwid) => {
    let updateSelectCamera = {
      imei: hwid,
      render_frame: clearEmptyString(this.state.render_frame),
      vehicle_select_detail: clearEmptyString(this.state.vehicle_select_detail),
      imei_select: clearEmptyString(this.state.imei_select),
      first_icing_index: [],
    };

    let len = this.state.windowNum;
    if (this.state.windowNum < 9) {
      len = 4;
      this.handleClearVehicleList();
    }
    updateSelectCamera.imei = hwid;
    for (let index = 1; index <= camera.length; index++) {
      let check_in_array = !updateSelectCamera.render_frame.includes(
        hwid + "_" + String(index)
      );
      let check_length = updateSelectCamera.render_frame.length < len;
      if (check_in_array && check_length) {
        updateSelectCamera.render_frame.push(hwid + "_" + String(index));
        updateSelectCamera.imei_select.push(hwid);
      }
    }

    updateSelectCamera.render_frame.map((x) => {
      let arr = x.split("_");
      if (
        arr.length > 1 &&
        typeof updateSelectCamera.first_icing_index.find(
          (x) => x.imei === arr[0]
        ) === "undefined"
      ) {
        updateSelectCamera.first_icing_index.push({
          imei: arr[0],
          camera_idx: arr[1],
        });
      }
      return;
    });
    updateSelectCamera.vehicle_select_detail = this.props.honeytoaststream.vehicleListHoneytoast.filter(
      (x) => {
        return updateSelectCamera.imei_select.includes(x.hardware_id);
      }
    );
    this.setState(
      {
        ...updateSelectCamera,
        display_id: -99,
        disply_user_permission: [this.props.profile.id],
        clear_disabled: false,
      },
      this.handleCheckProcess
    );
  };
  customSort(order, array) {
    // Create a map to store the order of each id
    const orderMap = new Map();
    order.forEach((value, index) => orderMap.set(value, index));

    // Sort the array based on the order in the orderMap
    array.sort((a, b) => {
      const aOrder = orderMap.has(a.hardware_id)
        ? orderMap.get(a.hardware_id)
        : order.length;
      const bOrder = orderMap.has(b.hardware_id)
        ? orderMap.get(b.hardware_id)
        : order.length;
      return aOrder - bOrder;
    });

    return array;
  }
  setWindowNum = (window_nums, e) => {
    if (this.state.windowNum === window_nums) {
      return;
    }

    let vehicle_select_detail = [...this.state.vehicle_select_detail];
    let renderFrame = [...this.state.render_frame];
    let allImei = [...this.state.imei_select];
    let firstIcingIdx = [...this.state.first_icing_index];

    let new_select = [];
    let new_render_frame = [];
    let new_all_imei = [];
    let new_first_icing_indx = [];

    if (renderFrame.length >= window_nums) {
      let remainImei = [];
      for (let i = 0; i < window_nums; i++) {
        remainImei.push(renderFrame[i].split("_")[0]);
        new_render_frame[i] = renderFrame[i];
      }
      new_select = vehicle_select_detail.filter((x) =>
        remainImei.includes(x.hardware_id)
      );
      new_all_imei = allImei.filter((x) => remainImei.includes(x));
      new_render_frame.map((x) => {
        let arr = x.split("_");
        if (
          arr.length > 1 &&
          typeof new_first_icing_indx.find((x) => x.imei === arr[0]) ===
            "undefined"
        ) {
          new_first_icing_indx.push({ imei: arr[0], camera_idx: arr[1] });
        }
        return;
      });
    } else {
      new_select = vehicle_select_detail;
      new_render_frame = renderFrame;
      new_all_imei = allImei;
      new_first_icing_indx = firstIcingIdx;
    }

    this.setState({
      windowNum: window_nums,
      vehicle_select_detail: new_select,
      render_frame: new_render_frame,
      imei_select: new_all_imei,
      first_icing_index: new_first_icing_indx,
    });
  };

  getDeviceData = (imei) => {
    if (typeof imei === "undefined" || imei === "") {
      return { target_link: "honeytoast.php", device_type: "" };
    }

    let rec = this.props.honeytoaststream.vehicleListHoneytoast.find(
      (x) => x.hardware_id === imei
    );

    if (typeof rec !== "undefined") {
      return {
        target_link: rec.target_link,
        device_type: rec.device_types_id,
        plate_no: rec.plate_no,
        camera_channel: rec.camera_channel,
      };
    }
    return {
      target_link: "honeytoast.php",
      device_type: "",
      plate_no: "",
      camera_channel: [],
    };
  };

  handleCheckProcess = async () => {
    if (this.state.imei === "") {
      return;
    }

    if (this.state.windowNum === 1) {
      this.handleGetSingleImei();
    } else {
      this.handleGetMultiImei();
    }
  };

  handleGetSingleImei = async () => {
    try {
      const allconnect = await axios.get(
        `https://${process.env.REACT_APP_WEBSOCKET}/getDeviceProcessByImei?imei=${this.state.imei}`
      );
      const { cntDownload, cntLivestream, cntPlayback } = allconnect.data;
      let total = cntDownload + cntLivestream + cntPlayback;
      let result = allconnect.data;
      if (total === 0) {
        result = null;
      }
      this.setState({
        deviceProcess: result,
      });
    } catch (error) {}
  };

  handleGetMultiImei = async () => {
    try {
      const allconnect = await axios.get(
        `https://${process.env.REACT_APP_WEBSOCKET}/getDeviceProcesses`
      );

      let result = allconnect.data
        .map((x) => {
          if (this.state.imei_select.includes(x.imei)) {
            const { cntDownload, cntLivestream, cntPlayback } = x;
            let total = cntDownload + cntLivestream + cntPlayback;
            if (total !== 0) {
              let p = this.props.honeytoaststream.vehicleListHoneytoast.find(
                (a) => a.hardware_id === x.imei
              );
              x.plate_no = typeof p !== "undefined" ? p.plate_no : "";
              return x;
            }
          }
        })
        .filter((x) => typeof x !== "undefined");

      if (result.length === 0) {
        result = null;
      }

      this.setState({
        deviceProcess: result,
      });
    } catch (error) {}
  };

  handleOpenDeviceProcessModal = () => {
    if (this.state.windowNum === 1) {
      let html = genDeviceProcessDetail(this.state.deviceProcess.userList);

      Modal.warning({
        title: "อุปกรณ์นี้ กำลังมีการเชื่อมต่อการใช้งานวีดีโอดังนี้",
        content: <div dangerouslySetInnerHTML={{ __html: html }}></div>,
        okText: "ปิด",
      });
    } else {
      let lastIdx = this.state.deviceProcess.length - 1;
      let html = "";
      this.state.deviceProcess.map((x, idx) => {
        let useFooter = idx === lastIdx ? true : false;
        let body = genDeviceProcessDetail(x.userList, useFooter);
        html += "<b>" + x.plate_no + "</b>" + "<br/>" + body;
        return;
      });

      Modal.warning({
        title: "กำลังมีการเชื่อมต่อการใช้งานวีดีโอดังนี้",
        content: <div dangerouslySetInnerHTML={{ __html: html }}></div>,
        okText: "ปิด",
      });
    }
  };

  handleResetVehicleList = () => {
    let tempRendorVideoList = [];
    let tempDropdownvideoList = [];
    let tempImeiSelected = [];
    tempRendorVideoList = clearEmptyString(this.state.render_frame).sort();
    tempDropdownvideoList = clearEmptyString(
      this.state.vehicle_select_detail
    ).sort();
    tempImeiSelected = clearEmptyString(this.state.imei_select).sort();
    this.setState({
      imei_select: tempImeiSelected,
      vehicle_select_detail: tempDropdownvideoList,
      render_frame: tempRendorVideoList,
    });
  };

  handleClearVehicleList = () => {
    this.setState({
      isFull: false,
      visible: false,
      imei: "",
      windowNum: 4,
      render_frame: [],
      vehicle_select_detail: [],
      imei_select: [],
      clear_disabled: true,
      first_icing_index: [],
      url: process.env.REACT_APP_API_MDVR_TERMINUS,
      iFrameHeight: window.innerHeight - 150,
      deviceProcess: null,
      ...this.state.filter,
    });
  };

  handleSelectDisplay = (value, loadVideo = false) => {
    this.setState({ display_id: value });
    if (loadVideo) {
      this.props.loadVideo(
        { display_id: value, user_id: this.props.profile.id },
        this.setRenderFrameVideo
      );
    }
  };
  handleDeleteDisplay = () => {
    this.props.deleteDisplay(
      { display_id: this.state.display_id },
      this.generateMsg
    );
    this.setState({
      display_id: -99,
      disply_user_permission: [this.props.profile.id],
      imei: "",
      render_frame: [],
      windowNum: 4,
      first_icing_index: [],
    });
  };
  setRenderFrameVideo = (data) => {
    let res = data.data[0];
    let windowNum = res.x * res.y;
    let [allImei, detail] = this.setImeiSelect(res.render_frame);
    let arr = res.user_id.split(",");
    // Create a Set to store only unique values
    let uniqueArr = [...new Set(arr)].map(Number);

    this.setState({
      render_frame: res.render_frame,
      windowNum: windowNum,
      vehicle_select_detail: detail,
      imei_select: allImei,
      disply_user_permission: uniqueArr,
    });
  };

  setImeiSelect = (renderFrame) => {
    let allImei = [];
    let detail = [...this.state.vehicle_select_detail];
    if (renderFrame.length > 0) {
      allImei = renderFrame.filter((x) => x != "").map((x) => x.split("_")[0]);
      detail = this.props.honeytoaststream.vehicleListHoneytoast.filter((x) => {
        return allImei.includes(x.hardware_id);
      });
    }
    detail = this.customSort(allImei, detail);
    this.props.honeytoaststream.vehicleListHoneytoast = this.customSort(
      allImei,
      this.props.honeytoaststream.vehicleListHoneytoast
    );
    return [allImei, detail];
  };

  handleOnSubmitForm = (value) => {
    let formData = {
      ...value,
      render_frame: this.state.render_frame,
      camera_layout_type_id: Math.sqrt(this.state.windowNum),
      companies_id: this.props.profile.company_id,
    };
    if (value.display_select === -99) {
      this.props.createDisplay(formData, this.generateMsg);
    } else {
      this.props.updateDisplay(formData, this.generateMsg);
    }
  };
  generateMsg = () => {
    switch (this.props.honeytoaststream.status) {
      case "success":
        message.success(this.props.honeytoaststream.msg);
        this.props.loadDisplay(this.props.profile.id);
        this.hideModalManageTemplate();
        break;
      case "fail":
        message.error(this.props.honeytoaststream.msg);
        break;
      default:
        break;
    }
  };
  componentDidMount() {
    this.props.loadDisplay(this.props.profile.id);
    this.props.loadUser(this.props.profile.company_id);
    this.props.loadVehicleType();
    this.handleOnSelectVehicleGroup(33);
    this.interval1 = setInterval(() => {
      this.handleCheckProcess();
    }, 1000 * 15);

    // Set an interval that updates the state every 10 seconds

    if (this.props.match.params.vehicleId != "") {
      console.log("has vehicle");
    }

    let url = new URL(window.location.href);
    let imei = url.searchParams.get("imei");
    let vhID = url.searchParams.get("vehicle_id");

    if (imei === null) {
      return;
    }

    this.handleOk(
      {
        list_vehicle_id: [parseInt(vhID)],
        orderBy: "plate_no",
        orderType: "asc",
        vehicle_status: [
          "running",
          "stop-with-engine-on",
          "stop-with-engine-off",
          "connection-error",
        ],
        device_type: ["J40", "HNT", "ISU"],
      },
      true
    );

    this.loadVehicleSelect(imei);
  }
  componentDidUpdate(prevProps) {
    const { vehicleListHoneytoast } = this.props.honeytoaststream;
    const prevVehicleList = prevProps.honeytoaststream.vehicleListHoneytoast;

    // Check if 'imei' is present in the URL
    const url = new URL(window.location.href);
    const imei = url.searchParams.get("imei");

    if (imei && vehicleListHoneytoast.length !== prevVehicleList.length) {
      const firstVehicle = vehicleListHoneytoast[0];

      // Proceed only if the first vehicle's alias is 'ISU'
      if (firstVehicle.alias === "ISU") {
        const renderFrame = firstVehicle.camera_channel.map(
          (item) => `${imei}_${item.index}`
        );

        // Update state with relevant vehicle information
        this.setState(
          {
            windowNum: 4,
            imei_select: [imei],
            render_frame: renderFrame,
            vehicle_select_detail: [firstVehicle],
          },
          this.handleCheckProcess
        );
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    this.interval = 0;
    this.intervalId = 0;
  }

  videoFrame = (imei, idx, loading) => {
    if (typeof imei === "undefined" || imei === "") {
      return this.emptyFrame(idx);
    }
    let arr = imei.split("_");
    let pureImei = arr[0];
    let data = this.getDeviceData(pureImei);
    let endpoint = data.target_link;
    let uid = this.props.profile.id.toString();
    let plate_no = data.plate_no;

    let url = `${this.state.url}${endpoint}?imei=${pureImei}&plate_no=${plate_no}&user_id=${uid}&frame_idx=${idx}`;
    if (this.state.autoplay_on) {
      let camera = this.state.autoplay_hnt_cam === 0 ? "OUT" : "IN";
      url = `${this.state.url}${endpoint}?imei=${pureImei}&plate_no=${plate_no}&user_id=${uid}&frame_idx=${idx}&camera=${camera}`;
    }
    if (data.device_type == 40) {
      let cmIdx = typeof arr[1] === "undefined" ? "0" : arr[1];

      let cameraName = "ch0";
      let c = data.camera_channel.find((x) => x.index == cmIdx);
      if (typeof c !== "undefined") {
        cameraName = c.name;
      }

      let firstRec = this.state.first_icing_index.find(
        (x) => x.imei === pureImei
      );
      url = `${this.state.url}${endpoint}?imei=${arr[0]}&plate_no=${plate_no}&camera_btn=hide&camera_idx=${cmIdx}&camera_name=${cameraName}`;
      if (typeof firstRec !== "undefined") {
        // ถ้ามี params user_id จะส่งไปสร้าง log กรณี icing จะส่งไปสร้าง log แค่กล้องเดียวเท่านั้น
        if (parseInt(firstRec.camera_idx) === parseInt(cmIdx)) {
          url += `&user_id=${uid}`;
        }
      }
    }
    return this.iFrame(url, idx);
  };

  iFrame = (url, idx) => {
    let height = this.state.iFrameHeight / Math.sqrt(this.state.windowNum);
    return (
      <Iframe url={url} width="100%" height={height + "px"} scrolling="no" />
    );
  };

  emptyFrame = (idx) => {
    let endpoint = this.getDeviceData("");
    let height = this.state.iFrameHeight / Math.sqrt(this.state.windowNum);

    return (
      <Iframe
        url={
          this.state.url +
          endpoint.target_link +
          "?imei=&plate_no=&user_id=&frame_idx="
        }
        width="100%"
        height={height + "px"}
      />
    );
  };

  getFrameComponent = () => {
    let vehList = this.state.render_frame;
    let iframe = [];
    let i;

    for (let index = 0; index < this.state.windowNum; index++) {
      if (vehList[index]) {
        iframe.push(
          <Col span={24 / Math.sqrt(this.state.windowNum)}>
            {this.videoFrame(vehList[index], index)}
          </Col>
        );
      } else {
        iframe.push(
          <Col span={24 / Math.sqrt(this.state.windowNum)}>
            {this.emptyFrame()}
          </Col>
        );
      }
    }
    return iframe;
  };
  generateSelectWindowNumOption = () => {
    let windowList = [1, 4, 9, 16];
    let option = windowList.map((x) => {
      return (
        <Select.Option key={x} value={x}>
          {x}
        </Select.Option>
      );
    });
    return option;
  };
  render() {
    const {
      vehicleList,
      vehicleListHoneytoast,
      videoLoading,
    } = this.props.honeytoaststream;
    this.props.honeytoaststream.vehicleListHoneytoast = this.customSort(
      this.state.imei_select,
      this.props.honeytoaststream.vehicleListHoneytoast
    );
    let iframe = this.getFrameComponent();
    let pureFrame = this.state.render_frame.filter((x) => x !== "");
    // console.log("first", this.props.event.vehiclebygroupLists);
    return (
      <Spin spinning={this.props.honeytoaststream.loading}>
        <AuthorizeComponent
          {...this.props}
          matching_name="honeytoast_livestream"
        >
          <GeneralStyledContent>
            <div>
              <Row style={{ margin: "0px 0px 5px 0px" }}>
                <Col span={12}>
                  <h4>
                    <LangContext.Consumer>
                      {(i18n) => i18n.l.liveStream}
                    </LangContext.Consumer>
                  </h4>
                </Col>
              </Row>
              <Row style={{ margin: "0px 0px 5px 0px" }}>
                <Col span={6}>
                  <DisplaySelector
                    vehicleList={vehicleList}
                    formsLoading={this.props.honeytoaststream.formsLoading}
                    displaySelect={this.state.display_id}
                    displayList={this.props.honeytoaststream.displayList}
                    handleDisplaySelect={this.handleSelectDisplay}
                    showModalManageTemplate={this.showModalManageTemplate}
                  />
                  <Button
                    icon="filter"
                    size="small"
                    onClick={this.showModal}
                    style={{
                      marginRight: "6px",
                    }}
                  ></Button>
                  <Button
                    size="small"
                    onClick={this.handleClearVehicleList}
                    type="danger"
                    disabled={this.state.clear_disabled}
                  >
                    <Clear
                      fill={
                        this.state.render_frame.length === 0
                          ? "#d9d9d9"
                          : "#FFFFFF"
                      }
                    />
                  </Button>
                </Col>

                <Col span={18}>
                  <Row
                    gutter={[12, 12]}
                    justify="space-between"
                    type="flex"
                    align="middle"
                  >
                    <Col
                      span={17}
                      style={{
                        display: "flex",
                        // width: "100%",
                        overflowX: "auto",
                      }}
                    >
                      {/* <VehicleDropdown
                      vehicles={this.state.vehicle_select_detail}
                      renderFrame={pureFrame}
                      remainFrame={this.state.windowNum - pureFrame.length}
                      handleSelectCamera={this.loadVehicleSelect}
                      windowNum={this.state.windowNum}
                      handleSelectAllIsingCamera={this.handleSelectAllIsingCamera}
                    /> */}
                    </Col>

                    <Col
                      span={7}
                      style={{ display: "flex", justifyContent: "end" }}
                    >
                      {this.generateAutoplayButton()}
                      {/* {this.generateCountdownAutoplay()} */}
                      <Button
                        className="blinking"
                        type="danger"
                        disabled={this.state.deviceProcess === null}
                        icon="eye"
                        size="small"
                        style={{ marginLeft: "5px" }}
                        onClick={this.handleOpenDeviceProcessModal}
                      />
                      <Button
                        icon="reload"
                        size="small"
                        style={{ marginLeft: "5px" }}
                        onClick={this.handleResetVehicleList}
                      />
                      <Select
                        size="small"
                        style={{ marginLeft: "5px", width: "50px" }}
                        value={this.state.windowNum}
                        onChange={(num, evt) => this.setWindowNum(num, evt)}
                      >
                        {this.generateSelectWindowNumOption()}
                      </Select>
                      <Button
                        icon="fullscreen"
                        size="small"
                        onClick={this.goFull}
                        style={{ marginLeft: "5px" }}
                      >
                        เต็ม
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row gutter={4}>
                <Col
                  span={6}
                  style={{
                    margin: "0px 0px 0px 0px",
                    maxHeight: `${window.innerHeight - 150}px`,
                    overflowY: "auto",
                  }}
                >
                  <Row>
                    <Col span={24}>
                      <ListVehicle
                        loadListVehicleHoneytoast={
                          this.loadListVehicleHoneytoast
                        }
                        vehicleListHoneytoast={vehicleListHoneytoast}
                        videoLoading={videoLoading}
                        loadVehicleSelect={this.loadVehicleSelect}
                        vehicle_select={this.state.imei_select}
                        handleSelectAllIsingCamera={
                          this.handleSelectAllIsingCamera
                        }
                        vehicles={this.state.vehicle_select_detail}
                        renderFrame={pureFrame}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={18}>
                  <Fullscreen
                    enabled={this.state.isFull}
                    onChange={(isFull) => this.onchangeFullscreen(isFull)}
                  >
                    <Row>{iframe}</Row>
                  </Fullscreen>
                </Col>
              </Row>
            </div>

            <Filter
              visible={this.state.visible}
              onOk={this.handleOk}
              loadVehicle={this.loadVehicle}
              loadListVehicleHoneytoast={this.loadListVehicleHoneytoast}
              vehicleList={vehicleList}
              onCancel={this.handleCancel}
              filter={this.state.filter}
              groupList={this.props.adasevent.vehicleTypeLists}
              loadVehicleFromGroup={this.handleOnSelectVehicleGroup}
              vehicleVisibility={this.props.profile.vehicle_visibility}
            />
            <Forms
              formsLoading={this.props.honeytoaststream.formsLoading}
              visible={this.state.visibleManageTemplate}
              displayList={this.props.honeytoaststream.displayList}
              onSubmitForm={this.handleOnSubmitForm}
              onCancel={this.hideModalManageTemplate}
              vehicleSelected={this.state.vehicle_select_detail}
              vehicleList={vehicleList}
              userList={this.props.honeytoaststream.userList}
              userId={this.state.disply_user_permission}
              displaySelect={this.state.display_id}
              handleDisplaySelect={this.handleSelectDisplay}
              deleteDisplay={this.handleDeleteDisplay}
            ></Forms>
            <AutoplayModal
              visible={this.state.autoplay_modal_visible}
              handleCancel={this.handleAutoPlayModalCancel}
              handleSetState={this.handleSetState}
              handleAutoPlayModalOk={this.handleAutoPlayModalOk}
              handleContinue={this.handleAutoPlayModalContinue}
              autoplayStatus={this.state.autoplay_status}
              autoplaySize={this.state.autoplay_size}
              loading={this.props.honeytoaststream.videoLoading}
              autoplayIsuCam={this.state.autoplay_isu_cam}
              autoplayHntCam={this.state.autoplay_hnt_cam}
              autoplayTimeticker={this.state.autoplay_timeticker}
            ></AutoplayModal>
          </GeneralStyledContent>
        </AuthorizeComponent>
      </Spin>
    );
  }
}

const mapStateToProps = ({
  honeytoaststream,
  auth: { profile },
  event,
  adasevent,
}) => ({
  honeytoaststream,
  profile,
  event,
  adasevent,
});

const mapDispatchToProps = {
  loadListVehicleHoneytoast: actions.loadListVehicleHoneytoast.request,
  loadVehicle: actions.loadVehicle.request,
  loadDisplay: actions.loadDisplay.request,
  loadUser: actions.loadUserCompany.request,
  loadVideo: actions.loadDisplayDetail.request,
  createDisplay: actions.createDisplay.request,
  updateDisplay: actions.updateDisplay.request,
  deleteDisplay: actions.deleteDisplay.request,
  loadVehicleType: loadVehicleTypeWithValue.request,
  loadVehiclebygroupid: loadVehiclebygroupid.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
