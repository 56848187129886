import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Index from './Index'
import ViewDetail from './ViewDetail'

export default () => (
  <Switch>
    <Route
      exact
      path='/monitor-sdcard/view-detail/:vehicle_id/:hardware_id/:active_date'
      component={ViewDetail}
    />
    <Route exact path='/monitor-sdcard' component={Index} />
  </Switch>
)
