import React from "react";
import { renderToString } from "react-dom/server";

const mapStyle = {
  flex: 1,
  display: "flex",
  cursor: "pointer",
};

export class HereMap extends React.Component {
  mapRef = React.createRef();

  state = {
    maps: null,
    map: null,
    marker: null,
  };

  shouldComponentUpdate(nextPros, nextState) {
    if (nextPros.virtualMarker != this.props.virtualMarker) {
      if (nextPros.virtualMarker != null) {
        let htmlMarker = renderToString(nextPros.virtualMarker);
        var icon = new nextState.maps.map.DomIcon(htmlMarker),
          coords = {
            lat: nextPros.virtualMarker.props.lat,
            lng: nextPros.virtualMarker.props.lng,
          },
          marker = new nextState.maps.map.DomMarker(coords, { icon: icon });
        this.setState({ marker: marker });
        // Add the marker to the map:
        // console.log(this.state.marker);
        if (this.props.virtualMarker != null)
          this.state.map.removeObjects([this.state.marker]);
        nextState.map.addObject(marker);
      } else {
        if (this.props.virtualMarker != null)
          this.state.map.removeObjects([this.state.marker]);
      }
    }
    return true;
  }

  componentDidMount() {
    let self = this;

    // setTimeout(function() {
    const hObject = window.H;

    console.log(window);
    const platform = new hObject.service.Platform({
      app_id: process.env.REACT_APP_HERE_MAP_APP_ID,
      app_code: process.env.REACT_APP_HERE_MAP_APP_ID,
      apikey: process.env.REACT_APP_HERE_MAP_API_KEY,
    });

    const defaultLayers = platform.createDefaultLayers();

    // Create an instance of the map
    const map = new hObject.Map(
      self.mapRef.current,
      defaultLayers.raster.normal.map,
      {
        center: { lat: 13.729852, lng: 100.559484 },
        zoom: 9,
        pixelRatio: window.devicePixelRatio || 1,
      }
    );

    window.addEventListener("resize", () => map.getViewPort().resize());

    // MapEvents enables the event system
    // Behavior implements default interactions for pan/zoom (also on mobile touch environments)
    // This variable is unused and is present for explanatory purposes
    const behavior = new hObject.mapevents.Behavior(
      new hObject.mapevents.MapEvents(map)
    );

    // Create the default UI components to allow the user to interact with them
    // This variable is unused
    const ui = hObject.ui.UI.createDefault(map, defaultLayers);
    let allMarker = [];

    self.setState({ map, maps: hObject });

    self.props.handleApiLoaded(map, hObject, platform, defaultLayers);

    // createMarkers();
    // function createMarkers() {
    //   // create SVG Dom Icon
    //   var svg = `<svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" width="10px" height="10px">
    //         <circle cx="5" cy="5" r="5.5" fill="#2E9AFE" stroke-width="0" stroke="#06C900" opacity="1"/>
    //         <circle cx="5" cy="5" r="4" fill="white" stroke-width="0" stroke="#06C900" opacity="1"/>
    //         <circle cx="5" cy="5" r="2.7" fill="#06C900" stroke-width="0" stroke="#06C900" opacity="1"/>
    //         </svg>`,
    //     domIcon = new hObject.map.DomIcon(svg),
    //     markers = [],
    //     // array of initial positions of 40 markers
    //     initialPositions = [];

    //   for (let i = 0; i < 1800; i++) {
    //     initialPositions.push([13.712294 + i * 0.001, 100.539361 + i * 0.001]);
    //   }
    //   // create markers
    //   initialPositions.forEach(function(pos) {
    //     markers.push(
    //       new hObject.map.DomMarker(
    //         { lat: pos[0], lng: pos[1] },
    //         {
    //           icon: domIcon,
    //         }
    //       )
    //     );
    //   });

    //   // add markers to map
    //   map.addObjects(markers);
    // }

    // }, 1000);
  }

  componentWillUnmount() {
    // Cleanup after the map to avoid memory leaks when this component exits the page
    this.state.map && this.state.map.dispose();
  }

  render() {
    return (
      // Set a height on the map so it will display
      <div style={mapStyle}>
        <div
          ref={this.mapRef}
          style={{
            width: "100%",
            height: `${window.innerHeight - 300}px`,
          }}
        >
          {/* {this.props.tiles.map((tile) => (
            <CanvasTile
              key={`${tile.x} ${tile.y}`}
              zoom={this.state.getZoom()}
              renderMarkers={this.props.renderMarkers}
              {...tile}
            />
          ))} */}
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default HereMap;
