const initialValues = {
  value_yellow: "",
  value_red: "",
  value_red2: "",
  timepicker: "00:00:00",
  timepicker_red: "00:00:00",
  period: "1",
  vehicleGroup: "all",
  vehicle: "",
  vehicleSelected: [],
  startTimePickerNight: "19:00",
  endTimePickerNight: "06:00",
  zone: null,
  stop_point: "-",
  locationType: "INSIDE",
  typeLocation: [],
  day_duration: 0,
  day_duration_red: 0,
  BREAK_WARNING_TIME: "2",
  INTERVAL_WARNING: "2",
  INTERVAL_POLICY: "2",
  ringToneYellow: "",
  ringToneRed: "",
  ringToneRed2: "",
  ringToneYellowVCD: [
    {
      audio: "",
      time: "4",
    },
  ],
  min_temp: "0",
  max_temp: "10",
  wigp_start_time: "00:00:00",
  wigp_end_time: "00:00:00",
  wltl_dur: "10",
  wltd_dur: "1",
  wltd_distance: "10",
  pto_on: "0",
  boom_on: "0",
  parkingTime: "00:30:00",
  drivingContinueusly: "04:00:00",
  approachRange: "",
  drivingSpeed: "5",
  drivingTimePolicy: "4",
  warningAudioForParkingTimeComplete: "",
  warningAudioForNotStoppingInTime: "",
  geofence: null,
};

//* original version by plan
const checkInitialValuesOld = (
  // original version by plan
  dataForm,
  type,
  dataFromEdit,
  initialValuesPolicy
) => {
  switch (true) {
    case !!dataForm:
      return dataForm;

    case type === "edit" && !!dataFromEdit:
      switch (dataFromEdit["policy_type"]) {
        case "D":
          return {
            policyType: dataFromEdit["type"],
            dataType: dataFromEdit["policy_type"],
            name: dataFromEdit["name"],
            value_yellow:
              dataFromEdit["policy"]["P_DEFAULT"]["ALARM_VEL"]["YELLOW"],
            value_red: dataFromEdit["policy"]["P_DEFAULT"]["ALARM_VEL"]["RED"],
            timepicker: dataFromEdit["policy"]["ALERT_DURATION"]["TIME"],
            vehicleGroup: "all",
            vehicle: "",
            vehicleSelected: dataFromEdit["vehicles"],
            period: dataFromEdit["period"],
            BREAK_WARNING_TIME:
              dataFromEdit["policy"]["ALERT_DURATION"]["BREAK_WARNING_TIME"],
            INTERVAL_WARNING:
              dataFromEdit["policy"]["P_DEFAULT"]["ALARM_VEL"][
              "INTERVAL_WARNING"
              ],
            INTERVAL_POLICY:
              dataFromEdit["policy"]["P_DEFAULT"]["ALARM_VEL"][
              "INTERVAL_POLICY"
              ],
            ringToneYellow:
              dataFromEdit["policy"]["P_DEFAULT"]["ALARM_VEL"]["AUDIO_WARNING"],
            ringToneRed:
              dataFromEdit["policy"]["P_DEFAULT"]["ALARM_VEL"]["AUDIO_POLICY"],
            id: dataFromEdit["id"],
          };

        case "GZ":
        case "RZ":
          return {
            policyType: dataFromEdit["type"],
            dataType: dataFromEdit["policy_type"],
            name: dataFromEdit["name"],
            value_yellow:
              dataFromEdit["policy"]["P_GEOFENCE"]["ALARM_VEL"]["YELLOW"],
            value_red: dataFromEdit["policy"]["P_GEOFENCE"]["ALARM_VEL"]["RED"],
            timepicker: dataFromEdit["policy"]["ALERT_DURATION"]["TIME"],
            vehicleGroup: "all",
            vehicle: "",
            vehicleSelected: dataFromEdit["vehicles"],
            zone: dataFromEdit["policy"]["DETAIL_GEOFENCE"]["GID"],
            geofence: dataFromEdit["policy"]["DETAIL_GEOFENCE"]["GEOFENCE"],
            period: dataFromEdit["period"],
            id: dataFromEdit["id"],
          };

        case "SZ":
          return {
            policyType: dataFromEdit["type"],
            dataType: dataFromEdit["policy_type"],
            name: dataFromEdit["name"],
            value_yellow:
              dataFromEdit["policy"]["P_GEOFENCE"]["ALARM_VEL"]["YELLOW"],
            value_red: dataFromEdit["policy"]["P_GEOFENCE"]["ALARM_VEL"]["RED"],
            timepicker: dataFromEdit["policy"]["ALERT_DURATION"]["TIME"],
            vehicleGroup: "all",
            vehicle: "",
            vehicleSelected: dataFromEdit["vehicles"],
            zone: dataFromEdit["policy"]["DETAIL_GEOFENCE"]["GID"],
            period: dataFromEdit["period"],
            INTERVAL_WARNING:
              dataFromEdit["policy"]["P_GEOFENCE"]["ALARM_VEL"][
              "INTERVAL_WARNING"
              ],
            INTERVAL_POLICY:
              dataFromEdit["policy"]["P_GEOFENCE"]["ALARM_VEL"][
              "INTERVAL_POLICY"
              ],
            ringToneYellow:
              dataFromEdit["policy"]["P_GEOFENCE"]["ALARM_VEL"][
              "AUDIO_WARNING"
              ],
            ringToneRed:
              dataFromEdit["policy"]["P_GEOFENCE"]["ALARM_VEL"]["AUDIO_POLICY"],
            approachRange: dataFromEdit["policy"]["EXTEND_RADIUS"]["METER"],
            ringToneRed2: dataFromEdit["policy"]["EXTEND_RADIUS"]["AUDIO"],
            wigp_start_time: dataFromEdit["policy"]["P_TIME"]["START"],
            wigp_end_time: dataFromEdit["policy"]["P_TIME"]["END"],
            id: dataFromEdit["id"],
          };

        case "N":
        case "R":
        case "DH":
        case "UH":
          let obj = {
            policyType: dataFromEdit["type"],
            dataType: dataFromEdit["policy_type"],
            name: dataFromEdit["name"],
            value_yellow:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
              "ALARM_VEL"
              ]["YELLOW"],
            value_red:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
              "ALARM_VEL"
              ]["RED"],
            timepicker: dataFromEdit["policy"]["ALERT_DURATION"]["TIME"],
            vehicleGroup: "all",
            vehicle: "",
            vehicleSelected: dataFromEdit["vehicles"],
            period: dataFromEdit["period"],
            id: dataFromEdit["id"],
          };
          if (dataFromEdit["policy_type"] == "N") {
            obj.startTimePickerNight =
              dataFromEdit["policy"]["NIGHT_TIME"]["START"];
            obj.endTimePickerNight =
              dataFromEdit["policy"]["NIGHT_TIME"]["END"];
          }
          return obj;

        case "AI":
        case "AS":
        case "APON":
        case "AFUA":
        case "A_WDLR":
          return {
            policyType: dataFromEdit["type"],
            dataType: dataFromEdit["policy_type"],
            name: dataFromEdit["name"],
            typeLocation:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
              "LOC_TYPE"
              ],
            stop_point:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
              "AREA"
              ],
            day_duration: dataFromEdit["day"],
            timepicker: dataFromEdit["duration"],
            vehicleGroup: "all",
            vehicle: "",
            vehicleSelected: dataFromEdit["vehicles"],
            id: dataFromEdit["id"],
          };
        case "ATEMP":
          return {
            policyType: dataFromEdit["type"],
            dataType: dataFromEdit["policy_type"],
            name: dataFromEdit["name"],
            min_temp:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
              "MIN"
              ],
            max_temp:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
              "MAX"
              ],
            vehicleGroup: "all",
            vehicle: "",
            vehicleSelected: dataFromEdit["vehicles"],
            id: dataFromEdit["id"],
          };

        case "A_WIGP":
          return {
            policyType: dataFromEdit["type"],
            dataType: dataFromEdit["policy_type"],
            name: dataFromEdit["name"],
            wigp_start_time:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
              "END_TIME"
              ],
            wigp_end_time:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
              "START_TIME"
              ],
            vehicleGroup: "all",
            vehicle: "",
            vehicleSelected: dataFromEdit["vehicles"],
            id: dataFromEdit["id"],
          };

        case "A_WLTL":
          return {
            policyType: dataFromEdit["type"],
            dataType: dataFromEdit["policy_type"],
            name: dataFromEdit["name"],
            wltl_dur:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
              "MAX_DUR"
              ],
            vehicleGroup: "all",
            vehicle: "",
            vehicleSelected: dataFromEdit["vehicles"],
            id: dataFromEdit["id"],
          };

        case "A_WLTD":
          return {
            policyType: dataFromEdit["type"],
            dataType: dataFromEdit["policy_type"],
            name: dataFromEdit["name"],
            wltd_dur:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
              "MAX_DUR"
              ],
            wltd_distance:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
              "DISTANCE"
              ],
            vehicleGroup: "all",
            vehicle: "",
            vehicleSelected: dataFromEdit["vehicles"],
            id: dataFromEdit["id"],
          };

        case "A_WLTD":
          return {
            policyType: dataFromEdit["type"],
            dataType: dataFromEdit["policy_type"],
            name: dataFromEdit["name"],
            wltd_dur:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
              "MAX_DUR"
              ],

            vehicleGroup: "all",
            vehicle: "",
            vehicleSelected: dataFromEdit["vehicles"],
            id: dataFromEdit["id"],
          };

        case "A_VMOFLT":
          return {
            policyType: dataFromEdit["type"],
            dataType: dataFromEdit["policy_type"],
            name: dataFromEdit["name"],
            wltd_dur:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
              "MAX_DUR"
              ],
            vehicleGroup: "all",
            vehicle: "",
            vehicleSelected: dataFromEdit["vehicles"],
            id: dataFromEdit["id"],
          };

        case "A_VDC":
          return {
            policyType: dataFromEdit["type"],
            dataType: dataFromEdit["policy_type"],
            name: dataFromEdit["name"],
            parkingTime:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
              "ALERT_DURATION"
              ],
            vehicleGroup: "all",
            vehicle: "",
            vehicleSelected: dataFromEdit["vehicles"],
            id: dataFromEdit["id"],
          };

        case "A_VCD":
          return {
            policyType: dataFromEdit["type"],
            dataType: dataFromEdit["policy_type"],
            name: dataFromEdit["name"],
            parkingTime:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
              "PARKING_TIME"
              ],
            drivingSpeed:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
              "DRIVING_SPEED"
              ],
            drivingTimePolicy:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
              "DRIVING_TIME_POLICY"
              ]["time"],
            ringToneRed:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
              "DRIVING_TIME_POLICY"
              ]["audio"],
            ringToneYellowVCD:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
              "DRIVING_TIME_WARNING"
              ],
            warningAudioForParkingTimeComplete:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
              "PARKING_END_AUDIO"
              ],
            warningAudioForNotStoppingInTime:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
              "PARKING_NOT_END_AUDIO"
              ],
            vehicleGroup: "all",
            vehicle: "",
            vehicleSelected: dataFromEdit["vehicles"],
            id: dataFromEdit["id"],
          };
        case "SEVT":
          return {
            policyType: dataFromEdit["type"],
            dataType: dataFromEdit["policy_type"],
            name: dataFromEdit["name"],
            pto_on:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
              "INPUT1"
              ],
            boom_on:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
              "INPUT2"
              ],
            vehicleGroup: "all",
            vehicle: "",
            vehicleSelected: dataFromEdit["vehicles"],
            id: dataFromEdit["id"],
          };

        case "AB":
          return {
            policyType: dataFromEdit["type"],
            dataType: dataFromEdit["policy_type"],
            name: dataFromEdit["name"],
            value_red:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
              "ALARM_ACC"
              ]["RED"],
            ringToneRed:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
              "ALARM_ACC"
              ]["AUDIO_POLICY"],
            value_red2:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
              "ALARM_BRAKE"
              ]["RED"],
            ringToneRed2:
              dataFromEdit["policy"][`${dataFromEdit["policy_type_value"]}`][
              "ALARM_BRAKE"
              ]["AUDIO_POLICY"],
            vehicleGroup: "all",
            vehicle: "",
            vehicleSelected: dataFromEdit["vehicles"],
            id: dataFromEdit["id"],
          };

        default:
          return initialValuesPolicy;
      }

    default:
      return initialValuesPolicy;
  }
};

const selectValidationSchema = (yup) => {
  return yup.object().shape({
    policyType: yup.string().required("กรุณาเลือกประเภทข้อมูล"),
    dataType: yup.string().required("กรุณาเลือกประเภทข้อมูล"),
    name: yup.string().required("กรุณากรอกชื่อ"),
    ringToneYellowVCD: yup.array().of(
      yup.object().shape({
        time: yup
          .string()
          .required("กรุณาเลือกเวลา")
          .nullable(),
        audio: yup
          .string()
          .required("กรุณาเลือกเสียง")
          .nullable(),
      })
    ),
    ringToneRed: yup.string().required("กรุณาเลือกเสียง"),
  });
};

const resetFormHelper = (setFieldValue) => {
  Object.keys(initialValues).forEach((field) => {
    setFieldValue(field, initialValues[field]);
  });
};

const checkInitialValues = (
  dataForm,
  type,
  dataFromEdit,
  initialValuesPolicy
) => {
  // console.log("dataFromEdit", dataFromEdit);

  switch (true) {
    case !!dataForm:
      return dataForm;

    case type === "edit" && !!dataFromEdit:
      switch (dataFromEdit["policy_type"]) {
        case "D":
          return {
            policyType: getSafeValue(
              dataFromEdit,
              "type",
              initialValues["type"]
            ),
            dataType: getSafeValue(
              dataFromEdit,
              "policy_type",
              initialValues["policy_type"]
            ),
            name: getSafeValue(dataFromEdit, "name", initialValues["name"]),
            value_yellow: getSafeValue(
              dataFromEdit,
              "policy.P_DEFAULT.ALARM_VEL.YELLOW",
              initialValues["value_yellow"]
            ),
            value_red: getSafeValue(
              dataFromEdit,
              "policy.P_DEFAULT.ALARM_VEL.RED",
              initialValues["value_red"]
            ),
            timepicker: getSafeValue(
              dataFromEdit,
              "policy.ALERT_DURATION.TIME",
              initialValues["timepicker"]
            ),
            vehicleGroup: initialValues["vehicleGroup"], // ใช้ default 'all'
            vehicle: initialValues["vehicle"], // ใช้ default ''
            vehicleSelected: getSafeValue(
              dataFromEdit,
              "vehicles",
              initialValues["vehicleSelected"]
            ),
            period: getSafeValue(
              dataFromEdit,
              "period",
              initialValues["period"]
            ),
            BREAK_WARNING_TIME: getSafeValue(
              dataFromEdit,
              "policy.ALERT_DURATION.BREAK_WARNING_TIME",
              initialValues["BREAK_WARNING_TIME"]
            ),
            INTERVAL_WARNING: getSafeValue(
              dataFromEdit,
              "policy.P_DEFAULT.ALARM_VEL.INTERVAL_WARNING",
              initialValues["INTERVAL_WARNING"]
            ),
            INTERVAL_POLICY: getSafeValue(
              dataFromEdit,
              "policy.P_DEFAULT.ALARM_VEL.INTERVAL_POLICY",
              initialValues["INTERVAL_POLICY"]
            ),
            ringToneYellow: getSafeValue(
              dataFromEdit,
              "policy.P_DEFAULT.ALARM_VEL.AUDIO_WARNING",
              initialValues["ringToneYellow"]
            ),
            ringToneRed: getSafeValue(
              dataFromEdit,
              "policy.P_DEFAULT.ALARM_VEL.AUDIO_POLICY",
              initialValues["ringToneRed"]
            ),
            id: getSafeValue(dataFromEdit, "id", initialValues["id"]),
          };

        case "GZ":
        case "RZ":
          return {
            policyType: getSafeValue(
              dataFromEdit,
              "type",
              initialValues["type"]
            ),
            dataType: getSafeValue(
              dataFromEdit,
              "policy_type",
              initialValues["policy_type"]
            ),
            name: getSafeValue(dataFromEdit, "name", initialValues["name"]),
            value_yellow: getSafeValue(
              dataFromEdit,
              "policy.P_GEOFENCE.ALARM_VEL.YELLOW",
              initialValues["value_yellow"]
            ),
            value_red: getSafeValue(
              dataFromEdit,
              "policy.P_GEOFENCE.ALARM_VEL.RED",
              initialValues["value_red"]
            ),
            timepicker: getSafeValue(
              dataFromEdit,
              "policy.ALERT_DURATION.TIME",
              initialValues["timepicker"]
            ),
            vehicleGroup: initialValues["vehicleGroup"], // ใช้ default 'all'
            vehicle: initialValues["vehicle"], // ใช้ default ''
            vehicleSelected: getSafeValue(
              dataFromEdit,
              "vehicles",
              initialValues["vehicleSelected"]
            ),
            zone: getSafeValue(
              dataFromEdit,
              "policy.DETAIL_GEOFENCE.GID",
              initialValues["zone"]
            ),
            geofence: getSafeValue(
              dataFromEdit,
              "policy.DETAIL_GEOFENCE.GEOFENCE",
              initialValues["geofence"]
            ),
            period: getSafeValue(
              dataFromEdit,
              "period",
              initialValues["period"]
            ),
            id: getSafeValue(dataFromEdit, "id", initialValues["id"]),
          };
        case "SZ":
          return {
            policyType: getSafeValue(
              dataFromEdit,
              "type",
              initialValues["type"]
            ),
            dataType: getSafeValue(
              dataFromEdit,
              "policy_type",
              initialValues["policy_type"]
            ),
            name: getSafeValue(dataFromEdit, "name", initialValues["name"]),
            value_yellow: getSafeValue(
              dataFromEdit,
              "policy.P_GEOFENCE.ALARM_VEL.YELLOW",
              initialValues["value_yellow"]
            ),
            value_red: getSafeValue(
              dataFromEdit,
              "policy.P_GEOFENCE.ALARM_VEL.RED",
              initialValues["value_red"]
            ),
            timepicker: getSafeValue(
              dataFromEdit,
              "policy.ALERT_DURATION.TIME",
              initialValues["timepicker"]
            ),
            vehicleGroup: initialValues["vehicleGroup"],
            vehicle: initialValues["vehicle"],
            vehicleSelected: getSafeValue(
              dataFromEdit,
              "vehicles",
              initialValues["vehicleSelected"]
            ),
            zone: getSafeValue(
              dataFromEdit,
              "policy.DETAIL_GEOFENCE.GID",
              initialValues["zone"]
            ),
            period: getSafeValue(
              dataFromEdit,
              "period",
              initialValues["period"]
            ),
            INTERVAL_WARNING: getSafeValue(
              dataFromEdit,
              "policy.P_GEOFENCE.ALARM_VEL.INTERVAL_WARNING",
              initialValues["INTERVAL_WARNING"]
            ),
            INTERVAL_POLICY: getSafeValue(
              dataFromEdit,
              "policy.P_GEOFENCE.ALARM_VEL.INTERVAL_POLICY",
              initialValues["INTERVAL_POLICY"]
            ),
            ringToneYellow: getSafeValue(
              dataFromEdit,
              "policy.P_GEOFENCE.ALARM_VEL.AUDIO_WARNING",
              initialValues["ringToneYellow"]
            ),
            ringToneRed: getSafeValue(
              dataFromEdit,
              "policy.P_GEOFENCE.ALARM_VEL.AUDIO_POLICY",
              initialValues["ringToneRed"]
            ),
            approachRange: getSafeValue(
              dataFromEdit,
              "policy.EXTEND_RADIUS.METER",
              initialValues["approachRange"]
            ),
            ringToneRed2: getSafeValue(
              dataFromEdit,
              "policy.EXTEND_RADIUS.AUDIO",
              initialValues["ringToneRed2"]
            ),
            wigp_start_time: getSafeValue(
              dataFromEdit,
              "policy.P_TIME.START",
              initialValues["wigp_start_time"]
            ),
            wigp_end_time: getSafeValue(
              dataFromEdit,
              "policy.P_TIME.END",
              initialValues["wigp_end_time"]
            ),
            id: getSafeValue(dataFromEdit, "id", initialValues["id"]),
          };
        case "N":
        case "R":
        case "DH":
        case "UH":
          let obj = {
            policyType: getSafeValue(
              dataFromEdit,
              "type",
              initialValues["type"]
            ),
            dataType: getSafeValue(
              dataFromEdit,
              "policy_type",
              initialValues["policy_type"]
            ),
            name: getSafeValue(dataFromEdit, "name", initialValues["name"]),
            value_yellow: getSafeValue(
              dataFromEdit,
              `policy.${dataFromEdit["policy_type_value"]}.ALARM_VEL.YELLOW`,
              initialValues["value_yellow"]
            ),
            value_red: getSafeValue(
              dataFromEdit,
              `policy.${dataFromEdit["policy_type_value"]}.ALARM_VEL.RED`,
              initialValues["value_red"]
            ),
            timepicker: getSafeValue(
              dataFromEdit,
              "policy.ALERT_DURATION.TIME",
              initialValues["timepicker"]
            ),
            vehicleGroup: initialValues["vehicleGroup"], // ค่า default 'all'
            vehicle: initialValues["vehicle"], // ค่า default ''
            vehicleSelected: getSafeValue(
              dataFromEdit,
              "vehicles",
              initialValues["vehicleSelected"]
            ),
            period: getSafeValue(
              dataFromEdit,
              "period",
              initialValues["period"]
            ),
            id: getSafeValue(dataFromEdit, "id", initialValues["id"]),
          };

          if (dataFromEdit["policy_type"] == "N") {
            obj.startTimePickerNight = getSafeValue(
              dataFromEdit,
              "policy.NIGHT_TIME.START",
              initialValues["startTimePickerNight"]
            );
            obj.endTimePickerNight = getSafeValue(
              dataFromEdit,
              "policy.NIGHT_TIME.END",
              initialValues["endTimePickerNight"]
            );
          }

          return obj;
        case "AS":
        case "AI":
        case "APON":
        case "AFUA":
        case "A_WDLR":
          return {
            policyType: getSafeValue(
              dataFromEdit,
              "type",
              initialValues["type"]
            ),
            dataType: getSafeValue(
              dataFromEdit,
              "policy_type",
              initialValues["policy_type"]
            ),
            name: getSafeValue(dataFromEdit, "name", initialValues["name"]),
            typeLocation: getSafeValue(
              dataFromEdit,
              `policy.${dataFromEdit["policy_type_value"]}.LOC_TYPE`,
              initialValues["typeLocation"]
            ),
            stop_point: getSafeValue(
              dataFromEdit,
              `policy.${dataFromEdit["policy_type_value"]}.AREA`,
              initialValues["stop_point"]
            ),
            day_duration: getSafeValue(
              dataFromEdit,
              "day",
              initialValues["day_duration"]
            ),
            timepicker: getSafeValue(
              dataFromEdit,
              "duration",
              initialValues["timepicker"]
            ),
            vehicleGroup: initialValues["vehicleGroup"], // ใช้ค่า default 'all'
            vehicle: initialValues["vehicle"], // ใช้ค่า default ''
            vehicleSelected: getSafeValue(
              dataFromEdit,
              "vehicles",
              initialValues["vehicleSelected"]
            ),
            id: getSafeValue(dataFromEdit, "id", initialValues["id"]),
          };

        case "ATEMP":
          return {
            policyType: getSafeValue(
              dataFromEdit,
              "type",
              initialValues["type"]
            ),
            dataType: getSafeValue(
              dataFromEdit,
              "policy_type",
              initialValues["policy_type"]
            ),
            name: getSafeValue(dataFromEdit, "name", initialValues["name"]),
            min_temp: getSafeValue(
              dataFromEdit,
              `policy.${dataFromEdit["policy_type_value"]}.MIN`,
              initialValues["min_temp"]
            ),
            max_temp: getSafeValue(
              dataFromEdit,
              `policy.${dataFromEdit["policy_type_value"]}.MAX`,
              initialValues["max_temp"]
            ),
            vehicleGroup: initialValues["vehicleGroup"], // ค่า default 'all'
            vehicle: initialValues["vehicle"], // ค่า default ''
            vehicleSelected: getSafeValue(
              dataFromEdit,
              "vehicles",
              initialValues["vehicleSelected"]
            ),
            id: getSafeValue(dataFromEdit, "id", initialValues["id"]),
          };

        case "A_WIGP":
          return {
            policyType: getSafeValue(
              dataFromEdit,
              "type",
              initialValues["type"]
            ),
            dataType: getSafeValue(
              dataFromEdit,
              "policy_type",
              initialValues["policy_type"]
            ),
            name: getSafeValue(dataFromEdit, "name", initialValues["name"]),
            wigp_start_time: getSafeValue(
              dataFromEdit,
              `policy.${dataFromEdit["policy_type_value"]}.START_TIME`,
              initialValues["wigp_start_time"]
            ), // แก้เป็น START_TIME
            wigp_end_time: getSafeValue(
              dataFromEdit,
              `policy.${dataFromEdit["policy_type_value"]}.END_TIME`,
              initialValues["wigp_end_time"]
            ), // แก้เป็น END_TIME
            vehicleGroup: initialValues["vehicleGroup"], // ค่า default 'all'
            vehicle: initialValues["vehicle"], // ค่า default ''
            vehicleSelected: getSafeValue(
              dataFromEdit,
              "vehicles",
              initialValues["vehicleSelected"]
            ),
            id: getSafeValue(dataFromEdit, "id", initialValues["id"]),
          };

        case "A_WLTL":
          return {
            policyType: getSafeValue(
              dataFromEdit,
              "type",
              initialValues["type"]
            ),
            dataType: getSafeValue(
              dataFromEdit,
              "policy_type",
              initialValues["policy_type"]
            ),
            name: getSafeValue(dataFromEdit, "name", initialValues["name"]),
            wltl_dur: getSafeValue(
              dataFromEdit,
              `policy.${dataFromEdit["policy_type_value"]}.MAX_DUR`,
              initialValues["wltl_dur"]
            ),
            vehicleGroup: initialValues["vehicleGroup"], // ค่า default 'all'
            vehicle: initialValues["vehicle"], // ค่า default ''
            vehicleSelected: getSafeValue(
              dataFromEdit,
              "vehicles",
              initialValues["vehicleSelected"]
            ),
            id: getSafeValue(dataFromEdit, "id", initialValues["id"]),
          };

        case "A_WLTD":
          return {
            policyType: getSafeValue(
              dataFromEdit,
              "type",
              initialValues["type"]
            ),
            dataType: getSafeValue(
              dataFromEdit,
              "policy_type",
              initialValues["policy_type"]
            ),
            name: getSafeValue(dataFromEdit, "name", initialValues["name"]),
            wltd_dur: getSafeValue(
              dataFromEdit,
              `policy.${dataFromEdit["policy_type_value"]}.MAX_DUR`,
              initialValues["wltd_dur"]
            ),
            wltd_distance: getSafeValue(
              dataFromEdit,
              `policy.${dataFromEdit["policy_type_value"]}.DISTANCE`,
              initialValues["wltd_distance"]
            ),
            vehicleGroup: initialValues["vehicleGroup"], // ค่า default 'all'
            vehicle: initialValues["vehicle"], // ค่า default ''
            vehicleSelected: getSafeValue(
              dataFromEdit,
              "vehicles",
              initialValues["vehicleSelected"]
            ),
            id: getSafeValue(dataFromEdit, "id", initialValues["id"]),
          };

        case "A_VMOFLT":
          return {
            policyType: getSafeValue(
              dataFromEdit,
              "type",
              initialValues["type"]
            ),
            dataType: getSafeValue(
              dataFromEdit,
              "policy_type",
              initialValues["policy_type"]
            ),
            name: getSafeValue(dataFromEdit, "name", initialValues["name"]),
            wltd_dur: getSafeValue(
              dataFromEdit,
              `policy.${dataFromEdit["policy_type_value"]}.MAX_DUR`,
              initialValues["wltd_dur"]
            ),
            vehicleGroup: initialValues["vehicleGroup"], // ค่า default 'all'
            vehicle: initialValues["vehicle"], // ค่า default ''
            vehicleSelected: getSafeValue(
              dataFromEdit,
              "vehicles",
              initialValues["vehicleSelected"]
            ),
            id: getSafeValue(dataFromEdit, "id", initialValues["id"]),
          };

        case "A_VDC":
          return {
            policyType: getSafeValue(
              dataFromEdit,
              "type",
              initialValues["type"]
            ),
            dataType: getSafeValue(
              dataFromEdit,
              "policy_type",
              initialValues["policy_type"]
            ),
            name: getSafeValue(dataFromEdit, "name", initialValues["name"]),
            parkingTime: getSafeValue(
              dataFromEdit,
              `policy.${dataFromEdit["policy_type_value"]}.ALERT_DURATION`,
              initialValues["parkingTime"]
            ),
            vehicleGroup: initialValues["vehicleGroup"], // ค่า default 'all'
            vehicle: initialValues["vehicle"], // ค่า default ''
            vehicleSelected: getSafeValue(
              dataFromEdit,
              "vehicles",
              initialValues["vehicleSelected"]
            ),
            id: getSafeValue(dataFromEdit, "id", initialValues["id"]),
          };

        case "A_VCD":
          return {
            policyType: getSafeValue(
              dataFromEdit,
              "type",
              initialValues["type"]
            ),
            drivingContinueusly: '04:00:00',
            dataType: getSafeValue(
              dataFromEdit,
              "policy_type",
              initialValues["policy_type"]
            ),
            name: getSafeValue(dataFromEdit, "name", initialValues["name"]),
            parkingTime: '00:30:00',
            drivingSpeed: getSafeValue(
              dataFromEdit,
              `policy.${dataFromEdit["policy_type_value"]}.DRIVING_SPEED`,
              initialValues["drivingSpeed"]
            ),
            drivingTimePolicy: getSafeValue(
              dataFromEdit,
              `policy.${dataFromEdit["policy_type_value"]}.DRIVING_TIME_POLICY.time`,
              initialValues["drivingTimePolicy"]
            ),
            ringToneRed: getSafeValue(
              dataFromEdit,
              `policy.${dataFromEdit["policy_type_value"]}.DRIVING_TIME_POLICY.audio`,
              initialValues["ringToneRed"]
            ),
            ringToneYellowVCD: getSafeValue(
              dataFromEdit,
              `policy.${dataFromEdit["policy_type_value"]}.DRIVING_TIME_WARNING`,
              initialValues["ringToneYellowVCD"]
            ),
            warningAudioForParkingTimeComplete: getSafeValue(
              dataFromEdit,
              `policy.${dataFromEdit["policy_type_value"]}.PARKING_END_AUDIO`,
              initialValues["warningAudioForParkingTimeComplete"]
            ),
            warningAudioForNotStoppingInTime: getSafeValue(
              dataFromEdit,
              `policy.${dataFromEdit["policy_type_value"]}.PARKING_NOT_END_AUDIO`,
              initialValues["warningAudioForNotStoppingInTime"]
            ),
            vehicleGroup: initialValues["vehicleGroup"], // ค่า default 'all'
            vehicle: initialValues["vehicle"], // ค่า default ''
            vehicleSelected: getSafeValue(
              dataFromEdit,
              "vehicles",
              initialValues["vehicleSelected"]
            ),
            id: getSafeValue(dataFromEdit, "id", initialValues["id"]),
          };
        case "SEVT":
          return {
            policyType: getSafeValue(
              dataFromEdit,
              "type",
              initialValues["type"]
            ),
            dataType: getSafeValue(
              dataFromEdit,
              "policy_type",
              initialValues["policy_type"]
            ),
            name: getSafeValue(dataFromEdit, "name", initialValues["name"]),
            pto_on: getSafeValue(
              dataFromEdit,
              `policy.${dataFromEdit["policy_type_value"]}.INPUT1`,
              initialValues["pto_on"]
            ),
            boom_on: getSafeValue(
              dataFromEdit,
              `policy.${dataFromEdit["policy_type_value"]}.INPUT2`,
              initialValues["boom_on"]
            ),
            vehicleGroup: initialValues["vehicleGroup"], // ค่า default 'all'
            vehicle: initialValues["vehicle"], // ค่า default ''
            vehicleSelected: getSafeValue(
              dataFromEdit,
              "vehicles",
              initialValues["vehicleSelected"]
            ),
            id: getSafeValue(dataFromEdit, "id", initialValues["id"]),
          };
        case "AB":
          return {
            policyType: getSafeValue(
              dataFromEdit,
              "type",
              initialValues["type"]
            ),
            dataType: getSafeValue(
              dataFromEdit,
              "policy_type",
              initialValues["policy_type"]
            ),
            name: getSafeValue(dataFromEdit, "name", initialValues["name"]),
            value_red: getSafeValue(
              dataFromEdit,
              `policy.${dataFromEdit["policy_type_value"]}.ALARM_ACC.RED`,
              initialValues["value_red"]
            ),
            ringToneRed: getSafeValue(
              dataFromEdit,
              `policy.${dataFromEdit["policy_type_value"]}.ALARM_ACC.AUDIO_POLICY`,
              initialValues["ringToneRed"]
            ),
            value_red2: getSafeValue(
              dataFromEdit,
              `policy.${dataFromEdit["policy_type_value"]}.ALARM_BRAKE.RED`,
              initialValues["value_red2"]
            ),
            ringToneRed2: getSafeValue(
              dataFromEdit,
              `policy.${dataFromEdit["policy_type_value"]}.ALARM_BRAKE.AUDIO_POLICY`,
              initialValues["ringToneRed2"]
            ),
            vehicleGroup: initialValues["vehicleGroup"], // ค่า default 'all'
            vehicle: initialValues["vehicle"], // ค่า default ''
            vehicleSelected: getSafeValue(
              dataFromEdit,
              "vehicles",
              initialValues["vehicleSelected"]
            ),
            id: getSafeValue(dataFromEdit, "id", initialValues["id"]),
          };

        default:
          return initialValuesPolicy;
      }

    default:
      return initialValuesPolicy;
  }
};

const getSafeValue = (obj, path, defaultValue = undefined) => {
  let value_return = path
    .split(".")
    .reduce(
      (acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined),
      obj
    );
  if (value_return === undefined) {
    if (defaultValue === undefined) {
      return "";
    } else {
      return defaultValue;
    }
  }
  return value_return;
};

export { checkInitialValues, resetFormHelper, selectValidationSchema };
