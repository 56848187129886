import React, { useEffect, useState } from "react";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import { Formik } from "formik";
import * as yup from "yup";
import { Form, Row, Col, Input, Select, Button, Switch } from "antd";
import LangContext from "modules/shared/context/langContext";
import { menuPermission } from "../fetchApi/apiClient";
import { dataSource } from "../../../ui/components/constant";

const FormItem = Form.Item;
const Option = Select.Option;

const FormManuals = ({ initialValues, readOnly, onSubmit }) => {
  const [menuList, setMenuList] = useState([]);
  const [checkPath, setCheckPath] = useState(true);
  const [hasPath, setHasPath] = useState(null);

  useEffect(() => {
    menuPermission({}, (status, res) => {
      setMenuList(res.data.data.data);
    });
  }, []);

  const handleChangeSelect = (value, setFieldValue) => {
    setFieldValue("page", value);
  };

  const onChangePublish = (val, setFieldValue) => {
    setFieldValue("isPublish", val);
  };

  const findPath = (data, searchPath) => {
    for (const item of data) {
      // Check if the current item's path matches the searchPath
      if (item.matching_name === searchPath) {
        return item;
      }
      // If the item has children, search recursively
      if (item.children) {
        const result = findPath(item.children, searchPath);
        if (result) {
          return result;
        }
      }
    }
    return null; // Return null if no match is found
  };

  return (
    <LangContext.Consumer>
      {(i18n) => (
        <GeneralStyledContent>
          <div
            style={{
              border: "1px solid gray",
              borderRadius: 8,
              padding: 10,
              margin: 5,
            }}
          >
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={yup.object().shape({
                titleName: yup.string().required("กรุณากรอกชื่อหัวข้อ"),
                page: yup.string().required("กรุณาเลือกหน้าที่ต้องการอัพเดต"),
                link: yup.string().required("กรุณากรอก URL"),
              })}
              onSubmit={(values) => {
                onSubmit(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit} layout="vertical">
                  <Row gutter={[8, 8]}>
                    <Col span={24}>
                      <label>{i18n.d.detail}</label>
                    </Col>
                    {/* Title Field */}
                    <Col span={12}>
                      <FormItem
                        label={i18n.t.titleName}
                        required
                        validateStatus={
                          touched.titleName && errors.titleName && "error"
                        }
                        help={touched.titleName && errors.titleName}
                      >
                        <Input
                          readOnly={readOnly}
                          name="titleName"
                          value={values.titleName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={i18n.f.fillInfomation}
                          style={{ width: "100%" }}
                        />
                      </FormItem>
                    </Col>

                    <Col span={11}>
                      <FormItem
                        label={i18n.u.updateToPage}
                        required
                        validateStatus={touched.page && errors.page && "error"}
                        help={touched.page && errors.page}
                      >
                        <Select
                          placeholder="เลือกหน้า"
                          disabled={readOnly}
                          value={values.page}
                          style={{ width: "100%" }}
                          onChange={(val, option) => {
                            handleChangeSelect(val, setFieldValue);
                            let result = findPath(dataSource, option.key);
                            if (result != null) {
                              setCheckPath(false);
                              setHasPath(result.path);
                            } else {
                              setCheckPath(true);
                              setHasPath(null);
                            }
                          }}
                          showSearch
                          filterOption={(input, option) => {
                            if (typeof option.props.children != "object") {
                              return (
                                option.props.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              );
                            }
                          }}
                        >
                          {menuList.map((item) => (
                            <Option value={item.id} key={item.matching_name}>
                              {item.name}
                            </Option>
                          ))}
                        </Select>
                      </FormItem>
                    </Col>
                    <Col span={1} align="left">
                      <Button
                        type="link"
                        disabled={checkPath}
                        style={{
                          paddingTop: 32,
                        }}
                        onClick={() => window.open(hasPath, "_blank").focus()}
                      >
                        ตัวอย่าง
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <FormItem
                        label={<>{i18n.l.link} URL</>}
                        required
                        validateStatus={touched.link && errors.link && "error"}
                        help={touched.link && errors.link}
                      >
                        <Input
                          readOnly={readOnly}
                          name="link"
                          value={values.link}
                          onChange={handleChange}
                          placeholder={i18n.l.linkRequired}
                          style={{ width: "100%" }}
                        />
                      </FormItem>
                    </Col>
                    <Col span={12}>
                      {/* <Button
                        style={{ paddingTop: 36 }}
                        type="link"
                        disabled={!!values.link ? false : true}
                      >
                        {i18n.e.example}
                      </Button> */}
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 100 }}>
                    <Col span={12}>
                      <Switch
                        disabled={readOnly}
                        checkedChildren="Publish"
                        unCheckedChildren="Publish"
                        checked={values.isPublish}
                        onChange={(val) => onChangePublish(val, setFieldValue)}
                      />
                    </Col>
                    {/* Submit Button */}
                    <Col span={12} style={{ textAlign: "right" }}>
                      <Button
                        onClick={() => {
                          window.location.replace("/news-manuals");
                        }}
                      >
                        {i18n.b.back}
                      </Button>
                      {!readOnly && (
                        <Button
                          type="primary"
                          htmlType="submit"
                          style={{ marginLeft: 10 }}
                        >
                          {i18n.s.submit}
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </div>
        </GeneralStyledContent>
      )}
    </LangContext.Consumer>
  );
};

export default FormManuals;
