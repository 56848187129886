import axios from "axios";
import { all, put, call, takeEvery } from "redux-saga/effects";
import { getErrorMsg } from "lib/fetch";

import * as actions from "./actions";

function* loadFlowReport(action) {
  const { obj } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_REPORT_GOLANG_MONGO}/reports/go/flow`,
      {
        ...obj,
      }
    );

    yield put(actions.loadFlowReport.success(res.data));
    window.location = res.data.result;
  } catch (err) {
    yield put(actions.loadFlowReport.failure());
  }
}
export default function* watchreportwithdrawfuelcredit() {
  yield all([takeEvery(actions.LOAD_FLOW_REPORT.REQUEST, loadFlowReport)]);
}
export { loadFlowReport };
