import React from "react";

const Button = (props) => {
  const { children, format, active, ...rest } = props;
  return (
    <button
      htmlType="button"
      className={active ? "richTextButtonActive" : "richTextButtonInActive"}
      title={format}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
