import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Steps, Row, Col, Spin, Button, message } from "antd";
import {
  GeneralStyledContent,
  StyledSearchForm,
} from "../../../../styled/common-styled";
import {
  loadPolicyTemplateDetail,
  createTemplatePolicy,
  updateTemplatePolicy,
} from "./../actions";
import { autoGenKey } from "../../../../lib/helper";
import LangContext, { i18n } from "modules/shared/context/langContext";
import history from "../../../../lib/history";
import AuthorizeComponent from "./../../../auth/components/AuthorizeComponent";
import Breadcrumb from "./../../../shared/components/Breadcrumb";
import Main from "./Form/Template/Main";

const initialValue = {
  policy_id: null,
  policy_name: "",
  policy_type: "speed_limit",
  conditionList: [],
  duration_minute_range: [1, 60],
  duration_second_range: [15, 45],
  duration_unit: "minute",
  duration_trigger_inside_range: true,
  recount_duration: "-",
  apply_monday: true,
  apply_tuesday: true,
  apply_wendnesday: true,
  apply_thursday: true,
  apply_friday: true,
  apply_saturday: true,
  apply_sunday: true,
  time_trigger_inside_range: true,
  time_start_at: "00:00:00",
  time_end_at: "23:59:59",
  critical_level: "medium",
  open_noti_id: "alert",
  repeat_noti_id: "one_time",
  selectedVehicleGroup: [],
  all_vehicle: false,
  selectedVehicle: [],
  all_user: false,
  selectedUser: [],
  channel_web: false,
  channel_line: false,
  channel_email: false,
  is_incident: false,
  value_red: 120,
  value_yellow: 90,
  acc_yel: null,
  acc_red: null,
  brake_yel: null,
  brake_red: null,
  lat_yel: null,
  lat_red: null,
  w_yel: null,
  w_red: null,
  shock_yel: null,
  shock_red: null,
  high_rpm_yel: null,
  high_rpm_red: null,
};

class Detail extends Component {
  state = {
    current: 0,
    zoneList: [],
    initValue: {
      ...initialValue,
      policy_type:
        this.props.auth.profile.company_id == 395
          ? "abnormal_temperature"
          : "speed_limit",
    },

    policy_id: !(
      Object.keys(this.props.match.params).length === 0 &&
      this.props.match.params.constructor === Object
    )
      ? this.props.match.params.id
      : null,
    pageAction: !(
      Object.keys(this.props.match.params).length === 0 &&
      this.props.match.params.constructor === Object
    )
      ? "edit"
      : "create",
  };

  changePageAction = (value) => this.setState({ pageAction: value });

  handlePropsChange = (obj) => {
    this.setState(obj);
  };

  componentDidMount() {
    if (this.state.pageAction === "edit") {
      this.props.loadPolicyTemplateDetail({
        id: this.state.policy_id,
        accessToken: this.props.auth.accessToken,
      });
      return <Redirect to="/policy" />;
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.policyState.selectedPolicy.policy_id !=
      nextProps.policyState.selectedPolicy.policy_id
    ) {
      if (this.state.pageAction == "create") {
        // this.setState({
        //   initValue: initialValue
        // });
      } else {
        this.setState({
          initValue: {
            ...nextProps.policyState.selectedPolicy,
          },
        });
      }
    }
    return true;
  }

  createPolicy = (values, cb) => {
    this.props.createTemplatePolicy(
      {
        ...values,
        company_id: this.props.auth.profile.company_id,
        created_by: this.props.auth.profile.id,
        accessToken: this.props.auth.accessToken,
      },
      cb
    );
  };

  updatePolicy = (values, cb) => {
    this.props.updateTemplatePolicy(
      {
        ...values,
        company_id: this.props.auth.profile.company_id,
        updated_by: this.props.auth.profile.id,
        accessToken: this.props.auth.accessToken,
      },
      cb
    );
  };

  renderRedirect = () => <Redirect to="/policy" />;

  render() {
    return (
      <AuthorizeComponent matching_name="policy">
        <GeneralStyledContent>
          <Spin spinning={this.props.policyState.detailLoading}>
            <Row gutter={24}>
              <Col span={24}>
                <Breadcrumb
                  customeurl={["/policy", null, null]}
                  match={this.props.match}
                  style={{ margin: "0px 0px 14px 10px" }}
                />
              </Col>
            </Row>
            <StyledSearchForm>
              <Main
                history={history}
                initValue={this.state.initValue}
                action={this.state.pageAction}
                createPolicy={this.createPolicy}
                updatePolicy={this.updatePolicy}
              />
            </StyledSearchForm>
          </Spin>
        </GeneralStyledContent>
      </AuthorizeComponent>
    );
  }
}

export default connect(({ policyState, auth }) => ({ policyState, auth }), {
  loadPolicyTemplateDetail: loadPolicyTemplateDetail.request,
  createTemplatePolicy: createTemplatePolicy.request,
  updateTemplatePolicy: updateTemplatePolicy.request,
})(Detail);
