import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { getlocalstorage } from "./../../../constants/local_storage";
//import { push } from "connected-react-router";

import * as actions from "./actions";
import moment from "moment";

function* loadDrivinglog(action) {
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );
  const USER_ID = getlocalstorage(localStorage.getItem("profile"), "id");

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem("profile"),
    "vehicle_visibility"
  );

  const {
    data: {
      list_vehicle_id,
      list_driver_id,
      filter_by,
      vehicle_type_id,
      eventdate,
      vehicle_status,
      group_data,
      type_file,
    },
  } = action.payload;
  const date_start = moment(eventdate[0]["_d"]).format("YYYY-MM-DD") + " 00:00";
  const date_end = moment(eventdate[1]["_d"]).format("YYYY-MM-DD") + " 23:59";
  let group_data_new = "";
  if (group_data !== "") {
    group_data_new = group_data.target.checked;
  } else {
    group_data_new = false;
  }
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_REPORT_GOLANG_MONGO}/reports/go/drivinglog`,
      {
        date_start,
        date_end,
        list_vehicle_id,
        list_driver_id,
        filter_by,
        company_id: COMPANY_ID,
        vehicle_type_id,
        user_id: USER_ID,
        vehicle_status,
        vehicle_visibility,
        group_data: group_data_new,
        type_file,
      }
      //  { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );

    yield put(actions.loadDrivinglog.success(res.data));

    window.location = res.data.result;
  } catch (err) {
    yield put(actions.loadDrivinglog.failure());
  }
}

function* loadVehicle(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem("profile"),
    "vehicle_visibility"
  );

  const { vehicletypeID } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclebygroupid`,
      {
        companyID: COMPANY_ID,
        vehicletypeID: vehicletypeID,
        allow_vehicles: vehicle_visibility,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.loadVehicle.success(res.data));
  } catch (err) {
    yield put(actions.loadVehicle.failure());
  }
}

function* loadVehicleType(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getgroupvehicle`,
      {
        company_id: COMPANY_ID,
      },
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    );
    yield put(actions.loadVehicleType.success(res.data));
  } catch (err) {
    yield put(actions.loadVehicleType.failure());
  }
}

function* loadDriver(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );
  const VUC = getlocalstorage(
    localStorage.getItem("profile"),
    "vehicle_visibility"
  );
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getdriverall`,
      {
        allow_vehicles: VUC,
        company_id: COMPANY_ID,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.loadDriver.success(res.data));
  } catch (err) {
    yield put(actions.loadDriver.failure());
  }
}

export default function* watchReportPoolState() {
  yield all([
    takeEvery(actions.LOAD_DRIVINGLOGSPECIAL.REQUEST, loadDrivinglog),
    takeEvery(actions.LOAD_VEHICLE_DRIVINGLOGSPECIAL.REQUEST, loadVehicle),
    takeEvery(
      actions.LOAD_VEHICLE_TYPE_DRIVINGLOGSPECIAL.REQUEST,
      loadVehicleType
    ),
    takeEvery(actions.LOAD_DRIVER_DRIVINGLOGSPECIAL.REQUEST, loadDriver),
  ]);
}

export { loadDrivinglog, loadVehicle, loadVehicleType, loadDriver };
