import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import moment from "moment";

import {
  Button,
  Form,
  Select,
  Row,
  Col,
  DatePicker,
  Dropdown,
  Menu,
  Icon,
} from "antd";

import LangContext, { i18n } from "modules/shared/context/langContext";

const { RangePicker } = DatePicker;
const FormItem = Form.Item;
const Option = Select.Option;
const companyList = [];
for (let i = 10; i < 36; i++) {
  companyList.push({
    label: i.toString(36) + i,
    value: i.toString(36) + i,
  });
}

const logType = [
  {
    value: 1,
    label: "Replay",
    word: (i18n) => i18n.r.replay,
  },
  {
    value: 2,
    label: "Tracking",
    word: (i18n) => i18n.t.tracking,
  },
  {
    value: 3,
    label: "Report",
    word: (i18n) => i18n.r.report,
  },
];

export default class Forms extends Component {
  state = {
    event_date_start: "",
    event_date_end: "",
  };

  handleMenuClick = (e, setFieldValue, handleSubmit) => {
    if (e.key === "excel") {
      setFieldValue("type_file", "excel", false);
    } else if (e.key === "pdf") {
      setFieldValue("type_file", "pdf", false);
    }
    handleSubmit();
  };

  changeCompany = (value, option, setFieldValue) => {
    this.setState({ company_id: value });
    setFieldValue("company_id", option.props.children);
  };

  render() {
    const { companyList, onSubmit, lang } = this.props;

    return (
      <div>
        <h3>
          {" "}
          <LangContext.Consumer>
            {(i18n) => i18n.p.performanceLog}
          </LangContext.Consumer>
        </h3>
        <Formik
          enableReinitialize={true}
          initialValues={{
            company_id: [],
            logtype_id: "",
            eventdate: [
              moment("00:00:00", "HH:mm:ss"),
              moment("23:59:59", "HH:mm:ss"),
            ],
          }}
          validate={(values) => {
            let errors = {};
            if (values.eventdate.length > 0) {
              const date_start = moment(values.eventdate[0]["_d"]);
              const date_end = moment(values.eventdate[1]["_d"]);
              let day_diff = date_end.diff(date_start, "months");
              if (day_diff > 0) {
                errors.eventdate = (
                  <LangContext.Consumer>
                    {(i18n) => i18n.o.overOneMonth}
                  </LangContext.Consumer>
                );
              }
            }
            return errors;
          }}
          validationSchema={yup.object().shape({
            company_id: yup.string().required(i18n[lang].p.pleaseChooseCompany),
            logtype_id: yup.string().required(i18n[lang].p.pleaseChooseLog),
            eventdate: yup.array().required(i18n[lang].p.pleaseChooseDateRange),
          })}
          onSubmit={(values) => {
            const loading = true;
            onSubmit(values, loading);
          }}
          render={({ errors, touched, handleSubmit, setFieldValue }) => (
            <Row type="flex" justify="center">
              <Form onSubmit={handleSubmit}>
                <Form.Item
                  required={true}
                  label={
                    <LangContext.Consumer>
                      {(i18n) => i18n.c.company}
                    </LangContext.Consumer>
                  }
                  validateStatus={
                    touched.company_id && errors.company_id && "warning"
                  }
                  help={touched.company_id && errors.company_id}
                >
                  <Select
                    mode="multiple"
                    allowClear
                    placeholder={
                      <LangContext.Consumer>
                        {(i18n) => i18n.p.pleaseSelect}
                      </LangContext.Consumer>
                    }
                    onChange={(value) => {
                      setFieldValue("company_id", value);
                    }}
                    filterOption={(val, options) => {
                      const { children } = options.props;
                      if (typeof children != "string") return false;
                      return (
                        children.toLowerCase().indexOf(val.toLowerCase()) >= 0
                      );
                    }}
                  >
                    {companyList.map((item) => (
                      <Select.Option key={item.key} value={`${item.key}`}>
                        {item.value}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  required={true}
                  label={
                    <LangContext.Consumer>
                      {(i18n) => i18n.l.logType}
                    </LangContext.Consumer>
                  }
                  validateStatus={
                    touched.logtype_id && errors.logtype_id && "warning"
                  }
                  help={touched.logtype_id && errors.logtype_id}
                >
                  <Select
                    placeholder={
                      <LangContext.Consumer>
                        {(i18n) => i18n.p.pleaseSelect}
                      </LangContext.Consumer>
                    }
                    onChange={(value) => setFieldValue("logtype_id", value)}
                  >
                    {logType.map((val) => {
                      return (
                        <Option value={val.value}>
                          <LangContext.Consumer>
                            {val.word}
                          </LangContext.Consumer>
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>

                <Form.Item
                  required={true}
                  label={
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.dateRange}
                    </LangContext.Consumer>
                  }
                  validateStatus={
                    touched.eventdate && errors.eventdate && "warning"
                  }
                  help={touched.eventdate && errors.eventdate}
                >
                  <RangePicker
                    showTime={{ format: "HH:mm" }}
                    onChange={(value) => setFieldValue("eventdate", value)}
                    format="DD/MM/YYYY HH:mm"
                    placeholder={["Start Time", "End Time"]}
                    defaultValue={[
                      moment("00:00:00", "HH:mm:ss"),
                      moment("23:59:59", "HH:mm:ss"),
                    ]}
                  />
                </Form.Item>

                <FormItem>
                  <Row gutter={24}>
                    <Col span={8}>
                      <Button type="defualt" block>
                        <Link to="/">
                          <LangContext.Consumer>
                            {(i18n) => i18n.c.cancel}
                          </LangContext.Consumer>
                        </Link>
                      </Button>
                    </Col>
                    <Col span={8}>
                      <Dropdown
                        overlay={
                          <Menu
                            onClick={(value) =>
                              this.handleMenuClick(
                                value,
                                setFieldValue,
                                handleSubmit
                              )
                            }
                          >
                            <Menu.Item key="excel">
                              <Icon type="file-excel" />{" "}
                              <LangContext.Consumer>
                                {(i18n) => i18n.e.excel}
                              </LangContext.Consumer>
                            </Menu.Item>
                            {/* <Menu.Item key="pdf">
                              <Icon type="file-pdf" />{" "}
                              <LangContext.Consumer>
                                {(i18n) => i18n.p.pdf}
                              </LangContext.Consumer>
                            </Menu.Item> */}
                          </Menu>
                        }
                      >
                        <Button type="primary" block>
                          <LangContext.Consumer>
                            {(i18n) => i18n.r.report}
                          </LangContext.Consumer>{" "}
                          {/* <Icon type="down" /> */}
                        </Button>
                      </Dropdown>
                    </Col>
                  </Row>
                </FormItem>
              </Form>
            </Row>
          )}
        />
      </div>
    );
  }
}
