import React, { Component } from "react";
import { Table, Collapse, Modal, Select, Row, Col, Spin, Alert } from "antd";
const mockData = {
  files: [{ name: "test" }, { name: "test1" }, { name: "test2" }],
};
const columns = [
  {
    title: "ชื่อไฟล์",
    dataIndex: "name",
    key: "name",
    width: "35%",
  },
  {
    title: "วันที่",
    dataIndex: "create_date",
    key: "create_date",
    width: "35%",
  },
  {
    title: "ตำแหน่งกล้อง",
    dataIndex: "camera_source",
    key: "camera_source",
    width: "25%",
  },
];
export default class ModalVideoLog extends Component {
  state = {
    camera_source: 1,
    data_source: [],
  };

  handleChange = (value) => {
    this.setState({ camera_source: value });
  };

  generatePanel = (groupedData) => {
    if (groupedData == "") {
      return (
        <Spin tip="Loading" size="large">
          <div
            style={{
              padding: 50,
              background: "rgba(0, 0, 0, 0.05)",
              borderRadius: 4,
            }}
          />
          ;
        </Spin>
      );
    }
    return (
      <Collapse>
        {Object.entries(groupedData)
          .sort(([dateA], [dateB]) => new Date(dateB) - new Date(dateA)) // Sort descending (newest first)
          .map((
            [date, files],
            index // Added index as the second argument
          ) => (
            <Collapse.Panel
              header={date}
              key={index} // Fixed: Ensure index is used correctly
              style={{ padding: "10px", borderBottom: "1px solid #ccc" }}
            >
              <Table
                columns={columns}
                dataSource={files[this.state.camera_source]}
              />
            </Collapse.Panel>
          ))}
      </Collapse>
    );
  };
  render() {
    return (
      <Modal
        title="ดูประวัติวิดีโอย้อนหลัง"
        visible={this.props.isModalOpen}
        // onOk={this.props.handleOk}
        onCancel={this.props.handleCancel}
        footer={false}
        width="80%"
      >
        <Row
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 8,
            marginBottom: 16,
          }}
        >
          <Col span={10}>
            <div>ทะเบียน : {this.props.plateNo}</div>
          </Col>
          <Col span={10}>
            <div>
              <span>ตำแหน่งกล้อง : </span>
              <Select
                value={this.state.camera_source}
                onChange={this.handleChange}
              >
                <Select.Option value={1}>1</Select.Option>
                <Select.Option value={2}>2</Select.Option>
                <Select.Option value={3}>3</Select.Option>
                <Select.Option value={4}>4</Select.Option>
              </Select>
            </div>
          </Col>
        </Row>

        {this.generatePanel(this.props.logVideoFile)}
      </Modal>
    );
  }
}
