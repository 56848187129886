import React from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import NotiCenter from "./Index";
import ReplayForVehicle from "modules/monitoring/replay/components/Index";
import PolicyEdit from "modules/setting/policynew/components/Edit";
import TrackingForVehicle from "modules/monitoring/tracking/components/Index";
import Video from "modules/monitoring/honeytoaststream/components/Index";
import Honeytoastdownloadfile from "modules/monitoring/honeytoastdownloadfilewebsocketinqueue/components/Index";

const NoticenterRoute = () => (
  <Router>
    <Route exact path="/noti-center" component={NotiCenter} />
    <Route path="/policy-new/edit/:id" component={PolicyEdit} />
    <Route
      exact
      path="/tracking/vehicle/:id/:companyID"
      component={TrackingForVehicle}
    />
    <Route exact path="/livestream" component={Video} />
    <Route exact path="/replay" component={ReplayForVehicle} />
    <Route
      exact
      path="/downloadvideoqueue"
      component={Honeytoastdownloadfile}
    />
  </Router>
);

export default NoticenterRoute;
