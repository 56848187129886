import React, { Component } from "react";

import GoogleMapReact from "google-map-react";
export class GoogleMap extends Component {
  render() {
    return (
      <GoogleMapReact {...this.props}>{this.props.children}</GoogleMapReact>
    );
  }
}

export default GoogleMap;
