import React, { Component, Fragment } from "react";
import {
  TreeSelect,
  Select,
  Form,
  Input,
  Slider,
  Spin,
  Modal,
  DatePicker
} from "antd";
import moment from "moment";
import LangContext, { i18n } from "modules/shared/context/langContext";
import { autoGenKey } from "../../../../lib/helper";
const Option = Select.Option;
const { RangePicker } = DatePicker;

export class SearchModal extends Component {
  state = {
    data: [],
    searchQ: ""
  };

  handleSearch = value => {
    this.setState({ searchQ: value });
  };

  render() {
    const options = this.props.vehicleList
      .filter(ele => {
        if (ele.id == null) return false;
        if (this.state.searchQ.trim() == "") return true;
        if (
          ele.plate_no
            .toLowerCase()
            .includes(this.state.searchQ.toLowerCase().trim())
        )
          return true;
        return false;
      })
      .map(d => (
        <Option key={autoGenKey("plate-no")} value={d.id}>
          {d.plate_no}
        </Option>
      ));

    return (
      <Modal
        style={{ top: 20 }}
        title={
          <LangContext.Consumer>{i18n => i18n.v.vehicle}</LangContext.Consumer>
        }
        visible={this.props.filterVisible}
        onOk={this.props.handleApplayButtonClick}
        okButtonProps={{
          disabled: this.props.loading,
          loading: this.props.loading
        }}
        onCancel={this.props.handleFilterCancel}
        okText={<LangContext.Consumer>{i18n => i18n.o.okay}</LangContext.Consumer>}
        cancelText={<LangContext.Consumer>{i18n => i18n.c.cancel}</LangContext.Consumer>}
      >
        <Form layout="horizontal">
          <Form.Item
            label={
              <LangContext.Consumer>
                {i18n => i18n.s.selectPlateNo}
              </LangContext.Consumer>
            }
          >
            <Select
              showSearch
              value={this.props.selectedVehicle}
              // placeholder={this.props.placeholder}

              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={this.handleSearch}
              onChange={this.props.handleSeletedVehicleChange}
              notFoundContent={null}
            >
              {options}
            </Select>
          </Form.Item>
          <Form.Item
            label={
              <LangContext.Consumer>
                {i18n => i18n.d.dateRange}
              </LangContext.Consumer>
            }
          >
            <RangePicker
              showTime={{ format: "HH:mm" }}
              onChange={value =>
                this.props.handleSeletedDateTimeChange(
                  moment(value[0]["_d"]).format("YYYY-MM-DD HH:mm:ss"),
                  moment(value[1]["_d"]).format("YYYY-MM-DD HH:mm:ss")
                )
              }
              format="DD/MM/YYYY HH:mm"
              placeholder={["Start Time", "End Time"]}
              defaultValue={[
                moment("00:00", "HH:mm"),
                moment("23:59", "HH:mm")
              ]}
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default SearchModal;
