import React from "react";
import { Modal, Carousel, Row, Col, Icon } from "antd";

const Arrow = ({ type, style, className, onClick }) => (
  <Icon type={type} style={style} className={className} onClick={onClick} />
);

const handleImageLoaded = (event) => {
  event.target.parentNode.firstChild.style.display = "none";
  event.target.style.display = "inherit";
};

const handleImageErrored = (event) => {
  event.target.src = "/img/no-image.jpg";
};
const ModalSnapShot = ({ snapShotModal, onClose, selectedVehicle }) => {

  return (
    <Modal
      title={
        <h1 style={{ textAlign: "center", color: "#3f87e4", fontSize: "20px" }}>
          SNAPSHOT
        </h1>
      }
      visible={snapShotModal}
      onOk={() => onClose()}
      onCancel={() => onClose()}
      
      footer={null}
      width={700}
      centered
      className="snapshot-modal-tracking"
    >
      <Row>
        <Col span={22} offset={1} align="center">
          <Carousel autoplay>
            <div>
              <img
                style={{
                  width: "90%",
                  padding: "10px 15px 10px 15px",
                  borderRadius:'30px'
                }}
                onLoad={handleImageLoaded}
                onError={handleImageErrored}
                src={
                  selectedVehicle.images.length > 0
                    ? selectedVehicle.images[0]
                    : "/img/no-image.jpg"
                }
              />
            </div>
            {selectedVehicle.images[1] && (
              <div>
                <img src="/img/no-image.jpg" />
                <img
                  style={{
                    width: "90%",
                    padding: "10px 15px 10px 15px",
                    display: "none",
                  }}
                  onLoad={handleImageLoaded}
                  onError={handleImageErrored}
                  src={
                    selectedVehicle.images.length > 0
                      ? selectedVehicle.images[1]
                      : "/img/no-image.jpg"
                  }
                />
              </div>
            )}
          </Carousel>
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalSnapShot;
