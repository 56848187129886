import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { getlocalstorage } from "../../../constants/local_storage";
import * as actions from "./actions";
import moment from "moment";

function* loadTruckusageSJCMonthly(action) {
  const {
    data: { list_vehicle_id, eventdate, type_file, vehicle_type_id, user_id },
  } = action.payload;
  const date_start = moment(eventdate[0]["_d"]).format("YYYY-MM-DD HH:mm");
  const date_end = moment(eventdate[1]["_d"]).format("YYYY-MM-DD HH:mm");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_REPORT_GOLANG_MONGO}/reports/go/sjcmonthlytruckusage`,
      {
        date_start,
        date_end,
        list_vehicle_id,
        vehicle_type_id: vehicle_type_id.toString(),
        type_file,
        company_id: COMPANY_ID,
        user_id
      }
    );

    yield put(actions.loadTruckusageSJCMonthly.success(res.data));
    window.location = res.data.result;
  } catch (err) {
    yield put(actions.loadTruckusageSJCMonthly.failure());
  }
}

function* loadVehicle(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem("profile"),
    "vehicle_visibility"
  );

  const { vehicletypeID } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclebygroupid`,
      {
        companyID: COMPANY_ID,
        vehicletypeID: vehicletypeID,
        allow_vehicles: vehicle_visibility,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.loadVehicle.success(res.data));
  } catch (err) {
    yield put(actions.loadVehicle.failure());
  }
}

function* loadVehicleType(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getgroupvehicle`,
      {
        company_id: COMPANY_ID,
      },
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    );
    yield put(actions.loadVehicleType.success(res.data));
  } catch (err) {
    yield put(actions.loadVehicleType.failure());
  }
}

export default function* watchSJCMonthlyVehicleUseage() {
  yield all([
    takeEvery(
      actions.LOAD_TRUCKUSAGE_SJC_MONTHLY.REQUEST,
      loadTruckusageSJCMonthly
    ),
    takeEvery(actions.LOAD_VEHICLE_TRUCKUSAGE_SJC_MONTHLY.REQUEST, loadVehicle),
    takeEvery(actions.LOAD_VEHICLE_TYPE_TRUCKUSAGE_SJC_MONTHLY.REQUEST, loadVehicleType),
  ]);
}

export { loadTruckusageSJCMonthly, loadVehicle, loadVehicleType };
