import React, { useState } from "react";
import { Modal, Spin, Row, Col } from "antd";
import Iframe from "react-iframe";

const ModalMdvr = ({
  visibleMDVR,
  onClose,
  modalData,
  jsession,
  numberOfChanel,
  platenoList,
  vehList,
}) => {
  const [iframeLoading3, setIframeLoading3] = useState(true);
  const [iframeLoading4, setIframeLoading4] = useState(true);
  const [iframeLoading5, setIframeLoading5] = useState(true);
  const [iframeLoading6, setIframeLoading6] = useState(true);
  const [iframeLoading7, setIframeLoading7] = useState(true);
  const [iframeLoading8, setIframeLoading8] = useState(true);
  const [iframeLoading9, setIframeLoading9] = useState(true);
  const [iframeLoading10, setIframeLoading10] = useState(true);
  const [iframeLoading11, setIframeLoading11] = useState(true);
  const [iframeLoading12, setIframeLoading12] = useState(true);
  const [iframeLoading13, setIframeLoading13] = useState(true);
  const [iframeLoading14, setIframeLoading14] = useState(true);
  const [iframeLoading15, setIframeLoading15] = useState(true);
  const [iframeLoading16, setIframeLoading16] = useState(true);
  const [iframeLoading17, setIframeLoading17] = useState(true);
  const [iframeLoading18, setIframeLoading18] = useState(true);
  const [iframeLoading19, setIframeLoading19] = useState(true);
  const [iframeLoading20, setIframeLoading20] = useState(true);

  let iFrameHeight = window.innerHeight;

  // console.log("modalData", modalData.data);

  let url = process.env.REACT_APP_API_MDVR_TERMINUS + "?";
  let getVeh = vehList; //00011
  let getPlateno = platenoList; //กท95-5962
  let fourChannel = [0, 1, 2, 3];
  let eightChannel = [0, 1, 2, 3, 4, 5, 6, 7];
  let sixChannel = [0, 1, 2, 3, 4, 5];

  const hideSpinner3 = () => {
    setIframeLoading3(false);
  };

  const hideSpinner4 = () => {
    setIframeLoading4(false);
  };

  const hideSpinner5 = () => {
    setIframeLoading5(false);
  };

  const hideSpinner6 = () => {
    setIframeLoading6(false);
  };

  const hideSpinner7 = () => {
    setIframeLoading7(false);
  };

  const hideSpinner8 = () => {
    setIframeLoading8(false);
  };

  const hideSpinner9 = () => {
    setIframeLoading9(false);
  };

  const hideSpinner10 = () => {
    setIframeLoading10(false);
  };

  const hideSpinner11 = () => {
    setIframeLoading11(false);
  };

  const hideSpinner12 = () => {
    setIframeLoading12(false);
  };

  const hideSpinner13 = () => {
    setIframeLoading13(false);
  };

  const hideSpinner14 = () => {
    setIframeLoading14(false);
  };

  const hideSpinner15 = () => {
    setIframeLoading15(false);
  };

  const hideSpinner16 = () => {
    setIframeLoading16(false);
  };

  const hideSpinner17 = () => {
    setIframeLoading17(false);
  };

  const hideSpinner18 = () => {
    setIframeLoading18(false);
  };

  const hideSpinner19 = () => {
    setIframeLoading19(false);
  };

  const hideSpinner20 = () => {
    setIframeLoading20(false);
  };
  // let num =  6
  let iframe = "";
  if (numberOfChanel == 4) {
    iframe = (
      <div>
        <Row gutter={[12, 12]} type="flex" justify="center">
          <Col span={12}>
            <Spin spinning={iframeLoading3}>
              <Iframe
                url={
                  url +
                  "lang=en&devIdno=" +
                  getVeh +
                  "&plate_no=" +
                  getPlateno +
                  "&channel=" +
                  fourChannel[0] +
                  "&jsession=" +
                  jsession
                }
                width="100%"
                height={iFrameHeight / 3 + "px"}
                scrolling="no"
                onLoad={hideSpinner3}
              />
            </Spin>
          </Col>
          <Col span={12}>
            <Spin spinning={iframeLoading4}>
              <Iframe
                url={
                  url +
                  "lang=en&devIdno=" +
                  getVeh +
                  "&plate_no=" +
                  getPlateno +
                  "&channel=" +
                  fourChannel[1] +
                  "&jsession=" +
                  jsession
                }
                width="100%"
                height={iFrameHeight / 3 + "px"}
                scrolling="no"
                onLoad={hideSpinner4}
              />
            </Spin>
          </Col>
          <Col span={12}>
            <Spin spinning={iframeLoading5}>
              <Iframe
                url={
                  url +
                  "lang=en&devIdno=" +
                  getVeh +
                  "&plate_no=" +
                  getPlateno +
                  "&channel=" +
                  fourChannel[2] +
                  "&jsession=" +
                  jsession
                }
                width="100%"
                height={iFrameHeight / 3 + "px"}
                scrolling="no"
                onLoad={hideSpinner5}
              />
            </Spin>
          </Col>
          <Col span={12}>
            <Spin spinning={iframeLoading6}>
              <Iframe
                url={
                  url +
                  "lang=en&devIdno=" +
                  getVeh +
                  "&plate_no=" +
                  getPlateno +
                  "&channel=" +
                  fourChannel[3] +
                  "&jsession=" +
                  jsession
                }
                width="100%"
                height={iFrameHeight / 3 + "px"}
                scrolling="no"
                onLoad={hideSpinner6}
              />
            </Spin>
          </Col>
        </Row>
      </div>
    );
  } else if (numberOfChanel == 8) {
    iframe = (
      <div>
        <Row gutter={[12, 12]} type="flex" justify="center">
          <Col span={6}>
            <Spin spinning={iframeLoading7}>
              <Iframe
                url={
                  url +
                  "lang=en&devIdno=" +
                  getVeh +
                  "&plate_no=" +
                  getPlateno +
                  "&channel=" +
                  eightChannel[0] +
                  "&jsession=" +
                  jsession
                }
                width="100%"
                height={iFrameHeight / 3 + "px"}
                scrolling="no"
                onLoad={hideSpinner7}
              />
            </Spin>
          </Col>
          <Col span={6}>
            <Spin spinning={iframeLoading8}>
              <Iframe
                url={
                  url +
                  "lang=en&devIdno=" +
                  getVeh +
                  "&plate_no=" +
                  getPlateno +
                  "&channel=" +
                  eightChannel[1] +
                  "&jsession=" +
                  jsession
                }
                width="100%"
                height={iFrameHeight / 3 + "px"}
                scrolling="no"
                onLoad={hideSpinner8}
              />
            </Spin>
          </Col>
          <Col span={6}>
            <Spin spinning={iframeLoading9}>
              <Iframe
                url={
                  url +
                  "lang=en&devIdno=" +
                  getVeh +
                  "&plate_no=" +
                  getPlateno +
                  "&channel=" +
                  eightChannel[2] +
                  "&jsession=" +
                  jsession
                }
                width="100%"
                height={iFrameHeight / 3 + "px"}
                scrolling="no"
                onLoad={hideSpinner9}
              />
            </Spin>
          </Col>
          <Col span={6}>
            <Spin spinning={iframeLoading10}>
              <Iframe
                url={
                  url +
                  "lang=en&devIdno=" +
                  getVeh +
                  "&plate_no=" +
                  getPlateno +
                  "&channel=" +
                  eightChannel[3] +
                  "&jsession=" +
                  jsession
                }
                width="100%"
                height={iFrameHeight / 3 + "px"}
                scrolling="no"
                onLoad={hideSpinner10}
              />
            </Spin>
          </Col>
          <Col span={6}>
            <Spin spinning={iframeLoading11}>
              <Iframe
                url={
                  url +
                  "lang=en&devIdno=" +
                  getVeh +
                  "&plate_no=" +
                  getPlateno +
                  "&channel=" +
                  eightChannel[4] +
                  "&jsession=" +
                  jsession
                }
                width="100%"
                height={iFrameHeight / 3 + "px"}
                scrolling="no"
                onLoad={hideSpinner11}
              />
            </Spin>
          </Col>
          <Col span={6}>
            <Spin spinning={iframeLoading12}>
              <Iframe
                url={
                  url +
                  "lang=en&devIdno=" +
                  getVeh +
                  "&plate_no=" +
                  getPlateno +
                  "&channel=" +
                  eightChannel[5] +
                  "&jsession=" +
                  jsession
                }
                width="100%"
                height={iFrameHeight / 3 + "px"}
                scrolling="no"
                onLoad={hideSpinner12}
              />
            </Spin>
          </Col>
          <Col span={6}>
            <Spin spinning={iframeLoading13}>
              <Iframe
                url={
                  url +
                  "lang=en&devIdno=" +
                  getVeh +
                  "&plate_no=" +
                  getPlateno +
                  "&channel=" +
                  eightChannel[6] +
                  "&jsession=" +
                  jsession
                }
                width="100%"
                height={iFrameHeight / 3 + "px"}
                scrolling="no"
                onLoad={hideSpinner13}
              />
            </Spin>
          </Col>
          <Col span={6}>
            <Spin spinning={iframeLoading14}>
              <Iframe
                url={
                  url +
                  "lang=en&devIdno=" +
                  getVeh +
                  "&plate_no=" +
                  getPlateno +
                  "&channel=" +
                  eightChannel[7] +
                  "&jsession=" +
                  jsession
                }
                width="100%"
                height={iFrameHeight / 3 + "px"}
                scrolling="no"
                onLoad={hideSpinner14}
              />
            </Spin>
          </Col>
        </Row>
      </div>
    );
  } else if (numberOfChanel == 6) {
    iframe = (
      <div>
        <Row gutter={[12, 12]} type="flex" justify="center">
          <Col span={8}>
            <Spin spinning={iframeLoading15}>
              <Iframe
                url={
                  url +
                  "lang=en&devIdno=" +
                  getVeh +
                  "&plate_no=" +
                  getPlateno +
                  "&channel=" +
                  sixChannel[0] +
                  "&jsession=" +
                  jsession
                }
                width="100%"
                height={iFrameHeight / 3 + "px"}
                scrolling="no"
                onLoad={hideSpinner15}
              />
            </Spin>
          </Col>
          <Col span={8}>
            <Spin spinning={iframeLoading16}>
              <Iframe
                url={
                  url +
                  "lang=en&devIdno=" +
                  getVeh +
                  "&plate_no=" +
                  getPlateno +
                  "&channel=" +
                  sixChannel[1] +
                  "&jsession=" +
                  jsession
                }
                width="100%"
                height={iFrameHeight / 3 + "px"}
                scrolling="no"
                onLoad={hideSpinner16}
              />
            </Spin>
          </Col>
          <Col span={8}>
            <Spin spinning={iframeLoading17}>
              <Iframe
                url={
                  url +
                  "lang=en&devIdno=" +
                  getVeh +
                  "&plate_no=" +
                  getPlateno +
                  "&channel=" +
                  sixChannel[2] +
                  "&jsession=" +
                  jsession
                }
                width="100%"
                height={iFrameHeight / 3 + "px"}
                scrolling="no"
                onLoad={hideSpinner17}
              />
            </Spin>
          </Col>
          <Col span={8}>
            <Spin spinning={iframeLoading18}>
              <Iframe
                url={
                  url +
                  "lang=en&devIdno=" +
                  getVeh +
                  "&plate_no=" +
                  getPlateno +
                  "&channel=" +
                  sixChannel[3] +
                  "&jsession=" +
                  jsession
                }
                width="100%"
                height={iFrameHeight / 3 + "px"}
                scrolling="no"
                onLoad={hideSpinner18}
              />
            </Spin>
          </Col>
          <Col span={8}>
            <Spin spinning={iframeLoading19}>
              <Iframe
                url={
                  url +
                  "lang=en&devIdno=" +
                  getVeh +
                  "&plate_no=" +
                  getPlateno +
                  "&channel=" +
                  sixChannel[4] +
                  "&jsession=" +
                  jsession
                }
                width="100%"
                height={iFrameHeight / 3 + "px"}
                scrolling="no"
                onLoad={hideSpinner19}
              />
            </Spin>
          </Col>
          <Col span={8}>
            <Spin spinning={iframeLoading20}>
              <Iframe
                url={
                  url +
                  "lang=en&devIdno=" +
                  getVeh +
                  "&plate_no=" +
                  getPlateno +
                  "&channel=" +
                  sixChannel[5] +
                  "&jsession=" +
                  jsession
                }
                width="100%"
                height={iFrameHeight / 3 + "px"}
                scrolling="no"
                onLoad={hideSpinner20}
              />
            </Spin>
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <>
      <Modal
        title={
          <h1
            style={{ textAlign: "center", color: "#3f87e4", fontSize: "20px" }}
          >
            MDVR Live Stream
          </h1>
        }
        visible={visibleMDVR}
        onOk={() => onClose()}
        onCancel={() => onClose()}
        footer={null}
        width={window.innerWidth - 600}
      >
        {iframe}
      </Modal>
    </>
  );
};

export default ModalMdvr;
