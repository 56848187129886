import React from "react";
import { useFocused, useSelected, useSlateStatic } from "slate-react";

import { removeLink } from "../../utils/link.js";

import "./styles.css";
import { Button } from "antd";
const Link = ({ attributes, element, children }) => {
  const editor = useSlateStatic();
  const selected = useSelected();
  const focused = useFocused();
  return (
    <div className="link-richtext-style">
      <a href={element.href} {...attributes}>
        {children}
      </a>
      {selected && focused && (
        <div className="link-popup-style" contentEditable="false">
          <a href={element.href}>{element.href}</a>
          <Button type="link" onClick={() => removeLink(editor)}>
            unlink
          </Button>
        </div>
      )}
    </div>
  );
};

export default Link;
