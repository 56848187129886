import React, { Component } from "react";
import AuthorizeComponent from "../../../auth/components/AuthorizeComponent";
import {
  Badge,
  Button,
  Col,
  Icon,
  message,
  Pagination,
  Row,
  Spin,
  Table,
  Modal,
  Tooltip,
  Upload,
} from "antd";

import { GeneralStyledContent } from "../../../../styled/common-styled";
import Breadcrumb from "../../../shared/components/Breadcrumb";
import { Link } from "react-router-dom";
import LangContext from "modules/shared/context/langContext";
import Filter from "./Filter";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import * as actions from "../actions";
import { connect } from "react-redux";
import Sheet from "./Sheet";

const columnStyle = { fontSize: "12px", fontWeight: "400" };
const initialFilter = {
  vehicle_code: "",
  page: 1,
  uploader: [],
  files: [],
  checkUpload: 1,
};

class Index extends Component {
  state = {
    ...initialFilter,
    filterVisible: false,
    modalVisible: false,
    uploadModalVisible: false,
    currentUploadWithdrawsheet: "",
  };
  handlePageChange = (page) => {
    this.setState({ page });
    this.props.load({
      ...this.state,
      page: page,
      vehicle_visibility: this.props.auth.profile.vehicle_visibility,
      user_id: this.props.auth.profile.id
    });
  };

  handleStateChange = (type, v) => {
    switch (type) {
      case "vehicle_code":
        this.setState({
          vehicle_code: v.target.value,
        });
        break;
      default:
        break;
    }
  };

  showModal = (value) => {
    this.props.loadWithdrawInfo({ id: value.id });
    this.setState({
      modalVisible: true,
    });
  };
  showUploadModal = (value) => {
    // this.props.loadWithdrawInfo({ id: value.id });
    this.props.loadPath({ withdraw_id: value.id });
    this.setState({
      uploadModalVisible: true,
      currentUploadWithdrawsheet: value.id,
      uploader: [],
      files: [],
      checkUpload: 1,
    });
  };
  hideModal = () => {
    this.setState({
      modalVisible: false,
      uploadModalVisible: false,
    });
  };

  openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  generatePDF = async (fileName) => {
    const input = document.getElementById("pdf-content");

    const canvas = await html2canvas(input);
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF();
    pdf.addImage(imgData, "PNG", 10, 10, 190, 0);
    pdf.save("fuelwithdraw_" + fileName + ".pdf");
  };
  generateMsg = () => {
    switch (this.props.withdrawfuelcredit.status) {
      case "success":
        message.success(this.props.withdrawfuelcredit.msg);
        this.props.loadPath({
          withdraw_id: this.state.currentUploadWithdrawsheet,
        });
        this.setState({
          uploader: [],
          files: [],
          checkUpload: 1,
        });
        break;
      case "fail":
        message.error(this.props.withdrawfuelcredit.msg);
        break;
      default:
        break;
    }
  };
  handleOnClickUploadFile = () => {
    console.log(this.state.currentUploadWithdrawsheet, this.state.uploader);
    this.props.updatePath(
      {
        withdraw_id: this.state.currentUploadWithdrawsheet,
        file: this.state.uploader[0],
      },
      this.generateMsg
    );
  };

  generateWithdrawFile = () => {
    if (
      this.props.withdrawfuelcredit.withdrawPath != "" &&
      !this.props.withdrawfuelcredit.loading
    ) {
      if (
        this.getTypeFile(this.props.withdrawfuelcredit.withdrawPath) === "pdf"
      ) {
        return (
          <embed
            src={this.props.withdrawfuelcredit.withdrawPath}
            width="660px"
            height="800px"
          />
        );
      } else {
        return (
          <img src={this.props.withdrawfuelcredit.withdrawPath} width="700px" />
        );
      }
    }
    if (this.props.withdrawfuelcredit.loading) {
      return <Spin spinning={this.props.withdrawfuelcredit.loading} />;
    }
  };

  getTypeFile = (url) => {
    let file_type = url.split("?");
    file_type = file_type[0].split(".");
    return file_type.slice(-1)[0];
  };
  componentDidMount() {
    this.props.load({
      ...initialFilter,
      vehicle_visibility: this.props.auth.profile.vehicle_visibility,
    });
  }
  render() {
    const columns = [
      {
        title: "#",
        dataIndex: "rowNumber",
        width: 60,
        render: (text, record, index) => {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{index + 1 + (this.state.page - 1) * 10}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.p.plateNo}
          </LangContext.Consumer>
        ),
        dataIndex: "plate_no",
        key: "plate_no",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.v.vehicleCode}
          </LangContext.Consumer>
        ),
        dataIndex: "code",
        key: "code",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.w.withdrawfuelcredit}
          </LangContext.Consumer>
        ),
        dataIndex: "withdraw_credit",
        key: "withdraw_credit",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.d.distanceWithKm}
          </LangContext.Consumer>
        ),
        dataIndex: "distance",
        key: "distance",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.d.durationMin}
          </LangContext.Consumer>
        ),
        dataIndex: "parking_duration",
        key: "parking_duration",
        render(text, record, index) {
          let min = text * 60;
          return {
            props: {
              style: columnStyle,
            },
            children:
              text !== null ? (
                <div>{Math.floor(min / 60) + ":" + (parseInt(min) % 60)}</div>
              ) : (
                <div></div>
              ),
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.f.firstName}
          </LangContext.Consumer>
        ),
        dataIndex: "firstname",
        key: "firstname",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.c.createdAt}
          </LangContext.Consumer>
        ),
        dataIndex: "created_at",
        key: "created_at",
        width: 150,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>{(i18n) => i18n.a.action}</LangContext.Consumer>
        ),
        dataIndex: "action",
        key: "action",
        width: 90,
        // fixed: "right",
        render: (text, record, index) => {
          return (
            <div>
              <Tooltip
                placement="left"
                title={
                  <LangContext.Consumer>
                    {(i18n) => i18n.d.detail}
                  </LangContext.Consumer>
                }
                arrowPointAtCenter
                style={{
                  padding: "0px 1px 0px 0px",
                  marginLeft: "5px",
                  marginRight: "5px",
                }}
              >
                <Link style={{ padding: "0px 5px 0px 5px" }}>
                  <Icon
                    type="file-text"
                    onClick={() => this.showModal(record)}
                  />
                </Link>
              </Tooltip>
              <Tooltip
                placement="left"
                title={
                  <LangContext.Consumer>
                    {(i18n) => i18n.d.detail}
                  </LangContext.Consumer>
                }
                arrowPointAtCenter
                style={{
                  padding: "0px 1px 0px 0px",
                  marginLeft: "5px",
                  marginRight: "5px",
                }}
              >
                <Link style={{ padding: "0px 5px 0px 5px" }}>
                  <Icon
                    type="upload"
                    onClick={() => this.showUploadModal(record)}
                  />
                </Link>
              </Tooltip>
            </div>
          );
        },
      },
    ];
    const footerModal = (
      <Button type="primary" onClick={this.handleOnClickUploadFile}>
        <LangContext.Consumer>
          {(i18n) => i18n.u.uploadFile}
        </LangContext.Consumer>
      </Button>
    );
    return (
      <AuthorizeComponent matching_name="managewithdrawfuelcredit">
        <Spin spinning={this.props.withdrawfuelcredit.loading}>
          {/* <Spin spinning={false}> */}
          <GeneralStyledContent>
            <Row gutter={24}>
              <Col span={24}>
                <Breadcrumb
                  customeurl={["/withdraw-fuel-credit", null, null]}
                  match={this.props.match}
                  style={{ margin: "0px 0px 14px 10px" }}
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={15} sm={20} md={21} lg={22} xl={22}>
                <Link to="/withdraw-fuel-credit/create">
                  <Button
                    type="primary"
                    icon="plus"
                    size="small"
                    style={{ margin: "0px 0px 14px 0px" }}
                  >
                    <span></span>
                    <LangContext.Consumer>
                      {(i18n) => i18n.n.new}
                    </LangContext.Consumer>
                  </Button>
                </Link>
              </Col>
              <Col span={1}>
                <Badge dot={this.props.isFilter}>
                  <Button
                    icon="filter"
                    size="small"
                    onClick={(e) => {
                      this.setState({
                        filterVisible: true,
                      });
                    }}
                  >
                    <LangContext.Consumer>
                      {(i18n) => i18n.f.filter}
                    </LangContext.Consumer>
                  </Button>
                </Badge>
                <Filter
                  {...this.state}
                  onClose={() => this.setState({ filterVisible: false })}
                  handleStateChange={this.handleStateChange}
                  onFilterButtonClick={() => {
                    this.props.load({
                      ...this.state,
                      vehicle_visibility: this.props.auth.profile
                        .vehicle_visibility,
                      page: 1,
                    });
                    this.setState({ page: 1 });
                  }}
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Table
                  id="main-table"
                  // showSorterTooltip={false}
                  bordered
                  columns={columns}
                  dataSource={this.props.withdrawfuelcredit.historylist.data}
                  pagination={false}
                  rowKey="phone_number"
                  // scroll={{ y: 500 }}
                />
              </Col>
            </Row>
            <Row type="flex" justify="end" style={{ padding: "15px" }}>
              <Col pan={24} align="right">
                <Pagination
                  defaultPageSize={10}
                  size="small"
                  current={this.state.page}
                  total={this.props.withdrawfuelcredit.historylist.total}
                  onChange={this.handlePageChange}
                />
              </Col>
            </Row>
            <Modal
              // title="Basic Modal"
              visible={this.state.modalVisible}
              onOk={this.cancelJob}
              onCancel={this.hideModal}
              width={840}
              footer={null}
              bodyStyle={{ padding: "45px" }}
              style={{ borderRadius: "0px" }}
            >
              <div id="pdf-content" style={{ marginBottom: "30px" }}>
                <Sheet
                  loading={this.props.withdrawfuelcredit.loading}
                  withdrawInfo={this.props.withdrawfuelcredit.withdrawInfo}
                />
              </div>
              <Button
                onClick={() =>
                  this.generatePDF(
                    this.props.withdrawfuelcredit.withdrawInfo.withdraw_info
                      .code
                  )
                }
                type="button"
                block
              >
                Export PDF
              </Button>
            </Modal>
            <Modal
              // title="Basic Modal"
              visible={this.state.uploadModalVisible}
              onOk={this.cancelJob}
              onCancel={this.hideModal}
              width={840}
              footer={footerModal}
              bodyStyle={{ padding: "45px" }}
              style={{ borderRadius: "0px" }}
            >
              <Row>
                <Col span={24} align="center">
                  {this.generateWithdrawFile()}
                  {/* <embed
                    src={this.props.withdrawfuelcredit.withdrawPath}
                    width="600px"
                    height="800px"
                  /> */}
                  {/* <iframe
                    src="https://terminus-master.sgp1.digitaloceanspaces.com/withdrawfuelcredit/withdrawfuelcredit_23_20230816.pdf?v=155025"
                    style="width:600px; height:500px;"
                    frameborder="0"
                  ></iframe> */}
                </Col>
              </Row>
              <Row>
                <Col span={24} align="center">
                  <Upload
                    name="uploader"
                    multiple={false}
                    showUploadList={false}
                    onChange={(e) => {
                      const file_type = e.file.type;
                      const isLt2M = e.file.size / 1024 <= 512;
                      if (
                        file_type == "image/png" ||
                        file_type == "image/jpeg" ||
                        file_type == "application/pdf"
                      ) {
                        if (!isLt2M) {
                          this.setState({
                            uploader: [],
                            files: [],
                            checkUpload: 1,
                          });

                          message.error("more than 512kb");
                        } else {
                          this.setState({
                            uploader: [e.file],
                          });
                        }
                      } else {
                        this.setState({
                          uploader: [],
                          files: [],
                          checkUpload: 1,
                        });

                        message.error("Only pdf,png");
                      }
                    }}
                    onRemove={(file) => {
                      this.setState({
                        uploader: [],
                        files: [],
                        checkUpload: 1,
                      });
                    }}
                    beforeUpload={(file) => {
                      this.setState({
                        uploader: [],
                        files: [file],
                        checkUpload: 2,
                      });
                      return false;
                    }}
                    fileList={this.state.uploader}
                  >
                    <Button
                      style={{
                        width: "221px",
                        height: "50px",
                        borderRadius: "10px",
                        boxShadow: "0px 0px 10px #00000029",
                        opacity: "1",
                        marginTop: "15px",
                        backgroundColor: "#172B4D",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "14px",
                          color: "#FFFFFF",
                        }}
                      >
                        <LangContext.Consumer>
                          {(i18n) => i18n.s.selectFile}
                        </LangContext.Consumer>
                      </div>
                    </Button>
                  </Upload>
                </Col>
              </Row>
              <Row type="flex" justify="center" align="middle">
                {this.state.uploader.map((v) =>
                  v.url == undefined ? (
                    <div
                      style={{
                        marginTop: "20px",
                        width: "327px",
                        height: "50px",
                        borderRadius: "10px",
                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                        boxShadow: "0px 0px 10px",
                        opacity: "1",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Col span={3} align="center"></Col>
                      <Col span={18} align="center">
                        <div
                          style={{
                            fontSize: "14px",
                            color: "#172B4D",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <Tooltip placement="top" title={v.name}>
                            {v.name}
                          </Tooltip>
                        </div>
                      </Col>
                      <Col span={3} align="center">
                        <a href="javascript:;">
                          <Icon
                            type="delete"
                            onClick={(e) => {
                              this.setState({
                                uploader: [],
                                files: [],
                                checkUpload: 1,
                              });
                            }}
                          />
                        </a>
                      </Col>
                    </div>
                  ) : (
                    <Link
                      to={{
                        pathname: v.url,
                      }}
                      target="_blank"
                    >
                      <div
                        style={{
                          marginTop: "20px",
                          width: "327px",
                          height: "50px",
                          borderRadius: "10px",
                          background: "#FFFFFF 0% 0% no-repeat padding-box",
                          boxShadow: "0px 0px 10px",
                          opacity: "1",
                        }}
                      >
                        <Col span={3} align="center"></Col>
                        <Col span={18} align="left">
                          <div
                            style={{
                              fontSize: "14px",
                              color: "#172B4D",
                              marginTop: "6px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            <Tooltip placement="top" title={v.name}>
                              {v.name}
                            </Tooltip>
                          </div>
                        </Col>
                        <Col span={3} align="center">
                          <a href="javascript:;">
                            <Icon
                              type="delete"
                              style={{ marginTop: "19px" }}
                              onClick={(e) => {
                                this.setState({
                                  uploader: [],
                                  files: [],
                                  checkUpload: 1,
                                });
                              }}
                            />
                          </a>
                        </Col>
                      </div>
                    </Link>
                  )
                )}
              </Row>
            </Modal>
          </GeneralStyledContent>
        </Spin>
      </AuthorizeComponent>
    );
  }
}
const mapStateToProps = ({ auth, auth: { profile }, withdrawfuelcredit }) => ({
  auth,
  profile,
  withdrawfuelcredit,
});

const mapDispatchToProps = {
  load: actions.loadWithdrawFuelCreditHistory.request,
  loadWithdrawInfo: actions.loadWithdrawInfo.request,
  loadPath: actions.loadWithdrawPath.request,
  updatePath: actions.updateWithdrawPath.request,
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);
