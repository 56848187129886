import React, { Component, Fragment } from "react";
import axios from "axios";

import {
  Row,
  Col,
  Input,
  Spin,
  Form,
  Tooltip,
  Select,
  message,
  AutoComplete,
  Button,
} from "antd";
import LangContext, { i18n } from "modules/shared/context/langContext";
import debounce from "lodash/debounce";

const { Option } = Select;
const InputGroup = Input.Group;

export class UniversalSearch extends Component {
  state = {
    placeSearchStr: "",
    isFireReq: false,
    suggestPlace: [],
    locationType: ["Plant", "Site", "Other"],
  };

  handleSearchPlace = (value, options) => {
    this.setState({ placeSearchStr: options.props.title });

    if (
      typeof options.props.lat == "undefined" ||
      typeof options.props.lng == "undefined" ||
      options.props.lat === "" ||
      options.props.lng === ""
    ) {
      message.error("current latitude and longitude not found");
      return false;
    }

    let result = {
      name: options.props.title,
      type: "poi",
      lat: parseFloat(options.props.lat),
      lon: parseFloat(options.props.lng),
    };

    this.props.handleSearchPlace(
      options.props.title,
      options.props.type,
      result
    );
  };

  handleSuggestPlace = (keyword) => {
    if (keyword !== "") {
      this.setState({ placeSearchStr: keyword }, () =>
        this.delaySuggestData(keyword)
      );
    } else {
      this.setState({ placeSearchStr: keyword });
    }
  };

  delaySuggestData = debounce(async (keyword) => {
    this.setState({ suggestPlace: [], isFireReq: true }, () => {
      if (this.props.searchSource === "internet") {
        this.loadDataFromLongdo(keyword);
      } else {
        this.loadDataFromCpac(keyword);
      }
    });
  }, 750);

  loadDataFromCpac = (keyword) => {
    axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/checklocationfortracking`,
        {
          company_id: this.props.auth.profile.company_id,
          location_visibility: this.props.auth.profile.location_visibility,
          limit: 100,
          search_str: keyword,
          location_type: this.props.searchLocationType,
          bounds: [
            {
              lat: this.props.bounds.nw.lat,
              lng: this.props.bounds.nw.lng,
            },
            {
              lat: this.props.bounds.nw.lat,
              lng: this.props.bounds.se.lng,
            },
            {
              lat: this.props.bounds.se.lat,
              lng: this.props.bounds.se.lng,
            },
            {
              lat: this.props.bounds.se.lat,
              lng: this.props.bounds.nw.lng,
            },
          ],
          relate_company: `${this.props.auth.profile.relate_company}`,
        },
        { headers: { Authorization: `Bearer ${this.props.accessToken}` } }
      )
      .then((response) => {
        let items = [];

        if (response.data.length > 0) {
          items.push({ title: "สถานที่ในระบบ", children: [] });
          let idx = items.length - 1;
          let local = [];

          response.data.map((v) => {
            local.push({
              id: v.id,
              name: v.name,
              lat: parseFloat(v.lat),
              lng: parseFloat(v.lng),
              type: "cpac",
            });
          });

          items[idx]["children"].push(...local);
        }

        this.setState({ suggestPlace: items, isFireReq: false });
      });
  };

  loadDataFromLongdo = (keyword) => {
    axios
      .get(
        `https://search.longdo.com/mapsearch/json/search?key=${this.props.mapKey}&keyword=${keyword}`
      )
      .then((res) => {
        let items = [];
        if (res.data.data.length > 0) {
          items.push({ title: "สถานที่จาก Longdo", children: [] });
          let idx = items.length - 1;
          res.data.data.map((v, i) => {
            if (v.type !== "poi") return false;

            items[idx]["children"].push({
              id: i,
              name: v.name,
              lat: v.lat,
              lng: v.lon,
              type: "longdo",
            });
            return true;
          });
          //this.setState({ suggestPlace: items, isFireReq: false })
        }
        this.setState({ suggestPlace: items, isFireReq: false });
      });
  };

  handleSubmitSearch = () => {
    this.props.handleSearchPlace(this.state.placeSearchStr);
  };

  renderOptions = () => {
    return this.state.suggestPlace.map((group) => (
      <AutoComplete.OptGroup key={`${group.title}`} label={group.title}>
        {group.children &&
          group.children.map((val) => (
            <AutoComplete.Option
              title={val.name}
              key={val.id}
              loc_id={val.id}
              lat={val.lat}
              lng={val.lng}
              type={val.type}
            >
              {val.name}
            </AutoComplete.Option>
          ))}
      </AutoComplete.OptGroup>
    ));
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.searchSource !== prevProps.searchSource) {
      this.setState({ suggestPlace: [], placeSearchStr: "" });
    }
  }

  render() {
    return (
      <Fragment>
        <Form.Item
          validateStatus={
            this.state.placeSearchStr.length > 0 &&
            this.state.placeSearchStr.length < 3
              ? "warning"
              : undefined
          }
          help={
            this.state.placeSearchStr.length > 0 &&
            this.state.placeSearchStr.length < 3 ? (
              <div
                style={{
                  fontSize: "11px",
                  position: "absolute",
                  margin: "-2px 25px 0px 0px",
                  padding: "4px 10px 0px 0px",
                  right: "0px",
                  zIndex: "-2",
                  width: "280px",
                  height: "20px",
                  backgroundColor: "#fff",
                  borderRadius: "5px",
                  border: "0px solid #d9d9d9",
                }}
              >
                {this.props.i18n.k.keywordAtleast3character}
              </div>
            ) : (
              undefined
            )
          }
        >
          <InputGroup compact>
            <Tooltip
              placement="left"
              title={
                <LangContext.Consumer>
                  {(i18n) => i18n.d.dataSource}
                </LangContext.Consumer>
              }
              mouseEnterDelay={1.2}
            >
              <Select
                style={{
                  width: "auto",
                  minWidth: "100px",
                }}
                defaultValue="internet"
                onChange={(e) => this.props.handleSearchSourceChange(e)}
              >
                <Option value="internet">{this.props.i18n.m.map}</Option>
                <Option value="master">{this.props.i18n.m.masterData}</Option>
              </Select>
            </Tooltip>

            <AutoComplete
              data-cy="search-place-map"
              className="edge-element"
              value={this.state.placeSearchStr}
              style={{
                width: "190px",
                margin: "0px 0px 0px 0px",
                textAlign: "left",
              }}
              onSelect={this.handleSearchPlace}
              onSearch={this.handleSuggestPlace}
              notFoundContent={
                this.state.isFireReq ? <Spin size="small" /> : null
              }
            >
              {this.renderOptions()}
            </AutoComplete>

            <Button
              type="default"
              icon="search"
              onClick={this.handleSubmitSearch}
            ></Button>
          </InputGroup>
        </Form.Item>
        {this.props.searchSource == "master" && (
          <div className="popup-master-data-tracking">
            <Row>
              <Col sm={24} md={6} lg={6} xl={6}>
                <div
                  style={{
                    margin: "0.625rem 0rem 0rem 0.5rem",
                  }}
                >
                  {this.props.i18n.l.locationType}
                </div>
              </Col>
              <Col sm={24} md={18} lg={18} xl={18}>
                <Select
                  mode="multiple"
                  style={{
                    margin: "4px 4px 0px 5px",
                    width: "95%",
                    maxHeight: "35px",
                  }}
                  placeholder="Please select"
                  defaultValue={["Site", "Plant", "Other"]}
                  onChange={(e) => this.props.handleSearchLocationType(e)}
                  maxTagCount={3}
                >
                  <Option value={"Plant"}>
                    {this.props.i18n.locationType.Plant}
                  </Option>
                  <Option value={"Site"}>
                    {this.props.i18n.locationType.Site}
                  </Option>
                  <Option value={"Other"}>{this.props.i18n.o.other}</Option>
                </Select>
              </Col>
            </Row>
          </div>
        )}
      </Fragment>
    );
  }
}

export default UniversalSearch;
