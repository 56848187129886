import React, { Component, Fragment } from "react";
import { Formik } from "formik";
import { Button, Form, AutoComplete, Spin } from "antd";
import LangContext from "modules/shared/context/langContext";
import ModalDownloadVideoQueue from "./ModalDownloadVideoQueue";
import { getpubliclink } from "./apiClient";
import {
  BREAKPOINTS,
  RESPONSIVE_COMPARE,
  withResponsive,
} from "../../../shared/context/ResponsiveContext";
const FormItem = Form.Item;
const { Option } = AutoComplete;
class FilterAllDataTab extends Component {
  state = {
    platenolists: [],
    downloadVideoVisible: false,
    code: "",
    expireDate: "",
    disableBtn: true,
  };

  handleCancel = () => {
    this.setState({ downloadVideoVisible: false });
  };

  handleOk = () => {
    this.handleCancel();
  };

  changeVehiclePlateno = (value, option, setFieldValue) => {
    setFieldValue("plate_no", value);
    setFieldValue("vehicle_id", "");
    this.setState({ disableBtn: true });
  };

  selectVehiclePlateno = (value, option, setFieldValue) => {
    // console.log("selectVehiclePlateno", value, option, setFieldValue);
    setFieldValue("plate_no", option.props.data_value);
    setFieldValue("vehicle_id", value);
    this.setState({ disableBtn: true });
  };

  convertToBoolean = (value) => {
    if (value === "true") {
      return true;
    }

    if (value === "false") {
      return false;
    }

    return value;
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.dataSource !== prevProps.dataSource ||
      this.props.vehicleId !== prevProps.vehicleId
    ) {
      if (this.props.dataSource > 0 && this.props.vehicleId !== "") {
        this.setState({ disableBtn: false });
      } else {
        this.setState({ disableBtn: true });
      }
    }
  }

  componentDidMount() {
    if (this.props.dataSource > 0 && this.props.vehicleId !== "") {
      this.setState({ disableBtn: false });
    }
  }

  render() {
    const { onSubmit, vehicleLists, loadVehicle, isBreakpoint } = this.props;
    const platenolists = vehicleLists
      .filter((x) => x.title !== "fileopt")
      .flatMap((group) =>
        group.children.map((opt) => {
          return (
            <Option
              key={opt.id}
              label={opt.imei}
              data_value={opt.plate_no}
              data_format={opt.old_format}
              data_otp={opt.has_file_opt.toString()}
              data_type={opt.device_types_id}
            >
              {opt.plate_no}
            </Option>
          );
        })
      );

    const isMobile = isBreakpoint(RESPONSIVE_COMPARE.DOWN, BREAKPOINTS.XS);

    return (
      <Fragment>
        <Formik
          enableReinitialize={true}
          initialValues={{
            plate_no: this.props.plateNo,
            vehicle_id: this.props.vehicleId,
          }}
          onSubmit={(values) => {
            onSubmit(values.vehicle_id, values.plate_no);
          }}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            handleFocus,
            setFieldValue,
            resetForm,
          }) => {
            return (
              <Form onSubmit={handleSubmit} layout={"inline"}>
                <FormItem
                  label={
                    <LangContext.Consumer>
                      {(i18n) => i18n.p.plateNo}
                    </LangContext.Consumer>
                  }
                  style={{
                    ...(isMobile && {
                      width: "100%",
                      marginTop: "8px",
                    }),
                  }}
                >
                  <AutoComplete
                    dataSource={platenolists}
                    style={{ width: "100%" }}
                    optionLabelProp="children"
                    filterOption={(inputValue, option) => {
                      if (typeof option.props.children !== "undefined") {
                        return (
                          option.props.children
                            .toLowerCase()
                            .indexOf(inputValue.toLowerCase()) !== -1
                        );
                      }
                      return false;
                    }}
                    optionFilterProp="children"
                    // placeholder="ค้นหาทะเบียน"
                    value={values.plate_no}
                    onFocus={this.props.loadData}
                    onSearch={(value, option) => {
                      this.changeVehiclePlateno(value, option, setFieldValue);
                    }}
                    onSelect={(value, option) =>
                      this.selectVehiclePlateno(value, option, setFieldValue)
                    }
                    notFoundContent={loadVehicle ? <Spin size="small" /> : null}
                  />
                </FormItem>
                <FormItem
                  style={{
                    ...(isMobile && {
                      width: "100%",
                      marginTop: "8px",
                    }),
                  }}
                >
                  <Button
                    htmlType="submit"
                    type="primary"
                    style={{ width: "100%" }}
                  >
                    <LangContext.Consumer>
                      {(i18n) => i18n.a.apply}
                    </LangContext.Consumer>
                  </Button>
                </FormItem>

                <FormItem
                  style={{
                    ...(isMobile && {
                      width: "100%",
                      marginTop: "8px",
                    }),
                  }}
                >
                  <Button
                    style={{ width: "100%" }}
                    onClick={() => {
                      getpubliclink(
                        {
                          vehicle_id: values.vehicle_id,
                          user_id: this.props.userId,
                        },
                        (status, res) => {
                          this.setState({
                            downloadVideoVisible: true,
                            code: res.data.data.hash_id,
                            expireDate: res.data.data.expired_at,
                          });
                        }
                      );
                    }}
                    type="primary"
                    disabled={this.state.disableBtn}
                  >
                    <LangContext.Consumer>
                      {(i18n) => i18n.c.createLink}
                    </LangContext.Consumer>
                  </Button>
                </FormItem>

                <ModalDownloadVideoQueue
                  handleOk={this.handleOk}
                  handleCancel={this.handleCancel}
                  downloadVideoVisible={this.state.downloadVideoVisible}
                  code={this.state.code}
                  expireDate={this.state.expireDate}
                />
              </Form>
            );
          }}
        />
      </Fragment>
    );
  }
}

export default withResponsive(FilterAllDataTab);
