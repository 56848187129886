// Libraries
import React, { Component } from "react";
import { connect } from "react-redux";
import LangContext, { i18n } from "modules/shared/context/langContext";
import { Icon, Tooltip, Checkbox, Popover, Button, Divider, Empty } from "antd";

class LocationTypeDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.disabled !== this.props.disabled && this.props.disabled) {
      this.props.handleChangeVisibleFilterLocationType(
        this.props.disabled,
        false
      );
    }
  }

  render() {
    const {
      classNames,
      styles,
      disabled,
      locationTypeList,

      indeterminate,
      checkAll,
      filter_location_type,

      onCheckAllFilterLocationType,
      onCheckFilterLocationType,

      visibleFilterLocationType,
      handleChangeVisibleFilterLocationType,

      showLocationName,
      onCheckShowLocationName,
    } = this.props;

    return (
      <Popover
        content={
          <>
            {// ถ้าไม่มี locationTypeList ให้แสดง Loading
            locationTypeList.length === 0 ? (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            ) : (
              <div className="checkbox-list-style">
                {/* เลือกทั้งหมด */}
                <div>
                  <Tooltip
                    placement="left"
                    title={i18n[this.props.language].s.selectAll}
                  >
                    <Checkbox
                      indeterminate={indeterminate}
                      onChange={(e) => {
                        onCheckAllFilterLocationType(e.target.checked);
                      }}
                      checked={checkAll}
                    >
                      <span>{i18n[this.props.language].s.selectAll}</span>
                    </Checkbox>
                  </Tooltip>
                </div>

                {/* ประเภทสถานที่ */}
                {locationTypeList.map((item) => {
                  return (
                    <div>
                      <Tooltip
                        placement="left"
                        title={i18n[this.props.language].locationType[item]}
                      >
                        <Checkbox
                          value={item}
                          checked={filter_location_type.includes(item)}
                          onChange={(e) => {
                            onCheckFilterLocationType(e.target.checked, item);
                          }}
                        >
                          <span>
                            {i18n[this.props.language].locationType[item]}
                          </span>
                        </Checkbox>
                      </Tooltip>
                    </div>
                  );
                })}

                <Divider />
                {/* แสดงชื่อสถานที่ */}
                <div>
                  <Tooltip
                    placement="left"
                    title={i18n[this.props.language].s.showLocationName}
                  >
                    <Checkbox
                      value={showLocationName}
                      checked={showLocationName}
                      onChange={(e) => {
                        onCheckShowLocationName(e.target.checked);
                      }}
                    >
                      <span>
                        {i18n[this.props.language].s.showLocationName}
                      </span>
                    </Checkbox>
                  </Tooltip>
                </div>
              </div>
            )}
          </>
        }
        title={`${i18n[this.props.language].f.filterLocationType}`}
        trigger="click"
        placement="leftBottom"
        overlayClassName="filter-popover-style"
        visible={visibleFilterLocationType}
        onVisibleChange={(visible) => {
          handleChangeVisibleFilterLocationType(disabled, visible);
        }}
      >
        <Tooltip
          placement="left"
          title={i18n[this.props.language].f.filterLocationType}
        >
          <Button className={classNames} style={styles} disabled={disabled}>
            <Icon type="dot-chart" />
          </Button>
        </Tooltip>
      </Popover>
    );
  }
}

export default connect(
  ({ ui: { language } }) => ({
    language,
  }),
  {}
)(LocationTypeDropdown);
