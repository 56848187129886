import React from "react";
import { Row, Col } from "antd";

const VehicleMarker = ({
  vehicleImg,
  stopImg,
  title,
  lat,
  lng,
  engine,
  speed,
  vehicleStatus,
  directionDegree,
  onClick,
  checkLengthTitle
}) => {
  // find vehicle status color

  let width = "90px";
  if (typeof title != "undefined") {
    let titleLength = title.length;
    if (typeof checkLengthTitle !== "undefined") {
      titleLength = checkLengthTitle.length;
    }


    if (titleLength >= 12 && titleLength < 17) width = "90px";
    else if (titleLength >= 17 && titleLength < 22) width = "120px";
    else if (titleLength >= 22 && titleLength < 27) width = "130px";
    else if (titleLength >= 27 && titleLength < 37) width = "140px";
    else if (titleLength >= 37) width = "150px"//width = "195px";
  }

  let color = "";
  if (vehicleStatus == "running") {
    color = "#00BF06";
  } else if (vehicleStatus == "stop-with-engine-on") {
    color = "#e6e600";
  } else if (vehicleStatus == "stop-with-engine-off") {
    color = "#F10000";
  } else if (vehicleStatus == "connection-error") {
    color = "#848484";
  }
  let imgUrl = "";

  if (
    directionDegree > -1 &&
    (speed == undefined || (speed != undefined && Number(speed) > 2.7))
  ) {
    var min = directionDegree * 25 * -1;
    var max = min + 25;

    imgUrl = `url('${vehicleImg}') no-repeat scroll  ${min}px 0px transparent`;
  } else {
    var min = 0;
    var max = min + 25;
    imgUrl = `url('${stopImg}') no-repeat scroll  ${min}px 0px transparent`;
  }

  return (
    <div onClick={onClick}>
      <div>
        {typeof title != "undefined" && (
          <div
            style={{
              zIndex: 50,
              minWidth: "90px",
              width: width,
              maxWidth: "130px",
              borderRadius: "1px",
              // padding: "4px 2px 4px 0px",
              padding: '4px',
              display: "inline-flex",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
              transform: "translate(-50%, -180%)",
              color: "#fff",
              backgroundColor: "#3F87E4 ",
              cursor: "pointer",
              margin: " 0 1em 1em 0",
              borderBottomLeftRadius: "90px",
              borderTopLeftRadius: "90px",
              borderLeft: `11px solid ${color}`,
            }}
          >
            <span>
              {/* <div>{title}</div> */}
              <div dangerouslySetInnerHTML={{ __html: title }}></div>
            </span>
          </div>
        )}
      </div>
      <div>
        <div
          style={{
            height: "25px",
            width: "25px",
            marginLeft: "4px",
            top: "0",
            position: "absolute",
            background: imgUrl,
            zIndex: 50,
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
            transform: "translate(-50%, -50%)",
            cursor: "pointer",
          }}
        // onClick={onClick}
        />
      </div>
    </div>
  );
};

export default VehicleMarker;
