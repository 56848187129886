// Setting.js
import React, { Component } from "react";
import styled from "styled-components";
import { Divider, Icon } from "antd";

const SettingBlock = styled.div`
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
  transition: background-color 0.3s; /* Add a smooth transition effect */

  &.active-setting {
    color: #1890ff;
  }

  span {
    padding-left: 5px;
    padding-right: 5px;
    font-weight: bold;
    font-size: 14px;
  }
`;

const Text = styled.div`
  margin-left: 20px;
  padding-bottom: 5px;
  transition: color 0.3s;

  &.active-text {
    color: #74bcff;
  }
`;

const items = [
  {
    id: "0",
    name: "จัดการข้อมูลจากการรูดใบขับขี่",
    description:
      "ตั้งค่าเพื่อให้ระบบนำข้อมูลที่ได้จากการรูดใบขับขี่ของพนักงานขับขี่ มาใช้ช่วยเหลือในการจัดการข้อมูล",
    icon: <Icon type="smile" style={{ padding: "2px" }} />,
  },
  {
    id: "1",
    name: "แจ้งเตือนใบขับขี่หมดอายุ",
    description:
      "ตั้งค่าเพื่อให้ระบบทำการแจ้งเตือนใบขับขี่หมดอายุ โดยจะตรวจจับเมื่อผู้ขับขี่ทำการรูดใบขับขี่",
    icon: <Icon type="smile" style={{ padding: "2px" }} />,
  },
  {
    id: "2",
    name: "แจ้งเตือนภาษี พรบ ประกัน",
    description:
      "ตั้งค่าเพื่อให้ระบบทำการแจ้งเตือนเอกสารต่างๆ โดยการแจ้งเตือนจะมี แจ้งเตือนพรบ.หมดอายุ, ภาษี, ประกัน",
    icon: <Icon type="smile" style={{ padding: "2px" }} />,
  },
  {
    id: "3",
    name: "แจ้งเตือนเติมน้ำมัน และ ค่าน้ำมันผิดปกติ",
    description:
      "ตั้งค่าเพื่อให้ระบบทำการแจ้งเตือนค่าน้ำมัน โดยการแจ้งเตือนจะมี แจ้งเตือนเติมน้ำมันและแจ้งเตือนค่าน้ำมันผิดปกติ",
    icon: <Icon type="smile" style={{ padding: "2px" }} />,
  },
  {
    id: "4",
    name: "แจ้งเตือนเหตุการณ์ ADAS+DMS ",
    description: "ตั้งค่าเพื่อให้ระบบทำการแจ้งเตือนขับรถไม่ปลอดภัย  ",
    icon: <Icon type="smile" style={{ padding: "2px" }} />,
  },
  {
    id: "8",
    name: "แจ้งเตือนไม่รูดใบขับขี่",
    description:
      "ตั้งค่าเพื่อให้ระบบทำการแจ้งเตือนเมื่อพนักงานขับขี่ไม่รูดใบขับขี่",
    icon: <Icon type="smile" style={{ padding: "2px" }} />,
  },
];

class SettingComponent extends Component {
  render() {
    const { onSettingClick, defaultSelectedSetting } = this.props;

    return (
      <div>
        {items.map((item) => (
          <div key={item.id}>
            <SettingBlock
              onClick={() => onSettingClick(item.id)}
              className={
                defaultSelectedSetting === item.id ? "active-setting" : ""
              }
            >
              {item.icon}
              <span>{item.name}</span>
              <Text
                className={
                  defaultSelectedSetting === item.id ? "active-text" : ""
                }
              >
                {item.description}
              </Text>
            </SettingBlock>

            <Divider style={{ margin: "10px" }} />
          </div>
        ))}
      </div>
    );
  }
}

export default SettingComponent;
