import React, { Component } from 'react'
import { Row, Col, Tooltip } from 'antd'

class IconWithShowTootip extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      title,
      lat,
      lng,
      zoomLevel,
      image,
      isshowetitle,
      alwaysShowTooltip,
    } = this.props

    return (
      <Row>
        <Col span={24}>
          {isshowetitle ? (
            alwaysShowTooltip ? (
              <div className='icon-with-show-tooltip'>
                <div className='icon-with-show-tooltip-title'>
                  <span>{title}</span>
                </div>
                <div className='icon-with-show-tooltip-title-arrow ' />
                <div className='icon-with-show-tooltip-image'>
                  <img
                    src={image}
                    style={{
                      zIndex: 1,
                    }}
                  />
                </div>
              </div>
            ) : (
              <Tooltip
                title={title}
                placement='top'
                arrowPointAtCenter
                mouseEnterDelay={0.5}
              >
                <div className='icon-with-show-tooltip-image'>
                  <img
                    src={image}
                    style={{
                      zIndex: 1,
                    }}
                  />
                </div>
              </Tooltip>
            )
          ) : (
            <div className='icon-with-show-tooltip-image'>
              <img
                src={image}
                style={{
                  zIndex: 1,
                }}
              />
            </div>
          )}
        </Col>
      </Row>
    )
  }
}

export default IconWithShowTootip
