import React, { useEffect, useState, useRef } from "react";
import {
  Row,
  Col,
  Icon,
  Table,
  Spin,
  DatePicker,
  Select,
  Empty,
  Popover,
  Tree,
} from "antd";
import LangContext, { i18n } from "modules/shared/context/langContext";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";
import { connect } from "react-redux";
import CustomTooltip from "./CustomTooltip";
import { DataDetail, icons, searchTree } from "./Constant";
import { DashboardAdasDetail } from "../../../../styled/common-styled";
import { ReactComponent as PlayIcon } from "../Icon/VideoIcon/Play.svg";
import _ from "lodash"; // For debouncing
import "../styles/LazyLoadTable.css";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import {
  loadDetail,
  getPhoneNumber,
  loadDetailTable,
  loadEvent,
  createAxios,
} from "../fetchAPI/apiClient";
import moment from "moment";
import { TableStyled } from "./StyleComponent";

const { Option } = Select;
const { RangePicker } = DatePicker;
const { TreeNode } = Tree;

const Detail = (props) => {
  const [tableData, setTableData] = useState([]); // Data for the table
  const [URLReactPlayer, setURLReactPlayer] = useState(null);
  const [loading, setLoading] = useState(false); // Loading state
  const [pageloading, setPageloading] = useState(false);
  const [hasMore, setHasMore] = useState(true); // To track if more data can be loaded
  const [page, setPage] = useState(1); // Current page number
  const pageSize = 10; // Number of records per page
  const [type, setType] = useState([]);

  const [vehicleDetail, setVehicleDetail] = useState({
    plate_no: "-",
    driver_name: "-",
    driver_license_number: "-",
    vehicle_code: "-",
    vehicle_model: "-",
    engine_status: "-",
    distance_time: {
      distance: 0,
      time: 0,
    },
    phone: "-",
    caution: "-",
    parking: "-",
    restingStatus: "-",
    address: "-",
    lat: "-",
    lng: "-",
    vehicle_id: "-",
    data_graph: [],
    reference_noti: [],
    starAt: "",
    endAt: "",
  });
  const [total, setTotal] = useState(0);
  const [duration, setDuration] = useState(undefined);
  const containerRef = useRef(null);

  useEffect(() => {
    if (page >= 2) {
      fetchData(page, duration, type);
    }
  }, [page]);

  useEffect(() => {
    createAxios(props.auth.accessToken);
    const matchingKeyGroups = findMatchingKeyGroups(
      searchTree,
      props.match.params.typeId.split(",")
    );
    setType(matchingKeyGroups);
    setDuration(props.match.params.duration_id);
    setPageloading(true);
    loadDetail(
      props.match.params.vehicle_id,
      {
        user_id: props.auth.profile.id,
        time_range:
          duration === undefined
            ? parseInt(props.match.params.duration_id)
            : parseInt(duration),
        event: matchingKeyGroups,
        date_start: moment().format("YYYY-MM-DD 00:00:00"),
        date_end: moment().format("YYYY-MM-DD 23:59:59"),
        vehicle_visibility: props.auth.profile.vehicle_visibility,
        company_id: props.auth.profile.company_id,
      },

      (status, res) => {
        let {
          plate_no,
          driver_name,
          driver_license_number,
          vehicle_code,
          vehicle_model,
          engine_status,
          distance_time,
          caution,
          parking,
          restingStatus,
          address,
          lat,
          lng,
          vehicle_id,
          data_graph,
          reference_noti,
          startAt,
          endAt,
        } = res.data.data;

        getPhoneNumber(
          { imei: props.match.params.hardware_id },
          (statusCode, response) => {
            setVehicleDetail({
              plate_no,
              driver_name,
              driver_license_number,
              vehicle_code,
              vehicle_model,
              engine_status,
              distance_time,
              phone: response.data.phone_no,
              caution,
              parking,
              restingStatus,
              address,
              lat,
              lng,
              vehicle_id,
              data_graph,
              reference_noti,
              startAt,
              endAt,
            });
            setPageloading(false);
          }
        );
      }
    );
    fetchData(1, props.match.params.duration_id, matchingKeyGroups);
    getEvent();
  }, [
    props.match.params.duration_id,
    props.match.params.vehicle_id,
    props.match.params.hardware_id,
    props.match.params.typeId,
  ]);

  function findMatchingKeyGroups(tree, keysToFind) {
    const result = [];

    function traverse(node) {
      if (node.subTreeNode) {
        node.subTreeNode.forEach((subNode) => traverse(subNode));
      }

      if (node.key) {
        const keyArray = String(node.key).split(","); // Convert key to string and split
        const hasMatchingKey = keyArray.some((key) => keysToFind.includes(key));

        if (hasMatchingKey) {
          result.push(node.key); // Add the whole key group if any key matches
        }
      }
    }

    if (tree.treeNode) {
      tree.treeNode.forEach((node) => traverse(node));
    }

    return result;
  }

  const fetchData = async (page, durationId, type) => {
    if (loading) return;

    setLoading(true);
    loadDetailTable(
      props.match.params.vehicle_id,
      {
        user_id: props.auth.profile.id,
        time_range:
          duration === undefined
            ? parseInt(props.match.params.duration_id)
            : parseInt(durationId),
        event: type,
        date_start: moment().format("YYYY-MM-DD 00:00:00"),
        date_end: moment().format("YYYY-MM-DD 23:59:59"),
        vehicle_visibility: props.auth.profile.vehicle_visibility,
        company_id: props.auth.profile.company_id,
        page: page,
      },
      (status, res) => {
        if (res.data.eventData.length !== 0) {
          const total = res.data.total; // Simulate total items in the dataset
          setTotal(res.data.total);
          const startIndex = (page - 1) * pageSize;
          const endIndex = startIndex + pageSize;

          if (startIndex >= total) {
            setHasMore(false);
            setLoading(false);
            return;
          }

          setTableData((prev) => [...prev, ...res.data.eventData]);
        }

        setLoading(false);
      }
    );
  };

  const handleScroll = (e) => {
    if (loading) return;

    const { scrollTop, scrollHeight, clientHeight } = e.target;

    if (
      scrollTop + clientHeight >= scrollHeight - 10 &&
      hasMore &&
      !loading &&
      tableData.length != total
    ) {
      setPage((prev) => prev + 1);
    }
  };

  const handleVisibleVideo = (url) => {
    setURLReactPlayer(url);
    // Add your logic to play the video here
  };

  const findNamesByKeys = (tree, keys) => {
    const result = [];

    const searchNode = (node) => {
      if (keys.includes(node.key.toString())) {
        result.push(icons[node.name]["label"]);
      }

      if (node.treeNode) {
        node.treeNode.forEach(searchNode);
      }
      if (node.subTreeNode) {
        node.subTreeNode.forEach(searchNode);
      }
    };

    searchNode(tree);
    if (keys.includes("0") || (result.length === 0 && keys.length !== 0)) {
      return "เหตุการณ์ทั้งหมด";
    } else if (
      keys.includes("600,601") &&
      keys.includes("602,603,608,609") &&
      keys.includes("606,607") &&
      keys.includes("618,619") &&
      keys.includes("620,621") &&
      keys.includes("624,625") &&
      keys.includes("622,623") &&
      keys.includes("626,627") &&
      keys.includes("704,705")
    ) {
      return "เหตุการณ์ทั้งหมด";
    } else if (keys.length === 0) {
      return "ไม่ได้เลือกเหตุการณ์";
    } else {
      return `(${result.join(",")})`;
    }
  };

  const extractKeys = (data) => {
    // Create a new array to store the keys
    const keys = [];

    // Iterate over the treeNode array
    data.treeNode.forEach((node) => {
      // If subTreeNode exists, iterate through and extract keys
      if (node.subTreeNode) {
        node.subTreeNode.forEach((subNode) => {
          keys.push(subNode.key);
        });
      }
    });

    // Return the keys array
    return keys;
  };

  const getEvent = () => {
    loadEvent({}, (statusCode, response) => {
      let newType = extractKeys(response.data.data).filter(
        (item) => !["0", "1", "5"].includes(item)
      );
    });
  };

  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      align: "center",
      width: 50,
    },
    {
      title: "ประเภท",
      dataIndex: "type",
      key: "type",
      align: "center",
      width: 100,
    },
    {
      title: "ประเภทการแจ้งเตือน",
      dataIndex: "noti_type",
      key: "noti_type",
      align: "center",
      render: (text, record) => {
        return icons[text]["label"];
      },
    },
    {
      title: "แจ้งเตือนล่าสุด",
      dataIndex: "latest_noti",
      key: "latest_noti",
      align: "center",
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      align: "center",
      width: 100,
      render: (text, record, index) => {
        return (
          <Row>
            <Col span={24}>
              {!!record.url ? (
                <div
                  onClick={() => {
                    handleVisibleVideo(record.url);
                  }}
                >
                  <Icon
                    type="play-circle"
                    style={{ fontSize: 16, cursor: "pointer", color: "black" }}
                  />
                </div>
              ) : null}
            </Col>
          </Row>
        );
      },
    },
  ];

  return (
    <Spin spinning={pageloading}>
      <DashboardAdasDetail>
        <Row
          style={{ height: 60 }}
          gutter={[8, 8]}
          type="flex"
          justify="space-around"
          align="middle"
        >
          <Col span={12}>
            <strong style={{ fontSize: 20, fontWeight: "bolder" }}>
              ข้อมูลรถ
            </strong>
          </Col>
          {duration == "3" && (
            <Col span={6} align="right">
              <RangePicker
                showTime
                style={{ width: "100%", marginRight: 10, textAlign: "center" }}
                size="large"
                className="dashboard__adas_range_datepicker"
                onChange={(value) => {}}
              />
            </Col>
          )}

          <Col span={duration == "3" ? 2 : 8} align="right">
            <Select
              className="dashboard__adas_selected"
              value={duration}
              style={{ width: 200 }}
              size="large"
              onChange={(e) => {
                setPageloading(true);
                setPage(1);
                setDuration(e);
                setTableData([]);
                setURLReactPlayer(null);
                fetchData(1, e, type);
                loadDetail(
                  props.match.params.vehicle_id,
                  {
                    user_id: props.auth.profile.id,
                    time_range: parseInt(e),
                    date_start: moment().format("YYYY-MM-DD 00:00:00"),
                    date_end: moment().format("YYYY-MM-DD 23:59:59"),
                    event: type,
                    vehicle_visibility: props.auth.profile.vehicle_visibility,
                    company_id: props.auth.profile.company_id,
                  },
                  (status, res) => {
                    let {
                      plate_no,
                      driver_name,
                      driver_license_number,
                      vehicle_code,
                      vehicle_model,
                      engine_status,
                      distance_time,
                      caution,
                      parking,
                      restingStatus,
                      address,
                      lat,
                      lng,
                      vehicle_id,
                      data_graph,
                      reference_noti,
                      startAt,
                      endAt,
                    } = res.data.data;

                    setVehicleDetail((prev) => {
                      return {
                        ...prev,
                        plate_no,
                        driver_name,
                        driver_license_number,
                        vehicle_code,
                        vehicle_model,
                        engine_status,
                        distance_time,
                        caution,
                        parking,
                        restingStatus,
                        address,
                        lat,
                        lng,
                        vehicle_id,
                        data_graph,
                        reference_noti,
                        startAt,
                        endAt,
                      };
                    });
                    setPageloading(false);
                  }
                );
              }}
            >
              <Option value={"0"}>ขับต่อเนื่อง</Option>
              <Option value={"1"}>3 ชั่วโมง</Option>
              <Option value={"2"}>24 ชั่วโมง</Option>
              {/* <Option value={"3"}>กำหนดเอง</Option> */}
              <Option value={"4"}>วันนี้</Option>
            </Select>
          </Col>
          <Col span={4} align="right">
            <Popover
              placement="bottom"
              title={null}
              content={
                <div style={{ width: 300, borderRadius: 10, minHeight: 200 }}>
                  <Tree
                    checkable
                    defaultExpandedKeys={[0, 1, 5]}
                    defaultCheckedKeys={type}
                    onCheck={(checkedKeys, info) => {
                      let newType = checkedKeys.filter(
                        (item) => !["0", "1", "5"].includes(item)
                      );
                      setType(newType);
                      setPageloading(true);
                      setPage(1);
                      setTableData([]);
                      setURLReactPlayer(null);
                      fetchData(1, duration, newType);
                      loadDetail(
                        props.match.params.vehicle_id,
                        {
                          user_id: props.auth.profile.id,
                          time_range: parseInt(duration),
                          date_start: moment().format("YYYY-MM-DD 00:00:00"),
                          date_end: moment().format("YYYY-MM-DD 23:59:59"),
                          event: newType,
                          vehicle_visibility:
                            props.auth.profile.vehicle_visibility,
                          company_id: props.auth.profile.company_id,
                        },
                        (status, res) => {
                          let {
                            plate_no,
                            driver_name,
                            driver_license_number,
                            vehicle_code,
                            vehicle_model,
                            engine_status,
                            distance_time,
                            caution,
                            parking,
                            restingStatus,
                            address,
                            lat,
                            lng,
                            vehicle_id,
                            data_graph,
                            reference_noti,
                            startAt,
                            endAt,
                          } = res.data.data;

                          setVehicleDetail((prev) => {
                            return {
                              ...prev,
                              plate_no,
                              driver_name,
                              driver_license_number,
                              vehicle_code,
                              vehicle_model,
                              engine_status,
                              distance_time,
                              caution,
                              parking,
                              restingStatus,
                              address,
                              lat,
                              lng,
                              vehicle_id,
                              data_graph,
                              reference_noti,
                              startAt,
                              endAt,
                            };
                          });
                          setPageloading(false);
                        }
                      );
                    }}
                  >
                    <TreeNode
                      title={searchTree["name"]}
                      key={searchTree["key"]}
                      selectable={false}
                    >
                      {searchTree["treeNode"].map((val) => (
                        <TreeNode
                          title={val["name"]}
                          key={val["key"]}
                          selectable={false}
                        >
                          {val["subTreeNode"].map((subVal) => (
                            <TreeNode
                              title={icons[subVal["name"]]["label"]}
                              key={subVal["key"]}
                              selectable={false}
                            />
                          ))}
                        </TreeNode>
                      ))}
                    </TreeNode>
                  </Tree>
                </div>
              }
              trigger="hover"
            >
              <Select
                className="dashboard__adas_selected"
                defaultValue="0"
                style={{ width: "100%" }}
                size="large"
                open={false}
              >
                <Option value="0">{findNamesByKeys(searchTree, type)}</Option>
              </Select>
            </Popover>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <div
              style={{
                padding: 30,
                borderRadius: 32,
                backgroundColor: "white",
                height: 355,
              }}
            >
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <label style={{ fontSize: 18, fontWeight: "bold" }}>
                    {vehicleDetail.plate_no}
                  </label>
                </Col>
                <Col span={12}>
                  <span>
                    <label>
                      <LangContext.Consumer>
                        {(i18n) => i18n.d.driverName}
                      </LangContext.Consumer>
                      {" : "}
                    </label>
                    <label>{vehicleDetail.driver_name}</label>
                  </span>
                </Col>
                <Col span={12}>
                  <span>
                    <label>
                      <LangContext.Consumer>
                        {(i18n) => i18n.c.cardId}
                      </LangContext.Consumer>
                    </label>
                    <label>{vehicleDetail.driver_license_number}</label>
                  </span>
                </Col>

                <Col span={12}>
                  <span>
                    <label>
                      <LangContext.Consumer>
                        {(i18n) => i18n.e.engineStatus}
                      </LangContext.Consumer>
                      {" : "}
                    </label>
                    <label>{vehicleDetail.engine_status}</label>
                  </span>
                </Col>
                <Col span={12}>
                  <span>
                    <label>
                      <LangContext.Consumer>
                        {(i18n) => i18n.p.phoneNumber}
                      </LangContext.Consumer>
                      {" (ของอุปกรณ์) : "}
                    </label>
                    <label>{vehicleDetail.phone}</label>
                  </span>
                </Col>
                <Col span={12}>
                  <span>
                    <label>
                      <LangContext.Consumer>
                        {(i18n) => i18n.d.distance}
                      </LangContext.Consumer>
                      &
                      <LangContext.Consumer>
                        {(i18n) => i18n.t.time}
                      </LangContext.Consumer>
                      {` (วิ่งต่อเนื่อง)`}
                      {" : "}
                    </label>
                    <label>
                      {vehicleDetail.distance_time.distance.toFixed(2)} กม.{" "}
                      {`(${vehicleDetail.distance_time.time.toFixed(0)} นาที)`}
                    </label>
                  </span>
                </Col>
                <Col span={24}>
                  การขับต่อเนื่อง เริ่มต้น/สิ้นสุด:{" "}
                  {`${vehicleDetail.startAt} - ${vehicleDetail.endAt}`}
                </Col>
                <Col span={24}>
                  Tracking:{"   "}
                  <Link
                    to={`/tracking/vehicle/${props.match.params.vehicle_id}/${props.auth.profile.company_id}`}
                    style={{ cursor: "pointer" }}
                    target="_blank"
                  >
                    {icons["trackingReport"].icon({
                      fill: "black",
                    })}
                  </Link>
                </Col>
                <Col span={24}>
                  Video Real Time:{"   "}
                  <Link
                    to={`/livestream?imei=${props.match.params.hardware_id}&vehicle_id=${props.match.params.vehicle_id}`}
                    style={{ cursor: "pointer" }}
                    target="_blank"
                  >
                    {icons["videoRealTimeReport"].icon({
                      fill: "black",
                    })}
                  </Link>
                </Col>
                <Col span={24}>
                  <span>
                    <label>
                      <LangContext.Consumer>
                        {(i18n) => i18n.a.address}
                      </LangContext.Consumer>
                      {" : "}
                    </label>
                    {/* <Link
                      to={`/tracking/vehicle/${props.match.params.vehicle_id}/${props.auth.profile.company_id}`}
                      style={{ textDecoration: "underline", color: "blue" }}
                    > */}
                    {vehicleDetail.lat}, {vehicleDetail.lng}
                    {/* </Link> */}
                  </span>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={12}>
            <div
              style={{
                padding: 30,
                borderRadius: 32,
                backgroundColor: "white",
                height: 355,
              }}
            >
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  width={500}
                  height={400}
                  data={vehicleDetail.data_graph}
                  margin={{
                    top: 0,
                    right: 0,
                    left: -20,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" minTickGap={100} />
                  <YAxis />
                  <Tooltip content={<CustomTooltip />} />
                  <Area
                    type="monotone"
                    dataKey="vehicleCloseToTheFront"
                    stackId="1"
                    stroke="#8884d8"
                    fill="#8884d8"
                  />
                  <Area
                    type="monotone"
                    dataKey="straddlingLanesSuddenlyLeavingLanes"
                    stackId="1"
                    stroke="#82ca9d"
                    fill="#82ca9d"
                  />
                  <Area
                    type="monotone"
                    dataKey="nearPedestrians"
                    stackId="1"
                    stroke="#ffc658"
                    fill="#ffc658"
                  />
                  <Area
                    type="monotone"
                    dataKey="sleepyYawning"
                    stackId="1"
                    stroke="#ff8042"
                    fill="#ff8042"
                  />
                  <Area
                    type="monotone"
                    dataKey="doNotLookAtTheRoad"
                    stackId="1"
                    stroke="#8dd1e1"
                    fill="#8dd1e1"
                  />
                  <Area
                    type="monotone"
                    dataKey="smoke"
                    stackId="1"
                    stroke="#d0ed57"
                    fill="#d0ed57"
                  />
                  <Area
                    type="monotone"
                    dataKey="notFoundDriver"
                    stackId="1"
                    stroke="#a4de6c"
                    fill="#a4de6c"
                  />
                  <Area
                    type="monotone"
                    dataKey="cameraBlocked"
                    stackId="1"
                    stroke="#d8867d"
                    fill="#d8867d"
                  />
                  {vehicleDetail.reference_noti.map((item) => (
                    <ReferenceLine x={item} stroke="black" strokeWidth={2} />
                  ))}
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </Col>
          <Col span={12}>
            <div
              style={{
                padding: "0px",
                borderRadius: 32,
                backgroundColor: "white",
                height: 355,
              }}
            >
              <div
                style={{ overflow: "auto" }}
                onScroll={handleScroll}
                ref={containerRef}
              >
                <Spin spinning={loading && page === 1}>
                  {tableData.length > 0 ? (
                    <TableStyled
                      className="custom-table" // Add custom className
                      size="large"
                      columns={columns}
                      style={{ borderRadius: 32 }}
                      dataSource={tableData}
                      pagination={false} // Disable default pagination
                      scroll={{ y: 280 }} // Ensure the table is scrollable
                    />
                  ) : (
                    <Empty
                      style={{
                        marginTop: 100,
                      }}
                    />
                  )}
                  {loading && page > 1 && (
                    <div style={{ textAlign: "center", marginTop: 10 }}>
                      <Spin />
                    </div>
                  )}
                </Spin>
              </div>
            </div>
          </Col>
          <Col span={12}>
            {URLReactPlayer === null ? (
              <div
                style={{
                  padding: 30,
                  borderRadius: 32,
                  backgroundColor: "#D9D9D9",
                  height: 355,
                  display: "flex", // Enable flexbox
                  justifyContent: "center", // Center horizontally
                  alignItems: "center", // Center vertically
                }}
              >
                <PlayIcon />
              </div>
            ) : (
              <div
                style={{
                  borderRadius: "32px",
                  overflow: "hidden", // Ensures the child content respects the border-radius
                  width: "100%", // Set the width of the wrapper
                  height: "360px", // Set the height of the wrapper
                }}
              >
                <ReactPlayer
                  url={URLReactPlayer}
                  width="100%" // Makes it responsive to the wrapper
                  height="100%"
                  controls={true}
                  playing={true}
                />
              </div>
            )}
          </Col>
        </Row>
      </DashboardAdasDetail>
    </Spin>
  );
};

export default connect(
  ({ auth, auth: { profile } }) => ({
    auth,
    profile,
  }),
  {}
)(Detail);
