import React from "react";
import * as moment from "moment";
import history from "./history";
import LangContext, { i18n } from "modules/shared/context/langContext";
import axios from 'axios';
import { ACCESSTOKEN } from '../constants/local_storage';

export const getUrlSection = (url, splitStr, index) => {
  let value = url.split(splitStr);

  return value[index];
};

export const findLatLngDiff = (lat, lng, lat2, lng2) => {
  let value = Math.abs(lat - lat2) + Math.abs(lng - lng2);

  return value;
};

export const checkUnauthorized = (fn, action) => {
  // localStorage.removeItem("access-token");
  // localStorage.removeItem("profile");
  history.push("/");
  return fn;
};

export const autoGenKey = (perfix) =>
  `${perfix}-${Math.random()
    .toString(36)
    .substring(7)}-${Math.ceil(Math.random() * 1000)}}`;

export const helper_convertDateformat = (
  dateStr,
  sourceFormat,
  resultFormat
) => {
  let result = "";
  if (dateStr === null) return dateStr;
  if (dateStr.length > 4 && dateStr !== "")
    result = moment(dateStr, sourceFormat).format(resultFormat);
  else if (dateStr === "now") result = "NOW";
  return result;
};

export const helper_changeNumberStatusToTextStatus = (
  number,
  arrKeyWithValue
) => arrKeyWithValue[number.toString()];

export const vecAdd = (v, a) => ({ x: v.x + a.x, y: v.y + a.y });
export const vecMul = (v, a) => ({ x: v.x * a, y: v.y * a });

export function latLng2World({ lat, lng }) {
  const sin = Math.sin((lat * Math.PI) / 180);
  const x = lng / 360 + 0.5;
  let y = 0.5 - (0.25 * Math.log((1 + sin) / (1 - sin))) / Math.PI;

  y =
    y < 0 // eslint-disable-line
      ? 0
      : y > 1
      ? 1
      : y;
  return { x, y };
}

export function tile2LatLng({ x, y }, zoom) {
  const n = Math.PI - (2 * Math.PI * y) / Math.pow(2, zoom);

  return {
    lat: (180 / Math.PI) * Math.atan(0.5 * (Math.exp(n) - Math.exp(-n))),
    lng: (x / Math.pow(2, zoom)) * 360 - 180,
  };
}

export function latLng2Scaled({ lat, lng }, zoom) {
  const worldCoords = latLng2World({ lat, lng });
  const scale = Math.pow(2, zoom);

  return {
    x: worldCoords.x * scale,
    y: worldCoords.y * scale,
  };
}

export function latLng2Tile({ lat, lng }, zoom) {
  const { x, y } = latLng2Scaled({ lat, lng }, zoom);

  return {
    x: Math.floor(x),
    y: Math.floor(y),
  };
}

export function getTileBounds({ bounds, zoom, tileExpand = 0 }) {
  const { nw, se } = bounds;
  const from = vecAdd(latLng2Tile(nw, zoom), {
    x: -tileExpand,
    y: -tileExpand,
  });
  const to = vecAdd(latLng2Tile(se, zoom), { x: tileExpand, y: tileExpand });
  return [from, to];
}

export function getTilesIds({ bounds, zoom, tileExpand }) {
  const [from, to] = getTileBounds({ bounds, zoom, tileExpand });
  const scale = Math.pow(2, zoom);
  const ids = [];

  for (let x = from.x; x !== (to.x + 1) % scale; x = (x + 1) % scale) {
    for (let y = from.y; y !== (to.y + 1) % scale; y = (y + 1) % scale) {
      ids.push({ zoom, x, y });
    }
  }

  return ids;
}

export function bboxIntersects(bbox1, bbox2) {
  const [{ x: x0, y: y0 }, { x: x1, y: y1 }] = bbox1;
  const [{ x: a0, y: b0 }, { x: a1, y: b1 }] = bbox2;

  return !((y1 < b0 || y0 > b1) && (x1 < a0 || x0 > a1));
}

export function distance({ x: x0, y: y0 }, { x: x1, y: y1 }) {
  return Math.sqrt(Math.pow(x1 - x0, 2) + Math.pow(y1 - y0, 2));
}

export function convertLatLngToXy(lat, lng) {
  var y = (-1 * lat + 90) * (window.innerHeight - 45 / 180);
  var x = (lng + 180) * (882 / 360);
  return { x: x, y: y };
}

export const findNewLatLng = (lat, lng) => {
  let sdVal = 70; // more go right ,less go left
  let earth = 6378.137;
  let pi = Math.PI;
  let m = 1 / (((2 * pi) / sdVal) * earth) / 1000;

  let new_latitude = lat + 1 * m;

  pi = Math.PI;
  let cos = Math.cos;
  m = 1 / (((2 * pi) / sdVal) * earth) / 1000;

  let new_longitude = lng + (-510 * m) / cos(lat * (pi / 180));

  return { lat: new_latitude, lng: new_longitude };
};

export const checkInsidePolygon = (point, vs) => {
  let x = point.lat,
    y = point.lng;

  let inside = false;
  for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    let xi = vs[i].lat,
      yi = vs[i].lng;
    let xj = vs[j].lat,
      yj = vs[j].lng;

    let intersect =
      yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }

  return inside;
};

export const computeArea = (latLngs) => {
  var pointsCount = latLngs.length,
    area = 0.0,
    d2r = Math.PI / 180.0,
    radius = 6378137.0,
    p1,
    p2;

  if (pointsCount <= 2) return 0;

  for (var i = 0; i < pointsCount; i++) {
    p1 = latLngs[i];
    p2 = latLngs[(i + 1) % pointsCount];
    area +=
      (p2.lng - p1.lng) *
      d2r *
      (2 + Math.sin(p1.lat * d2r) + Math.sin(p2.lat * d2r));
  }
  area = (area * radius * radius) / 2.0;
  return Math.round((Math.abs(area / 1000000) + Number.EPSILON) * 100) / 100;
};
export const Deg2Rad = (deg) => {
  return (deg * Math.PI) / 180;
};

export const getDistanceFromPointToPoint = (lat1, lng1, lat2, lng2, radius) => {
  lat1 = Deg2Rad(lat1);
  lat2 = Deg2Rad(lat2);
  lng1 = Deg2Rad(lng1);
  lng2 = Deg2Rad(lng2);

  let lngDiff = lng2 - lng1;
  let R = 6371000; // metres

  let dist =
    Math.acos(
      Math.sin(lat1) * Math.sin(lat2) +
        Math.cos(lat1) * Math.cos(lat2) * Math.cos(lngDiff)
    ) * R;

  return dist <= radius;
};

export const checkPermissions = (data, matching_name, actions) => {
  let action = true;

  let permissions = data.filter(function(ele) {
    return ele.matching_name == matching_name && ele[actions] == 1;
  });

  if (permissions.length == 0) {
    action = false;
  } else {
    action = true;
  }

  return action;
};

export const hasPermission = (permissions, matchingName, actionName) => {
  let canAccess = permissions.find((ele) => {
    return ele.matching_name == matchingName && ele[actionName] == 1;
  });

  if (matchingName == "") {
    canAccess = true;
  } else if (typeof canAccess == "undefined") {
    canAccess = false;
  } else {
    canAccess = true;
  }

  return canAccess;
};

export const isNumber = (n) => {
  return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
};

export const isNumberAndAlphabet = (value) => {
  return /^[0-9a-zA-Z]+$/.test(value);
};

export const calcCrowDistance = (lat1, lon1, lat2, lon2) => {
  let R = 6371; // km
  let dLat = toRad(lat2 - lat1);
  let dLon = toRad(lon2 - lon1);
  lat1 = toRad(lat1);
  lat2 = toRad(lat2);

  let a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  let d = R * c;
  return d;
};

// Converts numeric degrees to radians
function toRad(Value) {
  return (Value * Math.PI) / 180;
}

export const appendScript = (tag, scriptToAppend, self) => {
  const script = document.createElement(tag);
  script.src = scriptToAppend;
  //script.async = true;
  script.onload = () => {
    if (self !== null) {
      self.setState({ loadScriptSuccess: true });
    }
  };
  document.body.appendChild(script);
};

export const removeScript = (scriptToRemove) => {
  let allsuspects = document.getElementsByTagName("script");
  for (let i = allsuspects.length; i >= 0; i--) {
    let rx = new RegExp(scriptToRemove, "g");
    if (
      allsuspects[i] &&
      allsuspects[i].getAttribute("src") !== null &&
      allsuspects[i].getAttribute("src").match(rx)
    ) {
      allsuspects[i].parentNode.removeChild(allsuspects[i]);
    }
  }
};

export const translate = (key, cateKey, lang) => {
  if (typeof lang !== "undefined") {
    return i18n[lang][cateKey][key];
  } else {
    return (
      <LangContext.Consumer>{(val) => val[cateKey][key]}</LangContext.Consumer>
    );
  }
};

export const dummyRequest = ({ file, onSuccess }) => {
  var reader = new window.FileReader();
  reader.readAsDataURL(file);
  reader.onload = (...args) => {
    onSuccess("done", file);
  };
};

export const timeZone = (datetime, type) => {
  //2021-12-30 13:55:00
  //moment.tz("14/12/2021 13:10", "DD/MM/YYYY HH:mm", "Asia/Bangkok")

  if (moment(datetime, "YYYY-MM-DD HH:mm:ss", true).isValid()) {
    // return moment.tz(datetime, "Asia/Bangkok")
    // .format();//ไว้รอเปิดใช้
    return datetime;
  } else if (moment(datetime, "YYYY-MM-DD HH:mm", true).isValid()) {
    if (type === "start") {
      datetime = datetime + ":00";
    } else {
      datetime = datetime + ":59";
    }
    // return moment.tz(datetime, "Asia/Bangkok")
    // .format();//ไว้รอเปิดใช้
    return datetime;
  } else if (moment(datetime, "YYYY-MM-DD", true).isValid()) {
    if (type === "start") {
      datetime = datetime + " 00:00:00";
    } else {
      datetime = datetime + " 23:59:59";
    }

    // return moment.tz(datetime, "Asia/Bangkok")
    // .format();ไว้รอเปิดใช้
    return datetime;
  }
};

export const LatLngFormat = (data, type = "lng") => {
  let latlng_format = true;

  if (data == null) {
    latlng_format = false;
  } // null
  if (data == "") {
    latlng_format = false;
  } // " "
  if (parseFloat(data) == 0) {
    latlng_format = false;
  } // "0.00000"

  if (type === "lat" && (parseFloat(data) < -90 || parseFloat(data) > 90)) {
    latlng_format = false;
  } else if (
    type === "lng" &&
    (parseFloat(data) < -180 || parseFloat(data) > 180)
  ) {
    latlng_format = false;
  }

  return latlng_format;
};

export const handleFilter = (value) => {
  if(value == null || undefined){
    value = "";
  }
  value = value.trim();

  // แยกข้อความด้วย \n หรือ , หรือทั้งสองอย่าง
  let splitValues = value.split(/[\n,]+/);

  // ตัดช่องว่างซ้ายขวาของแต่ละรายการ และลบรายการว่าง
  splitValues = splitValues.map((val) => val.trim()).filter((val) => val !== "");

  // กรองค่าที่ซ้ำกัน
  splitValues = [...new Set(splitValues)];

  // เปลี่ยน array กลับเป็น string
  let newVals = splitValues.join(",");

  return newVals;
};

export const loggingUsageReport = async (data) => {
  let { type_file } = data;
  if (type_file === undefined || type_file === null) {
    type_file = "";
  }
  const res = await axios.post(
    `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/logging-usage-report/create`,
    {
      ...data,
      type_file,
      referer: window.location.href, 
    },
    {
      headers: {
        Authorization: `Bearer ${ACCESSTOKEN}`,
      },
    }
  );
  return res;
}
  
  