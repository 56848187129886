import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import Forms from "./Forms";
import { Row, Col, Spin, message } from "antd";
import Breadcrumb from "../../../shared/components/Breadcrumbreport";
import axios from "axios";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import AuthorizeComponent from "./../../../auth/components/AuthorizeComponent";
// import { Redirect } from "react-router-dom";

class Index extends Component {
  state = {
    companyList: [],
  };

  loadCompany = () => {
    axios
      .get(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/masterDataUser_company`,
        {
          headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
        }
      )
      .then((response) => {
        if (typeof response.data.data !== "undefined") {
          let companies = response.data.data.map((company) => {
            return {
              key: company.companies_id,
              value: company.companies_name,
            };
          });
          this.setState({ companyList: companies });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  handleExport = (params) => {
    this.props.exportReport(
      // { ...params, company_id: parseInt(params.company_id) },
      params,
      this.props.auth.accessToken
    );
  };

  componentDidMount() {
    this.loadCompany();
  }

  componentDidUpdate(prevProps, prevState) {
    const { errorMsg } = this.props.performacelogReport;
    if (
      errorMsg !== "" &&
      prevProps.performacelogReport.errorMsg !== errorMsg
    ) {
      message.error(errorMsg);
    }
  }

  render() {
    return (
      <AuthorizeComponent matching_name="performancelog">
        <GeneralStyledContent>
          <Spin spinning={this.props.performacelogReport.loading}>
            <Row gutter={24}>
              <Col span={24}>
                <Breadcrumb
                  match={this.props.match}
                  style={{ margin: "0px 0px 14px 10px" }}
                />
              </Col>
            </Row>
            <Forms
              companyList={this.state.companyList}
              lang={this.props.language}
              onSubmit={this.handleExport}
            />
          </Spin>
        </GeneralStyledContent>
      </AuthorizeComponent>
    );
  }
}

const mapStateToProps = ({ auth, ui: { language }, performacelogReport }) => ({
  auth,
  language,
  performacelogReport,
});

const mapDispatchToProps = {
  exportReport: actions.exportPerformanceLogReport.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
