import React, { Component, Fragment } from "react";
import {
  Select,
  Form,
  Spin,
  Modal,
  Transfer,
  Button,
  Row,
  Col,
  Checkbox,
} from "antd";
import LangContext, { i18n } from "modules/shared/context/langContext";

import { DEFAULT_CONFIG_COLUMN } from "../constants/tracking";
const Option = Select.Option;

export class ConfigModal extends Component {
  state = {
    unselected: [],
    selected: [],
    loading: false,
    checkCluster:
      JSON.parse(JSON.parse(localStorage.getItem("profile")).config).menu
        .tracking.is_group_marker == "undefined"
        ? false
        : JSON.parse(JSON.parse(localStorage.getItem("profile")).config).menu
            .tracking.is_group_marker,
  };

  arraysEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) return false;
    for (var i = arr1.length; i--; ) {
      if (arr1[i] !== arr2[i]) return false;
    }

    return true;
  }

  handleApplayConfigButtonClick = (defaultVal) => {
    let self = this;
    let columns = [];
    this.setState({ loading: true });
    if (defaultVal == null) {
      let { tracking } = self.props.auth.profile.config.menu;

      columns = tracking.columns;

      let oldColDiff = tracking.columns.filter(
        (x) => !self.state.selected.includes(x)
      );

      let newColDiff = self.state.selected.filter(
        (x) => !tracking.columns.includes(x)
      );

      columns = columns.filter(function(el) {
        return !oldColDiff.includes(el);
      });
      columns = columns.filter(function(el) {
        return !newColDiff.includes(el);
      });
      newColDiff.map((ele) => columns.push(ele));
    } else {
      columns = defaultVal;
    }
    fetch(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/updateuserconfigtrackingcolumn`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.auth.accessToken}`,
        },
        body: JSON.stringify({
          user_id: self.props.profile.id,
          columns: JSON.stringify(columns),
          is_group_marker: this.state.checkCluster,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        self.props.changeConfigTrackingColumns(self.props.profile, columns);
        self.props.onLatestConfigChange();
        this.setState({ loading: false });
        this.props.handleConfigCancel();
        if (this.props.profile != null) {
          let newProfile = {
            ...this.props.profile,
            permissions: JSON.stringify(this.props.profile.permissions),
            config: JSON.stringify({
              ...this.props.profile.config,
              menu: {
                ...this.props.profile.config.menu,
                tracking: {
                  ...this.props.profile.config.menu.tracking,
                  is_group_marker: this.state.checkCluster,
                },
              },
            }),
          };

          localStorage.setItem("profile", JSON.stringify(newProfile));
        }
      });
    // }
  };

  componentDidMount() {
    this.getSelectedColumns();
  }

  getSelectedColumns = () => {
    const selected = [];
    const unselected = [];
    for (let i = 0; i < this.props.allCol.length; i++) {
      const data = {
        key: this.props.allCol[i].dataIndex,
        title: this.props.allCol[i].title,
        chosen: Math.random() * 2 > 1,
      };
      if (
        this.props.auth.profile.config.menu.tracking.columns.find(
          (ele) => ele == data.key
        )
      ) {
        selected.push(data.key);
      }
      unselected.push(data);
    }
    this.setState({ unselected, selected });
  };

  filterOption = (inputValue, option) =>
    option.description.indexOf(inputValue) > -1;

  handleChange = (selected) => {
    this.setState({ selected });
  };

  handleSearch = (dir, value) => {};

  handleResetToDefault = () => {
    this.setState({ selected: DEFAULT_CONFIG_COLUMN });
  };

  render() {
    return (
      <Modal
        style={{ top: 20 }}
        title={
          <LangContext.Consumer>
            {(i18n) => i18n.c.configuration}
          </LangContext.Consumer>
        }
        visible={this.props.configVisible}
        onCancel={this.props.handleConfigCancel}
        footer={[
          <Button
            key="reset"
            loading={
              this.props.monitoringState.trackingLoading || this.state.loading
            }
            onClick={this.handleResetToDefault}
          >
            {" "}
            <LangContext.Consumer>
              {(i18n) => i18n.d.default}
            </LangContext.Consumer>
          </Button>,
          <Button key="back" onClick={this.props.handleConfigCancel}>
            <LangContext.Consumer>
              {(i18n) => i18n.c.cancel}
            </LangContext.Consumer>
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={
              this.props.monitoringState.trackingLoading || this.state.loading
            }
            onClick={(e) => this.handleApplayConfigButtonClick(null)}
          >
            <LangContext.Consumer>
              {(i18n) => i18n.a.apply}
            </LangContext.Consumer>
          </Button>,
        ]}
      >
        <Spin spinning={this.props.monitoringState.trackingLoading}>
          <Form layout="horizontal">
            <Form.Item
              label={
                <LangContext.Consumer>
                  {(i18n) => i18n.a.adjustTheTable}
                </LangContext.Consumer>
              }
            >
              <Row type="flex" justify="space-around" align="middle">
                <Col span={24} align="center">
                  <Transfer
                    style={{ width: "100%", height: "100%" }}
                    listStyle={{
                      height: "310px",
                      textAlign: "left",
                    }}
                    titles={[
                      <LangContext.Consumer>
                        {(i18n) => i18n.i.itemLeft}
                      </LangContext.Consumer>,
                      <LangContext.Consumer>
                        {(i18n) => i18n.i.itemSelected}
                      </LangContext.Consumer>,
                    ]}
                    size="small"
                    dataSource={this.state.unselected}
                    filterOption={this.filterOption}
                    targetKeys={this.state.selected}
                    onChange={this.handleChange}
                    render={(item) => item.title}
                  />
                </Col>
                <Col span={24} align="left">
                  <Checkbox
                    onChange={(e) => {
                      this.setState({
                        checkCluster: e.target.checked,
                      });
                    }}
                    style={{ marginLeft: 35 }}
                    defaultChecked={this.state.checkCluster}
                  >
                    แสดงจุดของรถทั้งหมด
                  </Checkbox>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    );
  }
}

export default ConfigModal;
