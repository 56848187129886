import React from "react";
import { Modal } from "antd";

import LangContext from "modules/shared/context/langContext";

const OfflineModal = ({ visible, hideModal }) => {
  return (
    <Modal
      style={{ top: 20 }}
      // title="Modal"
      visible={visible}
      onOk={hideModal}
      onCancel={hideModal}
      okType="danger"
      okText={
        <LangContext.Consumer>{(i18n) => i18n.o.okay}</LangContext.Consumer>
      }
      cancelButtonProps={{ style: { display: "none" } }}
    >
      <p>
        <LangContext.Consumer>
          {(i18n) => i18n.o.vehicleOffline}
        </LangContext.Consumer>
      </p>
    </Modal>
  );
};

export default OfflineModal;
