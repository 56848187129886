// Libraries
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import AuthorizeComponent from '../../../auth/components/AuthorizeComponent'
import { GeneralStyledContent } from '../../../../styled/common-styled'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import { checkPermissions, handleFilter } from '../../../../lib/helper'
import moment from 'moment'
import {
  Row,
  Col,
  Spin,
  Tooltip,
  PageHeader,
  Badge,
  Button,
  Tag,
  Divider,
} from 'antd'

// Components
import FormSearch from './FormSearch'
import TableLog from './TableLog'

// API
import {
  monitorSDCardTableLog,
  monitorSDCardChartLog,
  monitorSDCardCompanyList,
  removeLists,
} from '../actions'

// Lib
import {
  prepareLogFormatArray,
  genBackgroundColor,
  prepareDataForTable,
} from './MainFunction'

// CSS
import '../assets/css/index.css'

class Index extends Component {
  constructor(props) {
    super(props)

    this.state = {
      // *เช็คว่ามีการ filter หรือไม่
      isFilter: false,
      visible_form_search: false,

      // *filterObj
      filterObj: {
        FilterCompanyID: '',
        // 7 วันย้อนหลัง
        FilterStartDate: moment()
          .subtract(6, 'days')
          .format('YYYY-MM-DD'),
        FilterEndDate: moment().format('YYYY-MM-DD'),
        FilterVehicleCode: '',
        FilterPlateNo: '',
        FilterDeviceCode: '',
      },

      pagination: {
        page: 1,
        pageSize: 50,
      },

      // *ข้อมูลในตาราง table_log
      // เก็บ array ของวันที่และเดือนที่เลือก
      log_format_data: [],

      // columns
      table_columns: [],

      // ข้อมูล table log
      log_detail: [],
      total_log_detail: 0,
    }
  }

  componentDidMount() {
    let company_id = this.props.auth.profile.company_id
    if (
      checkPermissions(
        this.props.auth.profile.permissions,
        'monitorSDCard',
        'can_special_1'
      )
    ) {
      this.fetchCompanyList()
    }

    //*first search
    this.handleSearch({
      company_id: company_id,
      search_date: [moment().subtract(6, 'days'), moment()],
      vehicle_code: '',
      plate_no: '',
      device_code: '',
    })
  }

  componentWillUnmount() {
    this.onRemoveLists()
  }
  onRemoveLists = () => {
    this.props.removeLists()
  }

  //*เมื่อมีการเปลี่ยนแปลง state
  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.monitorSDCard.check_table_log !==
      nextProps.monitorSDCard.check_table_log
    ) {
      if (Object.keys(nextProps.monitorSDCard.table_log).length > 0) {
        let temp_data = nextProps.monitorSDCard.table_log.data || []
        let temp_total = nextProps.monitorSDCard.table_log.total || 0
        this.setState(
          {
            log_detail: prepareDataForTable(temp_data) || [],
            total_log_detail: temp_total,
          },
          () => {
            this.prepareColumns()
          }
        )
      }
    }
    return true
  }

  // *ดึงข้อมูล company
  fetchCompanyList = () => {
    this.props.monitorSDCardCompanyList({
      accessToken: this.props.auth.accessToken,
    })
  }

  // *ดึงข้อมูล table log
  fetchTableLogData = async () => {
    const { filterObj, pagination } = this.state

    // *fetch data table log
    this.props.monitorSDCardTableLog({
      ...pagination,
      filterObj,
      vehicleVisibility: this.props.auth.profile.vehicle_visibility,
      canFilterCompany: checkPermissions(
        this.props.auth.profile.permissions,
        'monitorSDCard',
        'can_special_1'
      ),
      accessToken: this.props.auth.accessToken,
    })
  }

  // *ค้นหา
  handleSearch = (values) => {
    let isFilter = false
    if (values.vehicle_code || values.plate_no || values.device_code) {
      isFilter = true
    }

    if (
      checkPermissions(
        this.props.auth.profile.permissions,
        'monitorSDCard',
        'can_special_1'
      )
    ) {
      if (values.company_id) {
        isFilter = true
      }
    }

    let start_date = moment(values.search_date[0]).format('YYYY-MM-DD')
    let end_date = moment(values.search_date[1]).format('YYYY-MM-DD')

    this.setState(
      {
        filterObj: {
          FilterCompanyID: values.company_id,
          FilterStartDate: start_date,
          FilterEndDate: end_date,
          FilterVehicleCode: handleFilter(values.vehicle_code),
          FilterPlateNo: handleFilter(values.plate_no),
          FilterDeviceCode: handleFilter(values.device_code),
        },

        pagination: {
          ...this.state.pagination,
          page: 1,
        },
        isFilter,

        visible_form_search: false,

        // *เตรียมข้อมูลวันที่
        log_format_data:
          prepareLogFormatArray(
            this.props.monitorSDCard.month_data,
            values.search_date[0],
            values.search_date[1]
          ) || [],
      },
      () => {
        // *fetch data table log
        this.fetchTableLogData()
      }
    )
  }

  // *เตรียม columns ของ table view
  prepareColumns = () => {
    const { log_format_data } = this.state
    let table_columns = []
    let all_columns = []

    let index_column = [
      {
        title: '#',
        dataIndex: 'key',
        key: 'key',
        width: 50,
        align: 'center',
        fixed: 'left',
        render: (text, record, index) => {
          if (record.visible) {
            const obj = {
              children: (
                <span>
                  {this.state.pagination.pageSize *
                    (this.state.pagination.page - 1) +
                    record.number_index}
                </span>
              ),
              props: {
                align: 'center',
                rowSpan: record.number_of_channel || 1,
              },
            }
            return obj
          } else {
            // สำหรับ channel อื่นๆ ไม่ต้องแสดงข้อมูลส่วนนี้เพราะ row แรก แสดงไปแล้ว
            const obj = {
              props: {
                align: 'center',
                rowSpan: 0,
              },
            }
            return obj
          }
        },
      },
      {
        title: i18n[this.props.language].v.vehicleData,
        dataIndex: 'vehicle_id',
        key: 'vehicles.code',
        width: 150,
        align: 'center',
        fixed: 'left',
        // sorter: true,
        render: (text, record, index) => {
          if (record.visible) {
            const obj = {
              children: (
                <div>
                  <Tooltip
                    title={
                      <span>{`(${record.vehicle_code}) ${record.plate_no}`}</span>
                    }
                    placement='topLeft'
                  >
                    {record.device_code ? (
                      <Button
                        type='link'
                        style={{
                          padding: '0',
                          whiteSpace: 'normal',
                          fontSize: '11px',
                        }}
                        size='small'
                        onClick={() => {
                          window.open(
                            `https://login.terminusfleet.com/receiver/test/show_simple_gps_data_v3.php?search=${record.device_code}`,
                            '_blank'
                          )
                        }}
                      >
                        <span>{`(${record.vehicle_code})`}</span>
                      </Button>
                    ) : (
                      <span>{`(${record.vehicle_code})`}</span>
                    )}

                    <span>{` ${record.plate_no}`}</span>
                  </Tooltip>

                  <br />
                  {record.device_code && (
                    <Tooltip
                      title={
                        <span>{`${
                          i18n[this.props.language].d.deviceCode
                        } : ${record.device_code || ''}`}</span>
                      }
                      placement='topLeft'
                    >
                      <span>{`${record.device_code || ''}`}</span>
                    </Tooltip>
                  )}
                </div>
              ),
              props: {
                align: 'center',
                rowSpan: record.number_of_channel || 1,
              },
            }
            return obj
          } else {
            // สำหรับ series อื่นๆ ไม่ต้องแสดงข้อมูลส่วนนี้เพราะ row แรก แสดงไปแล้ว
            const obj = {
              props: {
                align: 'center',
                rowSpan: 0,
              },
            }
            return obj
          }
        },
      },
      {
        title: i18n[this.props.language].f.firmwareVersion,
        dataIndex: 'firmware_version',
        key: 'firmware_version',
        width: 100,
        align: 'center',
        fixed: 'left',
        render: (text, record, index) => {
          if (record.visible) {
            const obj = {
              children: (
                <div>
                  <Tooltip
                    title={
                      <span>{`${
                        i18n[this.props.language].f.firmwareVersion
                      } : ${record.firmware_version || ''}`}</span>
                    }
                    placement='topLeft'
                  >
                    <span>{`${record.firmware_version || ''}`}</span>
                  </Tooltip>
                </div>
              ),
              props: {
                align: 'center',
                rowSpan: record.number_of_channel || 1,
              },
            }
            return obj
          } else {
            // สำหรับ series อื่นๆ ไม่ต้องแสดงข้อมูลส่วนนี้เพราะ row แรก แสดงไปแล้ว
            const obj = {
              props: {
                align: 'center',
                rowSpan: 0,
              },
            }
            return obj
          }
        },
      },
      {
        title: i18n[this.props.language].l.lastmaintenanceAt,
        dataIndex: 'vehicle_id',
        key: 'vehicles.code',
        width: 120,
        align: 'center',
        fixed: 'left',
        render: (text, record, index) => {
          if (record.visible) {
            const obj = {
              children: (
                <div>
                  {record.maintenance_data && (
                    <Tooltip
                      title={
                        <>
                          <span>
                            {`${record.maintenance_data.type_name_th || '-'}`}
                          </span>
                          <br></br>
                          <span>{`${record.maintenance_data.status_name_th ||
                            '-'}`}</span>
                          <br></br>
                          <span>
                            {`${i18n[this.props.language].d.date} : ${record
                              .maintenance_data.date_display || '-'}`}
                          </span>
                          <br></br>
                          <span style={{ fontSize: '10px' }}>{`(${record
                            .maintenance_data.date_name || '-'})`}</span>
                        </>
                      }
                      placement='top'
                    >
                      <Button
                        type='link'
                        style={{ padding: '0' }}
                        size='small'
                        onClick={() => {
                          window.open(
                            `https://login.terminusfleet.com/map/maintenancedevice/view/id/${record.maintenance_data.id}`,
                            '_blank'
                          )
                        }}
                      >
                        <span>
                          {`${record.maintenance_data.type_name_th || '-'}`}
                        </span>
                      </Button>
                      <br></br>
                      <span>{`${record.maintenance_data.status_name_th ||
                        '-'}`}</span>
                      <br></br>
                      <span>
                        {`${i18n[this.props.language].d.date} : ${record
                          .maintenance_data.date_display || '-'}`}
                      </span>
                      <br></br>
                      <span style={{ fontSize: '10px' }}>{`(${record
                        .maintenance_data.date_name || '-'})`}</span>
                    </Tooltip>
                  )}

                  <div>
                    <Button
                      type='link'
                      style={{ padding: '0' }}
                      size='small'
                      onClick={() => {
                        window.open(
                          `https://login.terminusfleet.com/map/maintenancedevice?plate_no=${record.plate_no}`,
                          '_blank'
                        )
                      }}
                    >
                      <span style={{ fontSize: '10px' }}>
                        {`${
                          i18n[this.props.language].m.maintenanceDeviceHistory
                        }`}
                      </span>
                    </Button>
                    <br></br>
                    <Button
                      type='link'
                      style={{ padding: '0' }}
                      size='small'
                      onClick={() => {
                        window.open(
                          `https://login.terminusfleet.com/map/maintenancedevice/add?vehicle_id=${record.vehicle_id}`,
                          '_blank'
                        )
                      }}
                      disabled={
                        record.maintenance_data &&
                        record.maintenance_data.maintenances_status_id &&
                        [1, 2, 6, 7].includes(
                          record.maintenance_data.maintenances_status_id
                        )
                      }
                    >
                      <span style={{ fontSize: '10px' }}>
                        {`${i18n[this.props.language].m.maintenanceDevice}`}
                      </span>
                    </Button>
                  </div>
                </div>
              ),
              props: {
                align: 'center',
                rowSpan: record.number_of_channel || 1,
              },
            }
            return obj
          } else {
            // สำหรับ series อื่นๆ ไม่ต้องแสดงข้อมูลส่วนนี้เพราะ row แรก แสดงไปแล้ว
            const obj = {
              props: {
                align: 'center',
                rowSpan: 0,
              },
            }
            return obj
          }
        },
      },
      {
        title: i18n[this.props.language].c.channel,
        dataIndex: 'channel_name',
        key: 'channel_name',
        width: 50,
        align: 'center',
        render: (text, record, index) => {
          const obj = {
            children: (
              <Tooltip title={text} placement='topLeft'>
                <span>{text}</span>
              </Tooltip>
            ),
            props: {
              align: 'center',
              rowSpan: 1,
              colSpan: 1,
            },
          }

          return obj
        },
      },
    ]

    all_columns = index_column

    // วนลูปใน log_format_data['order_key']
    for (let i = 0; i < log_format_data['order_key'].length; i++) {
      let key = log_format_data['order_key'][i]
      let date_array = log_format_data[key].date_array
      let day_array = log_format_data[key].day_array

      // วนลูปใน date_array
      for (let j = 0; j < date_array.length; j++) {
        let date = date_array[j]
        let day = day_array[j]

        let column = {
          title: day,
          dataIndex: date,
          key: date,
          align: 'center',
          render: (text, record) => {
            return {
              props: {
                style: {
                  background:
                    record.type == 'camera' && text
                      ? record[text].map((item, index) => {
                          return genBackgroundColor(
                            item,
                            item['video_record_percent'],
                            item['rom_video_record_percent']
                          )
                        })
                      : record.type == 'online' && text
                      ? record[text].map((item, index) => {
                          return genBackgroundColor(
                            item,
                            item['online_percent'],
                            null
                          )
                        })
                      : '#FFF',
                },
              },
              children: (
                <div>
                  {text &&
                    record[text].map((item, index) => {
                      return record.type === 'camera' ? (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '2px',
                          }}
                        >
                          <div>
                            <Tooltip
                              title={
                                <>
                                  <Divider
                                    type='horizontal'
                                    style={{
                                      margin: '5px 0',
                                      padding: '0',
                                      color: 'white',
                                    }}
                                  >
                                    SDCard
                                  </Divider>
                                  <div>
                                    <div>
                                      <span>
                                        {`${
                                          i18n[this.props.language].p
                                            .percentageOfTotalVideoRecorded
                                        } : ${
                                          item['engine_on_minute'] === 0 // ไม่ได้ติดเครื่องเลย
                                            ? '-'
                                            : item['video_record_percent'] + '%'
                                        }`}
                                      </span>
                                    </div>

                                    {/* จำนวนนาทีของวิดีโอ/จำนวนนาทีที่ติดเครื่อง */}
                                    <div>
                                      <span>
                                        {`${
                                          i18n[this.props.language].t
                                            .totalMinutesOfVideoRecorded
                                        }/${
                                          i18n[this.props.language].t
                                            .totalMinutesEngineRunning
                                        } : ${
                                          item['engine_on_minute'] == 0
                                            ? '-'
                                            : `${item['online_minute']}/${item['engine_on_minute']}`
                                        }`}
                                      </span>
                                    </div>

                                    {/* ขนาดไฟล์ */}
                                    <div>
                                      <span>
                                        {`${
                                          i18n[this.props.language].f.fileSize
                                        } : ${item['file_size']} GB.`}
                                      </span>
                                    </div>
                                  </div>
                                </>
                              }
                            >
                              <Link
                                to={`/monitor-sdcard/view-detail/${record.vehicle_id}/${record.hardware_id}/${item['date']}`}
                                target='_blank'
                              >
                                <Button
                                  type='link'
                                  size='small'
                                  className='btn-sdcard'
                                >
                                  <span
                                    style={{
                                      fontSize: '11px',
                                      color: 'black',
                                    }}
                                  >
                                    {item['engine_on_minute'] == 0
                                      ? '-'
                                      : `${item['video_record_percent']}%`}
                                  </span>
                                </Button>
                              </Link>
                            </Tooltip>
                          </div>
                          <div>
                            <span>|</span>
                          </div>
                          <div>
                            <Tooltip
                              title={
                                <>
                                  <Divider
                                    type='horizontal'
                                    style={{
                                      margin: '5px 0',
                                      padding: '0',
                                      color: 'white',
                                    }}
                                  >
                                    Rom
                                  </Divider>

                                  <div>
                                    <div>
                                      <span>
                                        {`${
                                          i18n[this.props.language].p
                                            .percentageOfTotalVideoRecorded
                                        } : ${
                                          item['rom_engine_on_minute'] === 0 // ไม่ได้ติดเครื่องเลย
                                            ? '-'
                                            : item['rom_video_record_percent'] +
                                              '%'
                                        }`}
                                      </span>
                                    </div>

                                    {/* จำนวนนาทีของวิดีโอ/จำนวนนาทีที่ติดเครื่อง */}
                                    <div>
                                      <span>
                                        {`${
                                          i18n[this.props.language].t
                                            .totalMinutesOfVideoRecorded
                                        }/${
                                          i18n[this.props.language].t
                                            .totalMinutesEngineRunning
                                        } : ${
                                          item['rom_engine_on_minute'] == 0
                                            ? '-'
                                            : `${item['rom_online_minute']}/${item['rom_engine_on_minute']}`
                                        }`}
                                      </span>
                                    </div>

                                    {/* ขนาดไฟล์ */}
                                    <div>
                                      <span>
                                        {`${
                                          i18n[this.props.language].f.fileSize
                                        } : ${item['rom_file_size']} GB.`}
                                      </span>
                                    </div>
                                  </div>
                                </>
                              }
                            >
                              <Link
                                to={`/monitor-sdcard/view-detail/${record.vehicle_id}/${record.hardware_id}/${item['date']}`}
                                target='_blank'
                              >
                                <Button
                                  type='link'
                                  size='small'
                                  className='btn-sdcard'
                                >
                                  <span
                                    style={{
                                      fontSize: '11px',
                                      color: 'black',
                                    }}
                                  >
                                    {item['rom_engine_on_minute'] == 0
                                      ? '-'
                                      : `${item['rom_video_record_percent']}%`}
                                  </span>
                                </Button>
                              </Link>
                            </Tooltip>
                          </div>
                        </div>
                      ) : record.type === 'online' ? (
                        <>
                          <div>
                            <Tooltip
                              title={
                                <div>
                                  <span>
                                    {`${
                                      i18n[this.props.language].n
                                        .numberOfTimesDeviceOnline
                                    }/${
                                      i18n[this.props.language].n
                                        .numberOfTimesEngineStarted
                                    }`}
                                  </span>
                                </div>
                              }
                            >
                              <span style={{ fontSize: '11px' }}>
                                {`${item['online_count']}/${item['active_total']}`}
                              </span>
                            </Tooltip>
                          </div>
                        </>
                      ) : null
                    })}
                </div>
              ),
            }
          },
        }

        all_columns.push(column)
      }
    }
    table_columns = all_columns

    this.setState(
      {
        table_columns,
      },
      () => {}
    )
  }

  // *เปิด form search
  handleOpenFormSearch = () => {
    this.setState({ visible_form_search: true })
  }

  // *ปิด form search
  handleCloseFormSearch = () => {
    this.setState({ visible_form_search: false })
  }

  handleChangePage = (page, pageSize) => {
    this.setState(
      {
        pagination: {
          page,
          pageSize,
        },
      },
      () => {
        this.fetchTableLogData()
      }
    )
  }

  render() {
    const {
      isFilter,
      visible_form_search,
      table_columns,
      pagination,

      log_detail,
      total_log_detail,
    } = this.state

    const { color_status, check_table_log } = this.props.monitorSDCard

    return (
      <AuthorizeComponent {...this.props} matching_name='monitorSDCard'>
        <GeneralStyledContent>
          <div className='monitor-sdcard'>
            {/* header */}
            <PageHeader
              style={{ paddingLeft: '0px' }}
              title={
                <div className='header__text'>
                  {i18n[this.props.language].m.monitorSDCard}
                </div>
              }
            />

            {/* ปุ่มค้นหาแถบบน */}
            <Row type='flex' style={{ marginBottom: '10px' }}>
              <Col span={10} align={'left'}>
                {color_status.map((item, index) => {
                  return (
                    <>
                      <Tag
                        color={item.color}
                        key={index}
                        style={{
                          width: '20px',
                          height: '20px',
                          borderRadius: '50%',
                        }}
                      >
                        &nbsp;
                      </Tag>
                      <span className='font-tag-style'>{item.title}</span>
                      &nbsp;
                    </>
                  )
                })}
              </Col>
              <Col span={14} align='right'>
                <Badge dot={isFilter} align='right'>
                  <Button
                    icon='filter'
                    size='small'
                    align='right'
                    onClick={this.handleOpenFormSearch}
                  >
                    <span>{i18n[this.props.language].s.search}</span>
                  </Button>
                </Badge>
              </Col>
            </Row>

            {/* table log */}
            <Row>
              <Spin
                spinning={check_table_log}
                tip={`${i18n[this.props.language].l.loading}...`}
              >
                <TableLog
                  data={log_detail || []}
                  column={table_columns}
                  className='table-log'
                  pagination={pagination}
                  total={total_log_detail}
                  handleChangePage={this.handleChangePage}
                />
              </Spin>
            </Row>

            {/* form search */}
            <FormSearch
              handleSearch={this.handleSearch}
              visible={visible_form_search}
              handleClose={this.handleCloseFormSearch}
            />
          </div>
        </GeneralStyledContent>
      </AuthorizeComponent>
    )
  }
}

export default connect(
  ({ monitorSDCard, ui: { language }, auth, auth: { profile } }) => ({
    monitorSDCard,
    language,
    auth,
    profile,
  }),
  {
    monitorSDCardTableLog: monitorSDCardTableLog.request,
    monitorSDCardChartLog: monitorSDCardChartLog.request,
    monitorSDCardCompanyList: monitorSDCardCompanyList.request,
    removeLists,
  }
)(Index)
