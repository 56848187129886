import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { getlocalstorage } from './../../../constants/local_storage'

import * as actions from './actions'
import moment from 'moment'
import { timeZone } from './../../../lib/helper'

function* loadSummaryLineNotification(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id'
  )

  const VEHICLE_VISIBILITY = getlocalstorage(
    localStorage.getItem('profile'),
    'vehicle_visibility'
  )

  const {
    data: { policy_id, eventdate, type_file, user_id },
  } = action.payload
  const date_start = timeZone(
    moment(eventdate[0]['_d']).format('YYYY-MM-DD HH:mm'),
    'start'
  )
  const date_end = timeZone(
    moment(eventdate[1]['_d']).format('YYYY-MM-DD HH:mm'),
    'end'
  )
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_REPORT_GOLANG_MONGO}/reports/go/summarylinenotification`,
      {
        date_start,
        date_end,
        company_id: COMPANY_ID,
        policy_id,
        type_file,
        vehicle_visibility: VEHICLE_VISIBILITY,
        user_id
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    )
    yield put(actions.loadSummaryLineNotification.success(res.data))
    window.location = res.data.result
  } catch (err) {
    yield put(actions.loadSummaryLineNotification.failure())
  }
}

export default function* watchReportPoolState() {
  yield all([
    takeEvery(
      actions.LOAD_SUMMARYLINENOTIFICATION.REQUEST,
      loadSummaryLineNotification
    ),
  ])
}

export { loadSummaryLineNotification }
