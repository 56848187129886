import { createTypes, createAction } from "lib/action";
const LOAD_FRIMWARE = createTypes("loadfrimware", "load");
const CREATE_FRIMWARE = createTypes("createfirmware", "create");
const LOAD_FRIMWARE_DETAIL = createTypes("loadfrimwaredetail", "load");
const UPDATE_FRIMWARE_DETAIL = createTypes(
  "updateloadfrimwaredetail",
  "update"
);
const REMOVE_LISTS = `REMOVE_LISTS`;

function removeLists() {
  return {
    type: REMOVE_LISTS,
  };
}

const loadFrimware = {
  request: (data, loading) =>
    createAction(LOAD_FRIMWARE.REQUEST, { data, loading }),
  success: (lists, page) =>
    createAction(LOAD_FRIMWARE.SUCCESS, { lists, page }),
  failure: () => createAction(LOAD_FRIMWARE.FAITLURE),
};

const createFrimware = {
  request: (data, createFrimwareloading) =>
    createAction(CREATE_FRIMWARE.REQUEST, { data, createFrimwareloading }),
  success: (res) => createAction(CREATE_FRIMWARE.SUCCESS, { res }),
  failure: () => createAction(CREATE_FRIMWARE.FAITLURE),
};

const loadFrimwareDetail = {
  request: (data, frimwareLoading) =>
    createAction(LOAD_FRIMWARE_DETAIL.REQUEST, { data, frimwareLoading }),
  success: (lists) => createAction(LOAD_FRIMWARE_DETAIL.SUCCESS, { lists }),
  failure: () => createAction(LOAD_FRIMWARE_DETAIL.FAITLURE),
};

const updateFrimware = {
  request: (data, updateFrimwareloading) =>
    createAction(UPDATE_FRIMWARE_DETAIL.REQUEST, {
      data,
      updateFrimwareloading,
    }),
  success: (res) => createAction(UPDATE_FRIMWARE_DETAIL.SUCCESS, { res }),
  failure: () => createAction(UPDATE_FRIMWARE_DETAIL.FAITLURE),
};

export {
  LOAD_FRIMWARE,
  loadFrimware,
  CREATE_FRIMWARE,
  createFrimware,
  LOAD_FRIMWARE_DETAIL,
  loadFrimwareDetail,
  UPDATE_FRIMWARE_DETAIL,
  updateFrimware,
  REMOVE_LISTS,
  removeLists,
};
