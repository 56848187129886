import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import moment from "moment";
import {
  Button,
  Form,
  Select,
  Row,
  Col,
  Spin,
  DatePicker,
  Transfer,
  InputNumber,
  Checkbox,
  Dropdown,
  Menu,
  Icon,
} from "antd";
import LangContext, { i18n } from "modules/shared/context/langContext";

const { RangePicker } = DatePicker;
const FormItem = Form.Item;
const Option = Select.Option;

export default class Forms extends Component {
  state = {
    event_date_start: "",
    event_date_end: "",
    vehicleList: [],
    vehicleSelect: [],
    selectedKeys: [],
    vehicle_type_id: "",
    initVehicleSelect: [],
    amount_date: 0,
    op_of_amount_date: "0",
    distance_more_than: 0,
  };

  componentDidMount() {
    const { loadVehicle, loadVehicleType, call } = this.props;
    loadVehicle("", false);
    loadVehicleType();

    if (call === "schedule") {
      if (this.props.dataedit !== "") {
        let obj = JSON.parse(this.props.dataedit);
        this.setState({
          vehicleSelect: obj.list_vehicle_id,
          vehicle_type_id: obj.vehicle_type_id,
          initVehicleSelect: obj.list_vehicle_id,
          amount_date: obj.amount_date,
          op_of_amount_date: obj.op_of_amount_date,
          distance_more_than: obj.distance_more_than,
        });
      }
    }
  }

  onVehicleTypeChange = (value, setFieldValue) => {
    const { loadVehicle } = this.props;
    setFieldValue("vehicle_type_id", value);
    loadVehicle(value, true);
    this.setState({ vehicleSelect: [] });
    this.setState({ selectedKeys: [] });
    setFieldValue("list_vehicle_id", []);
  };

  componentWillReceiveProps = (nextProps) => {
    const { vehicleLists } = nextProps;
    const vehlist = [];
    vehicleLists.map((item) =>
      vehlist.push({
        key: item.id,
        name: item.plate_no,
      })
    );

    this.setState({ vehicleList: vehlist });
  };

  tranferChange = (targetKeys, setFieldValue) => {
    setFieldValue("list_vehicle_id", targetKeys);
    this.setState({ vehicleSelect: targetKeys });
  };

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState({
      selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
    });
  };

  handleMenuClick = (e, setFieldValue, handleSubmit) => {
    if (e.key === "excel") {
      setFieldValue("type_file", "excel", false);
    } else if (e.key === "pdf") {
      setFieldValue("type_file", "pdf", false);
    }
    handleSubmit();
  };

  render() {
    const {
      vehicleTypeLists,
      numberofdaysworkedLoading,
      onSubmit,
    } = this.props;

    let validationShape = {
      eventdate: yup.array().required("กรุณาเลือกช่วงวันที่"),
      list_vehicle_id: yup.array().required("กรุณาเลือกรถ"),
    };

    if (this.props.call === "schedule") {
      validationShape = {
        list_vehicle_id: yup.array().required("กรุณาเลือกรถ"),
      };
    }
    return (
      <div>
        <h3>
          <LangContext.Consumer>
            {(i18n) => i18n.n.NumberofdaysworkedReport}
          </LangContext.Consumer>
        </h3>
        <Spin spinning={numberofdaysworkedLoading}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              vehicle_type_id: this.state.vehicle_type_id,
              eventdate: [moment("00:00", "HH:mm"), moment("23:59", "HH:mm")],
              list_vehicle_id: this.state.initVehicleSelect,
              amount_date: this.state.amount_date,
              op_of_amount_date: this.state.op_of_amount_date,
              distance_more_than: this.state.distance_more_than,
            }}
            validate={(values) => {
              let errors = {};
              // if (values.eventdate.length > 0) {
              //   const date_start = moment(values.eventdate[0]["_d"]);
              //   const date_end = moment(values.eventdate[1]["_d"]);
              //   let day_diff = date_end.diff(date_start, "days");
              //   if (day_diff > 31) {
              //     errors.eventdate = "ช่วงวันที่ที่เลือกไม่ควรเกิน 31 วัน";
              //   }
              // }

              return errors;
            }}
            validationSchema={yup.object().shape(validationShape)}
            onSubmit={(values) => {
              //console.log(values);
              const loading = true;
              onSubmit(values, loading);
            }}
            render={({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              handleFocus,
              setFieldValue,
              isValidating,
            }) => (
              <Row type="flex" justify="center">
                <LangContext.Consumer>
                  {(i18n) => (
                    <Form onSubmit={handleSubmit}>
                      <FormItem
                        label={
                          <LangContext.Consumer>
                            {(i18n) => i18n.v.vehicleGroup}
                          </LangContext.Consumer>
                        }
                        validateStatus={
                          touched.vehicle_type_id &&
                          errors.vehicle_type_id &&
                          "warning"
                        }
                        help={touched.vehicle_type_id && errors.vehicle_type_id}
                      >
                        <Select
                          //onChange={value => setFieldValue("vehicle_type_id", value)}
                          onChange={(value) =>
                            this.onVehicleTypeChange(value, setFieldValue)
                          }
                          value={values.vehicle_type_id}
                        >
                          <Option value="">
                            <LangContext.Consumer>
                              {(i18n) => i18n.a.all}
                            </LangContext.Consumer>
                          </Option>
                          {vehicleTypeLists.map((item) => (
                            <Option key={item.id} value={item.id}>
                              {item.name}
                            </Option>
                          ))}
                        </Select>
                      </FormItem>
                      <FormItem
                        required={true}
                        label={
                          <LangContext.Consumer>
                            {(i18n) => i18n.v.vehicle}
                          </LangContext.Consumer>
                        }
                        validateStatus={
                          touched.list_vehicle_id &&
                          errors.list_vehicle_id &&
                          "warning"
                        }
                        help={touched.list_vehicle_id && errors.list_vehicle_id}
                      >
                        <Transfer
                          titles={[
                            <LangContext.Consumer>
                              {(i18n) => i18n.i.itemLeft}
                            </LangContext.Consumer>,
                            <LangContext.Consumer>
                              {(i18n) => i18n.i.itemSelected}
                            </LangContext.Consumer>,
                          ]}
                          dataSource={this.state.vehicleList}
                          showSearch
                          listStyle={{
                            width: 250,
                            height: 300,
                          }}
                          selectedKeys={this.state.selectedKeys}
                          operations={[">>", "<<"]}
                          targetKeys={this.state.vehicleSelect}
                          onSelectChange={this.handleSelectChange}
                          onChange={(value) =>
                            this.tranferChange(value, setFieldValue)
                          }
                          render={(item) => `${item.name}`}
                          footer={this.renderFooter}
                        />
                      </FormItem>
                      {this.props.call !== "schedule" ? (
                        <Form.Item
                          label={
                            <LangContext.Consumer>
                              {(i18n) => i18n.d.dateRange}
                            </LangContext.Consumer>
                          }
                          validateStatus={
                            touched.eventdate && errors.eventdate && "warning"
                          }
                          help={touched.eventdate && errors.eventdate}
                        >
                          <RangePicker
                            onChange={(value) =>
                              setFieldValue("eventdate", value)
                            }
                            format="DD/MM/YYYY"
                            defaultValue={[
                              moment("00:00", "HH:mm"),
                              moment("23:59", "HH:mm")
                            ]}
                          />
                        </Form.Item>
                      ) : (
                        ""
                      )}
                      <Row>
                        <Col span={16}>
                          <FormItem
                            label={
                              <LangContext.Consumer>
                                {(i18n) => i18n.n.number_of_days}
                              </LangContext.Consumer>
                            }
                            validateStatus={
                              touched.op_of_amount_date &&
                              errors.op_of_amount_date &&
                              "warning"
                            }
                            help={
                              touched.op_of_amount_date &&
                              errors.op_of_amount_date
                            }
                          >
                            <Select
                              //showSearch
                              // style={{ width: 200 }}
                              onChange={(value) =>
                                setFieldValue("op_of_amount_date", value)
                              }
                              value={values.op_of_amount_date}
                            >
                              <Option value="0">
                                <LangContext.Consumer>
                                  {(i18n) => i18n.n.no_filter}
                                </LangContext.Consumer>
                              </Option>
                              <Option value="1">
                                <LangContext.Consumer>
                                  {(i18n) => i18n.g.greater_than}
                                </LangContext.Consumer>
                              </Option>
                              <Option value="2">
                                <LangContext.Consumer>
                                  {(i18n) => i18n.l.less_than}
                                </LangContext.Consumer>
                              </Option>
                            </Select>
                          </FormItem>
                        </Col>
                        <Col span={8}>
                          <FormItem label="">
                            <InputNumber
                              min={0}
                              type="number"
                              value={values.amount_date}
                              onChange={(value) =>
                                setFieldValue("amount_date", value)
                              }
                              style={{
                                width: "40%",
                                marginLeft: 5,
                                marginTop: "33px",
                              }}
                            />
                          </FormItem>
                        </Col>
                      </Row>
                      <FormItem
                        label={
                          <LangContext.Consumer>
                            {(i18n) => i18n.d.distance_more_than}
                          </LangContext.Consumer>
                        }
                      >
                        <InputNumber
                          min={0}
                          type="number"
                          value={values.distance_more_than}
                          onChange={(value) =>
                            setFieldValue("distance_more_than", value)
                          }
                          style={{ width: "40%", marginRight: 1 }}
                        />
                      </FormItem>

                      <FormItem>
                        <Row gutter={24}>
                          {this.props.call !== "schedule" ? (
                            <Col span={8}>
                              <Button type="defualt" block>
                                <Link to="/">
                                  <LangContext.Consumer>
                                    {(i18n) => i18n.c.cancel}
                                  </LangContext.Consumer>
                                </Link>
                              </Button>
                            </Col>
                          ) : (
                            ""
                          )}
                          {this.props.call !== "schedule" ? (
                            <Col span={8}>
                              <Dropdown
                                overlay={
                                  <Menu
                                    onClick={(value) =>
                                      this.handleMenuClick(
                                        value,
                                        setFieldValue,
                                        handleSubmit
                                      )
                                    }
                                  >
                                    <Menu.Item key="excel">
                                      <Icon type="file-excel" />{" "}
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.e.excel}
                                      </LangContext.Consumer>
                                    </Menu.Item>
                                    <Menu.Item key="pdf">
                                      <Icon type="file-pdf" />{" "}
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.p.pdf}
                                      </LangContext.Consumer>
                                    </Menu.Item>
                                  </Menu>
                                }
                              >
                                <Button type="primary" block>
                                  <LangContext.Consumer>
                                    {(i18n) => i18n.r.report}
                                  </LangContext.Consumer>{" "}
                                  <Icon type="down" />
                                </Button>
                              </Dropdown>
                            </Col>
                          ) : (
                            ""
                          )}
                          {this.props.call == "schedule" ? (
                            <Col span={8}>
                              <Button type="primary" htmlType="submit" block>
                                <LangContext.Consumer>
                                  {(i18n) => i18n.s.save}
                                </LangContext.Consumer>
                              </Button>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      </FormItem>
                    </Form>
                  )}
                </LangContext.Consumer>
              </Row>
            )}
          />
        </Spin>
      </div>
    );
  }
}
