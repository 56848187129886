import { Divider, List, Row, Col, Tooltip } from "antd";
import React, { useState } from "react";
import { Icons } from "../Asset/Icon";
import { ReactComponent as Alert } from "../Asset/Alert.svg";
import { ReactComponent as New } from "../Asset/new.svg";


const TabLists = ({ list, handleSelectType, isActiveId }) => {
  return (
    <>
      {list.length > 0 && (
        <>
          <List
            itemLayout="horizontal"
            dataSource={list}
            renderItem={(item, index) => (
              <List.Item
                onClick={() => {
                  handleSelectType(item);
                }}
                style={{
                  // padding: "10px 0px",
                  // cursor: "pointer",
                  ...(item.id == isActiveId && { backgroundColor: "#DFE3E8" }),
                }}
                className="ListItemMetaLandingPage"
              >
                <List.Item.Meta
                  title={
                    <Row type="flex" justify="space-around" align="middle">
                      <Col span={2} align='center' style={{ paddingTop: 8 }}>
                        <label
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          {Icons[item.news_category_id].icon()}
                        </label>
                      </Col>
                      <Col span={18}>
                        <label style={{ cursor: "pointer", fontWeight: 700 }}>
                          {item.topic}
                        </label>
                      </Col>
                      <Col span={2} align='center'>
                        {item.is_important == 1 || item.is_important == true ? <Tooltip title="ข่าวสำคัญ">
                          <Alert />
                        </Tooltip> : null}
                      </Col>
                      <Col span={2} align='center'>
                        {item.is_new == 1 || item.is_new == true ? <Tooltip title="ข่าวใหม่">
                          <New />
                        </Tooltip> : null}
                      </Col>
                    </Row>
                  }
                />
              </List.Item>
            )}
          />
          <Divider style={{ margin: 0 }} />
        </>
      )}
    </>
  );
};

export default TabLists;
