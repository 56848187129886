import React, { useState, useEffect } from "react";
import {
  loadTopic,
  loadVehicleByPolicy,
  loadCompanyList,
} from "../fetchAPI/apiClient";
import { Row, Col, Select, DatePicker, Button, Form } from "antd";
import LangContext, { i18n } from "modules/shared/context/langContext";
import moment from "moment";
import { ACCESS_BY_COMPANY_ID } from "../constant/Constant";

const { RangePicker } = DatePicker;

const { Option } = Select;

const Filter = ({ profile, onSubmit, filterObj }) => {
  const [policyType, setPolicyType] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);
  const [filterObject, setFilterObject] = useState({
    policyId: undefined,
    type: undefined,
    subType: undefined,
    vehicleSelected: undefined,
    vehicleGroupId: [],
    dateTime: [moment(), moment()],
    company: ACCESS_BY_COMPANY_ID.includes(profile.company_id)
      ? ACCESS_BY_COMPANY_ID[0].toString()
      : profile.company_id.toString(),
  });
  const [companyList, setCompanyList] = useState([]);
  const [checkPolicyId, setCheckPolicyId] = useState(false);
  useEffect(() => {
    loadTopic(
      {
        userId: profile.id,
        company_id: "",
      },
      (statusCode, response) => {
        setPolicyType(response.data.data);
      }
    );
    if (ACCESS_BY_COMPANY_ID.includes(profile.company_id)) {
      loadCompanyList({}, (status, response) => {
        setCompanyList(response.data.data);
      });
    }
  }, []);

  useEffect(() => {
    if (filterObj.vehicleSelected != filterObject.vehicleSelected) {
      setFilterObject((prev) => ({
        ...prev,
        vehicleSelected: filterObj.vehicleSelected.toString(),
      }));
    }
  }, [filterObj]);

  const onChangeDatepicker = (date, dateString) => {
    if (
      dateString &&
      moment(dateString[1], "YYYY-MM-DD").diff(
        moment(dateString[0], "YYYY-MM-DD"),
        "days"
      ) > 7
    ) {
      window.alert("เลือกห้ามเกิน 7 วัน");
    } else {
      setFilterObject((prev) => ({ ...prev, dateTime: date }));
    }
  };

  const selectList = ["event", "policy"].map((key) => {
    return (
      <Select.OptGroup
        key={key}
        label={
          <span style={{ color: "blue" }}>
            <LangContext.Consumer>
              {(i18n) => (key === "event" ? i18n.e[key] : i18n.p[key])}
            </LangContext.Consumer>
          </span>
        }
      >
        {policyType
          .filter((x) => x.topic_type === key)
          .map((item) => (
            <Option key={item.topic_id.toString()} value={item.topic_id}>
              {item.topic_name}
            </Option>
          ))}
      </Select.OptGroup>
    );
  });

  return (
    <Row gutter={[8, 8]}>
      {ACCESS_BY_COMPANY_ID.includes(profile.company_id) && (
        <Form.Item>
          <Col span={5} align="right">
            <Select
              value={filterObject.company}
              placeholder="เลือกบริษัท"
              style={{ width: "100%" }}
              onChange={(e) => {
                setFilterObject((prev) => {
                  loadTopic(
                    // 175,
                    {
                      userId: profile.id,
                      company_id: e,
                    },
                    (statusCode, response) => {
                      setPolicyType(response.data.data);
                    }
                  );
                  return {
                    ...prev,
                    company: e,
                    vehicleSelected: undefined,
                    policyId: undefined,
                    type: undefined,
                    subType: undefined,
                  };
                });
              }}
              showSearch
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {companyList.map((item) => (
                <Option key={item.id.toString()} value={item.id.toString()}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Col>
        </Form.Item>
      )}

      <Col
        span={ACCESS_BY_COMPANY_ID.includes(profile.company_id) ? 5 : 7}
        align="right"
      >
        <Form.Item
          validateStatus={`${checkPolicyId ? "error" : ""}`}
          help={`${checkPolicyId ? "กรุณาเลือกชนิดนโยบาย" : ""}`}
        >
          <Select
            value={filterObject.policyId}
            placeholder="ชนิดนโยบาย"
            style={{ width: "100%" }}
            showSearch
            filterOption={(inputValue, option) => {
              if (!["event", "policy"].includes(option.key)) {
                return (
                  option.props.children
                    .toLowerCase()
                    .indexOf(inputValue.toLowerCase()) >= 0
                );
              }
              return false;
            }}
            onChange={(e) => {
              setFilterObject((prev) => {
                let getType = policyType.find((val) => val.topic_id == e);
                loadVehicleByPolicy(
                  {
                    user_id: profile.id.toString(),
                    topic_id: [e],
                    topic_type: getType.topic_type,
                    company_id: !!filterObject.company
                      ? filterObject.company.toString()
                      : "",
                  },
                  (status, res) => {
                    setVehicleList(res.data.data);
                  }
                );
                setCheckPolicyId(false);
                return {
                  ...prev,
                  policyId: e,
                  type: getType.topic_type,
                  subType: getType.topic_sub_type,
                  vehicleSelected: undefined,
                };
              });
            }}
          >
            {selectList}
          </Select>
        </Form.Item>
      </Col>
      <Col
        span={ACCESS_BY_COMPANY_ID.includes(profile.company_id) ? 6 : 7}
        align="right"
      >
        <Form.Item>
          <Select
            disabled={!!filterObject.type ? false : true}
            value={filterObject.vehicleSelected}
            placeholder="เลือกพาหนะ"
            style={{ width: "100%" }}
            onChange={(e) => {
              if (e == undefined) {
                setFilterObject((prev) => {
                  return {
                    ...prev,
                    vehicleSelected: undefined,
                  };
                });
              } else {
                setFilterObject((prev) => {
                  return {
                    ...prev,
                    vehicleSelected: e.toString(),
                  };
                });
              }
            }}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            allowClear
            showSearch
          >
            {vehicleList.map((item) => (
              <Option key={item.id.toString()} value={item.id}>
                {`${item.plate_no} (${item.code})`}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col
        span={ACCESS_BY_COMPANY_ID.includes(profile.company_id) ? 6 : 7}
        align="center"
      >
        <Form.Item>
          <RangePicker
            onChange={onChangeDatepicker}
            style={{ width: "100%" }}
            value={filterObject.dateTime}
            allowClear={false}
          />
        </Form.Item>
      </Col>
      <Col span={2} align="right">
        <Form.Item>
          <Button
            type="primary"
            style={{
              width: "100%",
              backgroundColor: "#3f87e4",
              color: "white",
              borderRadius: 16,
            }}
            onClick={() => {
              if (!!filterObject.policyId) {
                return onSubmit(filterObject);
              }
              return setCheckPolicyId(true);
            }}
          >
            <LangContext.Consumer>
              {(i18n) => i18n.s.search}
            </LangContext.Consumer>
          </Button>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default Filter;
