import axios from "axios";
import { all, put, call, takeEvery } from "redux-saga/effects";
import { getErrorMsg } from "lib/fetch";

import * as actions from "./actions";

function* loadDepositFuelCreditHistory(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const { filterobj } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/depositfuelcredit`,
      {
        ...filterobj,
      },
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    );
    // console.log("companyLists", res.data.data);
    yield put(actions.loadDepositFuelCreditHistory.success(res.data));
  } catch (err) {
    yield put(actions.loadDepositFuelCreditHistory.failure());
  }
}
function* loadVehicleFuelCrediHistory(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const { obj } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/depositfuelcredit/getvehiclefromcode`,
      obj,
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    );
    // console.log("companyLists", res.data.data);
    yield put(actions.loadVehicleFuelCrediHistory.success(res.data));
  } catch (err) {
    yield put(actions.loadVehicleFuelCrediHistory.failure());
  }
}

function* loadVehicleInfo(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const { obj } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/depositfuelcredit/getvehicleinfo`,
      obj,
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    );
    // console.log("companyLists", res.data.data);
    yield put(actions.loadVehicleInfo.success(res.data));
  } catch (err) {
    yield put(actions.loadVehicleInfo.failure());
  }
}
function* loadDepositReason(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const { obj } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/depositfuelcredit/getdepositreason`,
      obj,
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    );
    // console.log("companyLists", res.data.data);
    yield put(actions.loadDepositReason.success(res.data));
  } catch (err) {
    yield put(actions.loadDepositReason.failure());
  }
}

function* updateVehicleFuelCredit(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const { obj, generateMsg } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/depositfuelcredit/updatefuelcredit`,
      obj,
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    );
    yield put(actions.updateVehicleFuelCredit.success(res.data));
  } catch (err) {
    yield put(actions.updateVehicleFuelCredit.failure(getErrorMsg(err)));
  }
  generateMsg();
}

export default function* watchmanageotp() {
  yield all([
    takeEvery(
      actions.LOAD_DEPOSIT_FUEL_CREDIT_HISTORY.REQUEST,
      loadDepositFuelCreditHistory
    ),
    takeEvery(
      actions.LOAD_VEHICLE_FUEL_CREDIT_HISTORY.REQUEST,
      loadVehicleFuelCrediHistory
    ),
    takeEvery(actions.LOAD_VEHICLE_INFO.REQUEST, loadVehicleInfo),
    takeEvery(actions.LOAD_REASON.REQUEST, loadDepositReason),
    takeEvery(
      actions.UPDATE_VEHICLE_FUEL_CREDIT.REQUEST,
      updateVehicleFuelCredit
    ),
  ]);
}
export { loadDepositFuelCreditHistory };
