import React, { Component, Fragment } from "react";
import { debounce } from "debounce";
import { Card, Divider, Row, Col, Icon } from "antd";
import LangContext, { i18n } from "modules/shared/context/langContext";
import { autoGenKey } from "../../../../lib/helper";
import {
  LineChart,
  Line,
  Legend,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  ResponsiveContainer,
  Brush,
  ComposedChart,
  BarChart,
  Bar,
  ReferenceArea,
} from "recharts";

const tickWidth = 140;
const chartLabelStyle = {
  marginLeft: "10px",
  position: "absolute",
  transform: " translateX(-50%) translateY(-50%) rotate(-90deg)",
};
const CustomTooltip = (props) => {
  const { active } = props;
  if (active && props.payload != null) {
    const { payload, label } = props;
    const reversePayload = payload.reverse();

    return (
      <div className="custom-tooltip">
        <div className="custom-tooltip-body">
          <p>{`${label}`}</p>
          {reversePayload.map((ele, i) => {
            if (ele.name == "taxiMeterOn")
              return (
                <p
                  key={autoGenKey("p-chart-tooltip-")}
                  style={{ color: `${ele.stroke}` }}
                >{`  ${ele.value} (฿${ele.payload.taxifare})`}</p>
              );
            else
              return (
                <p
                  key={autoGenKey("p-chart-tooltip-")}
                  style={{ color: `${ele.stroke}` }}
                >{`  ${ele.value}`}</p>
              );
          })}
        </div>
      </div>
    );
  }
  return null;
};
////////////////////////////////////////////////////////////
///////////////////// for make fakeData  //////////////////
///////////////////////////////////////////////////////////
// var x = 3; //minutes interval
// var data = [];
// var tt = 0; // start time

// for (var i = 0; tt < 24 * 20; i++) {
//   var hh = Math.floor(tt / 60);
//   var mm = tt % 60;
//   data[i] = {
//     name: ("0" + (hh % 12)).slice(-2) + ":" + ("0" + mm).slice(-2),
//     speed: Math.floor(Math.random() * (50 - 0 + 1)) + 0
//   };

//   tt = tt + x;
// }

///////////////////////////////////////////////////////////
///////////////// end  for make fakeData  /////////////////
///////////////////////////////////////////////////////////
const data = [
  {
    lat: 13.542084,
    lng: 100.788491,
    directionDegree: 89,
    name: "08:00",
    speed: 80,
    engineOn: "Engine on",
    boom: "Boom",
    img:
      "http://media.terminusfleet.com/imageserver/get_gps_image.php?key=259,450caa7e377d456d&type=0",
    img2:
      "http://media.terminusfleet.com/imageserver/get_gps_image.php?key=163,450cab1a1f046e51&type=0",
  },
  {
    lat: 13.544062,
    lng: 100.789119,
    directionDegree: 89,
    name: "08:02",
    speed: 82,
    engineOn: "Engine on",
    boom: "Boom",
    img:
      "http://media.terminusfleet.com/imageserver/get_gps_image.php?key=148,45050126b2877ed8&type=0",
    img2:
      "http://media.terminusfleet.com/imageserver/get_gps_image.php?key=24,4504e533f541942d&type=0",
  },
  {
    lat: 13.553257,
    lng: 100.787868,
    directionDegree: 85,
    name: "08:04",
    speed: 100,
    engineOn: "Engine on",
    speedOverLimit: "Speed over",
    boom: "Boom",
    pto: "PTO",
    img:
      "http://media.terminusfleet.com/imageserver/get_gps_image.php?key=157,4505361325a49af0&type=0",
    img2:
      "http://media.terminusfleet.com/imageserver/get_gps_image.php?key=62,45056c540a10ff28&type=0",
  },
  {
    lat: 13.556021,
    lng: 100.78823,
    directionDegree: 84,
    name: "08:06",
    speed: 118,
    engineOn: "Engine on",
    speedOverLimit: "Speed over",
    boom: "Boom",
    img:
      "http://media.terminusfleet.com/imageserver/get_gps_image.php?key=214,45ec6e2f2ef40882&type=0",
    img2:
      "http://media.terminusfleet.com/imageserver/get_gps_image.php?key=163,450cab1a1f046e51&type=0",
  },
  {
    lat: 13.557542,
    lng: 100.78878,
    directionDegree: 87,
    name: "08:08",
    speed: 83,
    engineOn: "Engine on",
    boom: "Boom",
    img:
      "http://media.terminusfleet.com/imageserver/get_gps_image.php?key=109,45ec8223b78be8fc&type=0",
    img2:
      "http://media.terminusfleet.com/imageserver/get_gps_image.php?key=163,450cab1a1f046e51&type=0",
  },
  {
    lat: 13.562131,
    lng: 100.790207,
    directionDegree: 88,
    name: "08:10",
    speed: 109,
    engineOn: "Engine on",
    speedOverLimit: "Speed over",
    boom: "Boom",
    pto: "PTO",
    img:
      "http://media.terminusfleet.com/imageserver/get_gps_image.php?key=149,45eca4b1cae838ad&type=0",
    img2:
      "http://media.terminusfleet.com/imageserver/get_gps_image.php?key=163,450cab1a1f046e51&type=0",
  },
  {
    lat: 13.564467,
    lng: 100.791087,
    directionDegree: 85,
    name: "08:12",
    speed: 43,
    engineOn: "Engine on",
    pto: "PTO",
    img:
      "http://media.terminusfleet.com/imageserver/get_gps_image.php?key=286,45ecc49c7fc61de9&type=0",
    img2:
      "http://media.terminusfleet.com/imageserver/get_gps_image.php?key=163,450cab1a1f046e51&type=0",
  },
  {
    lat: 13.56867,
    lng: 100.792299,
    directionDegree: 84,
    name: "08:14",
    speed: 3,
    engineOn: "Engine on",
    stopWithEngineOn: "Stop with engine on",
    img:
      "http://media.terminusfleet.com/imageserver/get_gps_image.php?key=157,45ece2c9ee0ebd8a&type=0",
    img2:
      "http://media.terminusfleet.com/imageserver/get_gps_image.php?key=163,450cab1a1f046e51&type=0",
  },
  {
    lat: 13.573728,
    lng: 100.793726,
    directionDegree: 82,
    name: "08:16",
    speed: 5,
    engineOn: "Engine on",
    stopWithEngineOn: "Stop with engine on",
    img:
      "http://media.terminusfleet.com/imageserver/get_gps_image.php?key=259,450caa7e377d456d&type=0",
    img2:
      "http://media.terminusfleet.com/imageserver/get_gps_image.php?key=163,450cab1a1f046e51&type=0",
  },
  {
    lat: 13.578786,
    lng: 100.795346,
    directionDegree: 83,
    name: "08:18",
    speed: 40,
    engineOn: "Engine on",
    door: "Door",
    img:
      "http://media.terminusfleet.com/imageserver/get_gps_image.php?key=212,45ed77d39b99a7e5&type=0",
    img2:
      "http://media.terminusfleet.com/imageserver/get_gps_image.php?key=163,450cab1a1f046e51&type=0",
  },
  {
    lat: 13.580721,
    lng: 100.796194,
    directionDegree: 89,
    speed: 30,
    name: "08:20",
    engineOn: "Engine on",
    img:
      "http://media.terminusfleet.com/imageserver/get_gps_image.php?key=12,45ed95d4135ccdc9&type=0",
    img2:
      "http://media.terminusfleet.com/imageserver/get_gps_image.php?key=163,450cab1a1f046e51&type=0",
  },
  {
    lat: 13.582693,
    lng: 100.799205,
    directionDegree: 359,
    speed: 43,
    name: "08:22",
    engineOn: "Engine on",
    img:
      "http://media.terminusfleet.com/imageserver/get_gps_image.php?key=95,45edb5e7b95c925c&type=0",
    img2:
      "http://media.terminusfleet.com/imageserver/get_gps_image.php?key=163,450cab1a1f046e51&type=0",
  },
  {
    lat: 13.579439,
    lng: 100.804376,
    directionDegree: 89,
    speed: 0,
    name: "08:24",
    engineOn: "Engine on",
    engineOff: "Engine off",
    img:
      "http://media.terminusfleet.com/imageserver/get_gps_image.php?key=17,45edd2660592fbd2&type=0",
    img2:
      "http://media.terminusfleet.com/imageserver/get_gps_image.php?key=163,450cab1a1f046e51&type=0",
  },
  {
    lat: 13.579439,
    lng: 100.804376,
    directionDegree: 89,
    speed: 0,
    name: "08:26",
    engineOff: "Engine off",
    img:
      "http://media.terminusfleet.com/imageserver/get_gps_image.php?key=17,45edd2660592fbd2&type=0",
    img2:
      "http://media.terminusfleet.com/imageserver/get_gps_image.php?key=163,450cab1a1f046e51&type=0",
  },
  {
    lat: 13.579439,
    lng: 100.804376,
    directionDegree: 89,
    speed: 0,
    name: "08:28",
    engineOff: "Engine off",
    img:
      "http://media.terminusfleet.com/imageserver/get_gps_image.php?key=17,45edd2660592fbd2&type=0",
    img2:
      "http://media.terminusfleet.com/imageserver/get_gps_image.php?key=163,450cab1a1f046e51&type=0",
  },
  {
    lat: 13.579439,
    lng: 100.804376,
    directionDegree: 89,
    speed: 0,
    name: "08:30",
    engineOff: "Engine off",
    engineOn: "Engine on",
    door: "Door",
    boom: "Boom",
    img:
      "http://media.terminusfleet.com/imageserver/get_gps_image.php?key=17,45edd2660592fbd2&type=0",
    img2:
      "http://media.terminusfleet.com/imageserver/get_gps_image.php?key=163,450cab1a1f046e51&type=0",
  },
  {
    lat: 13.568845,
    lng: 100.818948,
    directionDegree: 324,
    name: "08:32",
    speed: 10,
    engineOn: "Engine on",
    boom: "Boom",
    img:
      "http://media.terminusfleet.com/imageserver/get_gps_image.php?key=298,45ee1fdb5826b3ef&type=0",
    img2:
      "http://media.terminusfleet.com/imageserver/get_gps_image.php?key=163,450cab1a1f046e51&type=0",
  },
  {
    lat: 13.567981,
    lng: 100.823982,
    directionDegree: 23,
    name: "08:34",
    speed: 30,
    engineOn: "Engine on",
    boom: "Boom",
    img:
      "http://media.terminusfleet.com/imageserver/get_gps_image.php?key=115,45ee896334b2ab6a&type=0",
    img2:
      "http://media.terminusfleet.com/imageserver/get_gps_image.php?key=163,450cab1a1f046e51&type=0",
  },
  {
    lat: 13.575032,
    lng: 100.83127,
    directionDegree: 90,
    name: "08:36",
    speed: 59,
    engineOn: "Engine on",
    boom: "Boom",
    pto: "PTO",
    img:
      "http://media.terminusfleet.com/imageserver/get_gps_image.php?key=115,45ee896334b2ab6a&type=0",
    img2:
      "http://media.terminusfleet.com/imageserver/get_gps_image.php?key=163,450cab1a1f046e51&type=0",
  },
  {
    lat: 13.582439,
    lng: 100.834135,
    directionDegree: 74,
    name: "08:38",
    speed: 95,
    engineOn: "Engine on",
    speedOverLimit: "Speed over",
    boom: "Boom",
    pto: "PTO",
    img:
      "http://media.terminusfleet.com/imageserver/get_gps_image.php?key=303,45eeee9ee6de6c60&type=0",
    img2:
      "http://media.terminusfleet.com/imageserver/get_gps_image.php?key=163,450cab1a1f046e51&type=0",
  },
  {
    lat: 13.586987,
    lng: 100.837307,
    directionDegree: 89,
    name: "08:40",
    speed: 50,
    engineOn: "Engine on",
    boom: "Boom",
    pto: "PTO",
    img:
      "http://media.terminusfleet.com/imageserver/get_gps_image.php?key=259,450caa7e377d456d&type=0",
    img2:
      "http://media.terminusfleet.com/imageserver/get_gps_image.php?key=163,450cab1a1f046e51&type=0",
  },
  {
    lat: 13.588078,
    lng: 100.835268,
    directionDegree: 174,
    name: "08:38",
    speed: 35,
    engineOn: "Engine on",
    boom: "Boom",
    img:
      "http://media.terminusfleet.com/imageserver/get_gps_image.php?key=243,45eee0d02042c016&type=0",
    img2:
      "http://media.terminusfleet.com/imageserver/get_gps_image.php?key=163,450cab1a1f046e51&type=0",
  },
  {
    lat: 13.588491,
    lng: 100.833022,
    directionDegree: 150,
    name: "08:40",
    speed: 30,
    engineOn: "Engine on",
    boom: "Boom",
    pto: "PTO",
    img:
      "http://media.terminusfleet.com/imageserver/get_gps_image.php?key=259,450caa7e377d456d&type=0",
    img2:
      "http://media.terminusfleet.com/imageserver/get_gps_image.php?key=163,450cab1a1f046e51&type=0",
  },
];

function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

for (let i = 0; i < 700; i++) {
  data.push({
    lat: "13.5" + randomIntFromInterval(0, 9000),
    lng: "100.8" + randomIntFromInterval(0, 9000),
    directionDegree: randomIntFromInterval(0, 359),
    name: "08:" + i,
    speed: randomIntFromInterval(30, 100),
    engineOn: "Engine on",
    img:
      "http://media.terminusfleet.com/imageserver/get_gps_image.php?key=259,450caa7e377d456d&type=0",
    img2:
      "http://media.terminusfleet.com/imageserver/get_gps_image.php?key=163,450cab1a1f046e51&type=0",
  });
}

for (let i = 0; i < 700; i++) {
  data.push({
    lat: "13.588" + randomIntFromInterval(0, 1000),
    lng: "100.8" + randomIntFromInterval(0, 500),
    directionDegree: randomIntFromInterval(0, 359),
    name: "08:" + i,
    speed: 3,
    engineOff: "Engine off",
    img:
      "http://media.terminusfleet.com/imageserver/get_gps_image.php?key=259,450caa7e377d456d&type=0",
    img2:
      "http://media.terminusfleet.com/imageserver/get_gps_image.php?key=163,450cab1a1f046e51&type=0",
  });
}
export default class Chart extends Component {
  state = {
    startIndex: 0,
    endIndex: 0,
    currentInfo: null,
    opacity: {
      speed: 1,
      engineOn: 1,
      engineOff: 1,
      speedOverLimit: 1,
      door: 1,
      stopWithEngineOn: 1,
      boom: 1,
      pto: 1,
      image: 1,
      SOS: 1,
      taxiMeterOn: 1,
    },
  };

  handleMouseEnter = (o) => {
    const { dataKey } = o;
    const { opacity } = this.state;

    for (var key in opacity) {
      // console.log("key " + key + " has value " + opacity[key]);
      if (dataKey !== key) opacity[key] = 0;
    }
    // console.log(opacity);
    this.setState({
      opacity: { ...opacity, [dataKey]: 1 },
    });
  };

  handleMouseLeave = (o) => {
    const { dataKey } = o;
    const { opacity } = this.state;

    for (var key in opacity) {
      opacity[key] = 1;
    }
    this.setState({
      opacity: { ...opacity },
    });
  };

  addVirtualVehicle = (e, activeTooltipIndex) => {
    console.log("addVirtualVehicle", e.payload, activeTooltipIndex)
    this.props.handleVirtualMarkerChange(e.payload, activeTooltipIndex);
  };

  customTick = (e) => {
    let time = e.payload.value.split(" ")[1];
    let formatTime = time.split(":")[0] + ":" + time.split(":")[1];
    return (
      <g transform={`translate(${e.x},${e.y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          // transform="rotate(-35)"
        >
          {formatTime}
        </text>
      </g>
    );
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.data != this.props.data) return true;
    if (nextProps.currentSelectedPosition != this.props.currentSelectedPosition)
      return true;
    if (nextProps.isShowSpeedChart != this.props.isShowSpeedChart) return true;
    if (nextProps.isShowEventsChart != this.props.isShowEventsChart)
      return true;
    if (nextProps.isShowFuelChart != this.props.isShowFuelChart) return true;
    if (nextProps.isShowTaxiFareChart != this.props.isShowTaxiFareChart)
      return true;
    if (nextProps.isShowTempChart != this.props.isShowTempChart) return true;

    return false;
  }

  render() {
    return (
      <Fragment>
        {this.props.isShowSpeedChart && (
          <Fragment>
            <h3 style={{ ...chartLabelStyle, marginTop: "70px" }}>
              <LangContext.Consumer>
                {(i18n) => i18n.s.speed}
              </LangContext.Consumer>
            </h3>
            <LineChart
              width={window.innerWidth - 270}
              height={160}
              // onClick={e => console.log(e)}
              onClick={(e) => {
                if (e != null)
                  this.addVirtualVehicle(
                    e.activePayload[0],
                    e.activeTooltipIndex
                  );
              }}
              data={this.props.data}
              syncId="anyId"
              margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 10,
              }}
              // onMouseEnter={e => console.log(e)}
            >
              <Brush
                onChange={(e) => this.setState({ e })}
                style={{ display: "none" }}
                height={20}
                startIndex={this.state.startIndex}
                endIndex={this.state.endIndex}
              />

              <ReferenceLine
                ifOverflow="extendDomain"
                x={this.props.currentSelectedPosition}
                stroke="red"
                strokeWidth={2}
              />

              {this.props.driverPeriod.map((ele, index) => {
                return (
                  <ReferenceArea
                    key={"driver_ref_" + index}
                    ifOverflow="extendDomain"
                    label={ele.fullname}
                    x1={ele.start_at}
                    x2={ele.end_at}
                    y1={140}
                    y2={170}
                    isFront={true}
                    strokeOpacity={1}
                    fill={index % 2 == 0 ? "#E8EA86" : "#93DB7C"}
                  />
                );
              })}
              {this.props.data.length > 0 && (
                <ReferenceLine
                  label={"Speed Limit"}
                  x1={this.props.data[0]["created_at"]}
                  x2={this.props.data[this.props.data.length - 1]["created_at"]}
                  y={this.props.data[0]["vehicleSpeedLimitPolicy"]}
                  stroke="red"
                  strokeDasharray="3 3"
                />
              )}

              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="created_at"
                type="category"
                tick={(e) => this.customTick(e)}
              />
              <YAxis
                type="number"
                width={tickWidth}
                dot={false}
                domain={[0, 180]}
              />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="speed"
                stroke="#82ca9d"
                dot={false}
                strokeWidth={1.6}
              />
            </LineChart>
          </Fragment>
        )}

        {this.props.isShowEventsChart && (
          <Fragment>
            <h3 style={{ ...chartLabelStyle, marginTop: "80px" }}>
              <LangContext.Consumer>
                {(i18n) => i18n.e.event}
              </LangContext.Consumer>
            </h3>
            <LineChart
              width={window.innerWidth - 270}
              height={210}
              data={this.props.data}
              onClick={(e) => {
                if (e != null)
                  this.addVirtualVehicle(
                    e.activePayload[0],
                    e.activeTooltipIndex
                  );
              }}
              syncId="anyId"
              margin={{
                top: 10,
                right: 20,
                left: 0,
                bottom: 10,
              }}
            >
              <Brush
                onChange={(e) => this.setState({ e })}
                style={{ display: "none" }}
                height={0}
                width={0}
                startIndex={this.state.startIndex}
                endIndex={this.state.endIndex}
              />

              <ReferenceLine
                ifOverflow="extendDomain"
                x={this.props.currentSelectedPosition}
                stroke="red"
                strokeWidth={2}
              />

              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="created_at" tick={(e) => this.customTick(e)} />
              <YAxis type="category" width={tickWidth} />
              <Tooltip content={<CustomTooltip />} />

              {/* <Legend
                  onMouseEnter={this.handleMouseEnter}
                  onMouseLeave={this.handleMouseLeave}
                /> */}
              <Line
                // strokeWidth={6}
                type="linear"
                dataKey="SOS"
                stroke="black"
                fill="black"
                // strokeDasharray="5 5"
                // dot={false}
                strokeOpacity={this.state.opacity.SOS}
              />

              <Line
                // strokeWidth={6}
                type="linear"
                dataKey="taxiMeterOn"
                stroke="#B837EC"
                fill="#B837EC"
                // strokeDasharray="5 5"
                // dot={false}
                strokeOpacity={this.state.opacity.taxiMeterOn}
              />

              <Line
                // strokeWidth={6}
                type="linear"
                dataKey="engineOn"
                stroke="#07A70C"
                fill="#07A70C"
                // dot={false}
                strokeOpacity={this.state.opacity.engineOn}
              />

              <Line
                // strokeWidth={6}
                type="linear"
                dataKey="engineOff"
                stroke="#EF0303"
                fill="#EF0303"
                // dot={false}
                strokeOpacity={this.state.opacity.engineOff}
              />

              <Line
                // strokeWidth={6}
                type="linear"
                dataKey="speedOverLimit"
                stroke="#FA9A01"
                fill="#FA9A01"
                // strokeDasharray="5 5"
                // dot={false}
                strokeOpacity={this.state.opacity.speedOverLimit}
              />

              <Line
                // strokeWidth={6}
                type="linear"
                dataKey="stopWithEngineOn"
                stroke="#AEAEAE"
                fill="#AEAEAE"
                // strokeDasharray="5 5"
                // dot={false}
                strokeOpacity={this.state.opacity.stopWithEngineOn}
              />

              {/* <Line
                // strokeWidth={6}
                type="linear"
                dataKey="image"
                stroke="#AEAEAE"
                fill="#AEAEAE"
                // strokeDasharray="5 5"
                // dot={false}
                strokeOpacity={this.state.opacity.image}
              /> */}
            </LineChart>
          </Fragment>
        )}

        {this.props.isShowFuelChart && (
          <Fragment>
            <h3 style={{ ...chartLabelStyle, marginTop: "60px" }}>
              <LangContext.Consumer>
                {(i18n) => i18n.f.fuel}
              </LangContext.Consumer>
            </h3>
            <LineChart
              width={window.innerWidth - 275}
              height={150}
              data={this.props.data}
              onClick={(e) => {
                if (e != null)
                  this.addVirtualVehicle(
                    e.activePayload[0],
                    e.activeTooltipIndex
                  );
              }}
              syncId="anyId"
              margin={{
                top: 10,
                right: 10,
                left: 75,
                bottom: 10,
              }}
            >
              <ReferenceLine
                ifOverflow="extendDomain"
                x={this.props.currentSelectedPosition}
                stroke="red"
                strokeWidth={2}
              />

              <Line
                type="monotone"
                dataKey="fuelStatus"
                stroke="#177ECF"
                dot={false}
                strokeWidth={2}
              />

              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="created_at" tick={(e) => this.customTick(e)} />
              <XAxis
                dataKey="created_at"
                type="category"
                tick={(e) => this.customTick(e)}
              />
              <YAxis type="number" dot={false} />
              <Tooltip content={<CustomTooltip />} />
            </LineChart>
          </Fragment>
        )}

        {this.props.isShowTempChart && (
          <Fragment>
            <h3 style={{ ...chartLabelStyle, marginTop: "60px" }}>
              <LangContext.Consumer>
                {(i18n) => i18n.t.temperature}
              </LangContext.Consumer>
            </h3>
            <LineChart
              width={window.innerWidth - 275}
              height={150}
              data={this.props.data}
              onClick={(e) => {
                if (e != null)
                  this.addVirtualVehicle(
                    e.activePayload[0],
                    e.activeTooltipIndex
                  );
              }}
              syncId="anyId"
              margin={{
                top: 10,
                right: 10,
                left: 75,
                bottom: 10,
              }}
            >
              <ReferenceLine
                ifOverflow="extendDomain"
                x={this.props.currentSelectedPosition}
                stroke="red"
                strokeWidth={2}
              />
              <Line
                type="monotone"
                dataKey="temp1Status"
                stroke="#82ca9d"
                dot={false}
                strokeWidth={2}
                // label={<CustomizedLabel />}
              />

              <Line
                type="monotone"
                dataKey="temp2Status"
                stroke="#E8DC21"
                dot={false}
                strokeWidth={2}
                // label={<CustomizedLabel />}
              />
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="created_at" tick={(e) => this.customTick(e)} />
              <XAxis
                dataKey="created_at"
                type="category"
                tick={(e) => this.customTick(e)}
              />
              <YAxis type="number" dot={false} />
              <Tooltip content={<CustomTooltip />} />
            </LineChart>
          </Fragment>
        )}

        {/* {this.props.isShowTaxiFareChart && 
          <Fragment>
            <h3 style={{ ...chartLabelStyle, marginTop: "60px" }}>Taxi Fare</h3>
            <LineChart
              width={window.innerWidth - 275}
              height={150}
              data={this.props.data}
              onClick={e => {
                if (e != null)
                  this.addVirtualVehicle(e.activePayload[0], e.activeTooltipIndex);
              }}
              syncId="anyId"
              margin={{
                top: 5,
                right: 10,
                left: 75,
                bottom: 5
              }}
            >
              <ReferenceLine
                ifOverflow="extendDomain"
                x={this.props.currentSelectedPosition}
                stroke="red"
                strokeWidth={2}
              />
              <Line
                type="monotone"
                dataKey="taxifare"
                stroke="#82ca9d"
                dot={false}
                strokeWidth={2}
                // label={<CustomizedLabel />}
              />

  
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="created_at" tick={e => this.customTick(e)} />
              <XAxis
                dataKey="created_at"
                type="category"
                tick={e => this.customTick(e)}
              />
              <YAxis type="number" dot={false} />
                   <Tooltip content={<CustomTooltip/>}/>
            </LineChart>
          </Fragment>
        } */}
      </Fragment>
    );
  }
}
