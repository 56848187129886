import React, { Component, Fragment } from "react";
import { Row, Col, Carousel, Icon, Button, Tooltip, Tabs } from "antd";
import { helper_convertDateformat } from "../../../../lib/helper";
import LangContext, { i18n } from "modules/shared/context/langContext";
import { checkPermissions } from "../../../../lib/helper";
import { Link, Redirect, Route } from "react-router-dom";
const { TabPane } = Tabs;
const ButtonGroup = Button.Group;
export class Popup extends Component {
  componentDidMount() {}

  handleImageLoaded = (event) => {
    event.target.parentNode.firstChild.style.display = "none";
    event.target.style.display = "inherit";
  };

  handleImageErrored = (event) => {
    event.target.src = "/img/no-image.jpg";
  };

  handleLinkCreateLocaion = () => {
    let url = window.location.origin;
    window.open(
      `${url}/location/create/${this.props.selectedVehicle.lat}/${this.props.selectedVehicle.lng}`
    );
  };

  render() {
    return (
      <div
        style={{
          borderRadius: "8px 8px 8px 8px",
          backgroundColor: "white",
          width: this.props.width,
          minHeight: `${window.innerHeight - 220}px`,
          padding: "10px 0px 0px 0px",
          position: "fixed",
          zIndex: "1",
          top: 82,
          // marginLeft: "0px",
          marginLeft: `${this.props.panelWidth + 12}px`,
          overflowX: "hidden",
          transition: "0.5s",
        }}
      >
        <Row>
          <Col
            span={22}
            offset={1}
            style={{ minHeight: "200px", maxHeight: "250px" }}
          >
            <Icon
              style={{
                fontSize: "15px",
                position: "absolute",
                right: -5,
                zIndex: 10,
                color: "#40a9ff",
                fontWeight: "bold",
              }}
              size="small"
              onClick={this.props.closeNav}
              type="close"
            />
            <Carousel autoplay>
              <div>
                <img
                  style={{
                    width: "100%",
                    padding: "10px 15px 0px 15px",
                  }}
                  onLoad={this.handleImageLoaded.bind(this)}
                  onError={this.handleImageErrored.bind(this)}
                  src={
                    this.props.selectedVehicle.images.length > 0
                      ? this.props.selectedVehicle.images[0]
                      : "/img/no-image.jpg"
                  }
                />
              </div>
              {this.props.selectedVehicle.images[1] && (
                <div>
                  <img src="/img/no-image.jpg" />
                  <img
                    style={{
                      width: "100%",
                      padding: "10px 15px 0px 15px",
                      display: "none",
                    }}
                    onLoad={this.handleImageLoaded.bind(this)}
                    onError={this.handleImageErrored.bind(this)}
                    src={
                      this.props.selectedVehicle.images.length > 0
                        ? this.props.selectedVehicle.images[1]
                        : "/img/no-image.jpg"
                    }
                  />
                </div>
              )}
            </Carousel>
          </Col>
        </Row>
        <Row>
          <Col span={22} offset={2}>
            <Tabs
              size="small"
              defaultActiveKey="10a"
              //   activeKey="10a"
              animated={false}
              tabBarStyle={{ width: "300px" }}
              tabBarGutter={0}
              style={{
                height: `${window.innerHeight - 370}px`,
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <TabPane
                tab={
                  <LangContext.Consumer>
                    {(i18n) => i18n.v.vehicle}
                  </LangContext.Consumer>
                }
                key="10a"
              >
                <table className="tracking-detail-table">
                  <tbody>
                    <tr>
                      <th>
                        {
                          <LangContext.Consumer>
                            {(i18n) => i18n.p.plateNo}
                          </LangContext.Consumer>
                        }
                      </th>
                      <td>{this.props.selectedVehicle.plate_no}</td>
                    </tr>
                    <tr>
                      <th>
                        {
                          <LangContext.Consumer>
                            {(i18n) => i18n.v.vehicleCode}
                          </LangContext.Consumer>
                        }
                      </th>
                      <td>{this.props.selectedVehicle.vehicle_code}</td>
                    </tr>
                    <tr>
                      <th>
                        {
                          <LangContext.Consumer>
                            {(i18n) => i18n.v.vehicleCertNumber}
                          </LangContext.Consumer>
                        }
                      </th>
                      <td>{`${this.props.selectedVehicle.track_3} `}</td>
                    </tr>
                    <tr>
                      <th>
                        {
                          <LangContext.Consumer>
                            {(i18n) => i18n.e.estTransitDistance}
                          </LangContext.Consumer>
                        }
                      </th>
                      <td>{this.props.selectedVehicle.travel_distance}</td>
                    </tr>
                    <tr>
                      <th>
                        {
                          <LangContext.Consumer>
                            {(i18n) => i18n.d.durationRange}
                          </LangContext.Consumer>
                        }
                      </th>
                      <td>{this.props.selectedVehicle.duration_range}</td>
                    </tr>
                    <tr>
                      <th>
                        {
                          <LangContext.Consumer>
                            {(i18n) => i18n.e.engineStatus}
                          </LangContext.Consumer>
                        }
                      </th>
                      <td>
                        {this.props.selectedVehicle.engine == 1 ? "Off" : "On"}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        {
                          <LangContext.Consumer>
                            {(i18n) => i18n.s.speed}
                          </LangContext.Consumer>
                        }
                      </th>
                      <td>{this.props.selectedVehicle.speed} km/h</td>
                    </tr>
                    <tr>
                      <th>
                        {
                          <LangContext.Consumer>
                            {(i18n) => i18n.v.vehicleGroup}
                          </LangContext.Consumer>
                        }
                      </th>
                      <td>
                        {Array.isArray(
                          this.props.selectedVehicle.vehicle_group
                        ) &&
                          this.props.selectedVehicle.vehicle_group.map(
                            (ele) => ele.vehicle_group_name
                          )}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        {
                          <LangContext.Consumer>
                            {(i18n) => i18n.v.vehicleModel}
                          </LangContext.Consumer>
                        }
                      </th>
                      <td>{this.props.selectedVehicle.model}</td>
                    </tr>
                    <tr>
                      <th>
                        {
                          <LangContext.Consumer>
                            {(i18n) => i18n.v.vehicleColor}
                          </LangContext.Consumer>
                        }
                      </th>
                      <td>{this.props.selectedVehicle.color}</td>
                    </tr>
                    <tr>
                      <th>
                        {
                          <LangContext.Consumer>
                            {(i18n) => i18n.c.currentAddress}
                          </LangContext.Consumer>
                        }
                      </th>
                      <td>{this.props.selectedVehicle.address}</td>
                    </tr>
                  </tbody>
                </table>
              </TabPane>
              <TabPane
                tab={
                  <LangContext.Consumer>
                    {(i18n) => i18n.c.chauffeur}
                  </LangContext.Consumer>
                }
                key="20b"
              >
                <table className="tracking-detail-table">
                  <tbody>
                    <tr>
                      <th>
                        {
                          <LangContext.Consumer>
                            {(i18n) => i18n.n.nameFromDrivingID}
                          </LangContext.Consumer>
                        }
                      </th>
                      <td>
                        {this.props.selectedVehicle.driver_license_info &&
                          `${this.props.selectedVehicle.driver_license_info.fname} ${this.props.selectedVehicle.driver_license_info.lname}`}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        {
                          <LangContext.Consumer>
                            {(i18n) => i18n.s.slidingCard}
                          </LangContext.Consumer>
                        }
                      </th>
                      <td>
                        {this.props.selectedVehicle.gps_active_at != "now" ? (
                          <div>
                            <Icon
                              type="minus-circle"
                              theme="twoTone"
                              twoToneColor="#BDBDBD"
                            />{" "}
                            {
                              <LangContext.Consumer>
                                {(i18n) => i18n.l.lostConnectio}
                              </LangContext.Consumer>
                            }
                          </div>
                        ) : this.props.selectedVehicle.engine == 1 ? (
                          <div>
                            <Icon
                              type="minus-circle"
                              theme="twoTone"
                              twoToneColor="#BDBDBD"
                            />{" "}
                            {
                              <LangContext.Consumer>
                                {(i18n) => i18n.e.engineOff}
                              </LangContext.Consumer>
                            }
                          </div>
                        ) : this.props.selectedVehicle
                            .driver_license_swiping_status != null ? (
                          this.props.selectedVehicle
                            .driver_license_swiping_status.status ? (
                            <Icon
                              type="check-circle"
                              theme="twoTone"
                              twoToneColor="#52c41a"
                            />
                          ) : this.props.selectedVehicle
                              .driver_license_swiping_status.code == 500 ? (
                            <div>
                              <Icon
                                type="close-circle"
                                theme="twoTone"
                                twoToneColor="#F10000"
                              />{" "}
                              {
                                <LangContext.Consumer>
                                  {(i18n) =>
                                    i18n.d.doNotSwipeCardsOrDamageCards
                                  }
                                </LangContext.Consumer>
                              }
                            </div>
                          ) : this.props.selectedVehicle
                              .driver_license_swiping_status.code == 501 ? (
                            <div>
                              <Icon
                                type="close-circle"
                                theme="twoTone"
                                twoToneColor="#F10000"
                              />{" "}
                              {
                                <LangContext.Consumer>
                                  {(i18n) => i18n.p.partiallyDamagedCard}
                                </LangContext.Consumer>
                              }
                            </div>
                          ) : (
                            <div>
                              <Icon
                                type="close-circle"
                                theme="twoTone"
                                twoToneColor="#F10000"
                              />{" "}
                              {
                                <LangContext.Consumer>
                                  {(i18n) => i18n.s.slideWrongCardType}
                                </LangContext.Consumer>
                              }
                            </div>
                          )
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>

                    <tr>
                      <th>
                        {
                          <LangContext.Consumer>
                            {(i18n) => i18n.d.drivingTime}
                          </LangContext.Consumer>
                        }
                      </th>
                      <td>
                        {`${
                          this.props.selectedVehicle.driving_time != "{"
                            ? parseFloat(
                                this.props.selectedVehicle.driving_time
                              ).toFixed(0)
                            : "0"
                        } `}
                        {
                          <LangContext.Consumer>
                            {(i18n) => i18n.m.min}
                          </LangContext.Consumer>
                        }
                      </td>
                    </tr>
                  </tbody>
                </table>
              </TabPane>
              <TabPane tab="Support" key="30c">
                <table className="tracking-detail-table">
                  <tbody>
                    <tr>
                      <th>
                        {
                          <LangContext.Consumer>
                            {(i18n) => i18n.b.boxID}
                          </LangContext.Consumer>
                        }
                      </th>
                      <td>{this.props.selectedVehicle.device_code}</td>
                    </tr>
                    <tr>
                      <th>
                        {
                          <LangContext.Consumer>
                            {(i18n) => i18n.u.updatePictures}
                          </LangContext.Consumer>
                        }
                      </th>
                      <td>
                        {helper_convertDateformat(
                          this.props.selectedVehicle.gps_image_active_at,
                          "YYYY-MM-DD HH:mm",
                          "DD/MM/YY HH:mm"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        {
                          <LangContext.Consumer>
                            {(i18n) => i18n.u.updateLocation}
                          </LangContext.Consumer>
                        }
                      </th>
                      <td>
                        {helper_convertDateformat(
                          this.props.selectedVehicle.latest_pos_update_at,
                          "YYYY-MM-DD HH:mm",
                          "DD/MM/YY HH:mm"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        {
                          <LangContext.Consumer>
                            {(i18n) => i18n.c.connection}
                          </LangContext.Consumer>
                        }
                      </th>
                      <td>
                        {helper_convertDateformat(
                          this.props.selectedVehicle.gps_active_at,
                          "YYYY-MM-DD HH:mm",
                          "DD/MM/YY HH:mm"
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </TabPane>

              <TabPane
                tab={
                  <LangContext.Consumer>
                    {(i18n) => i18n.t.trip}
                  </LangContext.Consumer>
                }
                key="50d"
              >
                <table className="tracking-detail-table">
                  <tbody>
                    <tr>
                      <th>
                        {
                          <LangContext.Consumer>
                            {(i18n) => i18n.t.tripStatus}
                          </LangContext.Consumer>
                        }
                      </th>
                      <td>{this.props.selectedVehicle.trip_status}</td>
                    </tr>
                    <tr>
                      <th>
                        {
                          <LangContext.Consumer>
                            {(i18n) => i18n.s.startTrip}
                          </LangContext.Consumer>
                        }
                      </th>
                      <td>{this.props.selectedVehicle.start_trip}</td>
                    </tr>
                    <tr>
                      <th>
                        {
                          <LangContext.Consumer>
                            {(i18n) => i18n.d.deliveryStatus}
                          </LangContext.Consumer>
                        }
                      </th>
                      <td>{this.props.selectedVehicle.LDT}</td>
                    </tr>
                  </tbody>
                </table>
              </TabPane>
              <TabPane
                tab={
                  <LangContext.Consumer>
                    {(i18n) => i18n.e.etc}
                  </LangContext.Consumer>
                }
                key="40d"
              >
                <table className="tracking-detail-table">
                  <tbody>
                    <tr>
                      <th>
                        {
                          <LangContext.Consumer>
                            {(i18n) => i18n.t.taxiMeter}
                          </LangContext.Consumer>
                        }
                      </th>
                      <td>
                        {this.props.selectedVehicle.meter_status == "on"
                          ? "On"
                          : "Off"}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        {
                          <LangContext.Consumer>
                            {(i18n) => i18n.p.passenger}
                          </LangContext.Consumer>
                        }
                      </th>
                      <td>{this.props.selectedVehicle.passenger_count}</td>
                    </tr>
                    <tr>
                      <th>
                        {
                          <LangContext.Consumer>
                            {(i18n) => i18n.m.mixingDrum}
                          </LangContext.Consumer>
                        }
                      </th>
                      <td>{this.props.selectedVehicle.drum_status}</td>
                    </tr>
                    <tr>
                      <th>
                        {
                          <LangContext.Consumer>
                            {(i18n) => i18n.o.obd}
                          </LangContext.Consumer>
                        }
                      </th>
                      <td>{this.props.selectedVehicle.OBD}</td>
                    </tr>
                    <tr>
                      <th>
                        {
                          <LangContext.Consumer>
                            {(i18n) => i18n.p.pto}
                          </LangContext.Consumer>
                        }
                      </th>
                      <td>{this.props.selectedVehicle.PTO}</td>
                    </tr>
                    <tr>
                      <th>
                        {
                          <LangContext.Consumer>
                            {(i18n) => i18n.b.BOOM}
                          </LangContext.Consumer>
                        }
                      </th>
                      <td>{this.props.selectedVehicle.boom}</td>
                    </tr>
                    <tr>
                      <th>
                        {
                          <LangContext.Consumer>
                            {(i18n) => i18n.g.gate1}
                          </LangContext.Consumer>
                        }
                      </th>
                      <td>{this.props.selectedVehicle.door1}</td>
                    </tr>
                    <tr>
                      <th>
                        {
                          <LangContext.Consumer>
                            {(i18n) => i18n.g.gate2}
                          </LangContext.Consumer>
                        }
                      </th>
                      <td>{this.props.selectedVehicle.door2}</td>
                    </tr>
                    <tr>
                      <th>
                        {
                          <LangContext.Consumer>
                            {(i18n) => i18n.g.gate3}
                          </LangContext.Consumer>
                        }
                      </th>
                      <td>{this.props.selectedVehicle.door3}</td>
                    </tr>
                    <tr>
                      <th>
                        {
                          <LangContext.Consumer>
                            {(i18n) => i18n.t.temperature1}
                          </LangContext.Consumer>
                        }
                      </th>
                      <td>{this.props.selectedVehicle.temperature1}</td>
                    </tr>
                    <tr>
                      <th>
                        {
                          <LangContext.Consumer>
                            {(i18n) => i18n.t.temperature2}
                          </LangContext.Consumer>
                        }
                      </th>
                      <td>{this.props.selectedVehicle.temperature2}</td>
                    </tr>
                    <tr>
                      <th>
                        {
                          <LangContext.Consumer>
                            {(i18n) => i18n.r.rpm}
                          </LangContext.Consumer>
                        }
                      </th>
                      <td>{this.props.selectedVehicle.RPM}</td>
                    </tr>
                    <tr>
                      <th>
                        {
                          <LangContext.Consumer>
                            {(i18n) => i18n.b.battery}
                          </LangContext.Consumer>
                        }
                      </th>
                      <td>{this.props.selectedVehicle.battery}</td>
                    </tr>
                  </tbody>
                </table>
              </TabPane>
            </Tabs>
          </Col>
        </Row>
        <Row>
          <Col
            span={23}
            align="right"
            style={{
              margin: "15px 15px 10px 0px",
            }}
          >
            <ButtonGroup>
              <Button type="primary" size="small">
                <a
                  href={`http://www.google.com/maps?layer=c&cbll=${this.props.selectedVehicle.lat},${this.props.selectedVehicle.lng}`}
                  target="_blank"
                >
                  Google Street View
                </a>
              </Button>
              {checkPermissions(
                this.props.permissions,
                "location",
                "can_add"
              ) == true ? (
                <Button
                  type="primary"
                  size="small"
                  icon="plus"
                  onClick={this.handleLinkCreateLocaion}
                >
                  <span></span>
                  <LangContext.Consumer>
                    {(i18n) => i18n.l.location}
                  </LangContext.Consumer>
                </Button>
              ) : (
                ""
              )}
            </ButtonGroup>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Popup;
