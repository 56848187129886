import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";

function* loadMasterDataPlace(action) {
  const {
    data: {
      page,
      pageSize,
      searchName,
      companyID,
      orderBy,
      orderType,
      accessToken,
      filterObj,
      permissions_add,
      location_visibility
    }
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/masterDataPlace`,
      // `http://booking-api/api/customer`,
      {
        searchName,
        page,
        pageSize,
        companyID,
        orderBy,
        orderType,
        filterObj,
        permissions_add,
        location_visibility
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );

    yield put(actions.loadMasterDataPlace.success(res.data, page));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadMasterDataPlace.failure());
  }
}

function* createMasterDataPlace(action) {
  const {
    data: {
      companyID,
      type,
      facillityName1,
      facillityName2,
      facillityName3,
      facillityCode,
      resultLat,
      resultLng,
      geoFene,
      rad,
      address,
      description,
      gallery_id,
      accessToken
    },
    loading
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/createLocation`,
      // `${DEV_API_ENDPOINT}/customer/create`,
      {
        companyID,
        type,
        facillityName1,
        facillityName2,
        facillityName3,
        facillityCode,
        resultLat,
        resultLng,
        geoFene,
        rad,
        address,
        description,
        gallery_id,
        accessToken
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    loading(res.data);
    yield put(actions.createMasterDataPlace.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.createMasterDataPlace.failure());
  }
}

function* loadDetailMasterDataPlace(action) {
  const {
    data: { id, accessToken }
  } = action.payload;

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/detailMasterDataPlace/${id}`,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );

    yield put(actions.loadDetailMasterDataPlace.success(res.data));
  } catch (err) {
    console.log(err.message);

    if (err.response.status === 403) {
      window.location.href = "/";
    }
    
    yield put(actions.loadDetailMasterDataPlace.failure());
  }
}

function* loadDetailMasterDataPlaceEdit(action) {
  const {
    data: { id, accessToken }
  } = action.payload;

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/detailMasterDataPlace/${id}`,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );

    yield put(actions.loadDetailMasterDataPlaceEdit.success(res.data));
  } catch (err) {
    console.log(err.message);
    
    if (err.response.status === 403) {
      window.location.href = "/";
    }

    yield put(actions.loadDetailMasterDataPlaceEdit.failure());
  }
}

function* upDateDetailMasterDataPlace(action) {
  const {
    data: {
      location_id,
      companyID,
      type,
      facillityName1,
      facillityName2,
      facillityName3,
      facillityCode,
      resultLat,
      resultLng,
      geoFene,
      rad,
      address,
      description,
      gallery_id,
      accessToken
    },
    loading
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/editMasterDataPlace`,
      {
        location_id,
        companyID,
        type,
        facillityName1,
        facillityName2,
        facillityName3,
        facillityCode,
        resultLat,
        resultLng,
        geoFene,
        rad,
        address,
        description,
        gallery_id
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    loading(res.data);
    yield put(actions.upDateDetailMasterDataPlace.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.upDateDetailMasterDataPlace.failure());
  }
}
export default function* watchPlaceMasterData() {
  yield all([
    takeEvery(actions.LOAD_MASTERDATA_PLACE.REQUEST, loadMasterDataPlace),
    takeEvery(actions.CREATE_MASTERDATAPLACE.REQUEST, createMasterDataPlace),
    takeEvery(
      actions.LOAD_DETAILMASTERDATA_PLACE.REQUEST,
      loadDetailMasterDataPlace
    ),
    takeEvery(
      actions.LOAD_DETAILMASTERDATA_PLACE_EDIT.REQUEST,
      loadDetailMasterDataPlaceEdit
    ),
    takeEvery(
      actions.UPDATE_DETAILMASTERDATA_PLACE.REQUEST,
      upDateDetailMasterDataPlace
    )
  ]);
}
export {
  loadMasterDataPlace,
  createMasterDataPlace,
  loadDetailMasterDataPlace,
  loadDetailMasterDataPlaceEdit,
  upDateDetailMasterDataPlace
};
