import axios from "axios";

// Define your username and password
const username = "eventdasbbaord";
const password = "66e49accea2f72036676811d72aa6d51";

// Encode the username and password for the Basic Auth header
const authHeader =
  "Basic " + Buffer.from(`${username}:${password}`).toString("base64");

const axiosClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_EVENT_DASHBOARD}`,
  //baseURL: `${`http://localhost:8005/api`}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: authHeader,
  },
});

export async function loadTopic(obj, callback) {
  try {
    const res = await axiosClient.get(
      `/user/topics?user_id=${obj.userId}&company_id=${obj.company_id}`
    );
    callback(200, res);
  } catch (error) {
    handleError(error, callback);
  }
}

export async function eventDetail(params, callback) {
  try {
    const res = await axiosClient.post(`/event/detail`, params);
    callback(200, res);
  } catch (error) {
    handleError(error, callback);
  }
}

const axiosClientFromWeb = axios.create({
  baseURL: `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("access-token")}`,
  },
});

// Centralized error handling function (example)
const handleError = (error, callback) => {
  // Handle error here or pass it to callback

  console.error("API Error:", error);
  //   callback(error.response?.status, error.response?.data);
};

export async function loadPolicyType(type, params, callback) {
  try {
    const res = await axiosClientFromWeb.get(`/policy-type${type}`, params);
    callback(200, res);
  } catch (error) {
    handleError(error);
  }
}

export async function loadVehicleGroup(params, callback) {
  try {
    const res = await axiosClientFromWeb.post(
      "/vehicle-group/vehicle-group-by-company",
      params
    );
    callback(200, res);
  } catch (error) {
    handleError(error);
  }
}

export async function loadVehicleByPolicy(params, callback) {
  try {
    const res = await axiosClient.post("/user/vehicles", params);
    callback(200, res);
  } catch (error) {
    handleError(error);
  }
}

// API functions using generic request function
export async function loadPolicy(params, callback) {
  try {
    const res = await axiosClientFromWeb.post("/new-policy", params);
    callback(200, res);
  } catch (error) {
    handleError(error);
  }
}

export async function loadCompanyList(params, callback) {
  try {
    const res = await axiosClient.get(`/getcompanies`, {});
    callback(200, res);
  } catch (error) {
    handleError(error);
  }
}
export default axiosClientFromWeb;
