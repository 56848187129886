import React, { Component, createRef, Fragment } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import ReactCountryFlag from "react-country-flag";
import {
  Row,
  Col,
  Layout,
  Menu,
  Icon,
  Modal,
  AutoComplete,
  Input,
  Select,
  Button,
  Dropdown,
  Avatar,
} from "antd";
import { ReactComponent as ManualsIcon } from "../assets/Manual.svg";
import {
  BREAKPOINTS,
  RESPONSIVE_COMPARE,
  withResponsive,
} from "../../shared/context/ResponsiveContext";
// import NotificationReceiver from "./NotificationReceiver";
import CustomBadge from "./CustomBadge";
import Notification from "./Notification";
import LangContext, { i18n } from "modules/shared/context/langContext";
import * as authActions from "modules/auth/actions";
import {
  clearFlashMessage,
  loadNotificationCount,
  unregisterNotificate,
  removeUIState,
} from "modules/ui/actions";
import { messaging } from "../../../lib/fcm/fcm_init";

import styled from "styled-components";

import axios from "axios";
import { dataSource } from "./constant";
import { getURLByMatchingName, createAxios } from "../fetchAPI/fetchAPI";
import { el } from "date-fns/locale";
import { StyledImageHeader } from "../../../styled/common-styled";
import { breakpoints } from "../../shared/context/ResponsiveContext";
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;
const { Header: H } = Layout;
const StyledHearder = styled(H)`
  background: white;
  height: 45px;
  align-content: center;

  @media (max-width: ${breakpoints[BREAKPOINTS.SM]}px) {
    padding: 0 16px;
  }

  @media (min-width: ${breakpoints[BREAKPOINTS.MD]}px) {
    padding: 0 50px 0 12px;
  }
`;

const ManualButton = styled("div")({
  display: "flex",
  cursor: "pointer",
  alignItems: "center",
  gap: "8px",
  ":hover": {
    color: "#1890ff",
  },
  ":hover > svg": {
    fill: "#1890ff",
  },
});

const { Option, OptGroup } = AutoComplete;

class Header extends Component {
  constructor() {
    super();
  }

  state = {
    notiCount: 0,
    haveNewNoti: false,
    inputLng: "",
    token: "",
    searchText: "",
    menu: "",
    url: null,
  };

  _isMounted = false;

  onRemoveCredentials = () => {
    if (typeof this.props.profile !== "undefined") {
      this.updateUserHistoryLog();
    }
    this.props.unregisterNotificate({
      username: this.props.profile.username,
    });
    this.props.removeCredentials();
    this.props.removeUIState();
  };

  updateUserHistoryLog = () => {
    axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/updatelogonhistory`,
        {
          id: this.props.profile.user_history_logon_id,
        },
        {
          headers: { Authorization: `Bearer ${this.props.accessToken}` },
        }
      )
      .then((res) => {
        console.log("update logon history success");
      });
  };

  onDismiss = () => {
    this.props.clearFlashMessage();
  };

  componentDidMount() {
    createAxios(this.props.accessToken);

    this._isMounted = true;
    let self = this;
    let { tokenExp } = this.props;
    let timeNow = Date.now();

    if (typeof tokenExp == "undefined" || tokenExp == null || tokenExp == "") {
      setTimeout(function() {
        self.modal(
          <LangContext.Consumer>
            {(i18n) => i18n.n.notification}
          </LangContext.Consumer>,
          <LangContext.Consumer>
            {(i18n) => i18n.t.tokenExpired}
          </LangContext.Consumer>
        );
      }, 500);
    }
    if (Math.floor(timeNow / 1000) < tokenExp) {
      let diffTime = (tokenExp - Math.floor(timeNow / 1000)) * 1000;
      this.tokenExpTimeout = setTimeout(function() {
        self.modal(
          <LangContext.Consumer>
            {(i18n) => i18n.n.notification}
          </LangContext.Consumer>,
          <LangContext.Consumer>
            {(i18n) => i18n.t.tokenExpired}
          </LangContext.Consumer>
        );
      }, diffTime);
    }

    this.setState({
      inputLng: "th",
    });

    // if (messaging) {
    //   messaging
    //     .getToken()
    //     .then((token) => {
    //       this.setToken(token);
    //     })
    //     .catch((err) => {
    //       console.log("Unable to retrieve refreshed token ", err);
    //     });
    // }

    const result = this.findPath(dataSource, window.location.pathname);

    if (result != null) {
      getURLByMatchingName(
        { match_name: result.matching_name },
        (status, res) => {
          if (res.data.data == null) {
            this.setState({ url: null });
          } else {
            this.setState({ url: res.data.data.manual_link });
          }
        }
      );
    } else {
      this.setState({ url: null });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.headerUrl != this.props.headerUrl) {
      return true;
    }

    if (
      prevState.url != this.state.url ||
      prevProps.location.pathname != window.location.pathname
    ) {
      const result = this.findPath(dataSource, window.location.pathname);
      if (result != null) {
        getURLByMatchingName(
          { match_name: result.matching_name },
          (status, res) => {
            if (res.data.data == null) {
              this.setState({ url: null });
            } else {
              this.setState({ url: res.data.data.manual_link });
            }
          }
        );
      } else {
        this.setState({ url: null });
      }
    }
  }

  findPath = (data, searchPath) => {
    for (const item of data) {
      // Check if the current item's path matches the searchPath
      if (item.path === searchPath) {
        return item;
      }
      // If the item has children, search recursively
      if (item.children) {
        const result = this.findPath(item.children, searchPath);
        if (result) {
          return result;
        }
      }
    }
    return null; // Return null if no match is found
  };

  setToken(token) {
    this.setState({
      ...this.state,
      token,
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.tokenExpTimeout);
  }

  checkSelectLang = (inputLng) => {
    this.props.changeLang(inputLng);
    this.props.storeLang(inputLng);
    if (inputLng == "en") {
      this.setState({
        inputLng: "us",
      });
    } else {
      this.setState({
        inputLng: "th",
      });
    }
  };

  modal = (title, content) => {
    this.onRemoveCredentials();
    Modal.warning({
      title: title,
      okText: "Ok",
      okType: "default",
      onOk() {
        // window.location.href = "/";
      },
      content: content,
    });
  };

  handleSearch = (value) => {
    this.setState({ searchText: value });
  };

  render() {
    const {
      accessToken,
      done,
      flash,
      collapsed,
      toggle,
      profile,
      language,
      isBreakpoint,
    } = this.props;
    const { permissions } = this.props.profile;

    const isMobile = isBreakpoint(RESPONSIVE_COMPARE.ONLY, BREAKPOINTS.XS);

    function filterByLabel(data, input) {
      const result = [];

      data.forEach((item) => {
        const label =
          typeof item.label == "string"
            ? item.label
            : item.label(i18n[language]) || ".";

        if (
          typeof label == "string" &&
          label.toLowerCase().includes(input.toLowerCase()) &&
          permissions.some(
            (p) => p.can_view && p.matching_name === item.matching_name
          )
        ) {
          result.push(item);
        } else if (item.children) {
          const filteredChildren = filterByLabel(item.children, input);
          if (filteredChildren.length) {
            result.push({ ...item, children: filteredChildren });
          }
        }
      });

      return result;
    }

    const getLabel = (child) =>
      typeof child.label == "string"
        ? child.label
        : child.label(i18n[language]) || ".";

    const getChild = (childs) => {
      const result = [];

      childs.forEach((group) =>
        group.children
          ? result.push(
              <OptGroup key={getLabel(group)} label={getLabel(group)}>
                {group.children.map((item) => {
                  const res = [];
                  if (item.children) {
                    res.push(
                      <OptGroup key={getLabel(item)} label={getLabel(item)}>
                        {item.children.map((children) => (
                          <Option
                            key={getLabel(children)}
                            value={children.path}
                          >
                            {getLabel(children)}
                          </Option>
                        ))}
                      </OptGroup>
                    );
                  } else {
                    res.push(
                      <Option key={getLabel(item)} value={item.path}>
                        {getLabel(item)}
                      </Option>
                    );
                  }
                  return res;
                })}
              </OptGroup>
            )
          : result.push(
              <Option key={getLabel(group)} value={group.path}>
                {getLabel(group)}
              </Option>
            )
      );

      return result;
    };

    const options = getChild(filterByLabel(dataSource, this.state.searchText));

    const CheLan = (
      <LangContext.Consumer>
        {(i18n) => i18n.changeLanguage}
      </LangContext.Consumer>
    );

    return (
      <Fragment>
        <StyledHearder>
          <audio id="audio-alert">
            <source src="/audio/alert/serious-strike.mp3" type="audio/mpeg" />
          </audio>
          <audio id="audio-sos">
            <source src="/audio/alert/sos.mp3" type="audio/mpeg" />
          </audio>
          <audio id="audio-notification">
            <source
              src="/audio/notification/slow-spring-board.mp3"
              type="audio/mpeg"
            />
          </audio>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              height: 45,
              gap: 24,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "row-reverse" : "row",
                alignItems: "center",
                gap: 16,
              }}
            >
              <Link to="/">
                <img
                  style={{
                    width: "80px",
                    cursor: "pointer",
                    verticalAlign: "sub",
                  }}
                  src="/img/logo2.png"
                  alt=""
                />
              </Link>

              <Icon
                style={{ marginTop: "auto", marginBottom: "auto" }}
                className="trigger"
                type={collapsed ? "menu-unfold" : "menu-fold"}
                onClick={toggle}
              />
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                maxWidth: "500px",
              }}
            >
              <AutoComplete
                size="large"
                style={{ width: "100%" }}
                dataSource={options}
                value={this.state.menu}
                placeholder={i18n[language].s.searchMenu}
                optionLabelProp="value"
                onSearch={this.handleSearch}
                onChange={(value) => this.setState({ menu: value })}
                onSelect={(value) => {
                  this.props.history.push(value);
                  this.setState({ menu: "" });
                }}
              >
                <Input
                  suffix={
                    <Icon type="search" className="certain-category-icon" />
                  }
                />
              </AutoComplete>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: 16,
              }}
            >
              {this.state.url != null && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ManualButton
                    onClick={() =>
                      window.open(this.state.url, "_blank").focus()
                    }
                  >
                    <ManualsIcon />
                    {!isMobile && "คู่มือ"}
                  </ManualButton>
                </div>
              )}

              {isMobile ? (
                <>
                  <Dropdown
                    trigger={["click"]}
                    overlay={
                      <Menu>
                        <Menu.Item
                          key="en"
                          selected={true}
                          style={{ textAlign: "center" }}
                        >
                          <span
                            className="nav-text"
                            onClick={() => this.checkSelectLang("en")}
                          >
                            <ReactCountryFlag
                              styleProps={{ width: "20px", height: "20px" }}
                              code="us"
                              svg
                            />
                          </span>
                        </Menu.Item>
                        <Menu.Item
                          key="th"
                          selected={true}
                          style={{ textAlign: "center" }}
                        >
                          <span
                            className="nav-text"
                            onClick={() => this.checkSelectLang("th")}
                          >
                            <ReactCountryFlag
                              styleProps={{ width: "20px", height: "20px" }}
                              code="th"
                              svg
                            />
                          </span>
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <Button type="link" style={{ color: "#000", padding: 0 }}>
                      <ReactCountryFlag
                        styleProps={{
                          width: "20px",
                          height: "20px",
                        }}
                        code={this.state.inputLng}
                        svg
                      />
                    </Button>
                  </Dropdown>

                  <Dropdown
                    trigger={["click"]}
                    overlay={
                      <Menu style={{ width: "200px" }}>
                        <MenuItemGroup
                          title={
                            <LangContext.Consumer>
                              {(i18n) => i18n.t.titleAccount}
                            </LangContext.Consumer>
                          }
                        >
                          <Menu.Item
                            key="setting:1"
                            style={{ paddingLeft: "14px" }}
                          >
                            <Icon type="home" />
                            <LangContext.Consumer>
                              {(i18n) => i18n.h.home}
                            </LangContext.Consumer>
                            <Link to="/" />
                          </Menu.Item>
                          <Menu.Item
                            key="setting:2"
                            style={{ paddingLeft: "14px" }}
                          >
                            <Icon type="user" />
                            <LangContext.Consumer>
                              {(i18n) => i18n.p.profile}
                            </LangContext.Consumer>
                            <Link to="/profile" />
                          </Menu.Item>
                          {done ? (
                            <Menu.Item
                              key="menu:1"
                              style={{ paddingLeft: "14px" }}
                              onClick={this.onRemoveCredentials}
                            >
                              <Icon type="logout" />
                              <LangContext.Consumer>
                                {(i18n) => i18n.l.logout}
                              </LangContext.Consumer>
                              <Link to="/" />
                            </Menu.Item>
                          ) : (
                            <Menu.Item key="menu:1">
                              <LangContext.Consumer>
                                {(i18n) => i18n.l.login}
                              </LangContext.Consumer>
                              <Link to="/" />
                            </Menu.Item>
                          )}
                        </MenuItemGroup>
                      </Menu>
                    }
                  >
                    <Avatar icon="user" size={28} />
                  </Dropdown>
                </>
              ) : (
                <div style={{ maxWidth: "100%" }}>
                  <Menu
                    theme="light"
                    mode="horizontal"
                    style={{ height: "46px" }}
                    align="right"
                  >
                    <SubMenu
                      title={
                        <span>
                          <ReactCountryFlag
                            styleProps={{
                              margin: "0px 15px 5px 0px",
                              width: "20px",
                              height: "20px",
                            }}
                            code={this.state.inputLng}
                            svg
                          />
                          <Icon type="caret-down" />
                        </span>
                      }
                    >
                      <MenuItemGroup title={CheLan}>
                        <Menu.Item key="en" selected={true}>
                          <span
                            className="nav-text"
                            onClick={() => this.checkSelectLang("en")}
                          >
                            <ReactCountryFlag
                              styleProps={{
                                margin: "0px 15px 5px 0px",
                                width: "20px",
                                height: "20px",
                              }}
                              code="us"
                              svg
                            />
                            English
                          </span>
                        </Menu.Item>
                        <Menu.Item key="th" selected={true}>
                          <span
                            className="nav-text"
                            onClick={() => this.checkSelectLang("th")}
                          >
                            <ReactCountryFlag
                              styleProps={{
                                margin: "0px 15px 5px 0px",
                                width: "20px",
                                height: "20px",
                              }}
                              code="th"
                              svg
                            />
                            ไทย
                          </span>
                        </Menu.Item>
                      </MenuItemGroup>
                    </SubMenu>

                    <SubMenu
                      title={
                        <div>
                          <Row>
                            {" "}
                            <Col xs={24} sm={0} md={0} lg={0} xl={0}>
                              <Icon type="user" />
                            </Col>{" "}
                          </Row>
                          <Row>
                            <Col xs={0} sm={24} md={24} lg={24} xl={24}>
                              <Icon type="user" />
                              {
                                JSON.parse(localStorage.getItem("profile"))
                                  .username
                              }
                            </Col>
                          </Row>
                        </div>
                      }
                    >
                      <MenuItemGroup
                        title={
                          <LangContext.Consumer>
                            {(i18n) => i18n.t.titleAccount}
                          </LangContext.Consumer>
                        }
                      >
                        <Menu.Item
                          key="setting:1"
                          style={{ paddingLeft: "14px" }}
                        >
                          <Icon type="home" />
                          <LangContext.Consumer>
                            {(i18n) => i18n.h.home}
                          </LangContext.Consumer>
                          <Link to="/" />
                        </Menu.Item>
                        <Menu.Item
                          key="setting:2"
                          style={{ paddingLeft: "14px" }}
                        >
                          <Icon type="user" />
                          <LangContext.Consumer>
                            {(i18n) => i18n.p.profile}
                          </LangContext.Consumer>
                          <Link to="/profile" />
                        </Menu.Item>
                        {done ? (
                          <Menu.Item
                            key="menu:1"
                            style={{ paddingLeft: "14px" }}
                            onClick={this.onRemoveCredentials}
                          >
                            <Icon type="logout" />
                            <LangContext.Consumer>
                              {(i18n) => i18n.l.logout}
                            </LangContext.Consumer>
                            <Link to="/" />
                          </Menu.Item>
                        ) : (
                          <Menu.Item key="menu:1">
                            <LangContext.Consumer>
                              {(i18n) => i18n.l.login}
                            </LangContext.Consumer>
                            <Link to="/" />
                          </Menu.Item>
                        )}
                      </MenuItemGroup>
                    </SubMenu>
                  </Menu>
                </div>
              )}
            </div>
          </div>
        </StyledHearder>
        <Row type="flex" justify="end" style={{ margin: "0px" }} />
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  auth: { accessToken, done, profile, tokenExp },
  ui: { flash, lastestNotificationAt, language },
}) => ({
  accessToken,
  done,
  profile,
  flash,
  lastestNotificationAt,
  tokenExp,
  language,
});
const mapDispatchToProps = {
  removeCredentials: authActions.removeCredentials,
  removeUIState: removeUIState,
  loadCredentials: authActions.loadCredentials,
  clearFlashMessage: clearFlashMessage,
  loadNotificationCount: loadNotificationCount.request,
  unregisterNotificate: unregisterNotificate.request,
  storeLang: authActions.changeLang,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withResponsive(Header))
);
