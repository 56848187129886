import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import { StyledSearchForm } from "../../../../styled/common-styled";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import LangContext, { i18n } from "modules/shared/context/langContext";
import FormDividerCenter from "../../../shared/components/FormDividerCenter";
import {
  Button,
  Table,
  Row,
  Col,
  Pagination,
  Select,
  Spin,
  Badge,
  Modal,
  Popconfirm,
  Upload,
  Input,
  Divider,
  Tooltip,
  message,
  Form,
  Icon,
} from "antd";
const columnStyle = { fontSize: "8px", fontWeight: "320" };

class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null,
      currentPage: 1,
      page: 1,
      pageSize: 10,
    };

    this.columns = [
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.n.notification}
          </LangContext.Consumer>
        ),
        dataIndex: "address",
        render(text, record, index) {
          let last_date = "";
          let unit = "";

          if (record.last_day != 0) {
            last_date = record.last_day;
            unit = "day";
          } else if (record.last_day == 0 && record.last_hourt != 0) {
            last_date = record.last_hourt;
            unit = "hr";
          } else if (record.last_hourt == 0) {
            last_date = record.last_min;
            unit = "min";
          }
          return {
            children: (
              <div>
                <Tooltip
                  placement="top"
                  title={record.end_at}
                  arrowPointAtCenter
                >
                  <Row>
                    <Col span={19}>{record.body}</Col>
                    <Col span={5}>
                      <div align="right">
                        {record.is_read == false ? (
                          <Badge status="processing" />
                        ) : (
                          ""
                        )}
                        <span style={{ marginLeft: "5px" }}></span>
                        <font color="#C4B9B9">
                          {last_date}
                          {unit == "day" ? (
                            <LangContext.Consumer>
                              {(i18n) => i18n.d.day}
                            </LangContext.Consumer>
                          ) : unit == "hr" ? (
                            <LangContext.Consumer>
                              {(i18n) => i18n.h.hours}
                            </LangContext.Consumer>
                          ) : unit == "min" ? (
                            <LangContext.Consumer>
                              {(i18n) => i18n.m.min}
                            </LangContext.Consumer>
                          ) : (
                            ""
                          )}
                        </font>
                      </div>
                    </Col>
                  </Row>
                </Tooltip>
              </div>
            ),
          };
        },
      },
    ];
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const columns = this.columns.map((col) => col);

    return (
      <Row
        style={{
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        <FormDividerCenter>
          <LangContext.Consumer>
            {(i18n) => i18n.n.notification}
          </LangContext.Consumer>
        </FormDividerCenter>

        <Table
          showHeader={false}
          rowKey="driver_user_id"
          columns={columns}
          dataSource={this.props.dataSource}
          size="small"
          pagination={false}
          // scroll={{ y: `${window.innerHeight - 545}px`}}
          rowClassName={(record) => (record.is_read == false ? "#00afec" : "")}
          onRow={(selectedRowKeys, selectedRows) => ({
            onClick: () => {
              this.props.handelIsRead(selectedRowKeys, selectedRows);
            },
            style: {
              background:
                selectedRowKeys === this.state.selected ? "#00afec" : "white",
              color:
                selectedRowKeys === this.state.selected ? "white" : "black",
            },
          })}
        />
      </Row>
    );
  }
}
export default connect(({ auth }) => ({ auth }), {})(Notification);
