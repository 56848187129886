import React, { useState, useEffect } from "react";
import {
  Button,
  Row,
  Col,
  Icon,
  Form,
  Divider,
  Transfer,
  Modal,
  Select,
  Spin,
} from "antd";
import LangContext from "modules/shared/context/langContext";
import { Formik } from "formik";
import {
  loadUserByUserGroup,
  loadUserGroup,
} from "modules/setting/policynew/fetchAPI/apiClient";
const Option = Select.Option;

const PolicyHasUser = ({
  current,
  prev,
  createPolicy,
  type,
  userSelected,
  profile,
}) => {
  const [visibleModalConfirm, setVisibleModalConfirm] = useState(false);
  const [userSource, setUserSource] = useState([]);
  const [userGroup, setuserGroup] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    loadData();
  }, [current]);

  const loadData = () => {
    loadUserByUserGroup(
      {
        company_id: profile.company_id,
        usergroup_id: "",
      },
      (status, res) => {
        setUserSource(res.data.data);
        setLoading(false);
      }
    );

    loadUserGroup(profile.company_id, (status, res) => {
      setuserGroup(res.data.data);
      setLoading(false);
    });
  };

  const sortUser = (item1, item2) => {
    if (item1.disabled === item2.disabled) {
      return 0;
    }
    return item1.disabled ? 1 : -1;
  };

  const getTargetKey = (values) => {
    let targetKey = values.userSource
      .filter((x) => values.userSelected.includes(x.key))
      .sort(sortUser)
      .map((x) => x.key);
    return targetKey;
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Spin spinning={loading}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              userGroupId: "",
              userSource: userSource,
              userSelected: type == "edit" ? userSelected : [],
            }}
            onSubmit={(values, { resetForm }) => {
              setVisibleModalConfirm(true);
            }}
            render={({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              handleFocus,
              setFieldValue,
              resetForm,
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Row gutter={[24, 24]}>
                    <Col span={12}>
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <div style={{ fontSize: 16, fontWeight: "bold" }}>
                            <LangContext.Consumer>
                              {(i18n) => i18n.u.userGroup}
                            </LangContext.Consumer>
                          </div>
                          <Select
                            style={{ width: "100%" }}
                            placeholder="กรุณาเลือก"
                            optionFilterProp="children"
                            value={values.userGroupId}
                            onChange={(e) => {
                              setFieldValue("userGroupId", e);
                              loadUserByUserGroup(
                                {
                                  company_id: profile.company_id,
                                  usergroup_id: e,
                                },
                                (statusCode, response) => {
                                  let userGroupHasUserId = response.data.data.map(
                                    (x) => x.key
                                  );
                                  let newArr = userSource.map((item) => {
                                    return {
                                      key: item.key,
                                      title: item.title,
                                      disabled: !userGroupHasUserId.includes(
                                        item.key
                                      ),
                                      user_id: item.user_id,
                                    };
                                  });

                                  const sortedArray = newArr.sort(sortUser);

                                  setFieldValue("userSource", sortedArray);
                                }
                              );
                            }}
                          >
                            <Option key="0" value={""}>
                              <LangContext.Consumer>
                                {(i18n) => i18n.a.all}
                              </LangContext.Consumer>
                            </Option>
                            {userGroup.map((item) => (
                              <Option key={item.id.toString()} value={item.id}>
                                {item.name}
                              </Option>
                            ))}
                          </Select>
                          <Divider />

                          <Transfer
                            lazy={false}
                            dataSource={values.userSource}
                            titles={["เลือก", "เลือก"]}
                            targetKeys={getTargetKey(values)}
                            onChange={(nextTargetKeys) => {
                              setFieldValue("userSelected", nextTargetKeys);
                            }}
                            filterOption={(inputValue, item) => {
                              return (
                                item.title
                                  .toUpperCase()
                                  .indexOf(inputValue.toUpperCase()) !== -1
                              );
                            }}
                            render={(item) => item.title}
                            listStyle={{
                              width: "44%",
                              height: 400,
                            }}
                            showSearch
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Col span={24}>
                    <Button
                      onClick={() => {
                        prev();
                      }}
                      style={{ marginRight: 10 }}
                    >
                      <LangContext.Consumer>
                        {(i18n) => i18n.b.back}
                      </LangContext.Consumer>
                    </Button>
                    <Button htmlType="submit">
                      <LangContext.Consumer>
                        {(i18n) => i18n.s.submit}
                      </LangContext.Consumer>
                    </Button>
                  </Col>
                  <Modal
                    title={null}
                    centered
                    visible={visibleModalConfirm}
                    onCancel={() => setVisibleModalConfirm(false)}
                    footer={null}
                  >
                    <Row gutter={[16, 16]}>
                      <Col span={24} align="center">
                        <Icon
                          type="info-circle"
                          style={{ fontSize: 50, color: "#3F87E4" }}
                        />
                      </Col>
                      <Col span={24} align="center">
                        <p
                          style={{
                            color: "black",
                            fontWeight: "bold",
                            fontSize: 24,
                            margin: 0,
                          }}
                        >
                          โปรดตรวจสอบข้อมูล
                        </p>
                      </Col>
                      <Col span={24} align="center">
                        <p style={{ margin: 0 }}>
                          โปรดตรวจสอบข้อมูลของท่านอีกครั้งก่อนบันทึกข้อมูล
                        </p>
                      </Col>
                      <Col span={24} align="center">
                        <p style={{ margin: 0 }}>
                          เพื่อความถูกต้องของข้อมูลท่าน
                        </p>
                      </Col>
                      <Col span={24} align="center">
                        <Button
                          style={{ width: 140, marginRight: 15 }}
                          onClick={() => setVisibleModalConfirm(false)}
                        >
                          <LangContext.Consumer>
                            {(i18n) => i18n.c.cancel}
                          </LangContext.Consumer>
                        </Button>
                        <Button
                          type="primary"
                          style={{ width: 140 }}
                          onClick={() => {
                            setVisibleModalConfirm(false);
                            createPolicy(values.userSelected);
                          }}
                        >
                          <LangContext.Consumer>
                            {(i18n) => i18n.c.confirm}
                          </LangContext.Consumer>
                        </Button>
                      </Col>
                    </Row>
                  </Modal>
                </Form>
              );
            }}
          />
        </Spin>
      </Col>
    </Row>
  );
};

export default PolicyHasUser;
